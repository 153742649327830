import {IListenContractsByUser} from 'instances/contracts/types/responses';

import React, {
    FC,
    useEffect,
} from 'react';

import {WebSocket} from 'tools/components';

import {isNeedToWatchContracts} from 'instances/auth/functions';
import {useAuth} from 'instances/auth/hooks';
import {INSTANCE_PATH} from 'instances/contracts/constants';
import {useContractsByUser} from 'instances/contracts/hooks';

const ListenContractsByUser: FC = () => {
    const {
        contracts,
        getContracts,
        updateContractsByListening,
    } = useContractsByUser();

    const {auth} = useAuth();

    const onMessage = (res: IListenContractsByUser) => {
        updateContractsByListening(res);
    };

    useEffect(() => {
        (async () => {
            if (contracts) {
                return;
            }
            if (!auth) {
                return;
            }
            if (!isNeedToWatchContracts(auth)) {
                return;
            }

            await getContracts();
        })();
    }, [auth]);

    if (!contracts) {
        return null;
    }
    if (!auth) {
        return null;
    }
    if (!isNeedToWatchContracts(auth)) {
        return null;
    }

    return (
        <WebSocket
            path={`${INSTANCE_PATH}/listen`}
            onMessage={onMessage}
        />
    );
};

export default ListenContractsByUser;
