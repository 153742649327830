import {ICheckboxFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA} from '../../intl';

const CheckboxField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [value, setValue] = useState(props.field.value);

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): ICheckboxFieldSpecificProps {
                return {
                    value,
                    validation: {},
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {};
            },
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <div className={'m-2'}>
            <MaterialCheckbox
                label={translateByIntl(props.intl, INTL_DATA.DEFAULT_VALUE, 'Default value')}
                value={value}
                onChange={(value) => setValue(value)}
            />
        </div>
    );
});

export default CheckboxField;
