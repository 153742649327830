import { put, call, takeLatest } from 'redux-saga/effects';

import fetches from './fetch';

import {
    GET_SITES_CONSTRUCTOR,
    GET_SITES_CONSTRUCTOR_SUCCEED,
    GET_SITES_BY_USER,
    GET_SITES_BY_USER_SUCCEED,
    GET_SITES_VERSION,
    GET_SITES_VERSION_SUCCEED,
    POST_SITES_CONSTRUCTOR,
    POST_SITES_CONSTRUCTOR_SUCCEED,
    SET_ACTIVE_SITES_CONSTRUCTOR,
    SET_ACTIVE_SITES_CONSTRUCTOR_SUCCEED,
} from './actions';


const sites = {};

function* fetchGet() {
    const sites = yield call(fetches.get);
    yield put({type: GET_SITES_CONSTRUCTOR_SUCCEED, sites});
}
sites.get = function*() {
    yield takeLatest(GET_SITES_CONSTRUCTOR, fetchGet);
};

function* fetchGetByUser() {
    const sites = yield call(fetches.getByUser);
    yield put({type: GET_SITES_BY_USER_SUCCEED, sites});
}
sites.getByUser = function*() {
    yield takeLatest(GET_SITES_BY_USER, fetchGetByUser);
};

function* fetchGetOne(action) {
    const site = yield call(fetches.getOne, action.version);
    yield put({type: GET_SITES_VERSION_SUCCEED, site});
}
sites.getOne = function*() {
    yield takeLatest(GET_SITES_VERSION, fetchGetOne);
};

function* fetchPost(action) {
    const site = yield call(fetches.post, action.site);
    yield put({type: POST_SITES_CONSTRUCTOR_SUCCEED, site});
}
sites.post = function*() {
    yield takeLatest(POST_SITES_CONSTRUCTOR, fetchPost);
};

function* fetchSetActive(action) {
    const version = yield call(fetches.setActive, action.version);
    yield put({type: SET_ACTIVE_SITES_CONSTRUCTOR_SUCCEED, version});
}
sites.setActive = function*() {
    yield takeLatest(SET_ACTIVE_SITES_CONSTRUCTOR, fetchSetActive);
};

export default sites;
