import {ISpecificForm} from '../../../FormField/types';
import {IContract} from '../../ContractField/types';
import {
    IDestination,
    IOrigin,
    IUserService,
} from '../types';
import {IProps} from './types';

import React, {
    forwardRef,
    useContext,
    useImperativeHandle,
    useState,
} from 'react';

import Destination from './Destination';
import Origin from './Origin';
import Services from './Services';

import {context} from '../../../../Provider';
import {formatFormTitle} from '../../functions';
import {
    DESTINATION_NEW_VALUE,
    MAX_NUMBER_OF_BILLS_PER_CONTRACT,
    MIGRATE_TO,
} from '../constants';

import {ERROR} from './constants';
import {formatError} from './functions';

const USMigrationField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [error, setError] = useState<ERROR | null>(null);

    const {
        contracts,
        serviceStatuses,
        getContractsByContractBill,
        getUniqueContractsWithoutBills,
        getUserServices,
    } = useContext(context);

    const onChangeOrigin = (origin: IOrigin) => {
        props.onChange(props.field.name, {
            ...props.field.value,
            origin,
            destination: props.field.newDestinationValueAsDefault ?
                DESTINATION_NEW_VALUE :
                props.field.value.destination,
        });
        setError(null);
    };
    const onChooseService = (chosenUserService: IUserService) => {
        const isFound = props.field.value.userServices.find((userService) => userService.id === chosenUserService.id);

        const userServices = isFound ?
            props.field.value.userServices.filter((userService) => userService.id !== chosenUserService.id) :
            [...props.field.value.userServices, chosenUserService];

        props.onChange(props.field.name, {
            ...props.field.value,
            userServices,
        });
        setError(null);
    };
    const onChooseServices = (userServices: IUserService[]) => {
        props.onChange(props.field.name, {
            ...props.field.value,
            userServices,
        });
        setError(null);
    };
    const onChangeDestination = (destination: IDestination) => {
        props.onChange(props.field.name, {
            ...props.field.value,
            destination,
        });
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    const isEmpty =
                        !props.field.value.origin ||
                        !props.field.value.userServices.length ||
                        !props.field.value.destination;

                    if (isEmpty) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                }
                if (props.field.migrateTo === MIGRATE_TO.BILL) {
                    const {destination, origin} = props.field.value;

                    if (destination === DESTINATION_NEW_VALUE && origin && contracts) {
                        const neighbourBills = getContractsByContractBill(origin as IContract, contracts);

                        if (neighbourBills.length > MAX_NUMBER_OF_BILLS_PER_CONTRACT) {
                            setError(ERROR.VIRTUAL_CONTRACTS_MAX_COUNT);

                            return false;
                        }
                    }
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <>
            <label className={'col-form-label'}>
                {formatFormTitle(props.field, props.translation)}
            </label>
            <Origin
                migrateTo={props.field.migrateTo}
                originTitle={props.field.originTitle}
                origin={props.field.value.origin}
                contracts={contracts || []}
                translation={props.translation}
                intl={props.intl}
                getUniqueContractsWithoutBills={getUniqueContractsWithoutBills}
                onChange={onChangeOrigin}
            />
            {
                props.field.value.origin &&
                <Services
                    key={props.field.value.origin?.id}
                    migrateTo={props.field.migrateTo}
                    origin={props.field.value.origin}
                    serviceStatuses={serviceStatuses}
                    serviceSelectionType={props.field.serviceSelectionType}
                    selectedServices={props.field.value.userServices}
                    intl={props.intl}
                    getUserServices={getUserServices}
                    onChooseService={onChooseService}
                    onChooseServices={onChooseServices}
                />
            }
            {
                props.field.value.origin &&
                <Destination
                    migrateTo={props.field.migrateTo}
                    destinationTitle={props.field.destinationTitle}
                    newDestinationItemTitle={props.field.newDestinationItemTitle}
                    existedDestinationItemTitle={props.field.existedDestinationItemTitle}
                    origin={props.field.value.origin}
                    destination={props.field.value.destination}
                    contracts={contracts || []}
                    translation={props.translation}
                    intl={props.intl}
                    getContractsByContractBill={getContractsByContractBill}
                    getUniqueContractsWithoutBills={getUniqueContractsWithoutBills}
                    onChange={onChangeDestination}
                />
            }
            {
                error &&
                <span className={'text-danger'}>
                    {formatError(error, props.intl)}
                </span>
            }
        </>
    );
});

export default USMigrationField;
