import {
    INormNotification,
    NotificationsFilters,
    NotificationsSuggestions,
} from './types';
import {
    IGetNotifications,
    IGetSuggestions,
    IPatchStatus,
} from 'instances/notifications/types/responses';

import config from 'config';

import {onLogout} from 'instances/auth/functions';

import {INSTANCE_PATH} from './constants';

export const getAllNotifications = async (query: NotificationsFilters): Promise<IGetNotifications> => {
    const queryStr = new URLSearchParams(
        Object.entries(query).flatMap(([key, value]) =>
            Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]
        )
    ).toString();

    const url = `${config.URL}${INSTANCE_PATH}/?${queryStr}`;

    const res = await fetch(url, {
        credentials: 'include',
    });

    if (!res.ok) {
        const error = (await res.json().then((data) => data.error));

        if (res.status > 399) {
            return {error} as IGetNotifications;
        }
        if (res.status === 500) {
            throw new Error(error);
        }
    }

    const data = (await res.json().then((data) => data.notifications)) as INormNotification[];

    return {notifications: data};
};

export const getSuggestionsForNotifications = async (): Promise<IGetSuggestions> => {
    const locale = localStorage.getItem('locale') || 'ru';
    const url = `${config.URL}${INSTANCE_PATH}/suggestions?locale=${locale}`;

    const res = await fetch(url, {
        credentials: 'include',
    });

    if (!res.ok) {
        if (res.status === 401) {
            const error = await res.json();

            throw new Error(error);
        }
        if (res.status === 404) {
            const error = await res.json();

            throw new Error(error);
        }
        if (res.status === 500) {
            const error = await res.json();

            throw new Error(error);
        }
    }

    const data = (await res.json().then((data) => data.suggestions)) as NotificationsSuggestions;

    return {suggestions: data};
};

export const patchStatus = async (id: string): Promise<IPatchStatus> => {
    const url = `${config.URL}${INSTANCE_PATH}/${id}`;
    const res = await fetch(url, {
        credentials: 'include',
        method: 'PATCH',
    });

    if (res.status > 399) {
        if (res.status === 401) {
            onLogout();
        }

        const error = await res.json();

        throw new Error(error.error);
    }

    const data = (await res.json().then((data) => data.notification)) as INormNotification;

    return {notification: data};
};
