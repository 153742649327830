import {IFieldValue} from '../../../../Fields/types';
import {
    ICondition,
    ITrigger,
} from '../../../types';
import {
    ILock,
    IProps,
} from './types';

import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import Condition from './Condition';

import {translateByIntl} from '../../../../helpers';
import {TRIGGER_TYPE} from '../../../constants';
import {getDefaultCondition} from '../../functions';

import {INTL_DATA} from './intl';

const Lock = forwardRef<ILock, IProps>((props, ref) => {
    const [newConditionFieldName, setNewConditionFieldName] = useState<string | null>(null);
    const [conditions, setConditions] = useState<ICondition[]>(props.trigger.conditions || []);

    const onAddCondition = () => {
        if (!newConditionFieldName) {
            return;
        }

        const field = props.fields
            .filter((field) => field.name !== props.targetFieldName)
            .find((field) => field.name == newConditionFieldName);

        if (!field) {
            return;
        }

        const condition = getDefaultCondition(field);

        conditions.push(condition);
        setConditions([...conditions]);
        setNewConditionFieldName(null);
    };
    const onChangeCondition = (name: string, value: IFieldValue) => {
        const index = conditions.findIndex((condition) => condition.name === name);

        if (index === -1) {
            return;
        }

        const condition = conditions[index];

        if (!condition) {
            return;
        }

        conditions[index] = {
            ...condition,
            value,
        };
        setConditions([...conditions]);
    };
    const onRemoveCondition = (name: string) => {
        const newConditions = conditions.filter((condition) => condition.name !== name);

        setConditions(newConditions);
    };

    useImperativeHandle(ref, () => {
        return {
            getTriggerStructure(): ITrigger {
                return {
                    type: TRIGGER_TYPE.LOCK,
                    conditions,
                };
            },
            validate(): boolean {
                return !!conditions.length;
            },
        };
    });
    useEffect(() => {
        setConditions(props.trigger.conditions);
    }, [props.trigger]);

    return (
        <div>
            <div className={'mt-2 mr-3'}>
                {translateByIntl(props.intl, INTL_DATA.FIELD, 'Field')}
                &nbsp;
                <b>
                    {props.targetFieldName}
                </b>
                &nbsp;
                {translateByIntl(props.intl, INTL_DATA.LOCK, 'will be locked if')}
            </div>
            <div className={'d-flex flex-column'}>
                {
                    conditions.map((condition, i) =>
                        <div
                            key={condition.name}
                            className={'list-group-item'}
                        >
                            <div className={'row'}>
                                {
                                    i > 0 &&
                                <div className={'col col-2 mt-2 d-flex flex-row align-items-center'}>
                                    {translateByIntl(props.intl, INTL_DATA.AND, 'and')}
                                </div>
                                }
                                <div className={'col col-10'}>
                                    <Condition
                                        condition={condition}
                                        field={props.fields.find((field) => field.name === condition.name) || null}
                                        intl={props.intl}
                                        translation={props.translation}
                                        onChange={onChangeCondition}
                                        onRemove={onRemoveCondition}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className={'d-flex flex-row justify-content-end mt-3'}>
                <div className={'mr-2 d-flex flex-row align-items-center'}>
                    <select
                        className={'form-control form-control-sm'}
                        value={newConditionFieldName || ''}
                        onChange={(e) => setNewConditionFieldName(e.target.value)}
                    >
                        <option value={''}/>
                        {
                            props.fields
                                .filter((field) => field.name !== props.targetFieldName)
                                .map((field) => field.name)
                                .map(
                                    (name) =>
                                        <option
                                            key={name}
                                            value={name}
                                        >
                                            {name}
                                        </option>
                                )
                        }
                    </select>
                </div>
                <button
                    className={'btn btn-sm btn-secondary'}
                    disabled={!newConditionFieldName}
                    onClick={onAddCondition}
                >
                    Добавить
                </button>
            </div>
        </div>
    );
});

export default Lock;
