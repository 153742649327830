export const INSTANCE_PATH = '/orders';

export const LS_ORDERS_FILTER = 'LS_ORDERS_FILTER';

export enum SORT_BY {
    CONTRACT_NUMBER = 'CONTRACT_NUMBER',
    ORDER_NUMBER = 'ORDER_NUMBER',
    CITY = 'CITY',
    STATUS = 'STATUS',
    ORDER_DATE = 'ORDER_DATE',
    PLAN_DATE = 'PLAN_DATE',
    INSTALL_DATE = 'INSTALL_DATE',
    SALES_NAME = 'SALES_NAME',
    COORDINATOR_NAME = 'COORDINATOR_NAME',
}

export enum ACTION {
    ACTIVE = 'ACTIVE',
    IN_PROGRESS_OR_READY = 'IN_PROGRESS_OR_READY',
    SUSPENDED = 'SUSPENDED',
    CHANGE_PLANNED = 'CHANGE_PLANNED',
}

export enum STATUS {
    COMPLETE = 'COMPLETE',
    IN_PROC = 'IN PROC',
    HOLD = 'HOLD',
    DONE = 'DONE',
}

export const DOWNLOADED_USER_SITES_EXCEL_FILE = 'Мои заказы.xlsx';
