import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {capitalize} from '../../../../helpers';

const Step: FC<IProps> = (props) => {
    return (
        <div className={'d-flex flex-column align-items-center'}>
            <div>
                <MaterialCheckbox
                    value={props.isCurrent}
                    onChange={() => props.onSetCurrent(props.stepNumber)}
                />
            </div>
            <div className={'d-flex flex-row'}>
                <div>
                    <input
                        className={'form-control form-control-sm'}
                        value={props.title}
                        onChange={(e) => props.onChange(props.stepNumber, capitalize(e.target.value))}
                    />
                </div>
                <div>
                    <button
                        className={'btn btn-sm btn-danger'}
                        onClick={() => props.onRemove(props.stepNumber)}
                        disabled={!props.isAbleToRemove}
                    >
                        X
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Step;
