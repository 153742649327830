import { ILanguage } from './types/instance';

import { LS_LANGUAGES_ADMIN, LS_CURRENT_LOCALE, DEFAULT_LANGUAGE } from './constants';


export function getLSAdmin(): ILanguage | null {
    const data = localStorage.getItem(LS_LANGUAGES_ADMIN);

    return data ? JSON.parse(data) : null;
}

export function setLSAdmin(language: ILanguage | null) {
    localStorage.setItem(LS_LANGUAGES_ADMIN, JSON.stringify(language));
}

export function getLSCurrentLocale() {
    return localStorage.getItem(LS_CURRENT_LOCALE) || DEFAULT_LANGUAGE;
}

export function getDefaultLocale(availableLocales: string[]) {
    if (localStorage.getItem(LS_CURRENT_LOCALE)) {
        return localStorage.getItem(LS_CURRENT_LOCALE) || DEFAULT_LANGUAGE;
    } else {
        const locale = navigator.language.split('-')[0].toLowerCase();

        return availableLocales.indexOf(locale) === -1 ? DEFAULT_LANGUAGE : locale;
    }
}

export function setLSCurrentLocale(locale: string) {
    localStorage.setItem(LS_CURRENT_LOCALE, locale);
}
