import {IProps} from './types';

import React, {
    FC,
    useState,
} from 'react';

import Step from './Step';

import {translateByIntl} from '../../../helpers';

import {INTL_DATA} from './intl';

const StepBar: FC<IProps> = (props) => {
    const [newStepTitle, setNewStepTitle] = useState(`Step ${props.stepTitles.length + 1}`);

    const onCreate = () => {
        const stepTitle = newStepTitle;

        setNewStepTitle(`Step ${props.stepTitles.length + 2}`);
        props.onCreate(stepTitle);
    };
    const onRemove = (stepNumber: number) => {
        setNewStepTitle(`Step ${props.stepTitles.length}`);
        props.onRemove(stepNumber);
    };

    return (
        <div className={'border border-dark p-2 w-100'}>
            <div className={'d-flex flex-row justify-content-center'}>
                <h5>
                    {translateByIntl(props.intl, INTL_DATA.TITLE, 'Title')}
                </h5>
            </div>
            <div className={'d-flex flex-row justify-content-center'}>
                {
                    props.stepTitles.map(
                        (title, i) =>
                            <div key={i} className={'mr-2'}>
                                <Step
                                    stepNumber={i + 1}
                                    title={title}
                                    isCurrent={props.currentStepNumber === i + 1}
                                    isAbleToRemove={props.stepTitles.length !== 1}
                                    onChange={props.onChange}
                                    onRemove={onRemove}
                                    onSetCurrent={props.onSetCurrent}
                                />
                            </div>
                    )
                }
                <div className={'d-flex flex-row align-items-end'}>
                    <input
                        className={'form-control form-control-sm'}
                        value={newStepTitle}
                        onChange={(e) => setNewStepTitle(e.target.value)}
                        disabled={!props.isAbleToCreateStep}
                    />
                    <button
                        className={'btn btn-sm btn-primary'}
                        disabled={!props.isAbleToCreateStep}
                        onClick={onCreate}
                    >
                        +
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StepBar;
