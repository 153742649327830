import {IProps} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useId,
} from 'react';

import {translateByObject} from '../../../../helpers';

const BoostedCheckboxField: FC<IProps> = (props) => {
    const id = useId();

    return (
        <div className={classNames('form-check form-switch d-flex flex-row align-items-center justify-content-center')}>
            <input
                className={'form-check-input'}
                type={'checkbox'}
                role={'switch'}
                id={id}
                checked={props.field.value}
                disabled={true}
            />
            <label
                className={'form-check-label text-body ms-1'}
                htmlFor={id}
            >
                {translateByObject(props.field.title, props.translation)}
            </label>
        </div>
    );
};

export default BoostedCheckboxField;
