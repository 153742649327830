import {ISpecificForm} from '../../../FormField/types';
import {IUser} from '../types';
import {IProps} from './types';

import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {OrangeInput} from 'components/Helpers/Inputs';
import {MaterialTooltip} from 'components/Helpers/MaterialUI';

import {context} from '../../../../Provider';
import {formatFormTitle} from '../../functions';
import {
    formatUserTooltip,
    formatValue,
} from '../functions';

import {
    ERROR,
    FETCH_SUGGESTIONS_DELAY,
    SUGGESTIONS_COUNT,
} from './constants';
import {formatError} from './functions';

import questionImage from '../media/question.png';

const UserField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [value, setValue] = useState(formatValue(props.field.value));
    const [users, setUsers] = useState<IUser[]>([]);
    const [error, setError] = useState<ERROR | null>(null);
    const [isCompleteInitialPending, setIsCompleteInitialPending] = useState(false);
    const [delayTimeoutId, setDelayTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const {auth, getUsers} = useContext(context);

    const onChange = (value: string) => {
        setValue(value);

        const user = users.find((user) => formatValue(user) === value);

        props.onChange(props.field.name, user || null);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    if (!props.field.value) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                }

                setError(null);

                return true;
            },
        };
    });
    useEffect(() => {
        if (delayTimeoutId !== null) {
            clearTimeout(delayTimeoutId);
        }

        const id = setTimeout(async () => {
            const {users} = await getUsers({
                skip: 0,
                limit: SUGGESTIONS_COUNT,
                fullName: value,
            });

            const user = users?.find((user) => formatValue(user) === value);

            if (user) {
                if (user.email !== props.field.value?.email) {
                    props.onChange(props.field.name, user);
                }
            } else {
                if (props.field.withCurrentUserAsDefaultValue && !isCompleteInitialPending) {
                    if (!props.field.value) {
                        props.onChange(props.field.name, auth);
                        setValue(formatValue(auth));
                    }

                    setIsCompleteInitialPending(true);
                }
            }

            setUsers(users || []);
        }, FETCH_SUGGESTIONS_DELAY);

        setDelayTimeoutId(id);

        return () => {
            if (delayTimeoutId === null) {
                return;
            }

            clearTimeout(delayTimeoutId);
        };
    }, [value]);

    return (
        <div className={'d-flex flex-row justify-content-between align-items-center mb-1 mt-1'}>
            <OrangeInput
                className={'w-100'}
                title={formatFormTitle(props.field, props.translation)}
                value={value}
                suggestions={users.map((user) => formatValue(user))}
                isError={!!error}
                errorMessage={formatError(error, props.intl)}
                onChangeValue={onChange}
            />
            <MaterialTooltip
                className={'m-auto'}
                text={formatUserTooltip(props.field.value, props.intl)}
                placement={'bottom'}
            >
                <img
                    width={30}
                    height={30}
                    src={questionImage}
                    alt={''}
                />
            </MaterialTooltip>
        </div>
    );
});

export default UserField;
