import {IField} from '../../Fields/types';
import {IFieldPosition} from '../types';

export function getRows(fields: IField[] | null, positionKey: string): number[] {
    if (!fields) {
        return [];
    }

    const positions: IFieldPosition[] = fields
        .filter((field) => !!field[positionKey])
        .map((field) => field[positionKey]);
    const rowsCount = Math.max(...positions.map((position) => position.row)) + 1;

    return [...Array(rowsCount).keys()];
}
