import {IIntl} from '../../../types';
import {IValue} from './types';

import {translateByIntl} from '../../../helpers';

import {DESTINATION_NEW_VALUE} from './constants';
import {INTL_DATA} from './intl';

export function usMigrationFieldsAreEqual(first: IValue | null, second: IValue | null) {
    return first?.destination === DESTINATION_NEW_VALUE && second?.destination === DESTINATION_NEW_VALUE;
}

export function formatServiceStatus(status: string, intl: IIntl | null): string {
    switch (status) {
        case 'ACTIVE': {
            return translateByIntl(intl, INTL_DATA.USER_SERVICES_ACTIVE, 'Active');
        }
        default: {
            return '';
        }
    }
}
