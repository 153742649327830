import {
    IGetByAdminQuery,
    IPatchByAdminBody,
    IPostByAdminBody,
} from './types/requests';
import {
    IGetByAdmin,
    IGetOneByAdmin,
    IPatchByAdmin,
    IPostByAdmin,
    IRemoveByAdmin,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {onLogout} from 'instances/auth/functions';

import {
    INSTANCE_PATH,
    RESPONSE_ERROR,
} from './constants';

export async function getByAdmin(query: IGetByAdminQuery): Promise<IGetByAdmin> {
    return new Fetch<IGetByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec();
}

export async function getOneByAdmin(id: string): Promise<IGetOneByAdmin> {
    return new Fetch<IGetOneByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin/${id}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([404], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.NOT_FOUND: {
                    return {norFoundError: body.error};
                }
                default: {
                    return body;
                }
            }
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postClientByAdmin(body: IPostByAdminBody): Promise<IPostByAdmin> {
    return new Fetch<IPostByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function patchClientByAdmin(body: IPatchByAdminBody): Promise<IPatchByAdmin> {
    return new Fetch<IPatchByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin`,
        method: 'PATCH',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function removeByAdmin(id: string): Promise<IRemoveByAdmin> {
    return new Fetch<IRemoveByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin/${id}`,
        method: 'DELETE',
    })
        .onEmptyBody([204])
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}
