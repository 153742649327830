import {IDestinationNewValue} from './types';

export const MAX_NUMBER_OF_BILLS_PER_CONTRACT = 20;

export const DESTINATION_NEW_VALUE: IDestinationNewValue = 'new';

export enum MIGRATE_TO {
    BILL = 'bill',
    CONTRACT = 'contract',
    SITE = 'site',
}

export enum SERVICE_SELECTION_TYPE {
    FREE_EMPTY = 'FREE_EMPTY',
    FREE_ALL = 'FREE_ALL',
    FROZEN_ALL = 'FROZEN_ALL',
}
