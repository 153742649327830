import {IProps} from './types';

import React from 'react';
import MaskedInput from 'react-text-mask';

const TextMaskCustom = (props: IProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {inputRef, ...otherProps} = props;

    return (
        <MaskedInput
            {...otherProps}
            placeholder={props.placeholder}
            mask={props.mask}
            pipe={props.pipe}
            guide={true}
            placeholderChar={'\u2000'}
            showMask={false}
        />
    );
};

export default TextMaskCustom;
