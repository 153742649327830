import {IProps} from './types';

import {
    addIcon,
    backIcon,
    deleteIcon,
    downloadIcon,
    editIcon,
    filterIcon,
    frozenIcon,
    incidentIcon,
    lessIcon,
    manualIcon,
    moreIcon,
    readyIcon,
    runIcon,
    saveIcon,
    toUserIcon,
    undoIcon,
} from './media';

export function getIcon(mode: IProps['mode']): string {
    switch (mode) {
        case 'add': {
            return addIcon;
        }
        case 'edit': {
            return editIcon;
        }
        case 'delete': {
            return deleteIcon;
        }
        case 'undo': {
            return undoIcon;
        }
        case 'save': {
            return saveIcon;
        }
        case 'back': {
            return backIcon;
        }
        case 'more': {
            return moreIcon;
        }
        case 'less': {
            return lessIcon;
        }
        case 'ready': {
            return readyIcon;
        }
        case 'frozen': {
            return frozenIcon;
        }
        case 'filter': {
            return filterIcon;
        }
        case 'run': {
            return runIcon;
        }
        case 'incident': {
            return incidentIcon;
        }
        case 'manual': {
            return manualIcon;
        }
        case 'toUser': {
            return toUserIcon;
        }
        case 'download': {
            return downloadIcon;
        }
    }
}
