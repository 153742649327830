import {IProps} from './types';

import {
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import React, {
    ChangeEvent,
    FC,
    useId,
} from 'react';

const MaterialSelect: FC<IProps> = (props) => {
    const inputId = useId();

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange?.(e.target.value, e.target.name);
    };

    return (
        <FormControl fullWidth={true}>
            <InputLabel
                htmlFor={inputId}
                error={props.isError}
            >
                {props.label}
            </InputLabel>
            <Select
                input={
                    <Input
                        id={inputId}
                        value={props.value}
                        onChange={onChange}
                        fullWidth={true}
                        disabled={props.isDisabled}
                        error={props.isError}
                    />
                }
            >
                {
                    props.options.map((option, i) => (
                        <MenuItem key={i} value={props.values?.[i] ?? option}>
                            {option}
                        </MenuItem>
                    ))
                }
            </Select>
            {
                props.helperText && (
                    <FormHelperText error={props.isError}>
                        {props.helperText}
                    </FormHelperText>
                )
            }
        </FormControl>
    );
};

export default MaterialSelect;
