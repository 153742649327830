import {IStringFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import ClassNames from 'classnames';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA as FIELDS_INTL_DATA} from '../../intl';
import {INTL_DATA} from '../intl';

import {
    IF_NO_REGEX_TYPES_FOUND_IN_THE_RE_TYPES_CONSTANT_NONE,
    RE_TYPES,
} from './constants';
import {getReTypeNameByRe} from './functions';

const StringField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [value, setValue] = useState(props.field.value);
    const [required, setRequired] = useState(props.field.validation.required);
    const [maxLength, setMaxLength] = useState(props.field.validation.maxLength);
    const [isActiveMaxLength, setIsActiveMaxLength] = useState(maxLength !== undefined);
    const [reTypeName, setReTypeName] = useState(getReTypeNameByRe(props.field.validation.re));
    const [re, setRe] = useState(props.field.validation.re);
    const [isActiveRe, setIsActiveRe] = useState(props.field.validation.re !== undefined);
    const [isValidRe, setIsValidRe] = useState(true);

    const onChangeReType = (reType: string) => {
        const type = RE_TYPES.find((type) => type.name === reType);

        if (!type) {
            return;
        }

        setReTypeName(type.name);
        setRe(type.value);
    };

    const onChangeRe = (re: string) => {
        const reTypeName = getReTypeNameByRe(re);

        setReTypeName(reTypeName);
        setRe(re);
    };

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): IStringFieldSpecificProps {
                return {
                    value,
                    validation: {
                        required,
                        maxLength: isActiveMaxLength ? maxLength : undefined,
                        re: isActiveRe ? re : undefined,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {
                    [value]: value,
                };
            },
            validate(): boolean {
                try {
                    new RegExp(re || '');
                    setIsValidRe(true);

                    return true;
                } catch {
                    setIsValidRe(false);

                    return false;
                }
            },
        };
    });
    useEffect(() => {
        if (!isActiveMaxLength) {
            setMaxLength(undefined);
        }
    }, [isActiveMaxLength]);
    useEffect(() => {
        if (!isActiveRe) {
            setRe(undefined);
        }
    }, [isActiveRe]);

    return (
        <>
            <div className={'border-bottom border-dark pb-2'}>
                <div className={'row m-2'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, FIELDS_INTL_DATA.DEFAULT_VALUE, 'Default value')}
                            :
                        </label>
                    </div>
                    <div className={'col-8'}>
                        <input
                            className={'form-control'}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className={'m-2'}>
                <h5>
                    {translateByIntl(props.intl, FIELDS_INTL_DATA.VALIDATION, 'Validation')}
                </h5>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, FIELDS_INTL_DATA.REQUIRED, 'Required')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={required}
                            onChange={setRequired}
                        />
                    </div>
                </div>
                <div className={'row mt-2'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, INTL_DATA.MAX_LENGTH, 'Max length')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={isActiveMaxLength}
                            onChange={setIsActiveMaxLength}
                        />
                    </div>
                    <div className={'col-3'}>
                        <input
                            max={100}
                            min={1}
                            type={'number'}
                            className={'form-control'}
                            value={maxLength ?? ''}
                            disabled={!isActiveMaxLength}
                            onChange={(e) => setMaxLength(Number(e.target.value))}
                        />
                    </div>
                </div>
                <div className={'row mt-2'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, INTL_DATA.REGULAR_EXPRESSION, 'Regular expression')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={isActiveRe}
                            onChange={setIsActiveRe}
                        />
                    </div>
                    <div className={'col-3 d-flex flex-column'}>
                        <input
                            className={ClassNames('form-control', {'is-invalid': !isValidRe})}
                            value={re ?? ''}
                            disabled={!isActiveRe}
                            onChange={(e) => onChangeRe(e.target.value)}
                        />
                        {
                            !isValidRe &&
                            <span className={'text-danger'}>
                                Invalid re
                            </span>
                        }
                    </div>
                    <div className={'col-3'}>
                        <select
                            className={'form-control'}
                            value={reTypeName || ''}
                            onChange={(e) => onChangeReType(e.target.value)}
                            disabled={!isActiveRe}
                        >
                            <option value={IF_NO_REGEX_TYPES_FOUND_IN_THE_RE_TYPES_CONSTANT_NONE}>
                                {translateByIntl(props.intl, INTL_DATA.NONE, IF_NO_REGEX_TYPES_FOUND_IN_THE_RE_TYPES_CONSTANT_NONE)}
                            </option>
                            {
                                RE_TYPES.map((type) =>
                                    <option
                                        key={type.name}
                                        value={type.name}
                                    >
                                        {translateByIntl(props.intl, type.translation, type.name)}
                                    </option>
                                )
                            }
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
});

export default StringField;
