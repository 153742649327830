import { IReduxAction } from '../types';
import { IState, IGetSucceed, IPostSucceed, IPatchSucceed, IRemoveSucceed } from './types/redux';

import { ActionTypes } from './constants';


export const initialState: IState = {
    data: [],
    error: null,
    isLoaded: false,
};

export default function(state: IState = initialState, action: IReduxAction): IState {
    switch (action.type) {
        case ActionTypes.GetServicesGroupsSucceed: {
            const {servicesGroups, error} = <IGetSucceed>action;

            return error ?
                {
                    ...state,
                    error,
                    isLoaded: true,
                } :
                {
                    ...state,
                    data: servicesGroups!,
                    error,
                    isLoaded: true,
                };
        }
        case ActionTypes.PostServicesGroupSucceed: {
            const {servicesGroup, error} = <IPostSucceed>action;
            return error ?
                {
                    ...state,
                    error,
                    isLoaded: true,
                } :
                {
                    ...state,
                    data: [servicesGroup!, ...state.data],
                    error,
                    isLoaded: true,
                };
        }
        case ActionTypes.PatchServicesGroupSucceed: {
            const {servicesGroup, error} = <IPatchSucceed>action;
            return error ?
                {
                    ...state,
                    error,
                    isLoaded: true,
                } :
                {
                    ...state,
                    data: [servicesGroup!, ...state.data.filter((group) => group.id !== servicesGroup!.id)],
                    error,
                    isLoaded: true,
                };
        }
        case ActionTypes.RemoveServicesGroupSucceed: {
            const {id, error} = <IRemoveSucceed>action;
            return error ?
                {
                    ...state,
                    error,
                    isLoaded: true,
                } :
                {
                    ...state,
                    data: [...state.data.filter((group) => group.id !== id!)],
                    error,
                    isLoaded: true,
                };
        }
        default: {
            return state;
        }
    }
}
