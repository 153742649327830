import {IProps} from './types';

import React, {FC} from 'react';
import {
    Draggable,
    DraggingStyle,
    NotDraggingStyle,
} from 'react-beautiful-dnd';

import {translateByIntl} from '../../../helpers';
import {getDraggableIdForField} from '../functions';

import {INTL_DATA} from './intl';

const ApplicationField: FC<IProps> = (props) => {
    const getStyles = (isDragging: boolean, draggableStyles: DraggingStyle | NotDraggingStyle | undefined) => {
        return {
            padding: 16,
            margin: '0 0 8px 0',
            background: isDragging ? 'lightgreen' : 'grey',
            ...draggableStyles,
        };
    };

    return (
        <Draggable draggableId={getDraggableIdForField(props.field)} index={props.index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={'d-flex flex-column'}
                    style={getStyles(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <div className={'d-flex flex-row align-items-center'}>
                        <label className={'col-form-label'}>Id:&nbsp;</label>
                        <div className={'hidden-overflow-text'}>
                            {props.field.name}
                        </div>
                    </div>
                    <div className={'d-flex flex-row align-items-center'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, INTL_DATA.TITLE, 'Title')}
                            :&nbsp;
                        </label>
                        <div className={'hidden-overflow-text'}>
                            {props.field.title}
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default ApplicationField;
