import {
    IOrangeDateRef,
    IProps,
    IValue,
} from './types';

import classNames from 'classnames';
import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';

import {ERROR} from './constants';
import {getDefaultTranslation} from './functions';

import styles from './OrangeDate.module.scss';

const OrangeDate = forwardRef<IOrangeDateRef, IProps>((props, ref) => {
    const [value, setValue] = useState(props.initValue);
    const [error, setError] = useState<ERROR | null>(null);
    const [validatorError, setValidatorError] = useState<string | null>(null);

    const validate = async (valueToCheck?: IValue) => {
        valueToCheck = valueToCheck ?? value;

        if (props.isRequired && !valueToCheck) {
            setError(ERROR.ERROR_REQUIRED);

            return false;
        }

        for (const validator of props.validators || []) {
            const validatorResult = await validator(valueToCheck);

            if (validatorResult) {
                setValidatorError(validatorResult);

                return false;
            }
        }

        setError(null);

        return true;
    };
    const onChange = (value: IValue) => {
        setValue(value);
        validate(value);
        props.onBlur?.();
    };
    const onFocus = () => {
        props.onFocus?.();
    };
    const onBlur = () => {
        validate();
        props.onBlur?.();
    };

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            setValue(value) {
                setValue(value);
                setError(null);
                setValidatorError(null);
            },
            setError(error: string | null) {
                setError(null);
                setValidatorError(error);
            },
            clearValue() {
                setError(null);
                setValue(null);
            },
            async validate() {
                return validate();
            },
        };
    });

    return (
        <>
            <ReactDatePicker
                className={classNames('form-control', props.className, styles.orangeDate, {
                    'is-invalid': !!error,
                })}
                wrapperClassName={'w-100'}
                dateFormat={'dd.MM.yyyy'}
                selected={value}
                placeholderText={props.placeholder}
                onChange={onChange}
                disabled={props.isDisabled}
                minDate={props.min}
                maxDate={props.max}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <span className={'text-primary'}>
                {
                    error ?
                        getDefaultTranslation(error) :
                        validatorError
                }
            </span>
        </>
    );
});

export default OrangeDate;
