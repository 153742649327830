import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../../helpers';
import {MIGRATE_TO} from '../../constants';
import {formatServiceStatus} from '../../functions';

import {getStatusClassName} from './functions';

import styles from './Service.module.scss';

const Service: FC<IProps> = (props) => {
    return (
        <div className={`bg-light mt-1 mb-1 pl-2 pr-2 ${styles.userService}`}>
            <div className={'row'}>
                <div className={'com-sm-12 col-md-3 d-flex flex-row align-items-center'}>
                    {
                        props.onChoose &&
                        <div className={'mr-2'}>
                            <MaterialCheckbox
                                value={!!props.isSelected}
                                isDisabled={props.isDisabled}
                                onChange={() => props.onChoose?.(props.userService)}
                            />
                        </div>
                    }
                    <div className={`${styles.hideOverflowText} font-weight-bold`}>
                        {translateByObject(props.userService.type, props.userService.translation)}
                    </div>
                </div>
                <div className={'col-1 d-flex flex-row align-items-center'}>
                    {props.userService.SVID}
                </div>
                <div className={'col-2 d-flex flex-row align-items-center'}>
                    {props.userService.orderAddendumNumber}
                </div>
                {
                    (
                        props.migrateTo === MIGRATE_TO.BILL ||
                        props.migrateTo === MIGRATE_TO.CONTRACT
                    ) &&
                    <div className={'col-sm-6 col-md-3 d-flex flex-row align-items-center'}>
                        <div className={styles.hideOverflowText}>
                            {props.userService.siteAddress}
                        </div>
                    </div>
                }
                {
                    props.migrateTo === MIGRATE_TO.SITE &&
                    <>
                        <div className={'col-2 d-flex flex-row align-items-center'}>
                            <div className={styles.hideOverflowText}>
                                {props.userService.contractNumber}
                            </div>
                        </div>
                        <div className={'col-2 d-flex flex-row align-items-center'}>
                            <div className={styles.hideOverflowText}>
                                {props.userService.customerUserSiteRef}
                            </div>
                        </div>
                    </>
                }
                <div className={'col-sm-6 col-md-2'}>
                    <div className={`badge badge-pill h-100 ${getStatusClassName(props.userService.status)}`}>
                        <div className={'d-flex flex-row justify-content-center align-items-center'}>
                            {formatServiceStatus(props.userService.status, props.intl)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;
