import {ISpecificForm} from '../../../FormField/types';
import {IProps} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialDatePicker} from 'components/Helpers/MaterialUI';

import {formatFormTitle} from '../../functions';
import {formatValue} from '../functions';

import {ERROR} from './constants';
import {
    formatError,
    getMax,
    getMin,
} from './functions';

const DateField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (value: Date) => {
        props.onChange(props.field.name, value.toDateString());
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    if (!props.field.value) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <MaterialDatePicker
            label={formatFormTitle(props.field, props.translation)}
            value={formatValue(props.field.value)}
            isDisabled={props.field.readOnly}
            min={getMin(props.field.validation.pastIsDisabled)}
            max={getMax(props.field.validation.max)}
            isError={!!error}
            helperText={formatError(error, props.intl)}
            onChange={onChange}
        />
    );
});

export default DateField;
