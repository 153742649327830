import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialInput} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../helpers';
import {formatValue} from '../functions';

const GeoField: FC<IProps> = (props) => {
    return (
        <MaterialInput
            label={translateByObject(props.field.title, props.translation)}
            value={formatValue(props.field.value)}
            isDisabled={true}
        />
    );
};

export default GeoField;
