import * as NSRedux from './types/redux';
import * as NSFetchResult from './types/fetchResult';

import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionTypes } from './constants';

import { IGet, IPost, IPatch, IRemove } from './types/saga';

import * as fetches from './fetches';


export default {
    get(apiCaller?: typeof fetches.get): IGet {
        function* caller(action: NSRedux.IGet) {
            const {servicesGroups, error}: NSFetchResult.IGet = yield call(
                apiCaller ? apiCaller : fetches.get,
            );
            const success: NSRedux.IGetSucceed = {
                error,
                servicesGroups,
                type: ActionTypes.GetServicesGroupsSucceed,
            };
            yield put(success);
        }

        function* taker() {
            yield takeLatest(ActionTypes.GetServicesGroups, caller);
        }

        return {caller, taker};
    },

    post(apiCaller?: typeof fetches.post): IPost {
        function* caller(action: NSRedux.IPost) {
            const {servicesGroup, error}: NSFetchResult.IPost = yield call(
                apiCaller ? apiCaller : fetches.post,
                action.servicesGroup,
            );
            const success: NSRedux.IPostSucceed = {
                error,
                servicesGroup,
                type: ActionTypes.PostServicesGroupSucceed,
            };
            yield put(success);
        }

        function* taker() {
            yield takeLatest(ActionTypes.PostServicesGroup, caller);
        }

        return {caller, taker};
    },

    patch(apiCaller?: typeof fetches.patch): IPatch {
        function* caller(action: NSRedux.IPatch) {
            const {servicesGroup, error}: NSFetchResult.IPatch = yield call(
                apiCaller ? apiCaller : fetches.patch,
                action.servicesGroup,
            );
            const success: NSRedux.IPatchSucceed = {
                error,
                servicesGroup,
                type: ActionTypes.PatchServicesGroupSucceed,
            };
            yield put(success);
        }

        function* taker() {
            yield takeLatest(ActionTypes.PatchServicesGroup, caller);
        }

        return {caller, taker};
    },

    remove(apiCaller?: typeof fetches.remove): IRemove {
        function* caller(action: NSRedux.IRemove) {
            const {id, error}: NSFetchResult.IRemove = yield call(
                apiCaller ? apiCaller : fetches.remove,
                action.id,
            );
            const success: NSRedux.IRemoveSucceed = {
                error,
                id,
                type: ActionTypes.RemoveServicesGroupSucceed,
            };
            yield put(success);
        }

        function* taker() {
            yield takeLatest(ActionTypes.RemoveServicesGroup, caller);
        }

        return {caller, taker};
    },
};
