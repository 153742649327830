require('./setupPolyfills');

import config from 'config';
import React from 'react';
import {createRoot} from 'react-dom/client';

import App from './App';

import registerServiceWorker from './registerServiceWorker';

const domRoot = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(domRoot!);

root.render(<App/>);

config.REGISTER_SERVICE_WORKER && registerServiceWorker();
