import {IProps} from './types';

import React, {
    FC,
    useId,
} from 'react';

import {
    translateByObject,
} from 'components/Helpers/FormsEngine/helpers';

const BoostedNumberField: FC<IProps> = (props) => {
    const label = translateByObject(props.field.title, props.translation);

    const elemId = useId();

    if (props.field.readOnly) {
        return (
            <div>
                <label className={'col-form-label'}>
                    {label}
                </label>
            </div>
        );
    }

    return (
        <div className={'d-flex flex-column w-100'}>
            <label htmlFor={elemId} className={'form-label'}>
                {label}
            </label>
            <input
                className={'form-control'}
                id={elemId}
                disabled={true}
                value={props.field.value}
            />
        </div>
    );
};

export default BoostedNumberField;
