import React, { Component } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

import ReduxLoader from './ReduxLoader';


export default function(params: any) {
    return function<P>(WrappedComponent: React.ComponentType<P>): React.ComponentType<P> {
        class EnhancedReduxLoader extends Component {
            public render() {
                return(
                    <ReduxLoader
                        {...params}
                        {...this.props}
                        WrappedComponent={WrappedComponent}
                    />
                );
            }
        }
        hoistNonReactStatic(EnhancedReduxLoader, WrappedComponent);
        return EnhancedReduxLoader as React.ComponentType<P>;

    };
}
