import {IIntl} from '../../../../types';
import {IValue} from '../types';

import {OpenStreetMapProvider} from 'leaflet-geosearch';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA} from '../../intl';

import {ERROR} from './constants';

export function formatError(error: ERROR | null, intl: IIntl | null): string | undefined {
    switch (error) {
        case ERROR.IS_REQUIRED: {
            return translateByIntl(intl, INTL_DATA.NO_EMPTY, 'Required');
        }
        case null: {
            return undefined;
        }
    }
}

export async function getSuggestions(provider: OpenStreetMapProvider, value: string): Promise<IValue[]> {
    try {
        const results = await provider.search({query: value});

        return results.map((result) => (
            {
                name: result.raw?.display_name || '',
                lat: result.raw?.lat || null,
                lng: result.raw?.lon || null,
            }
        ));
    } catch {
        return [];
    }
}
