import classNames from 'classnames';
import React, {FC, useId} from 'react';

interface IProps {
    className?: string;
    value: string;
    values: string[];

    title?: string;
    isDisabled?: boolean;

    formatOption(value: string): string;
    onChange(value: string);
}

const OrangeSelect: FC<IProps> = ({
    className,
    value,
    values,
    title,
    isDisabled,
    formatOption,
    onChange,
                                  }) => {
    const id = useId()

    return (
        <div className={classNames('border-bottom border-1 border-light d-flex flex-column w-100',
            className,
        )}>
            {
                title &&
                <label
                    className={'col-form-label p-0'}
                    htmlFor={id}
                >
                    {title}
                </label>
            }
            <select
                id={id}
                className={classNames('form-select fs-5 fw-bold')}
                value={value}
                disabled={isDisabled}
                onChange={(e) => onChange(e.target.value)}
                style={{
                    minHeight:'40px'
                }}
            >
                {
                    values.map(
                        (value) =>
                            <option
                                key={value}
                                value={value}
                            >
                                {formatOption(value)}
                            </option>
                    )
                }
            </select>
        </div>
    );
};

export default OrangeSelect;
