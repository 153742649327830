import { IRootReduxState } from 'instances/types';
import { IServicesGroup, IToPost, IToPatch } from './types/instance';
import { IGet, IPost, IPatch, IRemove } from './types/redux';

import { Dispatch } from 'redux';

import { ActionTypes } from './constants';


export function getState({servicesGroups}: IRootReduxState) {
    return servicesGroups.data;
}

export function isLoaded({servicesGroups}: IRootReduxState) {
    return servicesGroups.isLoaded;
}

export function getError({servicesGroups}: IRootReduxState) {
    return servicesGroups.error;
}

export function get(dispatch: Dispatch) {
    return () => {
        const action: IGet = {type: ActionTypes.GetServicesGroups};
        dispatch(action);
    };
}

export function post(dispatch: Dispatch) {
    return (servicesGroup: IToPost): void => {
        const action: IPost = {type: ActionTypes.PostServicesGroup, servicesGroup};
        dispatch(action);
    };
}

export function patch(dispatch: Dispatch) {
    return (servicesGroup: IToPatch): void => {
        const action: IPatch = {type: ActionTypes.PatchServicesGroup, servicesGroup};
        dispatch(action);
    };
}

export function remove(dispatch: Dispatch) {
    return (id: IServicesGroup['id']): void => {
        const action: IRemove = {type: ActionTypes.RemoveServicesGroup, id};
        dispatch(action);
    };
}
