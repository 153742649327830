export const INSTANCE_PATH = '/common';

export enum ActionTypes {
    SetCheckoutMessage = 'SET_CHECKOUT_MESSAGE',

    GetCommonData = 'GET_COMMON_DATA',
    GetCommonDataSucceed = 'GET_COMMON_DATA_SUCCEED',

    UpdateMyServiceActualTime = 'UPDATE_MY_SERVICES_ACTUAL_TIME',
}
