import {Alert} from '@mui/material';
import React from 'react';
import {
    toast,
    ToastOptions,
} from 'react-toastify';

import {useIntl} from '../hooks';

import {INTL_DATA} from './intl';

class Notifier {
    private static options: ToastOptions = {
        hideProgressBar: true,
        position: 'bottom-right',
    };

    public static success(text = 'Empty message'): void {
        toast(
            (
                <Alert severity={'success'}>
                    {text}
                </Alert>
            ),
            this.options
        );
    }

    public static warning(text: string): void {
        toast(
            (
                <Alert severity={'warning'}>
                    {text}
                </Alert>
            ),
            this.options
        );
    }

    public static error(text: string) {
        toast(
            (
                <Alert severity={'error'}>
                    {text}
                </Alert>
            ),
            this.options
        );
    }

    public static info(text: string) {
        toast(
            (
                <Alert severity={'info'}>
                    {text}
                </Alert>
            ),
            this.options
        );
    }

    public static ooops(formatMessage: ReturnType<typeof useIntl>, error?: string) {
        toast(
            (
                <Alert severity={'error'}>
                    {error ? error : formatMessage(INTL_DATA.SOMETHING_WENT_WRONG)}
                </Alert>
            ),
            this.options
        );
    }
}

export default Notifier;
