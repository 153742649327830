import {
    IApplicationVersion,
    IFullVersion,
    IServiceVersion,
} from './types/instance';

import {useState} from 'react';

import * as fetches from './fetches';
import {NORM_MODES} from './constants';
import Notifier from "helpers/Notifier";
import {formatServiceVersionsError} from "./functions";
import {getByCodeAndVersion, getNew} from './fetches';

export function useActiveVersionsByUser() {
    const [activeServiceVersions, setActiveServiceVersions] = useState<IServiceVersion[] | null>(null);

    const getActiveServiceVersions = async () => {
        const res = await fetches.getActive();

        if (!res.versions || res.error) {
            return res;
        }

        setActiveServiceVersions(res.versions);

        return res;
    };
    const getApplicationActiveServiceVersion = async (id: string) => {
        const version = activeServiceVersions?.find((activeServiceVersion) =>
            activeServiceVersion.id === id &&
            activeServiceVersion._mode === NORM_MODES.APPLICATION
        ) as IApplicationVersion;

        if (version) {
            return {version, error: undefined};
        }

        const res = await fetches.getOne(id);

        if (!res.version || res.error) {
            console.log(res.error);

            return res;
        }

        if (!activeServiceVersions) {
            return res;
        }

        const index = activeServiceVersions.findIndex((activeServiceVersion) => activeServiceVersion.id === id);

        if (index === -1) {
            return res;
        }

        res.version.queue = activeServiceVersions[index].queue;
        activeServiceVersions[index] = res.version;
        setActiveServiceVersions([...activeServiceVersions]);

        return res;
    };

    const getClaimActiveVersion = async () => {
        const version = activeServiceVersions?.find((activeServiceVersion) =>
            activeServiceVersion.code === '109.01.100'
        ) as IApplicationVersion;

        const res = await fetches.getOne(version.id);

        if (!res.version || res.error) {
            console.log(res.error);

            return {error: res.error};
        }

        return {version: res.version as IApplicationVersion, error: undefined};
    };

    return {
        activeServiceVersions,
        getActiveServiceVersions,
        getApplicationActiveServiceVersion,
        getClaimActiveVersion,
    };
}

export function useServiceVersionsByAdmin() {
    const [serviceVersions, setServiceVersions] = useState<IServiceVersion[] | null>(null);

    const getServiceVersions = async (serviceId: string) => {
        setServiceVersions(null);

        const res = await fetches.getByService(serviceId);

        if (!res.versions || res.error) {
            console.log(res.error);
            return res;
        }

        setServiceVersions(res.versions);
        return res;
    };
    const getServiceVersion = async (id: string) => {
        const serviceVersion = serviceVersions?.find((serviceVersion) => serviceVersion.id === id);

        if (serviceVersion?._mode === NORM_MODES.FULL) {
            return {version: serviceVersion};
        }

        const res = await fetches.getOne(id);

        if (!res.version || res.error) {
            console.log(res.error);
            return res;
        }
        if (!serviceVersions) {
            return res;
        }

        const index = serviceVersions.findIndex((serviceVersion) => serviceVersion.id === id);

        if (index === -1) {
            return res;
        }

        serviceVersions[index] = {
            ...serviceVersions[index],
            ...res.version,
        };
        setServiceVersions([...serviceVersions]);
        return res;
    };

    const getVersionsByCode = async (code: string) => {
        const res = await fetches.getOneByCode(code);

        if (!res) {
            return res;
        }
        if (res.error) {
            console.log(res.error);
            return res;
        }

        return res;
    }

    return {
        serviceVersions,
        getServiceVersions,
        getServiceVersion,
        getVersionsByCode
    };
}

export function useServiceVersionByAdmin() {
    const [serviceVersions, setServiceVersions] = useState<IServiceVersion[] | null>(null);
    const [serviceVersion, setServiceVersion] = useState<IFullVersion | null>(null)
    const [newServiceVersion, setNewServiceVersion] = useState<IFullVersion | null>(null)

    const getNewServiceVersion = async (code: string) => {
        const res = await fetches.getNew(code);

        if (!res.serviceVersion || res.error) {
            const error = res.error || 'getNew error';
            Notifier.error(formatServiceVersionsError(error));

            return res;
        }

        setNewServiceVersion(res.serviceVersion);

        return res;
    }

    const getServiceVersionsByCode = async (code: string) => {
        const res = await fetches.getOneByCode(code);

        if (!res.serviceVersions || res.error) {
            const error = res.error || 'getOneByCode error';
            Notifier.error(formatServiceVersionsError(error));

            return res;
        }

        setServiceVersions(res.serviceVersions);

        return res;
    };

    const getServiceByCodeAndVersion = async (code: string, version: string) => {
        const {serviceVersion, error} = await getByCodeAndVersion(code, version);

        if (!serviceVersion || error) {
            const e = error || 'getByCodeAndVersion error';
            console.log(e);
            Notifier.error(formatServiceVersionsError(e));

            return {error: e};
        }

        setServiceVersion(serviceVersion);
        return {serviceVersion};
    };

    const patchServiceVersion = async (id: string, body: any) => {
        if (!serviceVersions) {
            return
        }

        const res = await fetches.patch(id, body);

        if (!res) {
            return res;
        }
        if (!res.version) {
            return res;
        }
        if (res.error) {
            console.log(res.error);

            return res;
        }

        const index = serviceVersions.findIndex((serviceVersion) => serviceVersion.id === id);

        if (index === -1) {
            return res;
        }

        serviceVersions[index] = {
            ...serviceVersions[index],
            ...res.version,
        };
        setServiceVersions([...serviceVersions]);
        return res;
    }

    return {
        serviceVersion,
        newServiceVersion,
        serviceVersions,
        getServiceVersionsByCode,
        getNewServiceVersion,
        getServiceByCodeAndVersion,
        patchServiceVersion
    };
}
