export const getCompanyName = (companyName: string | null): string => {
    if (companyName) {
        const name = companyName.replace(/['"«»`-]/g, '');

        if (name.split(' ').length === 3 && name.length > 20) {
            const inception = name.split(' ').splice(1, 1)
                .join(' ');
            const ending = name.split(' ').slice(-1)
                .join(' ');

            return inception + ' ' + ending;
        } else if (name.split(' ').length > 3 && name.length < 20) {
            const inception = name.split(' ').splice(1, 1)
                .join(' ');
            const ending = name.split(' ').slice(-1)
                .join(' ');

            return inception + '...' + ending;
        } else if (name.length > 20) {
            return name.split(' ').splice(-2)
                .join(' ');
        } else {
            return name;
        }
    } else {
        return '';
    }
};
