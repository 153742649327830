export function getStatusClassName(status: string): string {
    switch (status) {
        case 'ACTIVE': {
            return 'bg-success text-white';
        }
        default: {
            return '';
        }
    }
}
