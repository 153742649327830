import React, {FC} from 'react';
import {Navigate} from 'react-router-dom';

import {useAuth} from 'instances/auth/hooks';
import {USER_TYPE} from 'instances/users/constants';

import {ROUTE} from 'components/constants';
import {Loading} from 'components/Helpers/Other';

const Home: FC = () => {
    const {auth} = useAuth();

    if (!auth) {
        return <Loading isPage={true}/>;
    }
    if (auth.type === USER_TYPE.SUPER_ADMIN ||
        auth.type === USER_TYPE.ORANGE_ADMIN ||
        auth.type === USER_TYPE.CLIENT_ADMIN
    ) {
        return <Navigate to={ROUTE.ADMIN} replace={true}/>;
    } else {
        return <Navigate to={ROUTE.USER_SERVICES} replace={true}/>;
    }
};

export default Home;
