import {IProps} from './types';

import {
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
} from '@mui/material';
import React, {
    ChangeEvent,
    forwardRef,
    useId,
} from 'react';

const MaterialInput = forwardRef<unknown, IProps>((props, _) => {
    const inputId = useId();
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.isInputAvailable === false) {
            return;
        }

        props.onChange?.(e.target.value, e.target.name);
    };

    return (
        <FormControl fullWidth={true}>
            <InputLabel
                htmlFor={inputId}
                error={props.isError}
            >
                {props.label}
            </InputLabel>
            <Input
                id={inputId}
                type={props.type}
                onChange={onChange}
                disabled={props.isDisabled}
                error={props.isError}
                multiline={props.isMultiline}
                autoComplete={'off'}
                rows={props.isMultiline ? '4' : undefined}
                value={props.value}
                name={props.name}
                onFocus={props.onFocus}
            />
            {
                props.isError && props.helperText && (
                    <FormHelperText error={true}>
                        {props.helperText}
                    </FormHelperText>
                )
            }
        </FormControl>
    );
});

export default MaterialInput;
