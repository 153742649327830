export const INSTANCE_PATH = '/services_versions';

export enum ActionTypes {
    GetActiveServicesVersions = 'GetActiveServicesVersions',
    GetActiveServicesVersionsSucceed = 'GetActiveServicesVersionsSucceed',

    PostActiveServiceVersion = 'PostActiveVersion',
    PostActiveServiceVersionSucceed = 'PostActiveVersionSucceed',

    GetServiceVersionsByService = 'GetServiceVersionsByService',
    GetServiceVersionsByServiceSucceed = 'GetServiceVersionsByServiceSucceed',

    GetServiceVersion = 'GetServiceVersion',
    GetServiceVersionSucceed = 'GetServiceVersionSucceed',

    PatchServiceVersion = 'PatchServiceVersion',
    PatchServiceVersionSucceed = 'PatchServiceVersionSucceed',

    SetActiveServiceVersion = 'SetActiveServiceVersion',
    SetActiveServiceVersionSucceed = 'SetActiveServiceVersionSucceed',
}

export enum NORM_MODES {
    DEMO = 'DEMO',
    APPLICATION = 'APPLICATION',
    FULL = 'FULL',
}

export enum SUBTYPES {
    SIMPLE = 'simple',
    ADDITIONAL = 'additional',
}

export enum DEFAULT_FIELDS_NAMES {
    SITE = 'address',
    CONTRACT = 'contract',
}

export enum FIELD_APPLICATION_KEY {
    APPLICATION = 'application',
    ORDER_APPLICATION = 'orderApplication',
}

export enum FIELD_POSITION_KEY {
    POSITION = 'position',
    ORDER_POSITION = 'orderPosition',
}

export const DefaultFields = [
    {
        name: DEFAULT_FIELDS_NAMES.CONTRACT,
        type: DEFAULT_FIELDS_NAMES.CONTRACT,
        title: 'Contract',
        tooltip: '',
        readOnly: false,
        value: '',
        validation: {
            required: true,
        },
        // application: {
        //     frame: '1',
        //     row: 0,
        // },
    },
    {
        name: DEFAULT_FIELDS_NAMES.SITE,
        type: DEFAULT_FIELDS_NAMES.SITE,
        title: 'Address',
        tooltip: '',
        readOnly: false,
        value: null,
        validation: {
            required: true,
        },
        // application: {
        //     frame: '1',
        //     row: 1,
        // },
    },
];

export const DefaultFieldsTranslation = [
    {
        name: DEFAULT_FIELDS_NAMES.CONTRACT,
        messages: {
            Contract: 'Contract',
        }
    },
    {
        name: DEFAULT_FIELDS_NAMES.SITE,
        messages: {
            Address: 'Address',
        }
    },
];

export const AdditionalService = {
    // applicationStepTitles: [
    //     'Step 1',
    // ],
    // applicationFrames: [
    //     {id: '1', title: '', text: '', step: 1},
    // ],
    fields: [
        ...DefaultFields,
    ],
    translations: [
        {
            locale: 'en',
            type: '',
            shortDescription: '',
            description: '',
            // applicationStepTitles: ['Step 1'],
            // applicationFrames: [{id: '1', title: '', text: ''}],
            fields: [
                ...DefaultFieldsTranslation,
            ]
        }
    ],
};

export const SimpleService = {
    applicationStepTitles: [
        'Step 1',
    ],
    applicationFrames: [],

    orderApplicationStepTitles: [
        'Step 1',
    ],
    orderApplicationFrames: [],

    fields: [
        ...DefaultFields,
        {
            name: 'deployment_option',
            type: 'select',
            title: 'Deployment option',
            tooltip: '',
            readOnly: false,
            value: '',
            values: [],
            validation: {
                required: true,
            },
            // application: {
            //     frame: '1',
            //     row: 2,
            // },
        },
        {
            name: 'distribution_expected_date',
            type: 'date',
            title: 'Expected date for service distribution',
            tooltip: '',
            readOnly: false,
            value: '',
            validation: {
                required: true,
            },
            // application: {
            //     frame: '1',
            //     row: 0,
            // },
        },
        {
            name: 'contact_person',
            type: 'user',
            title: 'Contact person',
            tooltip: '',
            readOnly: false,
            value: null,
            validation: {
                required: true,
            },
            // application: {
            //     frame: '2',
            //     row: 1,
            // },
        },
        {
            name: 'comments',
            type: 'text',
            title: 'Comments',
            tooltip: '',
            readOnly: false,
            value: '',
            validation: {
                required: false,
            },
            // application: {
            //     frame: '2',
            //     row: 2,
            // },
        },
        {
            name: 'service_duration',
            type: 'select',
            title: 'Service duration',
            tooltip: '',
            readOnly: false,
            value: '',
            values: ['12', '24', '36', '48', '60'],
            validation: {
                required: false,
            },
            // application: {
            //     frame: '2',
            //     row: 3,
            // },
        },
    ],
    translations: [
        {
            locale: 'en',
            type: '',
            shortDescription: '',
            description: '',

            // applicationStepTitles: ['Step 1'],
            // applicationFrames: [
            //     {id: '1', title: '', text: ''},
            //     {id: '2', title: '', text: ''},
            // ],

            // orderApplicationStepTitles: ['Step 1'],
            // orderApplicationFrames: [
            //     {id: '1', title: '', text: ''},
            //     {id: '2', title: '', text: ''},
            // ],

            fields: [
                ...DefaultFieldsTranslation,
                {
                    name: 'deployment_option',
                    messages: {
                        'Deployment option': 'Deployment option',
                    }
                },
                {
                    name: 'distribution_expected_date',
                    messages: {
                        'Expected date for service distribution': 'Expected date for service distribution',
                    }
                },
                {
                    name: 'contact_person',
                    messages: {
                        'Contact person': 'Contact person',
                    }
                },
                {
                    name: 'comments',
                    messages: {
                        Comments: 'Comments',
                    }
                },
                {
                    name: 'service_duration',
                    messages: {
                        'Service duration': 'Service duration',
                        '12': '12',
                        '24': '24',
                        '36': '36',
                        '48': '48',
                        '60': '60',
                    },
                }
            ]
        }
    ],
};

export enum SERVICE_VERSION_CATEGORY {
    CONNECTIVITY = 'Connectivity',
    VOICE_UC = 'Voice & Unified communications',
    MANAGED_NETWORK = 'Managed Network',
    CYBER_DEFENSE = 'Cyber Defense',
    CLOUD = 'Cloud',
    IOT = 'IoT',
    AUXILIARY = 'Auxiliary',
    OTHER = 'Other',
}

export enum RESPONSE_ERROR {
    NOTHING_TO_NORMALIZE = 'NOTHING_TO_NORMALIZE',
    CLIENT_NOT_FOUND = 'CLIENT_NOT_FOUND',
    NOT_FOUND = 'NOT_FOUND',
    ACTIVE_VERSION_DOES_NOT_EXIST = 'ACTIVE_VERSION_DOES_NOT_EXIST',
    AUTH_NOT_FOUND = 'AUTH_NOT_FOUND',

    INVALID_CODE = 'INVALID_CODE',
    INVALID_SERVICE_VERSION = 'INVALID_SERVICE_VERSION',
}