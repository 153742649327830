import {IEquippedClient} from 'instances/equippedClients/types';
import {
    IDataItem,
    IEquippedUser,
} from 'instances/equippedUsers/types';
import {IPostUserByAdminBody} from 'instances/equippedUsers/types/requests';
import {IResponseError} from 'tools/types';

import React, {
    createRef,
    FC,
    ReactNode,
    RefObject,
    useEffect,
    useState,
} from 'react';

import {IIntl} from 'helpers/hooks';
import Notifier from 'helpers/Notifier';

import {
    InfiniteScroll,
    Loading,
} from 'components/Helpers/Other';

import ConfirmEquipmentDataModal from './ConfirmEquipmentDataModal';
import NewUserModal from './NewEquippedUserModal';
import PdIdentifiersItem from './PdIdentifiersItem';
import {IFormItem} from './PdIdentifiersItem/PdIdentifiersItem';
import UploadExelModal from './UploadExelModal';

interface IProps {
    equippedClient: IEquippedClient;
    equippedUsers: IEquippedUser[];
    equippedUsersTotal: number;
    topMiddleNode?: ReactNode;
    isPending?: boolean;
    isAdmin?: boolean;

    loadMoreIdentifiers(skip: number): Promise<void>;

    onSubmitDataItems(equipmentDataItems: IDataItem[]): Promise<void>;
    onSubmitNoChanges?(): Promise<void>;
    onAddUser?(body: IPostUserByAdminBody): Promise<IEquippedUser | null>;
    onRemoveUser?(id: string): Promise<void>;

    onUploadFile(file: File): Promise<IResponseError | null>;
    onDownloadSample(): Promise<void>;

    intl: IIntl;
}

const PdIdentifiersList: FC<IProps> = (props) => {
    const [formItemRefs, setFormItemRefs] = useState<RefObject<IFormItem>[] | null>(null);
    const [isOpenNewEquippedUserModal, setIsOpenNewEquippedUserModal] = useState(false);
    const [isOpenConfirmEquipmentDataModal, setIsOpenConfirmDataModal] = useState(false);
    const [isOpenUploadExelModal, setIsOpenUploadExelModal] = useState(false);
    const [isPending, setIsPending] = useState(false);

    const onDuplicate = async (index: number) => {
        if (!formItemRefs) {
            return;
        }

        const itemFromCopy = formItemRefs[index];

        if (!itemFromCopy.current?.isFullFilled()) {
            await itemFromCopy.current?.validate();

            return;
        }

        const itemToCopy = formItemRefs[index + 1];
        const valueFromCopy = itemFromCopy?.current?.getValue();
        const valueToCopy = itemToCopy?.current?.getValue();

        if (!valueFromCopy) {
            return;
        }
        if (!valueToCopy) {
            return;
        }
        if (itemToCopy?.current?.getUser().pdSentAt) {
            return;
        }

        itemToCopy?.current?.setValue({
            ...valueFromCopy,
            id: valueToCopy.id,
            identifier: valueToCopy.identifier,
        });
    };
    const onDuplicateAll = async (index: number) => {
        if (!formItemRefs) {
            return;
        }

        const itemFromCopy = formItemRefs[index];

        if (!await itemFromCopy?.current?.validate()) {
            return;
        }

        const valueFromCopy = itemFromCopy.current?.getValue();

        if (!valueFromCopy) {
            return;
        }

        formItemRefs.forEach((formItemRef) => {
            const value = formItemRef.current?.getValue();

            if (!value) {
                return;
            }
            if (formItemRef.current?.getUser().pdSentAt) {
                return;
            }

            formItemRef.current?.setValue({
                ...valueFromCopy,
                id: value.id,
                identifier: value.identifier,
            });
        });
    };
    const onSubmitData = async () => {
        if (isPending) {
            return;
        }
        if (!formItemRefs) {
            return;
        }

        setIsPending(true);

        const partlyFilledForms = formItemRefs.filter((form) => form.current?.isPartlyFilled());

        if (partlyFilledForms.length) {
            await Promise.all(
                partlyFilledForms
                    .map((form) => form.current?.validate())
            );
            Notifier.error('Заполните все поля или очистите форму');
            setIsOpenConfirmDataModal(false);
            setIsPending(false);

            return;
        }

        const fullFilledForms = formItemRefs.filter((form) => form.current?.isFullFilled());

        if (!fullFilledForms.length) {
            Notifier.error('Нет заполненных форм =(');
            setIsOpenConfirmDataModal(false);
            setIsPending(false);

            return;
        }

        const validForms = await Promise.all(
            fullFilledForms
                .filter((formItemRef) => !formItemRef.current?.getIsEdit())
                .map((formItemRef) => formItemRef.current?.validate())
        );

        if (validForms.includes(false)) {
            Notifier.error('Неверный формат данных!');
            setIsOpenConfirmDataModal(false);
            setIsPending(false);

            return;
        }

        const dataValues = fullFilledForms.map((el) => el.current?.getValue()) as IDataItem[];

        await props.onSubmitDataItems(dataValues);
        setIsPending(false);
        setIsOpenConfirmDataModal(false);
    };

    useEffect(() => {
        const newFormItemRefs = props.equippedUsers.map(() => createRef<IFormItem>());

        setFormItemRefs(formItemRefs ? [...formItemRefs, ...newFormItemRefs] : newFormItemRefs);
    }, [props.equippedUsers]);

    if (
        !formItemRefs ||
        props.isPending
    ) {
        return (
            <div className={'d-flex flex-row justify-content-center'}>
                <Loading size={100}/>
            </div>
        );
    }

    return (
        <div>
            <div className={'row mb-4'}>
                <div className={'col-4 d-flex flex-row'}>
                    <button
                        className={'btn btn-primary mr-4'}
                        disabled={isPending || !props.equippedUsers.length}
                        onClick={() => setIsOpenConfirmDataModal(true)}
                    >
                        Отправить
                    </button>
                </div>
                <div className={'col-4 d-flex flex-row justify-content-center'}>
                    {props.topMiddleNode}
                </div>
                <div className={'col-2 d-flex flex-row justify-content-end'}>
                </div>
                <div className={'col-2 d-flex flex-row justify-content-end'}>
                    <button
                        className={'btn btn-secondary'}
                        disabled={isPending}
                        onClick={() => setIsOpenUploadExelModal(true)}
                    >
                        Загрузить файл
                    </button>
                </div>
            </div>
            {
                !props.equippedUsers.length &&
                <div className={'d-flex flex-row justify-content-center'}>
                    <h3>
                        Никого нет :С
                    </h3>
                </div>
            }
            <div className={'mb-4'}>
                {
                    props.equippedUsers &&
                    props.equippedUsersTotal !== null &&
                    <InfiniteScroll
                        loadMore={
                            () => props.loadMoreIdentifiers(props.equippedUsers.length)
                        }
                        hasMore={props.equippedUsers.length < props.equippedUsersTotal}
                    >
                        {
                            props.equippedUsers
                                .map(
                                    (equippedUser, i, equippedUsers) =>
                                        formItemRefs[i] &&
                                        <PdIdentifiersItem
                                            key={equippedUser.id}
                                            ref={formItemRefs[i]}
                                            equippedClient={props.equippedClient}
                                            equippedUser={equippedUser}
                                            isLast={i === equippedUsers.length - 1}
                                            onDuplicate={() => onDuplicate(i)}
                                            onDuplicateAll={() => onDuplicateAll(i)}
                                            onRemove={null}
                                        />
                                )
                        }
                    </InfiniteScroll>
                }
            </div>
            {
                props.onAddUser &&
                    <NewUserModal
                        isOpen={isOpenNewEquippedUserModal}
                        clientId={props.equippedClient.clientId}
                        onAdd={props.onAddUser}
                        onClose={() => setIsOpenNewEquippedUserModal(false)}
                    />
            }
            <ConfirmEquipmentDataModal
                isPending={isPending}
                isOpen={isOpenConfirmEquipmentDataModal}
                onAccept={onSubmitData}
                onDecline={() => setIsOpenConfirmDataModal(false)}
            />
            <UploadExelModal
                isOpen={isOpenUploadExelModal}
                onAccept={() => console.log('accept')}
                onDecline={() => setIsOpenUploadExelModal(false)}
                equippedClient={props.equippedClient}
                onUploadFile={props.onUploadFile}
                onDownloadSample={props.onDownloadSample}
                isAdmin={props.isAdmin}
            />
        </div>
    );
};

export default PdIdentifiersList;
