import {IFieldsFrame} from './FieldsFrame/types';
import {
    IProps,
    IStep,
} from './types';

import React, {
    createRef,
    forwardRef,
    RefObject,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import FieldsFrame from './FieldsFrame';

const Step = forwardRef<IStep, IProps>((props, ref) => {
    const [framesRefs, setFramesRefs] = useState<RefObject<IFieldsFrame>[]>([]);

    const getFrameFields = (frame) => {
        return props.fields
            .filter((field) => field[props.fieldApplicationPositionKey]?.frame === frame.id)
            .filter((field) => !props.hiddenFieldsNames.find((name) => field.name === name));
    };

    useImperativeHandle(ref, () =>
        ({
            validate() {
                let isValid = true;

                framesRefs.forEach((frameRef) => {
                    const frameIsValid = (frameRef.current ? frameRef.current.validate() : true);

                    isValid = isValid && frameIsValid;
                });

                return isValid;
            },
            getFramesRefs() {
                return framesRefs;
            },
        })
    );
    useEffect(() => {
        setFramesRefs(
            new Array(props.stepFrames.length)
                .fill(null)
                .map((_, i) => framesRefs[i] || createRef())
        );
    }, [props.stepFrames]);

    return (
        <div className={'mt-2'}>
            {
                props.stepFrames
                    .filter((frame) => frame.row !== undefined)
                    .sort((a, b) => (a.row || 0) - (b.row || 0))
                    .map(
                        (frame, i) =>
                            <FieldsFrame
                                key={frame.id}
                                ref={framesRefs[i]}
                                frame={frame}
                                frameFields={getFrameFields(frame)}
                                fieldApplicationPositionKey={props.fieldApplicationPositionKey}
                                intl={props.intl}
                                translation={props.translation}
                                onChange={props.onChange}
                            />
                    )
            }
        </div>
    );
});

export default Step;
