import {
    IField,
    IFieldValue,
} from '../../Fields/types';
import {IStep} from './Step/types';
import {
    IApplicationForm,
    IProps,
} from './types';

import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import Footer from './Footer';
import Step from './Step';
import StepBar from './StepBar';

import {
    preventBackSpace,
    translateByIntl,
    usePrevious,
} from '../../helpers';

import {
    getHiddenFieldsNames,
    getHiddenSteps,
    getMaxStep,
    validateField,
} from './functions';
import {INTL_DATA} from './intl';

const ApplicationForm = forwardRef<IApplicationForm, IProps>((props, ref) => {
    const simpleGetHiddenFieldsNames = () => getHiddenFieldsNames(props.fields);
    const simpleGetHiddenSteps = () => getHiddenSteps(props.fields, props.frames, props.fieldApplicationPositionKey);

    const [currentStep, setCurrentStep] = useState(1);
    const [fields, setFields] = useState([...props.fields]);
    const [selectedFieldName, setSelectedFieldName] = useState<string | null>(null);
    const [hiddenFieldsNames, setHiddenFieldsNames] = useState<string[]>(simpleGetHiddenFieldsNames);
    const [hiddenSteps, setHiddenSteps] = useState<number[]>(simpleGetHiddenSteps);
    const [isPending, setIsPending] = useState(false);

    const stepRef = useRef<IStep>(null);
    const previousSelectedFieldName = usePrevious(selectedFieldName);

    const onChange = (name: string, value: IField['value']) => {
        const {fields} = props;
        const field = fields.find((field) => field.name === name);

        if (field) {
            field.value = value;
        }

        setSelectedFieldName(name);
        setHiddenFieldsNames(simpleGetHiddenFieldsNames);
        setHiddenSteps(simpleGetHiddenSteps);
        setFields([...fields]);
        props.onChange([...fields]);
    };
    const onIncreaseStep = () => {
        if (!stepRef.current?.validate()) {
            props.onFailStepValidation?.(translateByIntl(props.intl, INTL_DATA.ERROR_TEXT, 'Incorrect fields'));

            return;
        }

        const maxStep = getMaxStep(props.frames);
        let skip = 1;

        while (currentStep + skip <= maxStep) {
            if (!hiddenSteps.find((hiddenStep) => hiddenStep === currentStep + skip)) {
                break;
            }

            ++skip;
        }

        setCurrentStep(currentStep + skip);
        props.onIncreaseStep?.();
    };
    const onDecreaseStep = () => {
        let skip = 1;

        while (currentStep - skip > 0) {
            if (!hiddenSteps.find((hiddenStep) => hiddenStep === currentStep - skip)) {
                break;
            }

            ++skip;
        }

        setCurrentStep(currentStep - skip);
        props.onDecreaseStep?.();
    };
    const onConfirm = async () => {
        if (!stepRef.current?.validate()) {
            props.onFailStepValidation?.(translateByIntl(props.intl, INTL_DATA.ERROR_TEXT, 'Incorrect fields'));

            return;
        }

        setIsPending(true);
        await props.onConfirm?.();
        setIsPending(false);
    };

    useImperativeHandle(ref, () => {
        return {
            setStep(step: number) {
                setCurrentStep(step);
            },
            setFieldValue(name: string, value: IFieldValue) {
                const field = fields.find((field) => field.name === name);

                if (!field) {
                    return;
                }

                field.value = value;
                setFields([...props.fields]);
                props.onChange([...props.fields]);
            },
        };
    });
    useEffect(() => {
        document.addEventListener('keydown', preventBackSpace);

        return () => document.removeEventListener('keydown', preventBackSpace);
    }, []);
    useEffect(() => {
        previousSelectedFieldName && validateField(previousSelectedFieldName, stepRef);
    }, [selectedFieldName]);

    return (
        <div className={'d-flex flex-column justify-content-between mb-3'}>
            <div className={'d-flex flex-column w-100 h-100'}>
                <StepBar
                    currentStep={currentStep}
                    titles={props.stepTitles}
                    hiddenSteps={hiddenSteps}
                    translation={props.translation || null}
                />
                <Step
                    ref={stepRef}
                    currentStep={currentStep}
                    stepFrames={props.frames.filter((frame) => frame.step === currentStep)}
                    fields={fields}
                    hiddenFieldsNames={hiddenFieldsNames}
                    intl={props.intl || null}
                    translation={props.translation || null}
                    fieldApplicationPositionKey={props.fieldApplicationPositionKey}
                    onChange={onChange}
                />
            </div>
            <Footer
                currentStep={currentStep}
                maxStep={getMaxStep(props.frames)}
                hiddenSteps={hiddenSteps}
                isPending={isPending}
                intl={props.intl || null}
                onIncrease={onIncreaseStep}
                onDecrease={onDecreaseStep}
                onConfirm={onConfirm}
            />
        </div>
    );
});

export default ApplicationForm;
