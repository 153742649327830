import {ISpecificForm} from '../../../FormField/types';
import {IProps} from './types';

import classNames from 'classnames';
import React, {
    forwardRef,
    useId,
    useImperativeHandle,
    useState,
} from 'react';

import {translateByObject} from '../../../../helpers';
import {splitStringToWords} from '../functions';

import {ERROR} from './constants';
import {formatError} from './functions';

import styles from './TextField.module.scss';

const TextField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const elemId = useId();

    const [error, setError] = useState<ERROR | null>(null);

    const title = translateByObject(props.field.title, props.translation);
    const value = translateByObject(props.field.value, props.translation);

    const onChange = (value: string) => {
        props.onChange(props.field.name, value);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    if (!props.field.value) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                }

                setError(null);

                return true;
            },
        };
    });

    if (props.field.readOnly) {
        return (
            <div>
                {
                    props.field.hideTitle &&
                    <label>
                        {translateByObject(props.field.title, props.translation)}
                        :
                    </label>
                }
                {splitStringToWords(translateByObject(props.field.value, props.translation))}
            </div>
        );
    }

    return (
        <div className={classNames('d-flex flex-column', styles.container)}>
            <label
                htmlFor={elemId}
                className={'input-group-text p-0'}
            >
                {title}
            </label>
            <textarea
                className={'form-control w-100'}
                id={elemId}
                rows={props.rows}
                onChange={(e) => onChange(e.target.value)}
                value={value || ''}
            />
            {
                error &&
                <div>
                    {formatError(error, props.intl)}
                </div>
            }
        </div>
    );
});

export default TextField;
