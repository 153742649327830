import {IRadioFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import Values from './Values';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA} from '../../intl';

const RadioField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [value, setValue] = useState(props.field.value);
    const [values, setValues] = useState(props.field.values);
    const [required] = useState(props.field.validation.required);

    const addValue = (value: string) => {
        if (!values.length) {
            setValue(value);
        }

        setValues([...values, value]);
    };

    const removeValue = (index: number) => {
        if (values.length === 1) {
            setValue('');
        }

        const valueToRemove = values[index];
        const newValues = values.filter((value, i) => i !== index);

        setValues(newValues);

        if (value === valueToRemove) {
            setValue(newValues[0] || '');
        }
    };

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): IRadioFieldSpecificProps {
                return {
                    value,
                    values,
                    validation: {
                        required,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {
                    ...values.reduce(
                        (res, current) => ({
                            ...res,
                            [current]: current,
                        }),
                        {}
                    ),
                };
            },
            validate(): boolean {
                return !!values.length;
            },
        };
    });

    return (
        <div className={'m-2'}>
            <div className={'mt-2'}>
                <label>
                    {translateByIntl(props.intl, INTL_DATA.VALUES, 'Values')}
                    :
                </label>
                <Values
                    value={value}
                    values={values}
                    onAdd={addValue}
                    onRemove={removeValue}
                    onSetDefault={(value) => setValue(value)}
                    intl={props.intl}
                />
            </div>
        </div>

    );
});

export default RadioField;
