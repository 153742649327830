import {
    IProps,
    ITooltipInput,
} from './types';

import classNames from 'classnames';
import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {formatError} from '../TitleInput/functions';

import {
    capitalize,
    translateByIntl,
} from '../../../../helpers';

import {ERROR} from './constants';
import {INTL_DATA} from './intl';

const TooltipInput = forwardRef<ITooltipInput, IProps>((props, ref) => {
    const [value, setValue] = useState(props.initValue);
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (value: string) => {
        setValue(capitalize(value));
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): string {
                return value;
            },
            validate(): boolean {
                if (props.tooltip && !value) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    if (!props.tooltip) {
        return null;
    }

    return (
        <div className={'row'}>
            <div className={'col-4'}>
                <label className={'col-form-label'}>
                    {translateByIntl(props.intl, INTL_DATA.TOOLTIP, 'Tooltip')}
                </label>
            </div>
            <div className={'col-4'}>
                <label className={'col-form-label'}>
                    {props.tooltip}
                </label>
            </div>
            <div className={'col-4'}>
                <input
                    className={
                        classNames('form-control', {
                            'is-invalid': !!error,
                        })
                    }
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
                {
                    !!error &&
                    <div className={'text-danger'}>
                        {formatError(error)}
                    </div>
                }
            </div>
        </div>
    );
});

export default TooltipInput;
