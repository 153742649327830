import {IField} from '../../Fields/types';
import {
    IApplicationFrame,
} from '../types';
import {IStep} from './Step/types';

import {RefObject} from 'react';

import {isUnableField} from '../../helpers';

export function getMaxStep(frames: IApplicationFrame[]): number {
    return Math.max(
        ...frames
            .filter((frame) => frame !== undefined && frame.step !== undefined)
            .map((frame) => frame.step || 0)
    );
}

export function getHiddenFieldsNames(fields: IField[]): string[] {
    return fields
        .filter((field) => isUnableField(field.name, fields))
        .map((field) => field.name);
}

export function getHiddenSteps(fields: IField[], frames: IApplicationFrame[], fieldApplicationPositionKey: string): number[] {
    const hiddenSteps: number[] = [];
    const maxStep = getMaxStep(frames);

    for (let step = 1; step <= maxStep; ++step) {
        const shownStepFrames = frames
            .filter((frame) => frame.step === step)
            .filter(
                (frame) =>
                    fields
                        .filter((field) => field[fieldApplicationPositionKey]?.frame === frame.id)
                        .filter((field) => !isUnableField(field.name, fields))
                        .length
            );

        !shownStepFrames.length && hiddenSteps.push(step);
    }

    return hiddenSteps;
}

export function validateField(name: string, stepRef: RefObject<IStep>): void {
    stepRef.current?.getFramesRefs().forEach((frameRef) => {
        frameRef.current?.getFieldsRefs().forEach((fieldRef) => {
            if (fieldRef.current?.getName() === name) {
                fieldRef.current?.validate();
            }
        });
    });
}
