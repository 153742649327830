import {IUSMigrationFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA as FIELDS_INTL_DATA} from '../../intl';
import {
    MIGRATE_TO,
    SERVICE_SELECTION_TYPE,
} from '../constants';
import {INTL_DATA} from '../intl';

const USMigrationField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [migrateTo, setMigrateTo] = useState(props.field.migrateTo);
    const [originTitle, setOriginTitle] = useState(props.field.originTitle);
    const [destinationTitle, setDestinationTitle] = useState(props.field.destinationTitle);
    const [newDestinationItemTitle, setNewDestinationItemTitle] = useState(props.field.newDestinationItemTitle);
    const [existedDestinationItemTitle, setExistedDestinationItemTitle] = useState(props.field.existedDestinationItemTitle);
    const [newDestinationValueAsDefault, setNewDestinationValueAsDefault] = useState(props.field.newDestinationValueAsDefault);
    const [serviceSelectionType, setServiceSelectionType] = useState(props.field.serviceSelectionType);
    const [required, setRequired] = useState(props.field.validation.required);

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): IUSMigrationFieldSpecificProps {
                return {
                    value: {
                        origin: null,
                        userServices: [],
                        destination: null,
                    },
                    migrateTo,
                    originTitle,
                    destinationTitle,
                    newDestinationItemTitle,
                    existedDestinationItemTitle,
                    newDestinationValueAsDefault,
                    serviceSelectionType,

                    validation: {
                        required,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {
                    [originTitle]: originTitle,
                    [destinationTitle]: destinationTitle,
                    [newDestinationItemTitle]: newDestinationItemTitle,
                    [existedDestinationItemTitle]: existedDestinationItemTitle,
                };
            },
            validate(): boolean {
                return !!originTitle && !!destinationTitle && !!newDestinationItemTitle;
            },
        };
    });

    return (
        <>
            <div className={'border-bottom border-dark pb-2'}>
                <div className={'m-2'}>
                    <div className={'row'}>
                        <div className={'col-4'}>
                            <label className={'col-form-label'}>
                                {translateByIntl(props.intl, INTL_DATA.MIGRATE_TO, 'Migrate to')}
                                :
                            </label>
                        </div>
                        <div className={'col-4 d-flex flex-row align-items-center'}>
                            <select
                                className={'form-control'}
                                value={migrateTo}
                                onChange={(e) => setMigrateTo(e.target.value as MIGRATE_TO)}
                            >
                                {
                                    Object.values(MIGRATE_TO).map(
                                        (value) =>
                                            <option
                                                key={value}
                                                value={value}
                                            >
                                                {value}
                                            </option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className={'row mt-2'}>
                        <div className={'col-4'}>
                            <label className={'col-form-label'}>
                                {translateByIntl(props.intl, INTL_DATA.ORIGIN_TITLE, 'Origin title')}
                                :
                            </label>
                        </div>
                        <div className={'col-4 d-flex flex-row align-items-center'}>
                            <input
                                className={'form-control'}
                                value={originTitle}
                                onChange={(e) => setOriginTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={'row mt-2'}>
                        <div className={'col-4'}>
                            <label className={'col-form-label'}>
                                {translateByIntl(props.intl, INTL_DATA.DESTINATION_TITLE, 'Destination title')}
                                :
                            </label>
                        </div>
                        <div className={'col-4 d-flex flex-row align-items-center'}>
                            <input
                                className={'form-control'}
                                value={destinationTitle}
                                onChange={(e) => setDestinationTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={'row mt-2'}>
                        <div className={'col-4'}>
                            <label className={'col-form-label'}>
                                {translateByIntl(props.intl, INTL_DATA.NEW_DESTINATION_ITEM_TITLE, 'New destination item title')}
                                :
                            </label>
                        </div>
                        <div className={'col-4 d-flex flex-row align-items-center'}>
                            <input
                                className={'form-control'}
                                value={newDestinationItemTitle}
                                onChange={(e) => setNewDestinationItemTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={'row mt-2'}>
                        <div className={'col-4'}>
                            <label className={'col-form-label'}>
                                {translateByIntl(props.intl, INTL_DATA.EXISTED_DESTINATION_ITEM_TITLE, 'Existed destination item title')}
                                :
                            </label>
                        </div>
                        <div className={'col-4 d-flex flex-row align-items-center'}>
                            <input
                                className={'form-control'}
                                value={existedDestinationItemTitle}
                                onChange={(e) => setExistedDestinationItemTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={'row mt-2'}>
                        <div className={'col-4'}>
                            <label className={'col-form-label'}>
                                {translateByIntl(props.intl, INTL_DATA.NEW_DESTINATION_ITEM_AS_DEFAULT, 'New destination item as default')}
                                :
                            </label>
                        </div>
                        <div className={'col-4 d-flex flex-row align-items-center'}>
                            <MaterialCheckbox
                                value={newDestinationValueAsDefault}
                                onChange={setNewDestinationValueAsDefault}
                            />
                        </div>
                    </div>
                    <div className={'row mt-2'}>
                        <div className={'col-4'}>
                            <label className={'col-form-label'}>
                                Тип выбора услуг:
                            </label>
                        </div>
                        <div className={'col-4'}>
                            <select
                                className={'form-control'}
                                value={serviceSelectionType}
                                onChange={(e) => setServiceSelectionType(e.target.value as SERVICE_SELECTION_TYPE)}
                            >
                                <option value={SERVICE_SELECTION_TYPE.FREE_EMPTY}>Произвольный</option>
                                <option value={SERVICE_SELECTION_TYPE.FREE_ALL}>Все, изменяемо</option>
                                <option value={SERVICE_SELECTION_TYPE.FROZEN_ALL}>Все, не изменяемо</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'m-2'}>
                <h5>
                    {translateByIntl(props.intl, FIELDS_INTL_DATA.VALIDATION, 'Validation')}
                </h5>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, FIELDS_INTL_DATA.REQUIRED, 'Required')}
                            :
                        </label>
                    </div>
                    <div className={'col-4 d-flex flex-row align-items-center'}>
                        <MaterialCheckbox
                            value={required}
                            onChange={setRequired}
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

export default USMigrationField;
