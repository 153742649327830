import {IFramesForm} from './FramesForm/types';
import {IStepTitlesForm} from './StepTitlesForm/types';
import {
    IApplicationTranslation,
    IProps,
    ITranslationApplication,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
} from 'react';

import FramesForm from './FramesForm';
import StepTitlesForm from './StepTitlesForm/StepTitlesForm';

const TranslationApplication = forwardRef<ITranslationApplication, IProps>((props, ref) => {
    const stepTitlesFormRef = useRef<IStepTitlesForm>(null);
    const framesFormRef = useRef<IFramesForm>(null);

    useImperativeHandle(ref, () => {
        return {
            getValue(): IApplicationTranslation {
                return {
                    applicationStepTitles: stepTitlesFormRef.current?.getValue() || [],
                    applicationFrames: framesFormRef.current?.getValue() || [],
                };
            },
            validate(): boolean {
                return ![
                    stepTitlesFormRef.current?.validate(),
                    framesFormRef.current?.validate(),
                ].includes(false);
            },
        };
    });

    return (
        <div>
            <StepTitlesForm
                ref={stepTitlesFormRef}
                applicationStepTitles={props.applicationStepTitles}
                applicationStepTitleTranslations={props.applicationStepTitleTranslations}
                intl={props.intl || null}
            />
            <FramesForm
                ref={framesFormRef}
                applicationFrames={props.applicationFrames}
                applicationFrameTranslations={props.applicationFramesTranslations}
                intl={props.intl || null}
            />
        </div>
    );
});

export default TranslationApplication;
