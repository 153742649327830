import {IField} from '../../../types';
import {
    IProps,
    ISpecificForm,
} from './types';

import React, {forwardRef} from 'react';

import {FIELD_TYPE} from '../../../constants';
import AddressField from '../../FieldsTypes/AddressField/FormField/AddressField';
import CheckboxField from '../../FieldsTypes/CheckboxField/FormField/CheckboxField';
import ContractField from '../../FieldsTypes/ContractField/FormField/ContractField';
import DaDataField from '../../FieldsTypes/DaDataField/FormField/DaDataField';
import DateField from '../../FieldsTypes/DateField/FormField/DateField';
import GeoField from '../../FieldsTypes/GeoField/FormField/GeoField';
import IPField from '../../FieldsTypes/IPField/FormField/IPField';
import NumberField from '../../FieldsTypes/NumberField/FormField/NumberField';
import PhoneField from '../../FieldsTypes/PhoneField/FormField/PhoneField';
import RadioField from '../../FieldsTypes/RadioField/FormField/RadioField';
import RangeField from '../../FieldsTypes/RangeField/FormField/RangeField';
import SelectField from '../../FieldsTypes/SelectField/FormField/SelectField';
import StringField from '../../FieldsTypes/StringField/FormField/StringField';
import TextField from '../../FieldsTypes/TextField/FormField/TextField';
import UserField from '../../FieldsTypes/UserField/FormField/UserField';
import USMigrationField from '../../FieldsTypes/USMigrationField/FormField/USMigrationField';

const SpecificForm = forwardRef<ISpecificForm, IProps<IField>>((props, ref) => {
    switch (props.field.type) {
        case FIELD_TYPE.ADDRESS: {
            return (
                <AddressField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.CHECKBOX: {
            return (
                <CheckboxField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.CONTRACT: {
            return (
                <ContractField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.DA_DATA: {
            return (
                <DaDataField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.DATE: {
            return (
                <DateField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.GEO: {
            return (
                <GeoField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.IP: {
            return (
                <IPField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.NUMBER: {
            return (
                <NumberField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.PHONE: {
            return (
                <PhoneField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.RADIO: {
            return (
                <RadioField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.RANGE: {
            return (
                <RangeField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.SELECT: {
            return (
                <SelectField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.STRING: {
            return (
                <StringField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.TEXT: {
            return (
                <TextField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.USER: {
            return (
                <UserField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
        case FIELD_TYPE.US_MIGRATION: {
            return (
                <USMigrationField
                    ref={ref}
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={props.onChange}
                />
            );
        }
    }
});

export default SpecificForm;
