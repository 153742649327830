import {IFormField} from '../../../../Fields/types';
import {
    IFieldsFrame,
    IProps,
} from './types';

import React, {
    createRef,
    forwardRef,
    RefObject,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {FormField} from '../../../../Fields';
import {formatStringWithUrl} from '../../../../helpers';

const FieldsFrame = forwardRef<IFieldsFrame, IProps>((props, ref) => {
    const [fieldsRefs, setFieldsRefs] = useState<RefObject<IFormField>[]>([]);

    const formatText = (text) => {
        return text
            .split('\n')
            .map(
                (elem, i) => (
                    <div key={`frame-text-${i}`}>
                        {formatStringWithUrl(elem) || elem}
                    </div>
                )
            );
    };

    useImperativeHandle(ref, () => ({
        validate() {
            let isValid = true;

            fieldsRefs.forEach((fieldRef) => {
                const fieldIsValid = (fieldRef.current ? fieldRef.current.validate() : true);

                isValid = isValid && fieldIsValid;
            });

            return isValid;
        },
        getFieldsRefs() {
            return fieldsRefs;
        },
    })
    );
    useEffect(() => {
        setFieldsRefs(
            new Array(props.frameFields.length)
                .fill(null)
                .map((_, i) => fieldsRefs[i] || createRef())
        );
    }, [props.frameFields]);

    if (!props.frameFields.length) {
        return null;
    }

    return (
        <div className={'mb-3'}>
            {
                props.frame.title &&
                <label>
                    {
                        formatStringWithUrl(props.translation?.[props.frame.title] || props.frame.title)
                    }
                </label>
            }
            {
                props.frame.text &&
                <div>
                    {
                        formatText(props.translation?.[props.frame.text] || props.frame.text)
                    }
                </div>
            }
            {
                props.frameFields
                    .sort((a, b) => a[props.fieldApplicationPositionKey].row - b[props.fieldApplicationPositionKey].row)
                    .map(
                        (field, i) =>
                            <FormField
                                key={field.name}
                                ref={fieldsRefs[i]}
                                field={field}
                                intl={props.intl}
                                translation={props.translation}
                                onChange={props.onChange}
                            />
                    )
            }
        </div>
    );
});

export default FieldsFrame;
