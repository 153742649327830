import {
    IDownloadExcelByUserQuery,
    IGetDemoByUserQuery,
    IGetSuggestionsUser,
    IPatchByUserBody,
} from './types/requests';
import {
    IDownloadExcelByUser,
    IGetDemoByUser,
    IGetOneFullByUser,
    IGetSuggestionsByUser,
    IPatchByUser,
    IRemoveByUser,
} from './types/responses';

import axios, {AxiosInstance} from 'axios';
import config from 'config';

import Fetch from 'tools/Fetch';

import {onLogout} from 'instances/auth/functions';

import {
    DOWNLOADED_USER_SITES_EXCEL_FILE,
    INSTANCE_PATH,
} from './constants';

const usersSites: AxiosInstance = axios.create({
    baseURL: `${config.URL}${INSTANCE_PATH}`,
    withCredentials: true,
    headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
    },
});

export async function getByUser(query: IGetDemoByUserQuery): Promise<IGetDemoByUser> {
    const res = await usersSites
        .get<IGetDemoByUser>('', {params: query});

    if (res.status !== 200 || res.data.error) {
        let error = res.data.error;

        if (res.status === 401) {
            error = res.data.error;
            console.log(`Users sites error: ${error}`);

            onLogout();

            return res.data;
        }
        if ([400, 403, 500].includes(res.status)) {
            error = res.data.error;
            console.log(`Users sites error: ${error}`);

            return res.data;
        }

        error = res.data.error;
        console.log(`Users sites error: ${error}`);

        return res.data;
    }

    return res.data;
}
export async function getSuggestions(q: IGetSuggestionsUser): Promise<IGetSuggestionsByUser> {
    const res = await usersSites
        .get<IGetSuggestionsByUser>('/suggestions', {params: q});

    if (res.status !== 200 || !res.data.suggestions || res.data.error) {
        let error = '';

        if (res.status === 401) {
            error = res.data.error;
            console.log(`Users sites error: ${error}`);

            onLogout();

            return res.data;
        }
        if ([400, 403, 500].includes(res.status)) {
            error = res.data.error;
            console.log(`Users sites error: ${error}`);

            onLogout();

            return res.data;
        }

        error = res.data.error;
        console.log(`Users sites error: ${error}`);

        return res.data;
    }

    return res.data;
}

export async function getOneUserSiteByUser(id: string): Promise<IGetOneFullByUser> {
    return new Fetch<IGetOneFullByUser>({
        url: `${config.URL}${INSTANCE_PATH}/${id}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getDownloadExcelByUser(query: IDownloadExcelByUserQuery): Promise<IDownloadExcelByUser> {
    return new Fetch<IDownloadExcelByUser>({
        url: `${config.URL}${INSTANCE_PATH}/download`,
        method: 'GET',
        query,
    })
        .onDownload([200], DOWNLOADED_USER_SITES_EXCEL_FILE)
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export function patchUserSiteByUser(id: string, body: IPatchByUserBody): Promise<IPatchByUser> {
    return new Fetch<IPatchByUser>({
        url: `${config.URL}${INSTANCE_PATH}/${id}`,
        method: 'PATCH',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 404, 409, 500], (body) => {
            return body;
        })
        .exec();
}

export async function removeUsersSiteByUser(id: string): Promise<IRemoveByUser> {
    return new Fetch<IRemoveByUser>({
        url: `${config.URL}${INSTANCE_PATH}/${encodeURIComponent(id)}`,
        method: 'DELETE',
    })
        .onEmptyBody([204])
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([403, 404, 500], (body) => {
            return body;
        })
        .exec();
}
