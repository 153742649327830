export const INSTANCE_PATH = '/bids';
export const LS_BIDS_FILTER = 'LS_BIDS_FILTER';

export enum NORM_MODE {
    USER_DEMO = 'USER_DEMO',
    USER_FULL = 'USER_FULL',
}

export enum TYPE {
    INSTALL = 'INSTALL',
    UPGRADE = 'UPGRADE',
    CHANGE = 'CHANGE',
    CLOSE = 'CLOSE',
    SUPPL = 'SUPPL',
    CLAIM = 'CLAIM',
    SUSPEND = 'SUSPEND',
    RESTORE = 'RESTORE',
}

export enum STATUS {
    NEW = 'NEW',
    IN_PROC = 'IN_PROC',
    PROCESSED = 'PROCESSED',
    CLOSED = 'CLOSED',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED',
    CANCEL_REQUESTED = 'CANCEL_REQUESTED',
    ARCHIVE = 'ARCHIVE',
    HOLD = 'HOLD',
    MANUAL_PROC = 'MANUAL_PROC',
}

export enum RELATED_OBJECT_TYPE {
    OPPORTUNITY = 'OPPORTUNITY',
    CASE = 'CASE',
}

export enum SORT_BY {
    COMPLEX_NUMBER = 'complexNumber',
    SERVICE_TYPE = 'serviceType',
    CONTRACT = 'contractNumber',
    ADDRESS = 'siteAddress',
    SVID = 'SVID',
    INITIATOR_FULL_NAME = 'initiatorFullName',
    PERIOD_FROM = 'periodFrom',
    CREATED_AT_BY_INITIATOR = 'createdAtByInitiator',
}
export enum NOTIFICATION_ERROR {
    PERMISSION_DENIED = 'PERMISSION_DENIED',
}
