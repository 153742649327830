export const INSTANCE_PATH = '/services_groups';

export enum ActionTypes {
    GetServicesGroups = 'GET_SERVICES_GROUPS',
    GetServicesGroupsSucceed = 'GET_SERVICES_GROUPS_SUCCEED',

    PostServicesGroup = 'POST_SERVICES_GROUP',
    PostServicesGroupSucceed = 'POST_SERVICES_GROUP_SUCCEED',

    PatchServicesGroup = 'PATCH_SERVICES_GROUP',
    PatchServicesGroupSucceed = 'PATCH_SERVICES_GROUP_SUCCEED',

    RemoveServicesGroup = 'REMOVE_SERVICES_GROUP',
    RemoveServicesGroupSucceed = 'REMOVE_SERVICES_GROUP_SUCCEED',
}
