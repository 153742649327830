import {IIntl} from 'helpers/hooks';

import {ROUTE} from 'components/constants';

import {INTL_DATA} from './intl';

export function requestAnimFrame(cb: any) {
    if (window.requestAnimationFrame) {
        return window.requestAnimationFrame.bind(window)(cb);
    }

    return setTimeout(cb, 16);
}

export function formatRoute(route: ROUTE, intl: IIntl): string {
    switch (route) {
        case ROUTE.LOGIN: {
            return '';
        }
        case (ROUTE.HOME): {
            return intl(INTL_DATA.HOME);
        }
        case (ROUTE.ADMIN) : {
            return intl(INTL_DATA.ADMIN);
        }
        case (ROUTE.USER_SERVICES): {
            return intl(INTL_DATA.USER_SERVICES);
        }
        case (ROUTE.SITES): {
            return intl(INTL_DATA.USER_SITES);
        }
        case (ROUTE.SERVICES_CATALOG): {
            return intl(INTL_DATA.SERVICES_CATALOG);
        }
        case (ROUTE.INCIDENTS): {
            return intl(INTL_DATA.CASES);
        }
        case (ROUTE.BIDS): {
            return intl(INTL_DATA.BIDS);
        }
        case (ROUTE.BILLS): {
            return intl(INTL_DATA.BILLS);
        }
        case (ROUTE.BASKET): {
            return intl(INTL_DATA.BASKET);
        }
        case (ROUTE.NOTIFICATIONS): {
            return intl(INTL_DATA.NOTIFICATIONS);
        }
        case (ROUTE.ORDERS): {
            return intl(INTL_DATA.ORDERS);
        }
        case (ROUTE.SETTINGS): {
            return intl(INTL_DATA.SETTINGS);
        }
    }
}
