import {IProps} from './types';

import {
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
} from '@mui/material';
import React, {
    ChangeEvent,
    FC,
    useId,
    useState,
} from 'react';

import TextMaskCustom from './TextMaskCustom';

const MaterialMaskInput: FC<IProps> = (props) => {
    const inputId = useId();

    const [isFocused, setIsFocused] = useState(false);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange?.(e.target.value);
    };
    const onFocus = () => {
        setIsFocused(true);
        props.onFocus?.();
    };
    const onBlur = () => {
        setIsFocused(false);
        props.onBlur?.();
    };

    return (
        <FormControl fullWidth={true}>
            <InputLabel
                htmlFor={inputId}
                error={props.isError}
                shrink={!!props.value || (!!props.placeholder && isFocused)}
            >
                {props.label}
            </InputLabel>
            <Input
                id={inputId}
                inputProps={{
                    mask: props.mask,
                    pipe: props.confirmValueBeforeDisplay,
                    placeholder: props.placeholder,
                }}
                error={props.isError}
                disabled={props.isDisabled}
                value={props.value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                // @ts-ignore
                inputComponent={TextMaskCustom}
            />
            {
                props.isError && props.helperText && (
                    <FormHelperText error={props.isError}>
                        {props.helperText}
                    </FormHelperText>
                )
            }
        </FormControl>
    );
};

export default MaterialMaskInput;
