import {IIntl} from '../../../../types';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA} from '../intl';

import {ERROR} from './constants';

export function formatError(error: ERROR | null, intl: IIntl | null): string {
    switch (error) {
        case ERROR.IS_REQUIRED: {
            return translateByIntl(intl, INTL_DATA.NEED_TO_CHOOSE_SERVICE, 'Required');
        }
        case ERROR.VIRTUAL_CONTRACTS_MAX_COUNT: {
            return translateByIntl(intl, INTL_DATA.BILLS_MAX_NUMBER, 'Bills max count exceeded');
        }
        case null: {
            return '';
        }
    }
}
