import {IGeoFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA} from '../../intl';

const GeoField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [required, setRequired] = useState(props.field.validation.required);

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): IGeoFieldSpecificProps {
                return {
                    value: null,
                    validation: {
                        required,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {};
            },
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <>
            <div className={'border-bottom border-dark p-2'}/>
            <div className={'m-2'}>
                <h5>
                    {translateByIntl(props.intl, INTL_DATA.VALIDATION, 'Validation')}
                </h5>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, INTL_DATA.REQUIRED, 'Required')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={required}
                            onChange={setRequired}
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

export default GeoField;
