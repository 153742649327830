import {IProps} from './types';

import {
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import React, {
    FC,
    useId,
} from 'react';

const MaterialMultipleSelect: FC<IProps> = (props) => {
    const inputId = useId();

    const onChange = (e: SelectChangeEvent<{name?: string; value: unknown}>) => {
        props.onChange?.(e.target.value);
    };

    return (
        <FormControl fullWidth={true}>
            <InputLabel
                htmlFor={inputId}
                error={props.isError}
                shrink={props.value.length !== 0}
            >
                {props.label}
            </InputLabel>
            <Select
                id={inputId}
                multiple={true}
                value={props.value}
                onChange={onChange}
                input={
                    <Input
                        disabled={props.isDisabled}
                    />
                }
            >
                {
                    props.options.map((option, i) => (
                        <MenuItem
                            key={option}
                            value={props.values?.[i] || option}
                        >
                            {option}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

export default MaterialMultipleSelect;
