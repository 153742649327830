import {IProps} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useEffect,
    useState,
} from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {useIntl} from 'helpers/hooks';

const LinkView: FC<IProps> = (props) => {
    const [active, setActive] = useState(false);
    const location = useLocation();
    const intl = useIntl();
    const navigate = useNavigate();
    const isSticky = props.isSticky;

    useEffect(() => {
        setActive(location.pathname === props.route);
    }, [location]);

    return (
        <li className={'nav-item'}>
            <div
                className={classNames('nav-link fs-5 lh-lg', {active})}
                role={'button'}
                onClick={() => navigate(props.route)}
            >
                <p className={classNames({'mb-1': isSticky})}>
                    {intl(props.intlKey)}
                </p>
            </div>
        </li>
    );
};

export default LinkView;
