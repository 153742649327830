import {IAddressFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA} from '../../intl';

const AddressField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [required, setRequired] = useState(props.field.validation.required);

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): IAddressFieldSpecificProps {
                return {
                    value: null,
                    validation: {
                        required,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {};
            },
            validate(): boolean {
                return true;
            },

        };
    });

    return (
        <div className={'m-2'}>
            <h5>
                {translateByIntl(props.intl, INTL_DATA.VALIDATION, 'Validation')}
            </h5>
            <div className={'row'}>
                <label className={'col-form-label col col-3'}>
                    {translateByIntl(props.intl, INTL_DATA.REQUIRED, 'Required')}
                    :
                </label>
                <div className={'col col-1'}>
                    <MaterialCheckbox
                        value={required}
                        onChange={setRequired}
                    />
                </div>
            </div>
        </div>
    );
});

export default AddressField;
