import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { convertToRaw, EditorState } from 'draft-js';
import { stateFromElement } from 'draft-js-import-element';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class HTMLGenerator extends Component {
    static propTypes = {
        title: PropTypes.string,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        className: PropTypes.string,
        isDisabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const div = document.createElement('div');
        div.innerHTML = this.props.value;
        const state = stateFromElement(div.firstChild);
        this.state = {
            editorState: EditorState.createWithContent(state),
        };
    }

    onEditorStateChange = async (editorState) => {
        await this.setState({
            editorState,
        });
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.props.onChange(html);
    };

    setEditorStateByContent = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        const state = stateFromElement(div.firstChild);
        this.setState({editorState: EditorState.createWithContent(state)});
    };

    render() {
        const { editorState } = this.state;
        return (
            <div className={this.props.className}>
                {
                    this.props.title &&
                    <label className={'col-form-label'}>
                        {this.props.title}
                    </label>
                }
                <Editor
                    editorState={editorState}
                    editorClassName="form-control overflow-hidden"
                    editorStyle={{height: 300}}
                    onEditorStateChange={this.onEditorStateChange}
                    readOnly={this.props.isDisabled}
                />
            </div>
        );
    }
}

export default HTMLGenerator;
