import {
    IOrderInitialState,
    IOrdersFilters,
} from './types';

import {LS_ORDERS_FILTER} from './constants';

export const reducers = {
    mutateFilters: (state: IOrderInitialState, action: {payload: IOrdersFilters}) => {
        state.filters = {
            ...state.filters,
            ...action.payload,
        };

        localStorage.setItem(LS_ORDERS_FILTER, JSON.stringify(state.filters));
    },
};
