import {IProps} from './types';

import React, {FC} from 'react';

import LinkGroupView from './LinkGroupView';
import LinkView from './LinkView';

import {LINKS} from '../constants';

import {getPermissibleLinks} from './functions';

const LinkBar: FC<IProps> = (props) => {
    const {isSticky} = props;
    const {auth} = props;

    if (!auth) {
        return null;
    }

    return (
        <ul className={'navbar-nav'}>
            {
                getPermissibleLinks(auth, LINKS)
                    .map((link) => {
                        if (link.route) {
                            return (
                                <LinkView
                                    key={link.intlKey}
                                    intlKey={link.intlKey}
                                    route={link.route}
                                    isSticky={isSticky}
                                />
                            );
                        }
                        if (link.routes) {
                            return (
                                <LinkGroupView
                                    key={link.intlKey}
                                    intlKey={link.intlKey}
                                    routes={link.routes}
                                    auth={auth}
                                    isSticky={isSticky}
                                />
                            );
                        }
                    })
            }
        </ul>
    );
};

export default LinkBar;
