import {IField} from '../../Fields/types';
import {IApplicationFrame} from '../types';

import {v4} from 'uuid';

export function getDraggableIdForField(field: IField): string {
    return `field-${field.name}`;
}

export function getDraggableIdForFrame(frame: IApplicationFrame): string {
    return `frame-${frame.id}`;
}

export function getDroppableIdForFrame(frame: IApplicationFrame): string {
    return `frame-container-${frame.id}`;
}

export interface IMotionHandlerResult {
    fields: IField[];
    frames: IApplicationFrame[];
}

export function createFrameWithFieldFromUnsetFields(key: string, movedField: IField, fields: IField[], frames: IApplicationFrame[]): IMotionHandlerResult {
    fields = fields.map((field) => ({...field}));
    frames = frames.map((frame) => ({...frame}));

    const unsetFrames = frames.filter((frame) => !frame.step);
    const newFrame: IApplicationFrame = {
        id: v4(),
        title: '',
        text: '',
        row: unsetFrames.length,
    };

    fields.forEach((field) => {
        if (field.name === movedField.name) {
            field[key] = {
                frame: newFrame.id,
                row: 0,
            };
        }
    });
    frames.push(newFrame);

    return {
        fields,
        frames,
    };
}

export function createFrameWithFieldFromOtherFrame(key: string, movedField: IField, sourceFrame: IApplicationFrame, fields: IField[], frames: IApplicationFrame[]): IMotionHandlerResult {
    fields = fields.map((field) => ({...field}));
    frames = frames.map((frame) => ({...frame}));

    const unsetFrames = frames.filter((frame) => !frame.step);
    const newFrame: IApplicationFrame = {
        id: v4(),
        title: '',
        text: '',
        row: unsetFrames.length,
    };

    fields.forEach((field) => {
        if (field.name === movedField.name) {
            field[key] = {
                frame: newFrame.id,
                row: 0,
            };
        }
        if (field[key]?.frame === sourceFrame.id) {
            if (field[key].row) {
                if (field[key].row > movedField[key].row) {
                    field[key].row -= 1;
                }
            }
        }
    });
    frames.push(newFrame);

    return {
        fields,
        frames,
    };
}

export function moveFieldFromUnsetFieldsToFrame(key: string, movedField: IField, movedFieldRow: number, destinationFrame: IApplicationFrame, fields: IField[], frames: IApplicationFrame[]): IMotionHandlerResult {
    fields = fields.map((field) => ({...field}));
    frames = frames.map((frame) => ({...frame}));

    fields.forEach((field) => {
        if (field.name === movedField.name) {
            field[key] = {
                frame: destinationFrame.id,
                row: movedFieldRow,
            };
        }
        if (field[key]?.frame) {
            if (field[key].frame === destinationFrame.id && field.name !== movedField.name) {
                if (field[key].row >= movedFieldRow) {
                    field[key].row += 1;
                }
            }
        }
    });

    return {
        fields,
        frames,
    };
}

export function moveFieldFromFrameToUnsetFields(key: string, movedField, sourceFrame: IApplicationFrame, fields: IField[], frames: IApplicationFrame[]): IMotionHandlerResult {
    const movedFieldRow = movedField[key]?.row;

    fields = fields.map((field) => ({...field}));
    frames = frames.map((frame) => ({...frame}));

    fields.forEach((field) => {
        if (field.name === movedField.name) {
            field[key] = undefined;
        }
        if (field[key]) {
            if (field[key].frame === sourceFrame.id) {
                if (field[key].row > movedFieldRow) {
                    field[key].row -= 1;
                }
            }
        }
    });

    return {
        fields,
        frames,
    };
}

export function moveFieldFromFrameToOtherFrame(key: string, movedField: IField, movedFieldRow: number, sourceFrame: IApplicationFrame, destinationFrame: IApplicationFrame, fields: IField[], frames: IApplicationFrame[]): IMotionHandlerResult {
    const movedFieldRowBefore = movedField[key]?.row;

    fields = fields.map((field) => ({...field}));
    frames = frames.map((frame) => ({...frame}));

    fields.forEach((field) => {
        if (field.name === movedField.name) {
            field[key] = {
                frame: destinationFrame.id,
                row: movedFieldRow,
            };
        }
        if (field[key]) {
            if (sourceFrame.id === destinationFrame.id) {
                if (field[key].frame === sourceFrame.id) {
                    if (movedFieldRowBefore < movedFieldRow) {
                        if (field.name !== movedField.name && movedFieldRowBefore < field[key].row && field[key].row <= movedFieldRow) {
                            field[key].row -= 1;
                        }
                    } else {
                        if (field.name !== movedField.name && movedFieldRowBefore > field[key].row && field[key].row >= movedFieldRow) {
                            field[key].row += 1;
                        }
                    }
                }
            } else {
                if (field[key].frame === sourceFrame.id) {
                    if (field.name !== movedField.name && field[key].row > movedFieldRowBefore) {
                        field[key].row -= 1;
                    }
                }
                if (field[key].frame === destinationFrame.id) {
                    if (field.name !== movedField.name && field[key].row >= movedFieldRow) {
                        field[key].row += 1;
                    }
                }
            }
        }
    });

    return {
        fields,
        frames,
    };
}

export function removeFrame(key: string, removedFrame: IApplicationFrame, fields: IField[], frames: IApplicationFrame[]): IMotionHandlerResult {
    fields = fields.map((field) => ({...field}));
    frames = frames.map((frame) => ({...frame})).filter((frame) => frame.id !== removedFrame.id);

    fields.forEach((field) => {
        if (field[key]?.frame === removedFrame.id) {
            field[key] = undefined;
        }
    });

    return {
        fields,
        frames,
    };
}

export function moveFrameFromUnsetToUnset(movedFrame: IApplicationFrame, movedFrameRow: number, fields: IField[], frames: IApplicationFrame[]): IMotionHandlerResult {
    const movedFrameRowBefore = movedFrame.row;

    fields = fields.map((field) => ({...field}));
    frames = frames.map((frame) => ({...frame}));

    if (movedFrameRowBefore === undefined) {
        return {
            fields,
            frames,
        };
    }

    frames.forEach((frame) => {
        if (frame.step !== undefined) {
            return;
        }
        if (frame.row === undefined) {
            return;
        }
        if (frame.id === movedFrame.id) {
            frame.row = movedFrameRow;
        }
        if (movedFrameRowBefore < movedFrameRow) {
            if (frame.id !== movedFrame.id && movedFrameRowBefore < frame.row && frame.row <= movedFrameRow) {
                frame.row -= 1;
            }
        } else {
            if (frame.id !== movedFrame.id && movedFrameRowBefore > frame.row && frame.row >= movedFrameRow) {
                frame.row += 1;
            }
        }
    });

    return {
        fields,
        frames,
    };
}

export function moveFrameFromStepToUnset(movedFrame: IApplicationFrame, movedFrameRow: number, fields: IField[], frames: IApplicationFrame[]): IMotionHandlerResult {
    const movedFrameRowBefore = movedFrame.row;

    fields = fields.map((field) => ({...field}));
    frames = frames.map((frame) => ({...frame}));

    if (movedFrameRowBefore === undefined) {
        return {
            fields,
            frames,
        };
    }

    frames.forEach((frame) => {
        if (frame.id === movedFrame.id) {
            frame.step = undefined;
            frame.row = movedFrameRow;
        }
        if (frame.row === undefined) {
            return;
        }
        if (frame.id !== movedFrame.id && frame.step === movedFrame.step) {
            if (frame.row > movedFrameRowBefore) {
                frame.row -= 1;
            }
        }
        if (frame.id !== movedFrame.id && frame.step === undefined) {
            if (frame.row >= movedFrameRow) {
                frame.row += 1;
            }
        }
    });

    return {
        fields,
        frames,
    };
}

export function moveFrameFromUnsetToStep(stepNumber: number, movedFrame: IApplicationFrame, movedFrameRow: number, fields: IField[], frames: IApplicationFrame[]): IMotionHandlerResult {
    const movedFrameRowBefore = movedFrame.row;

    fields = fields.map((field) => ({...field}));
    frames = frames.map((frame) => ({...frame}));

    if (movedFrameRowBefore === undefined) {
        return {
            fields,
            frames,
        };
    }

    frames.forEach((frame) => {
        if (frame.id === movedFrame.id) {
            frame.step = stepNumber;
            frame.row = movedFrameRow;
        }
        if (frame.row === undefined) {
            return;
        }
        if (frame.id !== movedFrame.id && frame.step === stepNumber) {
            if (frame.row >= movedFrameRow) {
                frame.row += 1;
            }
        }
        if (frame.id !== movedFrame.id && frame.step === undefined) {
            if (frame.row < movedFrameRowBefore) {
                frame.row -= 1;
            }
        }
    });

    return {
        fields,
        frames,
    };
}

export function moveFrameFromStepToStep(stepNumber: number, movedFrame: IApplicationFrame, movedFrameRow: number, fields: IField[], frames: IApplicationFrame[]): IMotionHandlerResult {
    const movedFrameRowBefore = movedFrame.row;

    fields = fields.map((field) => ({...field}));
    frames = frames.map((frame) => ({...frame}));

    if (movedFrameRowBefore === undefined) {
        return {
            fields,
            frames,
        };
    }

    frames.forEach((frame) => {
        if (frame.step !== stepNumber) {
            return;
        }
        if (frame.row === undefined) {
            return;
        }
        if (frame.id === movedFrame.id) {
            frame.row = movedFrameRow;
        }
        if (movedFrameRowBefore < movedFrameRow) {
            if (frame.id !== movedFrame.id && movedFrameRowBefore < frame.row && frame.row <= movedFrameRow) {
                frame.row -= 1;
            }
        } else {
            if (frame.id !== movedFrame.id && movedFrameRowBefore > frame.row && frame.row >= movedFrameRow) {
                frame.row += 1;
            }
        }
    });

    return {
        fields,
        frames,
    };
}
