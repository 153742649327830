// TODO: выпилить
export enum INTL_DATA {
    HAS_BEEN_DELETED = 'users/listen/has-been-deleted',
    HAS_BEEN_UPDATED = 'users/listen/has-been-updated',

    AUTH_USER_HAS_BEEN_DELETED = 'users/listen/auth-user-has-been-deleted',
    AUTH_USER_HAS_BEEN_UPDATED = 'users/listen/auth-user-has-been-updated',

    CHANGE_SERVICES = 'admin/users/change_services',
    CHECKOUT_BASKET = 'admin/users/checkout_basket',
    DISABLE_SERVICES = 'admin/users/disable_services',
}

export enum INTL_USER_TYPE {
    SUPER_ADMIN = 'global/user-type/super-admin',
    ORANGE_ADMIN = 'global/user-type/orange-admin',
    CLIENT_ADMIN = 'global/user-type/client-admin',
    SIMPLE_USER = 'global/user-type/simple-user',
}
