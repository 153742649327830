import {IProps} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useEffect,
    useState,
} from 'react';
import ReactModal from 'react-modal';

import {Button} from 'components/Helpers/Buttons';
import {Loading} from 'components/Helpers/Other';

import style from './confirmModal.module.css';
import {
    activeCloseIcon,
    closeIcon,
} from './media';

const ConfirmModal: FC<IProps> = (props) => {
    const [isHover, setIsHover] = useState(false);

    useEffect(() => {
        if (props.isOpen) {
            document.body.style.overflowY = 'hidden';
        }

        return () => {
            document.body.style.overflowY = 'initial';
        };
    }, [props.isOpen]);

    return (
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={() => props.onClose()}
            className={style.confirmationModal}
            overlayClassName={classNames('d-flex flex-row justify-content-center', style.overlayInConfirmModal, {
                'mt-5': !!props.isNested,
            })}
            ariaHideApp={false}
        >
            {
                !props.isPure &&
            <div className={'row mt-2'}>
                <div className={'col-1'}/>
                <div className={'col-10'}>
                    <div className={'d-flex flex-row justify-content-start'}>
                        <h3>
                            {props.title}
                        </h3>
                    </div>
                </div>
                <div className={'col-1 cursor-pointer'} onClick={() => props.onClose()}>
                    <div
                        className={'d-flex justify-content-center align-items-center'}
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                    >
                        <img src={isHover ? activeCloseIcon : closeIcon} alt={''}/>
                    </div>
                </div>
            </div>
            }
            {
                props.isLoading ?
                    <div className={'mt-4'}>
                        <Loading size={70}/>
                    </div> :
                    <>
                        <div className={'fs-4 m-2 text-center'}>
                            {props.message}
                        </div>
                        <div className={'d-flex flex-row justify-content-around'}>
                            <Button
                                text={props.translations ? props.translations.onApply : 'Да'}
                                onClick={props.onApply}
                                isDisabled={props.isLoading}
                                className={'me-3 gap-3'}
                                style={'primary'}
                                autoFocus={true}
                            />
                            <Button
                                text={props.translations ? props.translations.onDecline : 'Нет'}
                                onClick={props.onClose}
                                isDisabled={props.isLoading}
                                style={'secondary'}
                                className={'ms-3 gap-3'}
                            />
                        </div>
                    </>
            }
        </ReactModal>
    );
};

export default ConfirmModal;
