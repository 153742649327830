import {
    IGet,
    IPostLogout,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {INSTANCE_PATH} from './constants';
import {onLogout} from './functions';

export async function login(): Promise<void> {
    document.location.replace(`${config.URL}${INSTANCE_PATH}/login`);
}

export async function get(): Promise<IGet> {
    return new Fetch<IGet>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postLogout(): Promise<IPostLogout> {
    return new Fetch<IPostLogout>({
        url: `${config.URL}${INSTANCE_PATH}/logout`,
        method: 'POST',
    })
        .onEmptyBody([204], () => {
            onLogout();
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec();
}
