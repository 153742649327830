import {
    IProps,
    ISpecificDataForm,
} from './types';

import React, {forwardRef} from 'react';

import Lock from './Lock';
import Unlock from './Unlock';

import {TRIGGER_TYPE} from '../../constants';

const SpecificDataForm = forwardRef<ISpecificDataForm, IProps>((props, ref) => {
    switch (props.trigger.type) {
        case TRIGGER_TYPE.LOCK: {
            return (
                <Lock
                    ref={ref}
                    targetFieldName={props.targetFieldName}
                    trigger={props.trigger}
                    fields={props.fields}
                    intl={props.intl}
                    translation={props.translation}
                />
            );
        }
        case TRIGGER_TYPE.UNLOCK: {
            return (
                <Unlock
                    ref={ref}
                    targetFieldName={props.targetFieldName}
                    trigger={props.trigger}
                    fields={props.fields}
                    intl={props.intl}
                    translation={props.translation}
                />
            );
        }
    }
});

export default SpecificDataForm;
