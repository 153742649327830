import {
    IUserSite,
    IUserSiteFull,
} from './types/instance';
import {
    IGetDemoByUserQuery,
    IGetSuggestionsUser,
} from 'instances/userSites/types/requests';

import {
    DEFAULT_FIELDS_NAMES,
    SORT_BY,
} from './constants';

export function getSiteCustomerAddress(site: IUserSiteFull | null | undefined): string {
    const field = site?.fields?.find((field) => field.name === DEFAULT_FIELDS_NAMES.CustomerRef);

    // @ts-ignore
    return field?.value || site?.customerSiteRef || '';
}

export function getSiteCoordinates(site: IUserSite | undefined) {
    const geoData = site?.geoData;

    if (!geoData) {
        return null;
    }

    const {lat, lng} = geoData;

    return isNaN(Number(lat)) || isNaN(Number(lng)) ?
        null : [Number(lat), Number(lng)];
}

export function getDefaultSuggestionsQ(): IGetSuggestionsUser {
    return {
        customerSiteRef: '',
        siteId: '',
        address: '',
        city: '',
    };
}

export function getDefaultQueryDemoUser(): IGetDemoByUserQuery {
    return {
        skip: 0,
        limit: 20,
        sortBy: SORT_BY.CUSTOMER_SITE_REF,
        sortOrder: true,

        address: [],
        customerSiteRef: [],
        siteId: [],
        city: [],
        status: [],
    };
}
