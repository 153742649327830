import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialInput} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../helpers';

const NumberField: FC<IProps> = (props) => {
    return (
        <MaterialInput
            type={'number'}
            label={translateByObject(props.field.title, props.translation)}
            value={props.field.value}
            isDisabled={true}
        />
    );
};

export default NumberField;
