import React, {FC} from 'react';

import {
    Loading,
    Modal,
} from 'components/Helpers/Other';

interface IProps {
    isOpen: boolean;
    isPending: boolean;

    onAccept(): void;
    onDecline(): void;
}

const ConfirmEquipmentDataModal: FC<IProps> = (props) => {
    return (
        <Modal
            isOpen={props.isOpen}
            title={'Подтвердите действие'}
            onClose={props.onDecline}
        >
            <div className={'d-flex flex-row justify-content-center mt-4 mb-4'}>
                <b>
                    После подтверждения, сформируется pdf-файл, который необходимо подписать усиленной квалифицированной электронной подписью и отправить своему account-менеджеру или на почтовый ящик
                    {' '}
                    <a
                        className={'cursor-pointer'}
                        href={'mailto:rus-sales@orange.com'}
                        target={'_blank'}
                        rel={'noreferrer'}
                    >
                        rus-sales@orange.com
                    </a>
                    .
                </b>
            </div>
            {
                props.isPending ?
                    <Loading size={30}/> :
                    <div className={'d-flex flex-row justify-content-around mb-4'}>
                        <button
                            className={'btn btn-secondary'}
                            onClick={props.onAccept}
                        >
                            Да
                        </button>
                        <button
                            className={'btn btn-secondary'}
                            onClick={props.onDecline}
                        >
                            Нет
                        </button>
                    </div>
            }
        </Modal>
    );
};

export default ConfirmEquipmentDataModal;
