import {IField} from '../../../../Fields/types';
import {ITrigger} from '../../../types';

export function updater(trigger: ITrigger, fields: IField[]): ITrigger | undefined {
    trigger.conditions = trigger.conditions.filter((condition) => {
        return fields.find((field) => field.name === condition.name);
    });

    if (!trigger.conditions.length) {
        return undefined;
    }

    return trigger;
}
