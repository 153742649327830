import {IIntl} from '../../../../types';

import {translateByIntl} from '../../../../helpers';
import {DA_DATA_TYPE} from '../constants';
import {INTL_DATA} from '../intl';

export function formatType(type: DA_DATA_TYPE, intl: IIntl | null): string {
    switch (type) {
        case DA_DATA_TYPE.ADDRESS: {
            return translateByIntl(intl, INTL_DATA.ADDRESS, 'Address');
        }
        case DA_DATA_TYPE.REGION: {
            return translateByIntl(intl, INTL_DATA.REGION, 'Region');
        }
        case DA_DATA_TYPE.COMPANY: {
            return translateByIntl(intl, INTL_DATA.COMPANY, 'Company');
        }
    }
}
