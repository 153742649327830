import {INameInput} from './NameInput/types';
import {IReadOnlyInput} from './ReadOnlyInput/types';
import {ITitleInput} from './TitleInput/types';
import {ITooltipInput} from './TooltipInput/types';
import {ITypeInput} from './TypeInput/types';
import {
    ICommonData,
    ICommonDataForm,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
} from 'react';

import NameInput from './NameInput';
import ReadOnlyInput from './ReadOnlyInput';
import TitleInput from './TitleInput';
import TooltipInput from './TooltipInput';
import TypeInput from './TypeInput';

const CommonDataForm = forwardRef<ICommonDataForm, IProps>((props, ref) => {
    const nameInputRef = useRef<INameInput>(null);
    const readOnlyInputRef = useRef<IReadOnlyInput>(null);
    const titleInputRef = useRef<ITitleInput>(null);
    const tooltipInputRef = useRef<ITooltipInput>(null);
    const typeInputRef = useRef<ITypeInput>(null);

    useImperativeHandle(ref, () => {
        return {
            getValue(): ICommonData {
                return {
                    name: nameInputRef.current?.getValue() ?? props.field.name,
                    title: titleInputRef.current?.getValue() ?? props.field.title,
                    tooltip: tooltipInputRef.current?.getValue() ?? props.field.tooltip,
                    readOnly: readOnlyInputRef.current?.getValue() ?? props.field.readOnly,
                };
            },
            validate(): boolean {
                return ![
                    nameInputRef.current?.validate(),
                    tooltipInputRef.current?.validate(),
                    typeInputRef.current?.validate(),
                    tooltipInputRef.current?.validate(),
                    readOnlyInputRef.current?.validate(),
                ].includes(false);
            },
        };
    });

    return (
        <div className={'p-2'}>
            <div className={'row'}>
                <div className={'col-4'}>
                    <NameInput
                        ref={nameInputRef}
                        initValue={props.field.name}
                        names={props.fields.map((field) => field.name)}
                        isDisabled={props.isDefaultField}
                        intl={props.intl}
                    />
                </div>
                <div className={'col-4'}>
                    <TitleInput
                        ref={titleInputRef}
                        initValue={props.field.title}
                        intl={props.intl}
                    />
                </div>
                <div className={'col-4'}>
                    <TypeInput
                        ref={typeInputRef}
                        initValue={props.field.type}
                        types={props.types}
                        isDisabled={props.isDefaultField}
                        intl={props.intl}
                        onChange={props.onChangeType}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-6'}>
                    <TooltipInput
                        ref={tooltipInputRef}
                        initValue={props.field.tooltip}
                        intl={props.intl}
                    />
                </div>
                <div className={'col-6'}>
                    <ReadOnlyInput
                        ref={readOnlyInputRef}
                        initValue={props.field.readOnly}
                        intl={props.intl}
                    />
                </div>
            </div>
        </div>
    );
});

export default CommonDataForm;
