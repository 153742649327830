import {ISelectFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import Values from './Values';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA as FIELDS_INTL_DATA} from '../../intl';
import {INTL_DATA} from '../intl';

const SelectField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [withDefaultValue, setWithDefaultValue] = useState(props.field.withDefaultValue);
    const [value, setValue] = useState(props.field.value);
    const [values, setValues] = useState(props.field.values);
    const [required, setRequired] = useState(props.field.validation.required);

    const addValue = (value: string) => {
        if (!values.length) {
            setValue(value);
        }

        setValues([...values, value]);
    };
    const removeValue = (index: number) => {
        if (values.length === 1) {
            setValue('');
        }

        const valueToRemove = values[index];
        const newValues = values.filter((value, i) => i !== index);

        setValues(newValues);

        if (withDefaultValue) {
            if (value === valueToRemove) {
                setValue(newValues[0] || '');
            }
        }
    };

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): ISelectFieldSpecificProps {
                return {
                    withDefaultValue,
                    value: withDefaultValue ? value : '',
                    values,
                    validation: {
                        required,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {
                    ...values.reduce(
                        (res, current) => ({
                            ...res,
                            [current]: current,
                        }),
                        {}
                    ),
                };
            },
            validate(): boolean {
                return true;
            },
        };
    });
    useEffect(() => {
        if (!values.length) {
            setWithDefaultValue(false);
        }
    }, [values]);
    useEffect(() => {
        if (withDefaultValue) {
            setValue(values[0] || '');
        } else {
            setValue('');
        }
    }, [withDefaultValue]);

    return (
        <>
            <div className={'border-bottom border-dark'}>
                <div className={'m-2'}>
                    <div className={'row'}>
                        <label className={'col-form-label col col-4'}>
                            {translateByIntl(props.intl, INTL_DATA.WITH_DEFAULT_VALUE, 'With default value')}
                            :
                        </label>
                        <div className={'col col-1'}>
                            <MaterialCheckbox
                                value={withDefaultValue}
                                isDisabled={!!values.length}
                                onChange={setWithDefaultValue}
                            />
                        </div>
                    </div>
                    <div className={'mt-2'}>
                        <label>
                            {translateByIntl(props.intl, FIELDS_INTL_DATA.VALUES, 'Values')}
                            :
                        </label>
                        <Values
                            value={value}
                            values={values}
                            withDefaultValue={withDefaultValue}
                            onAdd={addValue}
                            onRemove={removeValue}
                            onSetDefault={(value) => setValue(value)}
                            intl={props.intl}
                        />
                    </div>
                </div>
            </div>
            <div className={'m-2'}>
                <h5>
                    {translateByIntl(props.intl, FIELDS_INTL_DATA.VALIDATION, 'Validation')}
                </h5>
                <div className={'row'}>
                    <label className={'col-form-label col col-3'}>
                        {translateByIntl(props.intl, FIELDS_INTL_DATA.REQUIRED, 'Required')}
                        :
                    </label>
                    <div className={'col col-1'}>
                        <MaterialCheckbox
                            value={required}
                            onChange={setRequired}
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

export default SelectField;
