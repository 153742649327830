import {IProps} from './types';

import React, {FC} from 'react';

import BoostedSpecificDisplay from './BoostedSpecificDisplay';

const BoostedDisplayField: FC<IProps> = (props) => {
    return (
        <BoostedSpecificDisplay
            field={props.field}
            translation={props.translation || null}
            intl={props.intl || null}
        />
    );
};

export default BoostedDisplayField;
