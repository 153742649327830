import {ITitleForm} from './TitleForm/types';
import {
    IProps,
    IStepTitlesForm,
} from './types';

import React, {
    createRef,
    forwardRef,
    RefObject,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import TitleForm from './TitleForm';

import {translateByIntl} from '../../../helpers';

import {INTL_DATA} from './intl';

const StepTitlesForm = forwardRef<IStepTitlesForm, IProps>((props, ref) => {
    const [titleFormRefs, setTitleFormRefs] = useState<RefObject<ITitleForm>[]>([]);

    useImperativeHandle(ref, () => {
        return {
            getValue(): string[] {
                return titleFormRefs
                    .map((ref) => ref.current?.getValue() || '');
            },
            validate(): boolean {
                return !titleFormRefs
                    .map((ref) => ref.current?.validate())
                    .includes(false);
            },
        };
    });
    useEffect(() => {
        const titleFormRefs = props.applicationStepTitles.map(() => createRef<ITitleForm>());

        setTitleFormRefs(titleFormRefs);
    }, [props.applicationStepTitles]);

    return (
        <div>
            <h5>
                {translateByIntl(props.intl, INTL_DATA.APPLICATION_TITLES, 'Application step titles')}
            </h5>
            {
                props.applicationStepTitles.map(
                    (title, i) =>
                        <TitleForm
                            key={i}
                            ref={titleFormRefs[i]}
                            title={title}
                            titleTranslation={props.applicationStepTitleTranslations[i] || null}
                            stepNumber={i + 1}
                            intl={props.intl}
                        />
                )
            }
        </div>
    );
});

export default StepTitlesForm;
