import {IGetByAdminQuery} from './types/requests';

import {ACCESS_STATUS_FILTER} from 'instances/equippedClients/constants';

export function logoutEquippedClient() {
    window.location.pathname !== '/pdn/login' && window.location.replace('/pdn/login');
}

export function getDefaultQueryAdmin(): IGetByAdminQuery {
    return {
        skip: 0,
        limit: 20,

        inn: '',
        clientName: '',
        clientId: '',
        clientType: '',

        accessStatus: ACCESS_STATUS_FILTER.ALL,
    };
}
