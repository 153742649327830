import {IProps} from './types';

import React, {
    FC,
    useId,
} from 'react';

import {MaterialTooltip} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../helpers';
import {
    formatUserTooltip,
    formatValue,
} from '../functions';

import questionImage from '../media/question.png';

const BoostedUserField: FC<IProps> = (props) => {
    const elemId = useId();

    return (
        <div className={'d-flex flex-column justify-content-between align-items-center w-100'}>
            <label
                htmlFor={elemId}
                className={'form-label align-self-start'}
            >
                {translateByObject(props.field.title, props.translation)}
            </label>
            <div className={'d-flex flex-row w-100'}>
                <input
                    className={'form-control'}
                    id={elemId}
                    disabled={true}
                    value={formatValue(props.field.value)}
                />
                {
                    props.field.value &&
                    <MaterialTooltip
                        text={formatUserTooltip(props.field.value, props.intl)}
                        placement={'bottom'}
                    >
                        <img
                            width={'30'}
                            height={'30'}
                            src={questionImage}
                            alt={''}
                        />
                    </MaterialTooltip>
                }
            </div>
        </div>
    );
};

export default BoostedUserField;
