export const INSTANCE_PATH = '/user_services';

export const LS_USER_SERVICES_FILTER = 'LS_USER_SERVICES_FILTER';

export enum STATUS {
    ACTIVE = 'ACTIVE',
    IN_PROGRESS_OR_READY = 'IN_PROGRESS_OR_READY',
    SUSPENDED = 'SUSPENDED',
    CHANGE_PLANNED = 'CHANGE_PLANNED',
}

export enum NORM_MODE {
    USER_DEMO = 'USER_DEMO',
    USER_FULL = 'USER_FULL',
}

export enum SORT_BY {
    TYPE = 'type',
    SVID = 'SVID',
    CID = 'CID',
    ORDER_ADDENDUM_NUMBER = 'orderAddendumNumber',
    SITE_ADDRESS = 'siteAddress',
    SITE_ID = 'siteId',
    CUSTOMER_USERS_SITE_REF = 'customerUserSiteRef',
    CITY = 'city',
    CLIENT_KE_REF = 'clientKERef',
    CONTRACT_NUMBER = 'contractNumber',
    STATUS = 'status',
    CLIENT_SERVICE_ID = 'clientServId'
}

export enum DEFAULT_FIELD_NAME {
    SITE = 'address',
    CONTRACT = 'contract',
}

export enum NOTIFICATION_ERROR {
    PERMISSION_DENIED = 'PERMISSION_DENIED',
}

export const DOWNLOADED_USER_SERVICES_EXCEL_FILE = 'Мои услуги.xlsx';
