import {
    ICheckoutByUserBody,
    ICheckoutOneByUserBody,
    IGetDemoByUserQuery,
    IGetOneFullByUserQuery,
    IPatchByUserBody,
    IPostByUserBody,
} from './types/requests';
import {
    ICheckoutManyByUser,
    ICheckoutOneByUser,
    IGetByUser,
    IGetOneFullByUser,
    IPatchByUser,
    IPostByUser,
    IRemoveByUser,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {onLogout} from 'instances/auth/functions';

import {INSTANCE_PATH} from './constants';

export async function getDemoByUser(query: IGetDemoByUserQuery): Promise<IGetByUser> {
    return new Fetch<IGetByUser>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([500, 403], (body) => {
            return body;
        })
        .exec();
}

export async function getOneFullByUser(id: string, query: IGetOneFullByUserQuery): Promise<IGetOneFullByUser> {
    return new Fetch<IGetOneFullByUser>({
        url: `${config.URL}${INSTANCE_PATH}/${id}`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postByUser(body: IPostByUserBody): Promise<IPostByUser> {
    return new Fetch<IPostByUser>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function patchByUser(body: IPatchByUserBody): Promise<IPatchByUser> {
    return new Fetch<IPatchByUser>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'PATCH',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function checkoutByUser(body: ICheckoutByUserBody): Promise<ICheckoutManyByUser> {
    return new Fetch<ICheckoutManyByUser>({
        url: `${config.URL}${INSTANCE_PATH}/checkout`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function checkoutOneByUser(body: ICheckoutOneByUserBody): Promise<ICheckoutOneByUser> {
    return new Fetch<ICheckoutOneByUser>({
        url: `${config.URL}${INSTANCE_PATH}/checkout_one`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function removeByUser(id: string): Promise<IRemoveByUser> {
    return new Fetch<IRemoveByUser>({
        url: `${config.URL}${INSTANCE_PATH}/${encodeURIComponent(id)}`,
        method: 'DELETE',
    })
        .onEmptyBody([204])
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}
