import {
    IClientAdmin,
    IClientUser,
} from './types';
import {
    IGetByAdminQuery,
    IPatchByAdminBody,
    IPostByAdminBody,
} from './types/requests';
import {
    IGetByAdmin,
    IGetByUser,
    IGetOneByAdmin,
    IPatchByAdmin,
    IPostByAdmin,
} from './types/responses';
import {ISuggestionsAdmin} from './types/suggestions';

import {useState} from 'react';

import {useIntl} from 'helpers/hooks';
import Notifier from 'helpers/Notifier';

import * as fetches from './fetches';

export function useClientsByAdmin() {
    const [clients, setClients] = useState<IClientAdmin[] | null>(null);
    const [clientsSuggestions, setClientsSuggestions] = useState<ISuggestionsAdmin | null>(null);
    const [clientsTotal, setClientsTotal] = useState<number | null>(null);

    const intl = useIntl();

    const getClients = async (query: IGetByAdminQuery): Promise<IGetByAdmin> => {
        query.skip === 0 && setClients(null);

        const res = await fetches.getByAdmin(query);

        if (!res.clients || !res.suggestions || res.total === undefined || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }

        setClients([...(query.skip === 0 ? [] : clients || []), ...res.clients]);
        setClientsSuggestions(res.suggestions);
        setClientsTotal(res.total);

        return res;
    };
    const postClient = async (body: IPostByAdminBody): Promise<IPostByAdmin> => {
        const res = await fetches.postByAdmin(body);

        if (res.conflictError) {
            return res;
        }
        if (!res.client || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }

        setClients([res.client, ...(clients || [])]);
        setClientsTotal((clientsTotal || 0) + 1);

        return res;
    };
    const patchClient = async (body: IPatchByAdminBody): Promise<IPatchByAdmin> => {
        const res = await fetches.patchByAdmin(body);

        if (res.conflictError) {
            return res;
        }
        if (!res.client || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }
        if (!clients) {
            return res;
        }

        const index = clients.findIndex((oldClient) => oldClient.id === res.client?.id);

        clients[index] = res.client;
        setClients([...clients]);

        return res;
    };

    return {
        clients,
        clientsSuggestions,
        clientsTotal,
        getClients,
        postClient,
        patchClient,
    };
}

export function useClientByAdmin() {
    const [client, setClient] = useState<IClientAdmin | null>(null);

    const intl = useIntl();

    const getClient = async (clientId: string): Promise<IGetOneByAdmin> => {
        const res = await fetches.getOneByAdmin(clientId);

        if (!res.client || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }

        setClient(res.client);

        return res;
    };

    const removeClient = async (clientId: string): Promise<IPatchByAdmin> => {
        const res = await fetches.removeClientByAdmin(clientId);

        if (!res.client || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }
        if (!client) {
            return res;
        }

        return res;
    };

    const activateClient = async (clientId: string): Promise<IPatchByAdmin> => {
        const res = await fetches.activateClientByAdmin(clientId);

        if (!res.client || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }
        if (!client) {
            return res;
        }

        return res;
    };

    return {
        client,
        getClient,
        removeClient,
        activateClient,
    };
}

export function useClientByUser() {
    const [client, setClient] = useState<IClientUser | null>(null);

    const intl = useIntl();

    const getClient = async (): Promise<IGetByUser> => {
        setClient(null);

        const res = await fetches.getByUser(false);

        if (!res.client || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }

        setClient(res.client);

        return res;
    };

    return {
        client,
        getClient,
    };
}
