import {
    IRangeFieldSpecificProps,
    IValue,
} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {
    parseNumber,
    translateByIntl,
} from '../../../../helpers';
import {INTL_DATA as FIELDS_INTL_DATA} from '../../intl';
import {INTL_DATA} from '../intl';

const RangeField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [min, setMin] = useState(props.field.min);
    const [max, setMax] = useState(props.field.max);
    const [step, setStep] = useState(props.field.step);
    const [isDoubleRange, setIsDoubleRange] = useState(props.field.isDoubleRange);
    const [required] = useState(props.field.validation.required);

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): IRangeFieldSpecificProps {
                const value: IValue = isDoubleRange ? [min, max] : min;

                return {
                    value,
                    min,
                    max,
                    step,
                    isDoubleRange,
                    validation: {
                        required,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {};
            },
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <div className={'m-2'}>
            <div className={'row mt-2'}>
                <div className={'col-4'}>
                    <label className={'col-form-label'}>
                        {translateByIntl(props.intl, FIELDS_INTL_DATA.MIN, 'Min')}
                    </label>
                </div>
                <div className={'col-2'}>
                    <input
                        className={'form-control'}
                        type={'number'}
                        value={min}
                        onChange={(e) => setMin(parseNumber(e.target.value) || 0)}
                    />
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={'col-4'}>
                    <label className={'col-form-label'}>
                        {translateByIntl(props.intl, FIELDS_INTL_DATA.MAX, 'Max')}
                    </label>
                </div>
                <div className={'col-2'}>
                    <input
                        className={'form-control'}
                        type={'number'}
                        value={max}
                        onChange={(e) => setMax(parseNumber(e.target.value) || 0)}
                    />
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={'col-4'}>
                    <label className={'col-form-label'}>
                        {translateByIntl(props.intl, FIELDS_INTL_DATA.STEP, 'Step')}
                    </label>
                </div>
                <div className={'col-2'}>
                    <input
                        className={'form-control'}
                        type={'number'}
                        value={step}
                        min={1}
                        max={max}
                        onChange={(e) => setStep(parseNumber(e.target.value) || 1)}
                    />
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={'col-4'}>
                    <label className={'col-form-label'}>
                        {translateByIntl(props.intl, INTL_DATA.DOUBLE_RANGE, 'Double range')}
                        :
                    </label>
                </div>
                <div className={'col-2'}>
                    <MaterialCheckbox
                        value={isDoubleRange}
                        onChange={setIsDoubleRange}
                    />
                </div>
            </div>
        </div>
    );
});

export default RangeField;
