import {IIntl} from '../../../../types';

import {translateByIntl} from '../../../../helpers';

import {ERROR} from './constants';
import {INTL_DATA} from './intl';

export function formatError(error: ERROR | null, intl: IIntl | null): string {
    switch (error) {
        case ERROR.IS_REQUIRED: {
            return translateByIntl(intl, INTL_DATA.IS_REQUIRED, 'Required');
        }
        case ERROR.NOT_UNIQUE: {
            return translateByIntl(intl, INTL_DATA.NOT_UNIQUE, 'Must be unique');
        }
        case ERROR.INVALID_FORMAT: {
            return translateByIntl(intl, INTL_DATA.INVALID_FORMAT, 'Only english and "_" symbols allowed');
        }
        case null: {
            return '';
        }
    }
}
