import {IValue as IAddressFieldValue} from '../../../AddressField/types';
import {IValue as IContractFieldValue} from '../../../ContractField/types';
import {IProps} from './types';

import React, {FC} from 'react';

import {translateByObject} from '../../../../../helpers';
import {formatValue as formatAddress} from '../../../AddressField/functions';
import {formatValue as formatContract} from '../../../ContractField/functions';
import {
    DESTINATION_NEW_VALUE,
    MIGRATE_TO,
} from '../../constants';

import styles from './Place.module.scss';

const Place: FC<IProps> = (props) => {
    switch (props.place) {
        case DESTINATION_NEW_VALUE: {
            return (
                <div className={styles.hideOverflowText}>
                    {translateByObject(props.newDestinationItemTitle, props.translation)}
                </div>
            );
        }
        case null: {
            return null;
        }
    }

    switch (props.migrateTo) {
        case MIGRATE_TO.BILL: {
            return (
                <div className={styles.hideOverflowText}>
                    {formatContract(props.place as IContractFieldValue)}
                </div>
            );
        }
        case MIGRATE_TO.CONTRACT: {
            return (
                <div className={styles.hideOverflowText}>
                    {formatContract(props.place as IContractFieldValue)}
                </div>
            );
        }
        case MIGRATE_TO.SITE: {
            return (
                <div className={styles.hideOverflowText}>
                    {formatAddress(props.place as IAddressFieldValue)}
                </div>
            );
        }
    }
};

export default Place;
