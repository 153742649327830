import {
    IProps,
    ITitleForm,
} from './types';

import classNames from 'classnames';
import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    capitalize,
    translateByIntl,
} from '../../../../helpers';

import {ERROR} from './constants';
import {formatError} from './functions';
import {INTL_DATA} from './intl';

const TitleForm = forwardRef<ITitleForm, IProps>((props, ref) => {
    const [titleTranslation, setTitleTranslation] = useState(props.titleTranslation || props.title);
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (translation: string) => {
        setTitleTranslation(capitalize(translation));
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): string {
                return titleTranslation;
            },
            validate(): boolean {
                if (props.title && !titleTranslation) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <div className={'row'}>
            <div className={'col-4'}>
                <div>
                    <label className={'col-form-label'}>
                        {translateByIntl(props.intl, INTL_DATA.STEP, 'Step')}
                        {' '}
                        {props.stepNumber}
                    </label>
                </div>
                {
                    !!error &&
                    <div className={'text-danger'}>
                        {formatError(error, props.intl)}
                    </div>
                }
            </div>
            <div className={'col-4'}>
                <input
                    className={'form-control'}
                    value={props.title}
                    disabled={true}
                />
            </div>
            <div className={'col-4'}>
                <div>
                    <input
                        className={
                            classNames('form-control', {
                                'is-invalid': !!error,
                            })
                        }
                        value={titleTranslation}
                        onChange={(e) => onChange(e.target.value)}
                    />
                </div>
            </div>
        </div>
    );
});

export default TitleForm;
