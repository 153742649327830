import {
    IOrderInitialState,
    IOrdersCrumbs,
    IOrdersFilters,
} from './types';

import {SORT_BY} from './constants';

export function getInitState(): IOrderInitialState {
    return {
        orders: null,
        suggestions: {
            orderNumber: [],
            clientId: [],
            contractNumber: [],
            internalContractNumber: [],
            custno: [],
            internalContractNo: [],
            custname: [],

            salesEmail: [],
            salesName: [],

            coordinatorEmail: [],
            coordinatorName: [],

            orderDate: [],
            planDate: [],
            installDate: [],
            actDate: [],

            status: [],
            holdDate: [],
            allNotes: [],
            statusCause: [],

            clientEmail: [],

            addrId: [],
            address: [],
            city: [],
        },
        total: null,
        currentTotal: null,
        filters: getDefaultFilters(),
        crumbs: [],
        isLoading: true,
        error: undefined,
    };
}

export function getDefaultFilters(): IOrdersFilters {
    return {
        locale: 'ru',
        skip: 0,
        limit: 1000,

        sortBy: SORT_BY.ORDER_DATE,
        sortOrder: false,

        contractNumber: [],
        orderNumber: [],
        city: [],
        status: [],
        orderDateFrom: [],
        orderDateTo: [],
        planDateFrom: [],
        planDateTo: [],
        installDateFrom: [],
        installDateTo: [],
        salesName: [],
        coordinatorName: [],
    };
}

export function getCrumbsByFilters(filters: IOrdersFilters | null): [string, string[]][] {
    const result: IOrdersCrumbs = {
        contractNumber: [],
        orderNumber: [],
        city: [],
        status: [],
        orderDateFrom: [],
        orderDateTo: [],
        planDateFrom: [],
        planDateTo: [],
        installDateFrom: [],
        installDateTo: [],
        salesName: [],
        coordinatorName: [],
    };

    if (!filters) return [];

    for (const key in filters) {
        switch (key) {
            case 'orderNumber': {
                filters['orderNumber'].length &&
                typeof filters['orderNumber'] === 'string' ?
                    result['orderNumber'] = [filters['orderNumber']] :
                    filters['orderNumber']
                        .forEach((elem) => result['orderNumber'].push(elem));
                break;
            }
            case 'clientId': {
                filters['clientId'].length &&
                typeof filters['clientId'] === 'string' ?
                    result['clientId'] = [filters['clientId']] :
                    filters['clientId']
                        .forEach((elem) => result['clientId'].push(elem));
                break;
            }
            case 'contractNumber': {
                filters['contractNumber'].length &&
                typeof filters['contractNumber'] === 'string' ?
                    result['contractNumber'] = [filters['contractNumber']] :
                    filters['contractNumber']
                        .forEach((elem) => result['contractNumber'].push(elem));
                break;
            }
            case 'internalContractNumber': {
                filters['internalContractNumber'].length &&
                typeof filters['internalContractNumber'] === 'string' ?
                    result['internalContractNumber'] = [filters['internalContractNumber']] :
                    filters['internalContractNumber']
                        .forEach((elem) => result['internalContractNumber'].push(elem));
                break;
            }
            case 'custno': {
                filters['custno'].length &&
                typeof filters['custno'] === 'string' ?
                    result['custno'] = [filters['custno']] :
                    filters['custno']
                        .forEach((elem) => result['custno'].push(elem));
                break;
            }
            case 'internalContractNo': {
                filters['internalContractNo'].length &&
                typeof filters['internalContractNo'] === 'string' ?
                    result['internalContractNo'] = [filters['internalContractNo']] :
                    filters['internalContractNo']
                        .forEach((elem) => result['internalContractNo'].push(elem));
                break;
            }
            case 'custname': {
                filters['custname'].length &&
                typeof filters['custname'] === 'string' ?
                    result['custname'] = [filters['custname']] : filters['custname']
                        .forEach((elem) => result['custname'].push(elem));
                break;
            }
            case 'salesEmail': {
                filters['salesEmail'].length &&
                typeof filters['salesEmail'] === 'string' ?
                    result['salesEmail'] = [filters['salesEmail']] :
                    filters['salesEmail']
                        .forEach((elem) => result['salesEmail'].push(elem));
                break;
            }
            case 'salesName': {
                filters['salesName'].length &&
                typeof filters['salesName'] === 'string' ?
                    result['salesName'] = [filters['salesName']] :
                    filters['salesName']
                        .forEach((elem) => result['salesName'].push(elem));
                break;
            }
            case 'coordinatorEmail': {
                filters['coordinatorEmail'].length &&
                typeof filters['coordinatorEmail'] === 'string' ?
                    result['coordinatorEmail'] = [filters['coordinatorEmail']] :
                    filters['coordinatorEmail']
                        .forEach((elem) => result['coordinatorEmail'].push(elem));
                break;
            }
            case 'coordinatorName': {
                filters['coordinatorName'].length &&
                typeof filters['coordinatorName'] === 'string' ?
                    result['coordinatorName'] = [filters['coordinatorName']] :
                    filters['coordinatorName']
                        .forEach((elem) => result['coordinatorName'].push(elem));
                break;
            }
            case 'orderDateFrom': {
                filters['orderDateFrom'].length &&
                typeof filters['orderDateFrom'] === 'string' ?
                    result['orderDateFrom'] = [filters['orderDateFrom']] :
                    filters['orderDateFrom']
                        .forEach((elem) => result['orderDateFrom'].push(elem));
                break;
            }
            case 'orderDateTo': {
                filters['orderDateTo'].length &&
                typeof filters['orderDateTo'] === 'string' ?
                    result['orderDateTo'] = [filters['orderDateTo']] :
                    filters['orderDateTo']
                        .forEach((elem) => result['orderDateTo'].push(elem));
                break;
            }
            case 'planDateFrom': {
                filters['planDateFrom'].length &&
                typeof filters['planDateFrom'] === 'string' ?
                    result['planDateFrom'] = [filters['planDateFrom']] :
                    filters['planDateFrom']
                        .forEach((elem) => result['planDateFrom'].push(elem));
                break;
            }
            case 'planDateTo': {
                filters['planDateTo'].length &&
                typeof filters['planDateTo'] === 'string' ?
                    result['planDateTo'] = [filters['planDateTo']] :
                    filters['planDateTo']
                        .forEach((elem) => result['planDateTo'].push(elem));
                break;
            }
            case 'installDateFrom': {
                filters['installDateFrom'].length &&
                typeof filters['installDateFrom'] === 'string' ?
                    result['installDateFrom'] = [filters['installDateFrom']] :
                    filters['installDateFrom']
                        .forEach((elem) => result['installDateFrom'].push(elem));
                break;
            }
            case 'installDateTo': {
                filters['installDateTo'].length &&
                typeof filters['installDateTo'] === 'string' ?
                    result['installDateTo'] = [filters['installDateTo']] :
                    filters['installDateTo']
                        .forEach((elem) => result['installDateTo'].push(elem));
                break;
            }
            case 'actDate': {
                filters['actDate'].length &&
                typeof filters['actDate'] === 'string' ?
                    result['actDate'] = [filters['actDate']] :
                    filters['actDate']
                        .forEach((elem) => result['actDate'].push(elem));
                break;
            }
            case 'holdDate': {
                filters['holdDate'].length &&
                typeof filters['holdDate'] === 'string' ?
                    result['holdDate'] = [filters['holdDate']] :
                    filters['holdDate']
                        .forEach((elem) => result['holdDate'].push(elem));
                break;
            }
            case 'status': {
                filters['status'].length &&
                typeof filters['status'] === 'string' ?
                    result['status'] = [filters['status']] :
                    filters['status']
                        .forEach((elem) => result['status'].push(elem));
                break;
            }
            case 'allNotes': {
                filters['allNotes'].length &&
                typeof filters['allNotes'] === 'string' ?
                    result['allNotes'] = [filters['allNotes']] :
                    filters['allNotes']
                        .forEach((elem) => result['allNotes'].push(elem));
                break;
            }
            case 'statusCause': {
                filters['statusCause'].length &&
                typeof filters['statusCause'] === 'string' ?
                    result['statusCause'] = [filters['statusCause']] :
                    filters['statusCause']
                        .forEach((elem) => result['statusCause'].push(elem));
                break;
            }
            case 'clientEmail': {
                filters['clientEmail'].length &&
                typeof filters['clientEmail'] === 'string' ?
                    result['clientEmail'] = [filters['clientEmail']] :
                    filters['clientEmail']
                        .forEach((elem) => result['clientEmail'].push(elem));
                break;
            }
            case 'addrId': {
                filters['addrId'].length &&
                typeof filters['addrId'] === 'string' ?
                    result['addrId'] = [filters['addrId']] :
                    filters['addrId']
                        .forEach((elem) => result['addrId'].push(elem));
                break;
            }
            case 'address': {
                filters['address'].length &&
                typeof filters['address'] === 'string' ?
                    result['address'] = [filters['address']] :
                    filters['address']
                        .forEach((elem) => result['address'].push(elem));
                break;
            }
            case 'city': {
                filters['city'].length &&
                typeof filters['city'] === 'string' ?
                    result['city'] = [filters['city']] :
                    filters['city']
                        .forEach((elem) => result['city'].push(elem));
                break;
            }
        }
    }

    return Object.entries(result).filter(([_, item]) => item.length);
}
