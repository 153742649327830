import {IProps} from './types';

import React, {
    FC,
    useEffect,
    useRef,
    useState,
} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {Resizable} from 'react-resizable';

import {MaterialTooltip} from 'components/Helpers/MaterialUI';

import 'react-resizable/css/styles.css';

import {RESIZE_PADDING} from './constants';

import styles from './Element.module.scss';

const Element: FC<IProps> = (props) => {
    const [widthPx, setWidthPx] = useState(0);
    const [tempWidthForResize, setTempWidthForResize] = useState(0);

    const draggableContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (draggableContainerRef.current) {
            setWidthPx(draggableContainerRef.current.clientWidth);
            setTempWidthForResize(draggableContainerRef.current.clientWidth);
        }
    }, []);

    useEffect(() => {
        if (!draggableContainerRef.current) {
            return;
        }
        if (widthPx !== draggableContainerRef.current.clientWidth) {
            setWidthPx(draggableContainerRef.current.clientWidth);
        }
    });

    const onResize = (event, {size}) => {
        setTempWidthForResize(size.width);

        if (tempWidthForResize - widthPx > RESIZE_PADDING) {
            if (props.field[props.positionKey].width < 12) {
                props.onChangeWidth(props.field.name, props.field[props.positionKey].width + 1);
            }
        }
        if (widthPx - tempWidthForResize > RESIZE_PADDING) {
            if (props.field[props.positionKey].width > 1) {
                props.onChangeWidth(props.field.name, props.field[props.positionKey].width - 1);
            }
        }
    };

    const {formWrapper: FormWrapper, displayWrapper: DisplayWrapper} = props;

    return (
        <Resizable
            className={`col col-${props.field[props.positionKey].width}`}
            width={tempWidthForResize}
            height={0}
            axis={'x'}
            onResize={onResize}
            resizeHandles={['e']}
        >
            <div ref={draggableContainerRef}>
                <Draggable
                    key={props.field.name}
                    draggableId={props.field.name}
                    index={props.field[props.positionKey].column}
                >
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{...provided.draggableProps.style}}
                            className={styles.draggableElement}
                        >
                            <div className={'pl-2 pr-2'}>
                                <MaterialTooltip
                                    text={props.field.name}
                                    placement={'top'}
                                >
                                    {
                                        props.isForm ?
                                            <FormWrapper
                                                field={props.field}
                                                intl={props.intl}
                                                translation={props.translation}
                                                onChange={() => ({})}
                                            /> :
                                            <DisplayWrapper
                                                field={props.field}
                                                intl={props.intl}
                                                translation={props.translation}
                                            />
                                    }
                                </MaterialTooltip>
                            </div>
                        </div>
                    )}
                </Draggable>
            </div>
        </Resizable>
    );
};

export default Element;
