import {
    IActionGetDemoByUser,
    IActionGetOneFullByUser,
    IActionListenInsertByUser,
    IActionListenUpdateByUser,
    IActionPatchByUser,
} from './types/redux';
import {
    ICheckoutByUserBody,
    ICheckoutOneByUserBody,
    IGetDemoByUserQuery,
    IPatchByUserBody,
    IPostByUserBody,
} from './types/requests';
import {
    ICheckoutManyByUser,
    ICheckoutOneByUser,
    IGetOneFullByUser,
    IListenByUser,
    IPatchByUser,
    IPostByUser,
    IRemoveByUser,
} from './types/responses';
import {IRootReduxState} from 'instances/types';

import {
    useDispatch,
    useSelector,
} from 'react-redux';

import {WS_EVENT_TYPE} from 'tools/constants';

import {useIntl} from 'helpers/hooks';
import Notifier from 'helpers/Notifier';

import {INTL_DATA} from 'components/Basket/intl';

import {getLSCurrentLocale} from '../languages/functions';

import {
    ACTION_TYPES,
    NORM_MODE,
    NOTIFICATION_ERROR,
} from './constants';
import * as fetches from './fetches';

export function useBasketServicesActualByUser() {
    const basketServices = useSelector((state: IRootReduxState) => state.basketServices.data);

    const dispatch = useDispatch();

    const intl = useIntl();

    const getDemoBasketServices = async (query: IGetDemoByUserQuery) => {
        const res = await fetches.getDemoByUser(query);

        if (!res || !res.basketServices || res.error) {
            if (res.error === NOTIFICATION_ERROR.PERMISSION_DENIED) {
                console.log(res.error);
                Notifier.ooops(intl, intl(INTL_DATA.PERMISSION_ERROR));

                return res;
            }

            console.log(res.error);
            Notifier.ooops(intl);

            return;
        }

        dispatch<IActionGetDemoByUser>({
            type: ACTION_TYPES.GET_BASKET_SERVICES,
            basketServices: res.basketServices,
        });
    };
    const updateBasketServicesByListening = (res: IListenByUser) => {
        if (!res.type || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return;
        }

        switch (res.type) {
            case WS_EVENT_TYPE.INSERT: {
                if (!res.basketService) {
                    return;
                }

                dispatch<IActionListenInsertByUser>({
                    type: ACTION_TYPES.LISTEN_BASKET_SERVICE_INSERT,
                    basketService: res.basketService,
                    intl,
                });

                break;
            }
            case WS_EVENT_TYPE.UPDATE: {
                if (!res.basketService) {
                    return;
                }

                dispatch<IActionListenUpdateByUser>({
                    type: ACTION_TYPES.LISTEN_BASKET_SERVICE_UPDATE,
                    basketService: res.basketService,
                    intl,
                });

                break;
            }
        }
    };

    return {
        basketServices,
        getDemoBasketServices,
        updateBasketServicesByListening,
    };
}

export function useBasketServicesInitiationByUser() {
    const intl = useIntl();

    const postBasketService = async (body: IPostByUserBody): Promise<IPostByUser> => {
        const res = await fetches.postByUser(body);

        if (!res || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }

        return res;
    };

    return {
        postBasketService,
    };
}

export function useBasketServicesByUser() {
    const basketServices = useSelector(({basketServices}: IRootReduxState) => basketServices.data);

    const dispatch = useDispatch();

    const intl = useIntl();

    const getOneFullBasketService = async (id: string): Promise<IGetOneFullByUser> => {
        const basketService = basketServices?.find((basketService) => basketService.id === id);

        if (basketService?._mode === NORM_MODE.USER_FULL) {
            return {basketService};
        }

        const locale = getLSCurrentLocale();

        const res = await fetches.getOneFullByUser(id, {locale});

        if (!res || res.error || !res.basketService) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }

        dispatch<IActionGetOneFullByUser>({
            type: ACTION_TYPES.GET_BASKET_SERVICE,
            basketService: res.basketService,
        });

        return res;
    };

    const patchBasketService = async (body: IPatchByUserBody): Promise<IPatchByUser> => {
        const res = await fetches.patchByUser({
            ...body,
            locale: getLSCurrentLocale(),
        });

        if (!res || !res.basketService || res.error) {
            console.log(res.error);
            if (res.error === NOTIFICATION_ERROR.PERMISSION_DENIED) {
                console.log(res.error);
                Notifier.ooops(intl, intl(INTL_DATA.PERMISSION_ERROR));

                return res;
            }

            Notifier.ooops(intl);

            return res;
        }

        dispatch<IActionPatchByUser>({
            type: ACTION_TYPES.PATCH_BY_USER,
            basketService: res.basketService,
        });

        return res;
    };

    const checkoutBasketServices = async (body: ICheckoutByUserBody): Promise<ICheckoutManyByUser> => {
        const res = await fetches.checkoutByUser(body);

        if (!res.requestId || !res.basketServicesIds || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }

        return res;
    };

    const checkoutOneBasketService = async (body: ICheckoutOneByUserBody): Promise<ICheckoutOneByUser> => {
        const res = await fetches.checkoutOneByUser(body);

        if (!res.requestId || !res.basketServicesId || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }

        return res;
    };

    const removeBasketService = async (id: string): Promise<IRemoveByUser> => {
        const res = await fetches.removeByUser(id);

        if (!res || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }

        return res;
    };

    return {
        basketServices,
        getOneFullBasketService,
        patchBasketService,
        checkoutBasketServices,
        checkoutOneBasketService,
        removeBasketService,
    };
}
