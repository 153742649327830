export const INSTANCE_PATH = '/bills';
export const LS_BILLS_FILTER = 'LS_BILLS_FILTER';

export enum STATUS {
    LIVE = 'LIVE',
    FIXED = 'FIXED',
}

export enum SORT_BY {
    COMPANY_NAME = 'COMPANY_NAME',
    CONTRACT_NUMBER = 'CONTRACT_NUMBER',
    SUM = 'SUM',
    STATUS = 'STATUS',
    CLIENT_KE_REF = 'CLIENT_KE_REF',
    DATE = 'DATE'
}

export enum NOTIFICATION_ERROR {
    PERMISSION_DENIED = 'PERMISSION_DENIED',
}

export const DOWNLOADED_BILLS_EXCEL_FILE = 'Мои счета.xlsx';
