import {ISpecificForm} from '../../../FormField/types';
import {IProps} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialMaskInput} from 'components/Helpers/MaterialUI';

import {formatFormTitle} from '../../functions';

import {
    ERROR,
    IP_PATTERN,
} from './constants';
import {
    confirmValueBeforeDisplay,
    formatError,
} from './functions';

const IPField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (value: string) => {
        props.onChange(props.field.name, value);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    if (!props.field.value) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                    if (!IP_PATTERN.test(props.field.value)) {
                        setError(ERROR.INVALID_FORMAT);

                        return false;
                    }
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <MaterialMaskInput
            label={formatFormTitle(props.field, props.translation)}
            placeholder={''}
            value={props.field.value}
            isError={!!error}
            helperText={formatError(error, props.intl)}
            mask={(value) => Array(value.length).fill(/[\d.]/)}
            confirmValueBeforeDisplay={confirmValueBeforeDisplay}
            onChange={onChange}
        />
    );
});

export default IPField;
