export const defaultFields = [
    {
        name: 'contract',
        type: 'contract',
        title: 'Contract',
        tooltip: '',
        readOnly: false,
        value: {},
        validation: {
            required: true,
        },
    },
    {
        name: 'address',
        type: 'address',
        title: 'Address',
        tooltip: '',
        readOnly: false,
        value: '',
        validation: {
            required: true,
        },
    },
];

export const defaultTranslations = [
    {
        locale: 'en',
        type: '',
        shortDescription: '',
        description: '',
        applicationStepTitles: ['Step 1'],
        applicationFrames: [],
        fields: [
            {
                name: 'contract',
                messages: {
                    Contract: 'Contract',
                }
            },
            {
                name: 'address',
                messages: {
                    Address: 'Address',
                }
            },
        ]
    }
];

export enum ActionTypes {
    GetServices = 'GET_SERVICES',
    GetServicesSucceed = 'GET_SERVICES_SUCCEED',
    GetServiceVersions = 'GET_SERVICE_VERSIONS',
    GetServiceVersionsSucceed = 'GET_SERVICE_VERSIONS_SUCCEED',
    GetServiceVersion = 'GET_SERVICE_VERSION',
    GetServiceVersionSucceed = 'GET_SERVICE_VERSION_SUCCEED',
    PostService = 'POST_SERVICE',
    PostServiceSucceed = 'POST_SERVICE_SUCCEED',
    PatchService = 'PATCH_SERVICE',
    PatchServiceSucceed = 'PATCH_SERVICE_SUCCEED',
    PostVersion = 'POST_VERSION',
    PostVersionSucceed = 'POST_VERSION_SUCCEED',
    PutVersion = 'PUT_VERSION',
    PutVersionSucceed = 'PUT_VERSION_SUCCEED',
    SetActiveServiceVersion = 'SET_ACTIVE_SERVICE_VERSION',
    SetActiveServiceVersionSucceed = 'SET_ACTIVE_SERVICE_VERSION_SUCCEED',
    SetServicesQueue = 'SET_SERVICES_QUEUE',
    SetServicesQueueSucceed = 'SET_SERVICES_QUEUE_SUCCEED',
    GetActiveVersions = 'GET_ACTIVE_VERSIONS',
    GetActiveVersionsSucceed = 'GET_ACTIVE_VERSIONS_SUCCEED',
}
