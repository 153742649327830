import {IFieldTranslation} from '../types';
import {ICommonDataForm} from './CommonDataForm/types';
import {
    IProps,
    ISpecificDataForm,
    ITranslationField,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
} from 'react';

import CommonDataForm from './CommonDataForm';
import SpecificDataForm from './SpecificDataForm';

import {translateByIntl} from '../../helpers';

import {INTL_DATA} from './intl';

const TranslationField = forwardRef<ITranslationField, IProps>((props, ref) => {
    const commonDataFormRef = useRef<ICommonDataForm>(null);
    const specificDataFormRef = useRef<ISpecificDataForm>(null);

    useImperativeHandle(ref, () => {
        return {
            getValue(): IFieldTranslation {
                return {
                    name: props.field.name,
                    messages: {
                        ...commonDataFormRef.current?.getValue(),
                        ...specificDataFormRef.current?.getValue(),
                    },
                };
            },
            validate(): boolean {
                return ![
                    commonDataFormRef.current?.validate(),
                    specificDataFormRef.current?.validate(),
                ].includes(false);
            },
        };
    });

    return (
        <div className={'border-top border-dark'}>
            <div className={'m-2'}>
                <div className={'d-flex flex-row justify-content-center'}>
                    <h5>
                        {translateByIntl(props.intl, INTL_DATA.NAME, 'Field id')}
                        :
                        {' '}
                        {props.field.name}
                    </h5>
                </div>
                <CommonDataForm
                    ref={commonDataFormRef}
                    field={props.field}
                    translation={props.translation.messages}
                    intl={props.intl || null}
                />
                <SpecificDataForm
                    ref={specificDataFormRef}
                    field={props.field}
                    translation={props.translation.messages}
                    intl={props.intl || null}
                />
            </div>
        </div>
    );
});

export default TranslationField;
