import {IProps} from './types';

import React, {FC} from 'react';

import {translateByIntl} from '../../../helpers';
import {TRIGGER_TYPE} from '../../constants';

import {INTL_DATA} from './intl';

const CommonDataForm: FC<IProps> = (props) => {
    return (
        <div className={'d-flex flex-row'}>
            <label className={'col-form-label mr-2'}>
                {translateByIntl(props.intl, INTL_DATA.TYPE, 'Type')}
                :
            </label>
            <select
                className={'form-control'}
                value={props.trigger.type}
                onChange={(e) => props.onChangeType(e.target.value as TRIGGER_TYPE)}
            >
                {
                    props.types.map((type) =>
                        <option
                            key={type}
                            value={type}
                        >
                            {type}
                        </option>
                    )
                }
            </select>
        </div>
    );
};

export default CommonDataForm;
