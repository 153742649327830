import {IProps} from './types';

import React, {FC} from 'react';
import {Droppable} from 'react-beautiful-dnd';

import Element from './Element';

import styles from './Line.module.scss';

const Line: FC<IProps> = (props) => {
    return (
        <Droppable droppableId={`${props.row}`} direction={'horizontal'}>
            {(provided, snapshot) => {
                const styleForDroppableField = snapshot.isDraggingOver ? styles.droppableContainerBlue : styles.droppableContainerWhite;

                return (
                    <div
                        ref={provided.innerRef}
                        className={`${styleForDroppableField} d-flex`}
                        {...provided.droppableProps}
                    >
                        {
                            props.fields
                                .sort((a, b) => a[props.positionKey].column - b[props.positionKey].column)
                                .map((field) =>
                                    <Element
                                        key={field.name}
                                        positionKey={props.positionKey}
                                        isForm={props.isForm}
                                        field={field}
                                        displayWrapper={props.displayWrapper}
                                        formWrapper={props.formWrapper}
                                        intl={props.intl}
                                        translation={props.translation}
                                        onChangeWidth={props.onChangeWidth}
                                    />
                                )
                        }
                        {
                            !props.fields.length &&
                            <div className={styles.tableEditorEmptyLine}/>
                        }
                        {provided.placeholder}
                    </div>
                );
            }}
        </Droppable>
    );
};

export default Line;
