import classNames from 'classnames';
import React, {
    FC,
    useRef,
    useState,
} from 'react';

import {useClickOutside} from 'tools/hooks';

import Suggestion from './Suggestion';

interface IProps {
    className?: string;
    label: string;
    value: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    placeholder?: string;
    error?: string;

    suggestions: string[];

    onChange(value: string): void;
}

const InputAutoSuggest: FC<IProps> = ({
    className,
    label,
    value,
    isDisabled = false,
    isRequired = false,
    placeholder,
    error,
    suggestions,
    onChange,
}) => {
    const [isFocus, setIsFocus] = useState(false);
    const toggleFocus = () => {
        setIsFocus(!isFocus);
    };

    const onInputChange = (e) => {
        const {value} = e.target;

        onChange(value);
    };

    const onSelectSuggestion = (suggestion: string) => {
        if (!isFocus) {
            return;
        }

        onChange(suggestion);
        setIsFocus(false);
    };
    const containerRef = useRef<HTMLDivElement>(null);

    useClickOutside(containerRef, () => setIsFocus(false));

    return (
        <div className={classNames(className, 'd-flex flex-column')}>
            <div
                ref={containerRef}
                className={classNames(
                    'd-flex flex-row align-items-center align-items-start position-relative w-100',
                    className
                )}
            >
                <div className={'d-flex flex-column w-100'}>
                    <h5 className={classNames('mb-0',
                        {
                            'text-primary fw-bold': isRequired,
                        })}
                    >
                        {label}
                    </h5>
                    <input
                        className={classNames('form-control d-flex', {
                            'border-primary': !!error,
                        })}
                        type={'text'}
                        disabled={isDisabled}
                        value={value}
                        onChange={onInputChange}
                        placeholder={placeholder}
                        onFocus={toggleFocus}
                        style={{
                            minHeight: '40px',
                        }}
                    />
                </div>
                {
                    isFocus &&
                    suggestions &&
                    suggestions.length > 0 &&
                    !suggestions.includes(value) &&
                    <div
                        className={classNames('list-group list-group-flush position-absolute p-1 pb-0 bg-white border border-1 border-dark top-100 w-100')}
                    >
                        {
                            suggestions.map(
                                (suggestion) =>
                                    <Suggestion
                                        key={`suggestion-${suggestion}`}
                                        isChecked={false}
                                        isActive={false}
                                        text={suggestion}
                                        onClick={() => onSelectSuggestion(suggestion)}
                                    />
                            )
                        }
                    </div>
                }
                {
                    error &&
                    <div className={'text-danger position-absolute top-100 ps-1 fw-bold'}>
                        {error}
                    </div>
                }
            </div>
        </div>
    );
};

export default InputAutoSuggest;
