import {
    IFormField,
    IProps,
    ISpecificForm,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
} from 'react';

import {MaterialTooltip} from 'components/Helpers/MaterialUI';

import UnableField from '../UnableField';

import SpecificForm from './SpecificForm';

import {translateByObject} from '../../helpers';

const FormField = forwardRef<IFormField, IProps>((props, ref) => {
    const fieldRef = useRef<ISpecificForm>(null);

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                return fieldRef.current?.validate() || false;
            },
            getName(): string {
                return props.field.name;
            },
        };
    });

    if (props.isUnable) {
        return (
            <UnableField
                ref={fieldRef}
                title={props.field.title}
                translation={props.translation || null}
            />
        );
    }

    return (
        <MaterialTooltip
            placement={'left'}
            text={translateByObject(props.field.tooltip, props.translation)}
        >
            <SpecificForm
                ref={fieldRef}
                field={props.field}
                translation={props.translation || null}
                intl={props.intl || null}
                onChange={props.onChange}
            />
        </MaterialTooltip>
    );
});

export default FormField;
