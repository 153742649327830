import {IProps} from './types';

import {Radio} from '@mui/material';
import React, {FC} from 'react';

const MaterialRadio: FC<IProps> = (props) => {
    return (
        <div className={'d-flex flex-row align-items-center'}>
            <Radio
                checked={props.value}
                disabled={props.isDisabled}
                // sx={styles}
                onChange={(e) => props.onChange?.(e.target.checked)}
            />
            {
                props.label &&
                <label className={'col-form-label ml-2'}>
                    {props.label}
                </label>
            }
        </div>
    );
};

export default MaterialRadio;
