import {IRow} from './Row/types';
import {
    IDisplayTable,
    IProps,
} from './types';

import React, {
    createRef,
    forwardRef,
    RefObject,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';

import {BoostedDisplayField} from 'components/Helpers/FormsEngine/Fields';

import Row from './Row';

import {FormField} from '../../Fields';

import {getRows} from './functions';

const DisplayTable = forwardRef<IDisplayTable, IProps>((props, ref) => {
    const [rowRefs, setRowRefs] = useState<RefObject<IRow>[]>([]);

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                return true;
            },
        };
    });
    useEffect(() => {
        const rowRefs = rows.map(() => createRef<IRow>());

        setRowRefs(rowRefs);
    }, [props.fields]);

    const rows = useMemo(() => {
        return getRows(props.fields, props.positionKey);
    }, [props.fields]);

    return (
        <div>
            {
                rows.map(
                    (row, i) =>
                        <Row
                            key={row}
                            ref={rowRefs[i]}
                            positionKey={props.positionKey}
                            isForm={props.isForm}
                            row={row}
                            fields={props.fields}
                            displayWrapper={props.displayWrapper || BoostedDisplayField}
                            formWrapper={props.formWrapper || FormField}
                            intl={props.intl || null}
                            translation={props.translation || null}
                            onChange={props.onChange || (() => {
                                return;
                            })}
                        />
                )
            }
        </div>
    );
});

export default DisplayTable;
