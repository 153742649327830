import {IEquippedClient} from 'instances/equippedClients/types';
import {IResponseError} from 'tools/types';

import React, {
    FC,
    useState,
} from 'react';
import {useNavigate} from 'react-router-dom';

import {RESPONSE_ERROR} from 'instances/equippedUsers/constants';

import {Modal} from 'components/Helpers/Other';

import FileView from './FileView';

import {
    addFile,
    formatError,
} from './functions';

interface IProps {
    isOpen: boolean;
    isAdmin?: boolean;
    equippedClient: IEquippedClient;

    onDownloadSample(): Promise<void>;
    onUploadFile(file: File): Promise<IResponseError | null>;
    onAccept(): void;
    onDecline(): void;
}

const UploadExelModal: FC<IProps> = (props) => {
    const [file, setFile] = useState<File | null>(null);
    const [errors, setErrors] = useState<null | string[] | RESPONSE_ERROR>(null);
    const [isPending, setIsPending] = useState(false);

    const navigate = useNavigate();

    const onAdd = async (): Promise<void | null> => {
        setFile(await addFile('files'));
    };

    const onDownloadSample = async (): Promise<void> => {
        setIsPending(true);
        await props.onDownloadSample();
        setIsPending(false);
    };

    const onRemoveFile = () => {
        setFile(null);
        setErrors(null);
        setIsPending(false);
    };

    const onSendFile = async (): Promise<void> => {
        if (!file) {
            return;
        }

        setIsPending(true);

        const validationErrors = await props.onUploadFile(file);

        if (validationErrors) {
            if (Object.values(RESPONSE_ERROR).includes(validationErrors)) {
                setErrors(validationErrors);
                setIsPending(false);

                return;
            }

            setErrors(validationErrors);
            setIsPending(false);

            return;
        }

        setIsPending(false);

        props.isAdmin ?
            props.onDecline() :
            navigate('/pdn/success');
    };

    const onClose = () => {
        setFile(null);
        setErrors(null);
        setFile(null);
        props.onDecline();
    };

    return (
        <Modal
            isOpen={props.isOpen}
            title={'Загрузить файл'}
            onClose={onClose}
        >
            <div>
                <p> 1. Скачайте шаблонный файл с идентификаторами пользователей</p>
                <p> 2. Приложите заполненный шаблонный файл, заполнив данные клиентов</p>
                <p> 3. Все поля, кроме типа паспорта, обязательны к заполнению.</p>
                <p>
                    <b>Незаполненные или частично заполненные строки будут проигнорированы.</b>
                </p>
            </div>
            <div className={'d-flex flex-column justify-content-between'}>
                {
                    file ?
                        <>
                            <FileView
                                file={file}
                                onRemove={onRemoveFile}
                                isError={!!errors}
                            />
                            <div>
                                {
                                    errors &&
                                    Array.isArray(errors) ?
                                        errors.map((error) => {
                                            return (
                                                <div
                                                    key={error}
                                                    className={'d-flex text-primary pl-4 mt-1 mb-1'}
                                                >
                                                    <b>
                                                        {` - ${formatError(error)}`}
                                                    </b>
                                                </div>);
                                        }) :
                                        errors &&
                                        Object.values(RESPONSE_ERROR).includes(errors) &&
                                        <>
                                            <h4
                                                className={'d-flex text-primary align-self-center'}
                                            >
                                                <b>Неверный формат файла</b>
                                            </h4>
                                            <div
                                                key={errors}
                                                className={'d-flex text-primary pl-4 mt-1 mb-1'}
                                            >
                                                {formatError(errors)}
                                            </div>
                                        </>
                                }
                            </div>
                        </> : <div style={{minHeight: '50px'}}/>
                }
                <div className={'d-flex justify-content-center align-items-center'}>
                    <div className={'d-flex justify-content-center align-items-center mr-2'}>
                        <button
                            className={'btn btn-secondary'}
                            onClick={onDownloadSample}
                            disabled={isPending}
                        >
                            Скачать шаблон
                        </button>
                    </div>
                    {
                        file ?
                            <button
                                className={'btn btn-secondary'}
                                onClick={onSendFile}
                                disabled={isPending || !!errors}
                            >
                                Отправить
                            </button> :
                            <button
                                className={'btn btn-secondary ml-2'}
                                onClick={onAdd}
                            >
                                Добавить
                            </button>
                    }
                </div>
            </div>
        </Modal>
    );
};

export default UploadExelModal;
