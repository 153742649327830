export enum INTL_DATA {
    HEADER_TOP = 'header/header-top',
    // Base routes
    HOME = 'header/home',
    ADMIN = 'header/admin',
    NOT_FOUND = 'header/not-found',
    USER_SERVICES = 'header/my-services',
    USER_SITES = 'header/sites',
    SERVICES_CATALOG = 'header/services-catalog',
    CASES = 'header/cases',
    BIDS = 'header/bids',
    BILLS = 'header/current-accounts',
    BASKET = 'header/order',
    NOTIFICATIONS = 'header/notifications',
    ORDERS = 'header/orders',
    SETTINGS = 'header/settings',

    // Group of routes
    REQUESTS = 'header/requests',
    ANALYTICS = 'header/dashboards',

    // Admin routes
    ADMIN_GLOBAL_DATA = 'header/admin/global-data',
    ADMIN_SERVICES = 'header/admin/services',
    ADMIN_SERVICE_GROUPS = 'header/admin/service-groups',
    ADMIN_CLIENTS = 'header/admin/clients',
    ADMIN_USERS = 'header/admin/users',
    ADMIN_SITES = 'header/admin/sites',
    ADMIN_LOGS = 'header/admin/logs',
    ADMIN_LANGUAGES = 'header/admin/languages',
    ADMIN_EQUIPPED_CLIENTS = 'header/admin/equipped-clients',
    ADMIN_EQUIPPED_CONTACTS = 'header/admin/equipped-contacts',
    ADMIN_DOCS = 'header/admin/documents',

    // Other routes
    PAGE_404 = 'header/*',
}
