import {IProps} from './types';

import React, {FC} from 'react';

import MaterialMaskInput from '../MaterialMaskInput';

import {
    PHONE_MASK,
    PHONE_PLACEHOLDER,
} from './constants';

const MaterialPhoneInput: FC<IProps> = (props) => {
    return (
        <MaterialMaskInput
            label={props.label}
            value={props.value}
            mask={PHONE_MASK}
            isError={props.isError}
            placeholder={PHONE_PLACEHOLDER}
            onChange={props.onChange}
            helperText={props.helperText}
            isDisabled={props.isDisabled}
        />
    );
};

export default MaterialPhoneInput;
