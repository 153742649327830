import packageJson from '../package.json';

export enum CONFIG_MODE {
    DEV = 'DEV',
    TEST = 'TEST',
    PROD_BLUE = 'PROD_BLUE',
    PROD_GREEN = 'PROD_GREEN',
    LOCAL = 'LOCAL',
}

export interface IConfig {
    MODE: CONFIG_MODE;

    URL: string;
    PD_URL: string;
    WS_URL: string;

    DA_DATA_URL: string;
    REGISTER_SERVICE_WORKER: boolean;
}

const config: IConfig = {
    MODE: process.env.REACT_APP_MODE as CONFIG_MODE,

    URL: String(process.env.REACT_APP_URL),
    PD_URL: String(process.env.REACT_APP_PD_URL),
    WS_URL: String(process.env.REACT_APP_WS_URL),

    DA_DATA_URL: String(process.env.REACT_APP_DA_DATA_URL),
    REGISTER_SERVICE_WORKER: process.env.REACT_APP_REGISTER_SERVICE_WORKER === 'true',
};

console.log(`${packageJson.name}:v${packageJson.version}, ${config.MODE}`);

export default config;
