import {IIntl} from '../../../../types';
import {IDaDataGeoObject} from '../types';
import {IGetSuggestionsParams} from './types';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA} from '../../intl';
import {DA_DATA_TYPE} from '../constants';

import {ERROR} from './constants';

export function formatError(error: ERROR | null, intl: IIntl | null): string | undefined {
    switch (error) {
        case ERROR.IS_REQUIRED: {
            return translateByIntl(intl, INTL_DATA.NO_EMPTY, 'Required');
        }
        case null: {
            return undefined;
        }
    }
}

export async function getSuggestions(params: IGetSuggestionsParams): Promise<IDaDataGeoObject[]> {
    if (!params.url) {
        return [];
    }
    if (!params.token) {
        return [];
    }

    let path = '';

    switch (params.type) {
        case DA_DATA_TYPE.ADDRESS:
        case DA_DATA_TYPE.REGION: {
            path = 'address';
            break;
        }
        case DA_DATA_TYPE.COMPANY: {
            path = 'party';
            break;
        }
    }

    const res = await fetch(`${params.url}/${path}`, {
        method: 'POST',
        headers: [
            ['Content-Type', 'application/json'],
            ['Accept', 'application/json'],
            ['Authorization', `Token ${params.token}`],
        ],
        body: JSON.stringify({
            query: params.value,
            count: params.limit,
        }),
    });

    if (res.status !== 200) {
        return [];
    }

    try {
        const {suggestions} = await res.json();

        return suggestions.map((suggestion) => {
            return {
                input: suggestion.value,
                unrestrictedInput: suggestion.unrestricted_value,
                data: suggestion.data,
            };
        });
    } catch {
        return [];
    }
}
