import {IItem} from './Item/types';
import {
    IProps,
    IRow,
} from './types';

import React, {
    createRef,
    forwardRef,
    RefObject,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';

import Item from './Item';

import {
    getRowFields,
    isDisabledRow,
} from './functions';

const Row = forwardRef<IRow, IProps>((props, ref) => {
    const [itemRefs, setItemRefs] = useState<RefObject<IItem>[]>([]);

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                return !itemRefs
                    .map((itemRef) => itemRef.current?.validate())
                    .includes(false);
            },
        };
    });

    useEffect(() => {
        const itemRefs = rowFields.map(() => createRef<IItem>());

        setItemRefs(itemRefs);
    }, [props.fields]);

    const rowFields = useMemo(() => {
        return getRowFields(props.fields, props.row, props.positionKey);
    }, [props.fields]);

    if (isDisabledRow(rowFields, props.fields)) {
        return null;
    }

    return (
        <div className={'row mt-2 mb-2'}>
            {
                rowFields
                    .map(
                        (field, i) =>
                            <Item
                                key={field.name}
                                ref={itemRefs[i]}
                                positionKey={props.positionKey}
                                isForm={props.isForm}
                                field={field}
                                fields={props.fields}
                                displayWrapper={props.displayWrapper}
                                formWrapper={props.formWrapper}
                                intl={props.intl}
                                translation={props.translation}
                                onChange={props.onChange}
                            />
                    )
            }
        </div>
    );
});

export default Row;
