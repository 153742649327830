import {IProps} from './types';

import React, {FC} from 'react';
import {Droppable} from 'react-beautiful-dnd';

import ApplicationFrame from '../ApplicationFrame';

import {DROPPABLE_TYPE} from '../constants';

const FramesContainer: FC<IProps> = (props) => {
    const getStyles = (isDraggingOver: boolean) => {
        return {
            background: isDraggingOver ? 'lightblue' : 'lightgrey',
            padding: 8,
            width: '100%',
            minHeight: '100px',
        };
    };

    return (
        <Droppable droppableId={props.droppableId} type={DROPPABLE_TYPE.FRAMES_CONTAINER}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getStyles(snapshot.isDraggingOver)}>
                    {
                        props.frames
                            .sort((a, b) => a.row !== undefined && b.row !== undefined ? a.row - b.row : 1)
                            .map(
                                (frame, i) =>
                                    <ApplicationFrame
                                        key={frame.id}
                                        fieldApplicationPositionKey={props.fieldApplicationPositionKey}
                                        index={i}
                                        frame={frame}
                                        fields={props.fields.filter((field) => field[props.fieldApplicationPositionKey]?.frame === frame.id)}
                                        intl={props.intl}
                                        onChange={props.onChangeFrame}
                                    />
                            )
                    }
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default FramesContainer;
