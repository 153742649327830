import {RESPONSE_ERROR} from 'instances/equippedUsers/constants';

export function addFile(acceptType: 'images' | 'files'): Promise<File | null> {
    return new Promise<File | null>((resolve) => {
        const input = document.createElement('input');
        let accept = '';

        switch (acceptType) {
            case ('files'): {
                accept = '.xlsx';
                break;
            }
        }

        input.type = 'file';
        input.accept = accept;
        input.onchange = (e) => {
            // @ts-ignore
            resolve(e.target?.files?.[0] || null);
        };
        input.click();
    });
}
export function formatError(error: string | RESPONSE_ERROR): string {
    switch (error) {
        case (RESPONSE_ERROR.NOT_FOUND): {
            return 'Нет заполненных идентификаторов';
        }
        default: {
            return error;
        }
    }
}
