import {INumberFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {
    parseNumber,
    translateByIntl,
} from '../../../../helpers';
import {INTL_DATA} from '../../intl';
import {clearNumberValue} from '../functions';

const NumberField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [value, setValue] = useState(props.field.value);
    const [required, setRequired] = useState(props.field.validation.required);
    const [min, setMin] = useState(props.field.validation.min);
    const [isActiveMin, setIsActiveMin] = useState(props.field.validation.min !== undefined);
    const [max, setMax] = useState(props.field.validation.max);
    const [isActiveMax, setIsActiveMax] = useState(props.field.validation.max !== undefined);

    const minMaxOnBlur = () => {
        const parsedValue = parseInt(value);

        if (min == undefined) {
            return;
        }

        parsedValue < min && setValue(`${min}`);

        if (max === undefined) {
            return;
        }

        parsedValue > max && setValue(`${max}`);

        min > max && setMin(max);
        max < min && setMax(min);
    };

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): INumberFieldSpecificProps {
                return {
                    value,
                    validation: {
                        required,
                        min: isActiveMin ? min : undefined,
                        max: isActiveMax ? max : undefined,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {};
            },
            validate(): boolean {
                return true;
            },
        };
    });
    useEffect(() => {
        if (!isActiveMax) {
            setMax(undefined);
        }
    }, [isActiveMax]);
    useEffect(() => {
        if (!isActiveMin) {
            setMin(undefined);
        }
    }, [isActiveMin]);

    return (
        <>
            <div className={'border-bottom border-dark pb-2'}>
                <div className={'row m-2'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, INTL_DATA.DEFAULT_VALUE, 'Default value')}
                            :
                        </label>
                    </div>
                    <div className={'col-8'}>
                        <input
                            className={'form-control'}
                            value={value}
                            onChange={(e) => setValue(clearNumberValue(e.target.value))}
                        />
                    </div>
                </div>
            </div>
            <div className={'m-2'}>
                <h5>
                    {translateByIntl(props.intl, INTL_DATA.VALIDATION, 'Validation')}
                </h5>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, INTL_DATA.REQUIRED, 'Required')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={required}
                            onChange={setRequired}
                        />
                    </div>
                </div>
                <div className={'row mt-2'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, INTL_DATA.MIN, 'Min')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={isActiveMin}
                            onChange={setIsActiveMin}
                        />
                    </div>
                    <div className={'col-4'}>
                        <input
                            className={'form-control'}
                            type={'number'}
                            value={min ?? ''}
                            disabled={!isActiveMin}
                            onChange={
                                (e) =>
                                    setMin(parseNumber(e.target.value) || undefined)
                            }
                            onBlur={minMaxOnBlur}
                        />
                    </div>
                </div>
                <div className={'row mt-2'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, INTL_DATA.MAX, 'Max')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={isActiveMax}
                            onChange={setIsActiveMax}
                        />
                    </div>
                    <div className={'col-4'}>
                        <input
                            className={'form-control'}
                            type={'number'}
                            value={max ?? ''}
                            disabled={!isActiveMax}
                            onChange={
                                (e) =>
                                    setMax(parseNumber(e.target.value) || undefined)
                            }
                            onBlur={minMaxOnBlur}
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

export default NumberField;
