import {IContext} from './types';

import {createContext} from 'react';

const context = createContext<IContext>({
    auth: null,
    contracts: null,
    daDataUrl: null,
    daDataToken: null,
    serviceStatuses: [],

    getContractsByContractBill(): [] {
        return [];
    },
    getUniqueContractsWithoutBills(): [] {
        return [];
    },

    async getAddresses() {
        return {};
    },
    async getUsers() {
        return {};
    },
    async getUserServices() {
        return {};
    },
});

export default context.Provider;
export {context};
