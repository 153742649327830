import {IProps} from './types';

import React from 'react';
import ReactInfiniteScroll from 'react-infinite-scroller';

import {Loading} from 'components/Helpers/Other';

const InfiniteScroll = (props: IProps) => {
    return (
        <ReactInfiniteScroll
            pageStart={0}
            loadMore={props.loadMore}
            hasMore={props.hasMore}
            loader={
                <div key={0} className={'mt-2'}>
                    <Loading size={30}/>
                </div>
            }
        >
            {props.children}
        </ReactInfiniteScroll>
    );
};

export default InfiniteScroll;
