export enum INTL_DATA {
    NOTIFICATIONS_MORE = 'header/notificationsSlider/notifications-more',
    UNREAD_NOTIFICATIONS = 'header/notificationsSlider/unread-notifications',
    NOTIFICATION_START = 'header/notificationsSlider/notification-start',
    WORKING_START = 'header/notificationsSlider/working-start',
    DURATION = 'header/notificationsSlider/duration',
    HOUR = 'header/notificationsSlider/hour',
    HOURS = 'header/notificationsSlider/hours',
    MINUTES = 'header/notificationsSlider/minutes',
    REMOVE_NOTIFICATION = 'header/notificationsSlider/remove-notification',
    REMOVE_NOTIFICATION_CONFIRMATION = 'header/notificationsSlider/remove-notification-confirmation',
    REMOVE_NOTIFICATION_DECLINE = 'header/notificationsSlider/remove-notification-decline',
}
