import {IDateFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA as FIELDS_INTL_DATA} from '../../intl';
import {INTL_DATA} from '../intl';

const DateField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [required, setRequired] = useState(props.field.validation.required);
    const [pastIsDisabled, setPastIsDisabled] = useState(props.field.validation.pastIsDisabled);
    const [isActiveMax, setIsActiveMax] = useState(props.field.validation?.max !== undefined);
    const [max, setMax] = useState(props.field.validation.max);

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): IDateFieldSpecificProps {
                return {
                    value: '',
                    validation: {
                        required,
                        pastIsDisabled,
                        max: isActiveMax ? max : undefined,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {};
            },
            validate(): boolean {
                return true;
            },
        };
    });
    useEffect(() => {
        if (!isActiveMax) {
            setMax(undefined);
        }
    }, [isActiveMax]);

    return (
        <>
            <div className={'border-top border-dark'}/>
            <div className={'m-2'}>
                <h5>
                    {translateByIntl(props.intl, FIELDS_INTL_DATA.VALIDATION, 'Validation')}
                </h5>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, FIELDS_INTL_DATA.REQUIRED, 'Required')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={required}
                            onChange={setRequired}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, INTL_DATA.PAST_IS_DISABLED, 'Past is disabled')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={pastIsDisabled}
                            onChange={setPastIsDisabled}
                        />
                    </div>
                </div>
                <div className={'row mt-2'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, INTL_DATA.MAX_DAYS, 'Max days')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={isActiveMax}
                            onChange={setIsActiveMax}
                        />
                    </div>
                    <div className={'col-2'}>
                        <input
                            type={'number'}
                            className={'form-control'}
                            min={'1'}
                            value={max ?? ''}
                            onChange={(e) => setMax(Number(e.target.value))}
                            disabled={!isActiveMax}
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

export default DateField;
