import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialTooltip} from 'components/Helpers/MaterialUI';

import UnableField from '../UnableField';

import SpecificDisplay from './SpecificDisplay';

import {translateByObject} from '../../helpers';

const DisplayField: FC<IProps> = (props) => {
    if (props.isUnable) {
        return (
            <UnableField
                title={props.field.title}
                translation={props.translation || null}
            />
        );
    }

    return (
        <MaterialTooltip
            placement={'left'}
            text={translateByObject(props.field.tooltip, props.translation)}
        >
            <SpecificDisplay
                field={props.field}
                translation={props.translation || null}
                intl={props.intl || null}
            />
        </MaterialTooltip>
    );
};

export default DisplayField;
