import {IGet} from './types/fetchResult';

import config from 'config';

import Fetch from 'tools/Fetch';

import {getLSCurrentLocale} from 'instances/languages/functions';
import {onLogout} from 'instances/auth/functions';

import {INSTANCE_PATH} from './constants';

export async function get(): Promise<IGet> {
    const locale = getLSCurrentLocale();

    return new Fetch<IGet>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
        query: {
            locale,
        },
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec()
}
