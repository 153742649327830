import {IProps} from './types';

import React, {
    FC,
    useState,
} from 'react';

import {translateByObject} from 'helpers/functions';
import {useIntl} from 'helpers/hooks';
import Notifier from 'helpers/Notifier';

import {BID_TYPE} from 'instances/basketServices/constants';
import {useBidsByUser} from 'instances/bids/hooks';
import {getLSCurrentLocale} from 'instances/languages/functions';
import {FIELD_APPLICATION_KEY} from 'instances/servicesVersions/constants';

import {ApplicationForm} from 'components/Helpers/FormsEngine';
import {
    Loading,
    Modal,
} from 'components/Helpers/Other';

import {INTL_DATA} from './intl';

const ClaimModal: FC<IProps> = (props) => {
    const [isPending, setIsPending] = useState(false);

    const {postClaim} = useBidsByUser();

    const intl = useIntl();

    const post = async () => {
        if (!props.serviceVersion) {
            return;
        }

        setIsPending(true);

        const res = await postClaim({
            service: props.serviceVersion.serviceId,
            serviceVersion: props.serviceVersion.id,
            userService: null,
            bidType: BID_TYPE.CLAIM,

            fields: props.serviceVersion.fields,
            isTest: true,
            locale: getLSCurrentLocale(),
        });

        setIsPending(false);

        if (!res.requestId) {
            return;
        }
        if (res.error) {
            Notifier.ooops(intl);

            return;
        }

        Notifier.success(intl(INTL_DATA.CLAIM_BID_SENT) + res.requestId);
        props.onClose();
    };
    const onConfirmApplicationForm = async () => {
        if (isPending) {
            return;
        }
        if (!props.serviceVersion) {
            return;
        }

        await post();
    };

    return (
        <Modal
            isOpen={!!props.serviceVersion}
            safeExit={true}
            onClose={props.onClose}
        >
            {
                !props.serviceVersion || isPending ?
                    <div className={'d-flex flex-row justify-content-center align-items-center'}>
                        <Loading size={100}/>
                    </div> :
                    <div className={''}>
                        <label>
                            <h3>
                                {translateByObject(props.serviceVersion.type, props.serviceVersion.translation)}
                            </h3>
                        </label>
                        <ApplicationForm
                            fieldApplicationPositionKey={FIELD_APPLICATION_KEY.ORDER_APPLICATION}
                            stepTitles={props.serviceVersion.orderApplicationStepTitles || []}
                            frames={props.serviceVersion.orderApplicationFrames || []}
                            fields={props.serviceVersion.fields.map((field) => ({...field}))}
                            intl={intl}
                            translation={props.serviceVersion.translation}
                            onChange={props.onChangeServiceVersionFields}
                            onConfirm={onConfirmApplicationForm}
                            onFailStepValidation={(errorText) => Notifier.error(errorText)}
                        />
                    </div>
            }
        </Modal>
    );
};

export default ClaimModal;
