import { ActionTypes } from './constants';


export function getState({services}: any) {
    return services;
}

export function isLoaded({services}: any) {
    return !!services;
}

export function get(dispatch: any) {
    return () => dispatch({type: ActionTypes.GetServices});
}

export function getActive(dispatch: any) {
    return () => dispatch({type: ActionTypes.GetActiveVersions});
}

export function getError(state: any) {
    return null;
}
