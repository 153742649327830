import { IServiceVersion, IToPatch, IToPost } from './types/instance';
import { IGetActive, IGetByService, IGetOne, IPatch, IPost, ISetActive } from './types/redux';
import { IRootReduxState } from 'instances/types';

import { Dispatch } from 'redux';

import { getState as getServicesState } from '../services/actions';
import { getByCode } from '../services/functions';

import { ActionTypes, NORM_MODES } from './constants';


export function getState({servicesVersions}: IRootReduxState) {
    return servicesVersions.data;
}

export function getError({servicesVersions}: IRootReduxState) {
    return servicesVersions.error;
}

export function activeAreLoaded({servicesVersions}: IRootReduxState) {
    return servicesVersions.activeAreLoaded;
}

export function getActive(dispatch: Dispatch<IGetActive>) {
    return () => {
        dispatch({
            type: ActionTypes.GetActiveServicesVersions,
        });
    };
}

export function byServiceAreLoaded(state: IRootReduxState, serviceCode: string) {
    const services = getServicesState(state);
    const service = getByCode(serviceCode, services);

    return state.servicesVersions.loadedServicesIds.includes(service?.id || '');
}

export function getByService(dispatch: Dispatch<IGetByService>) {
    return (id: string) => {
        dispatch({
            type: ActionTypes.GetServiceVersionsByService,
            id,
        });
    };
}

export function versionIsLoadedFully(state: IRootReduxState, serviceCode: string, version: string) {
    const services = getServicesState(state);
    const service = getByCode(serviceCode, services);
    const serviceVersion = state.servicesVersions.data.find(
        (serviceVersion) => serviceVersion.serviceId === service?.id && serviceVersion.version === version
    );

    return serviceVersion?._mode === NORM_MODES.FULL;
}

export function getVersion(dispatch: Dispatch<IGetOne>) {
    return (id: string, onSucceed?: (version: IServiceVersion) => void) => {
        dispatch({
            type: ActionTypes.GetServiceVersion,
            id,
            onSucceed,
        });
    };
}

export function postVersion(dispatch: Dispatch<IPost>) {
    return (body: IToPost, onSucceed: ()=>void, onFailed: ()=>void) => {
        dispatch({
            type: ActionTypes.PostActiveServiceVersion,
            body,
            onSucceed,
            onFailed,
        });
    };
}

export function patchVersion(dispatch: Dispatch<IPatch>) {
    return (id: string, body: IToPatch, onSucceed: () => void, onFailed: () => void) => {
        dispatch({
            type: ActionTypes.PatchServiceVersion,
            id,
            body,
            onSucceed,
            onFailed,
        });
    };
}

export function setActiveVersion(dispatch: Dispatch<ISetActive>) {
    return (id: string) => {
        dispatch({
            type: ActionTypes.SetActiveServiceVersion,
            id,
        });
    };
}
