import {IPositionalField} from '../types';
import {IRowsColumns} from './types';

import {COLUMNS_COUNT} from './constants';

function copyFields(fields: IPositionalField[]): IPositionalField[] {
    return fields.map((field) => ({...field}));
}

export function calculateRowsCount(fields: IPositionalField[], positionKey: string): number {
    const rowsCount = Math.max(...fields.filter((item) => item[positionKey]).map((item) => item[positionKey].row + 1)) + 1;

    return rowsCount === -Infinity ? 1 : rowsCount;
}

function getRowWidth(fields: IPositionalField[], row: number, positionKey: string): number {
    return fields
        .filter((elem) => elem[positionKey] && elem[positionKey].row === row)
        .map((elem) => elem[positionKey].width)
        .reduce((value, curr) => curr + value, 0);
}

export function setNewWidth(fields: IPositionalField[], name: string, width: number, positionKey: string): IPositionalField[] | null {
    fields = copyFields(fields);

    const changedItem = fields.find((item) => item.name === name);

    if (!changedItem) {
        return null;
    }

    const rowWidth = getRowWidth(fields, changedItem[positionKey].row, positionKey);

    if (rowWidth > 11 && width > changedItem[positionKey].width) {
        return null;
    }

    changedItem[positionKey].width = width;

    return fields;
}

export function getDraggableId(field: IPositionalField): string {
    return `${field.name}`;
}

export function moveFieldFromUnset(movedField: IPositionalField, fields: IPositionalField[], rowsAndColumns: IRowsColumns, positionKey: string): IPositionalField[] {
    fields = copyFields(fields);

    const {endRow, endColumn} = rowsAndColumns;
    const destinationRowWidth = getRowWidth(fields, endRow, positionKey);

    if (destinationRowWidth === COLUMNS_COUNT) {
        return fields;
    }

    fields.forEach((field) => {
        if (!field[positionKey] && field.name === movedField.name) {
            field[positionKey] = {
                width: 1,
                row: endRow,
                column: endColumn,
            };
        }
    });

    return fields;
}

export function moveFieldToUnset(movedField: IPositionalField, fields: IPositionalField[], positionKey: string): IPositionalField[] {
    fields = copyFields(fields);

    fields.forEach((field) => {
        if (field.name === movedField.name) {
            field[positionKey] = undefined;
        }
    });

    return fields;
}

export function moveFieldInRow(movedField: IPositionalField, fields: IPositionalField[], rowsAndColumns: IRowsColumns, positionKey: string): IPositionalField[] {
    fields = copyFields(fields);

    const {startRow, startColumn, endColumn} = rowsAndColumns;

    fields.forEach((field) => {
        if (field[positionKey] && field[positionKey].row === startRow) {
            if (field.name === movedField.name) {
                field[positionKey].column = endColumn;
            } else if (startColumn < endColumn && field[positionKey] && startColumn < field[positionKey].column && field[positionKey].column <= endColumn) {
                field[positionKey].column -= 1;
            } else if (field[positionKey] && endColumn <= field[positionKey].column && field[positionKey].column < startColumn) {
                field[positionKey].column += 1;
            }
        }
    });

    return fields;
}

export function moveFieldToAnotherRow(movedField: IPositionalField, fields: IPositionalField[], rowsAndColumns: IRowsColumns, positionKey: string): IPositionalField[] {
    fields = copyFields(fields);

    const {startRow, startColumn, endRow, endColumn} = rowsAndColumns;
    const destinationRowWidth = getRowWidth(fields, endRow, positionKey);

    if (destinationRowWidth === COLUMNS_COUNT) {
        return fields;
    }

    fields.forEach((field) => {
        if (field[positionKey]) {
            if (field.name === movedField.name && COLUMNS_COUNT - destinationRowWidth < movedField[positionKey].width) {
                field[positionKey].width = 1;
            }
            if (field.name === movedField.name) {
                field[positionKey].column = endColumn;
                field[positionKey].row = endRow;
            } else if (field[positionKey] && field[positionKey].row === startRow && field[positionKey].column > startColumn) {
                field[positionKey].column -= 1;
            } else if (field[positionKey] && field[positionKey].row === endRow && field[positionKey].column >= endColumn) {
                field[positionKey].column += 1;
            }
        }
    });

    return fields;
}
