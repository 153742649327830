import {ISpecificForm} from '../../../FormField/types';
import {IDaDataGeoObject} from '../types';
import {IProps} from './types';

import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {InputAutoSuggest} from 'components/Helpers/Inputs';

import {context} from '../../../../Provider';
import {formatFormTitle} from '../../functions';
import {formatValue} from '../functions';

import {
    ERROR,
    FETCH_SUGGESTIONS_DELAY,
    SUGGESTIONS_COUNT,
} from './constants';
import {
    formatError,
    getSuggestions,
} from './functions';

const DaDataField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [value, setValue] = useState(formatValue(props.field.value, props.field.dataType));
    const [suggestions, setSuggestions] = useState<IDaDataGeoObject[]>([]);
    const [error, setError] = useState<ERROR | null>(null);
    const [delayTimeoutId, setDelayTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const {daDataUrl, daDataToken} = useContext(context);

    const onChange = (value: string) => {
        setValue(value);

        const suggestion = suggestions.find((suggestion) => formatValue(suggestion, props.field.dataType) === value);

        props.onChange(props.field.name, suggestion || null);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    if (!props.field.value) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                }

                setError(null);

                return true;
            },
        };
    });
    useEffect(() => {
        if (delayTimeoutId !== null) {
            clearTimeout(delayTimeoutId);
        }

        const id = setTimeout(async () => {
            const suggestions = await getSuggestions({
                value,
                type: props.field.dataType,
                limit: SUGGESTIONS_COUNT,
                url: daDataUrl,
                token: daDataToken,
            });
            const suggestion = suggestions.find((suggestion) => formatValue(suggestion, props.field.dataType) === value);

            if (suggestion) {
                if (suggestion.input !== props.field.value?.input) {
                    props.onChange(props.field.name, suggestion);
                }
            }

            setSuggestions(suggestions);
        }, FETCH_SUGGESTIONS_DELAY);

        setDelayTimeoutId(id);

        return () => {
            if (delayTimeoutId === null) {
                return;
            }

            clearTimeout(delayTimeoutId);
        };
    }, [value]);

    return (
        <InputAutoSuggest
            label={formatFormTitle(props.field, props.translation)}
            value={value}
            suggestions={suggestions.map((suggestion) => formatValue(suggestion, props.field.dataType))}
            onChange={onChange}
            error={formatError(error, props.intl)}
        />
    );
});

export default DaDataField;
