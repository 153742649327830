import {ISpecificDataForm} from '../../../TranslationField/types';
import {IProps} from './types';

import {
    forwardRef,
    useImperativeHandle,
} from 'react';

const PhoneField = forwardRef<ISpecificDataForm, IProps>((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            getValue(): Record<string, string> {
                return {};
            },
            validate(): boolean {
                return true;
            },
        };
    });

    return null;
});

export default PhoneField;
