import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialPhoneInput} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../helpers';

const PhoneField: FC<IProps> = (props) => {
    return (
        <MaterialPhoneInput
            label={translateByObject(props.field.title, props.translation)}
            value={props.field.value}
            isDisabled={true}
        />
    );
};

export default PhoneField;
