import {IUserFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA as FIELDS_INTL_DATA} from '../../intl';
import {INTL_DATA} from '../intl';

const UserField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [withCurrentUserAsDefaultValue, setWithCurrentUserAsDefaultValue] = useState(props.field.withCurrentUserAsDefaultValue);
    const [required, setRequired] = useState(props.field.validation.required);

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): IUserFieldSpecificProps {
                return {
                    value: null,
                    withCurrentUserAsDefaultValue,
                    validation: {
                        required,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {};
            },
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <>
            <div className={'border-bottom border-dark'}>
                <div className={'m-2'}>
                    <div className={'row'}>
                        <div className={'col-4'}>
                            <label className={'col-form-label'}>
                                {translateByIntl(props.intl, INTL_DATA.WITH_CURRENT_USER_AS_DEFAULT_VALUE, 'With active user as default value')}
                            </label>
                        </div>
                        <div className={'col-2'}>
                            <MaterialCheckbox
                                value={withCurrentUserAsDefaultValue}
                                onChange={setWithCurrentUserAsDefaultValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'m-2'}>
                <h5>
                    {translateByIntl(props.intl, FIELDS_INTL_DATA.VALIDATION, 'Validation')}
                </h5>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, FIELDS_INTL_DATA.REQUIRED, 'Required')}
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={required}
                            onChange={setRequired}
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

export default UserField;
