import { IReduxAction } from 'instances/types';
import { IReduxState } from './types/redux';

import { CONFIG_MODE } from 'config';

import { ActionTypes } from './constants';

export const initialState: IReduxState = {
    data: {
        jobsActivity: {
            [CONFIG_MODE.DEV]: false,
            [CONFIG_MODE.TEST]: true,
            [CONFIG_MODE.PROD_BLUE]: false,
            [CONFIG_MODE.PROD_GREEN]: true,
        },
        logsActivity: {
            [CONFIG_MODE.DEV]: true,
            [CONFIG_MODE.TEST]: true,
            [CONFIG_MODE.PROD_BLUE]: true,
            [CONFIG_MODE.PROD_GREEN]: true,
        },
        pdPortalToNotificationsActivity: {
            [CONFIG_MODE.DEV]: true,
            [CONFIG_MODE.TEST]: true,
            [CONFIG_MODE.PROD_BLUE]: false,
            [CONFIG_MODE.PROD_GREEN]: false,
        },
        pdPortalCcNotificationsActivity: {
            [CONFIG_MODE.DEV]: true,
            [CONFIG_MODE.TEST]: true,
            [CONFIG_MODE.PROD_BLUE]: false,
            [CONFIG_MODE.PROD_GREEN]: false,
        },
        pdPortalBccNotificationsActivity: {
            [CONFIG_MODE.DEV]: true,
            [CONFIG_MODE.TEST]: true,
            [CONFIG_MODE.PROD_BLUE]: false,
            [CONFIG_MODE.PROD_GREEN]: false,
        },
        portalToNotificationsActivity: {
            [CONFIG_MODE.DEV]: true,
            [CONFIG_MODE.TEST]: true,
            [CONFIG_MODE.PROD_BLUE]: false,
            [CONFIG_MODE.PROD_GREEN]: false,
        },
        portalCcNotificationsActivity: {
            [CONFIG_MODE.DEV]: true,
            [CONFIG_MODE.TEST]: true,
            [CONFIG_MODE.PROD_BLUE]: false,
            [CONFIG_MODE.PROD_GREEN]: false,
        },
        portalBccNotificationsActivity: {
            [CONFIG_MODE.DEV]: true,
            [CONFIG_MODE.TEST]: true,
            [CONFIG_MODE.PROD_BLUE]: false,
            [CONFIG_MODE.PROD_GREEN]: false,
        },
        basketServicesChecksActivity: {
            [CONFIG_MODE.DEV]: false,
            [CONFIG_MODE.TEST]: false,
            [CONFIG_MODE.PROD_BLUE]: false,
            [CONFIG_MODE.PROD_GREEN]: false,
        },
        isMxValidationEnabled: true
    },
    error: null,
    isLoaded: false,
};

export default function(state: IReduxState = initialState, action: IReduxAction): IReduxState {
    switch (action.type) {
        case ActionTypes.GetGlobalDataSucceed:
            const {data, error} = action;

            return error || !data ? {
                ...state,
                isLoaded: true,
                error,
            } : {
                ...state,
                data,
                isLoaded: true,
                error,
            };
        case ActionTypes.PatchGlobalDataSucceed: {
            const {data, error} = action;

            return error || !data ? {
                ...state,
                error,
            } : {
                ...state,
                data,
                error,
            };
        }
        default: {
            return state;
        }
    }
}
