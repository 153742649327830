import {
    IDENTIFIER_TYPE,
    PASSPORT_TYPE,
} from 'instances/equippedUsers/constants';

export function formatIdentifierType(identifierType: IDENTIFIER_TYPE): string {
    switch (identifierType) {
        case IDENTIFIER_TYPE.PHONE: {
            return 'телефонный номер';
        }
        case IDENTIFIER_TYPE.IP: {
            return 'IP-адрес';
        }
    }
}

export function formatPassportType(passportType: PASSPORT_TYPE): string {
    switch (passportType) {
        case PASSPORT_TYPE.REGULAR_PASSPORT: {
            return 'Паспорт РФ';
        }
        case PASSPORT_TYPE.INTERNATIONAL_PASSPORT: {
            return 'Заграничный паспорт';
        }
    }
}
