import {IProps} from './types';

import React, {FC} from 'react';
import {Draggable} from 'react-beautiful-dnd';

import {MaterialTooltip} from 'components/Helpers/MaterialUI';

import styles from './Element.module.scss';

const Element: FC<IProps> = (props) => {
    const {formWrapper: FormWrapper, displayWrapper: DisplayWrapper} = props;

    return (
        <Draggable
            key={props.field.name}
            draggableId={props.field.name}
            index={props.index}
        >
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{...provided.draggableProps.style}}
                    className={`${styles.draggableElement} w-100`}
                >
                    <MaterialTooltip
                        placement={'bottom'}
                        text={props.field.name}
                    >

                        {
                            props.isForm ?
                                <FormWrapper
                                    field={props.field}
                                    intl={props.intl}
                                    translation={props.translation}
                                    onChange={() => ({})}
                                /> :
                                <DisplayWrapper
                                    field={props.field}
                                    intl={props.intl}
                                    translation={props.translation}
                                />
                        }
                    </MaterialTooltip>
                </div>
            )}
        </Draggable>
    );
};

export default Element;
