import {
    IApplicationTranslation,
    ITranslationApplication,
} from './types';

import {useDefaultedRef} from '../../helpers';

export function useTranslationApplicationRef() {
    return useDefaultedRef<ITranslationApplication>({
        getValue(): IApplicationTranslation {
            return {
                applicationStepTitles: [],
                applicationFrames: [],
            };
        },
        validate(): boolean {
            return true;
        },
    });
}
