import {ITextFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA as FIELDS_INTL_DATA} from '../../intl';
import {INTL_DATA} from '../intl';

const TextField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [value, setValue] = useState(props.field.value);
    const [hideTitle, setHideTitle] = useState(props.field.hideTitle);
    const [required, setRequired] = useState(props.field.validation.required);

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): ITextFieldSpecificProps {
                return {
                    value,
                    hideTitle,
                    validation: {
                        required,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {
                    [value]: value,
                };
            },
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <>
            <div className={'border-bottom border-dark'}>
                <div className={'m-2'}>
                    <div className={'row'}>
                        <div className={'col-4'}>
                            <label className={'col-form-label'}>
                                {translateByIntl(props.intl, FIELDS_INTL_DATA.DEFAULT_VALUE, 'Default value')}
                                :
                            </label>
                        </div>
                        <div className={'col-8'}>
                            <textarea
                                className={'form-control'}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                            />

                        </div>
                    </div>
                    <div className={'row mt-2'}>
                        <div className={'col-4'}>
                            <label className={'col-form-label'}>
                                {translateByIntl(props.intl, INTL_DATA.HIDE_TITLE, 'Hide title')}
                                :
                            </label>
                        </div>
                        <div className={'col-2'}>
                            <MaterialCheckbox
                                value={hideTitle}
                                onChange={setHideTitle}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'m-2'}>
                <h5>
                    {translateByIntl(props.intl, FIELDS_INTL_DATA.VALIDATION, 'Validation')}
                </h5>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, FIELDS_INTL_DATA.REQUIRED, 'Required')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={required}
                            onChange={setRequired}
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

export default TextField;
