import {
    IProps,
    ITooltipInput,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialInput} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';

import {INTL_DATA} from './intl';

const TooltipInput = forwardRef<ITooltipInput, IProps>((props, ref) => {
    const [value, setValue] = useState(props.initValue);

    const onChange = (value: string) => {
        setValue(value);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): string {
                return value;
            },
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <MaterialInput
            label={translateByIntl(props.intl, INTL_DATA.TOOLTIP, 'Tooltip')}
            value={value}
            onChange={(value) => onChange(value)}
        />
    );
});

export default TooltipInput;
