import { IReduxInjectedState } from './types';
import { IRootReduxState } from 'instances/types';

import * as servicesGroups from 'instances/servicesGroups/actions';
import * as globalData from 'instances/globalData/actions';
import * as servicesVersions from 'instances/servicesVersions/actions';
import * as services from 'instances/services/actions';
import * as commonData from 'instances/commonData/actions';


export function mapStateToProps(state: IRootReduxState) {
    return {
        servicesGroups: servicesGroups.getError(state),
        globalData: globalData.getError(state),
        servicesVersions: servicesVersions.getError(state),
        services: services.getError(state),
        commonData: commonData.getError(state),
    };
}

export function cleanUpGarbage(errors: IReduxInjectedState) {
    return Object
        .entries(errors)
        .filter(([name, _]) => !['dispatch', 'intl', 'match', 'location', 'statusContext', 'logout'].includes(name));
}

export function errorIsHere(errors: IReduxInjectedState): boolean {
    return cleanUpGarbage(errors)
        .map(([_, value]) => !!value)
        .includes(true);
}
