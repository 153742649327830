import { parseBody, notify } from '../stuff.ts';


export default async function(response) {
    const {body, error} =  await parseBody(response);

    if (response.status === 204) {
        return {};
    }

    if (error) {
        notify();
        console.log(error);
        return {error};
    }

    switch(response.status) {
        case 200:
            return {body};
        case 401:
            return {};
        default:
            notify();
            console.log(body);
            return {error: body};
    }
}
