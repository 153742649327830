import {ISpecificForm} from '../../../FormField/types';
import {IProps} from './types';

import React, {
    forwardRef,
    useId,
    useImperativeHandle,
    useState,
} from 'react';

import {
    formatStringWithUrl,
    translateByObject,
} from '../../../../helpers';

import {ERROR} from './constants';
import {formatError} from './functions';

const StringField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [error, setError] = useState<ERROR | null>(null);
    const elemId = useId();

    const label = translateByObject(props.field.title, props.translation);
    const value = translateByObject(props.field.value, props.translation);

    const onChange = (value: string) => {
        if (props.field.validation.maxLength !== undefined) {
            value = value.slice(0, props.field.validation.maxLength);
        }

        props.onChange(props.field.name, value);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    if (!props.field.value) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                }
                if (props.field.validation.re) {
                    const re = new RegExp(props.field.validation.re);

                    if (props.field.value && !re.test(props.field.value)) {
                        setError(ERROR.INVALID_FORMAT);

                        return false;
                    }
                }

                setError(null);

                return true;
            },
        };
    });

    if (props.field.readOnly) {
        const parsedValue = formatStringWithUrl(translateByObject(props.field.value, props.translation));

        if (parsedValue) {
            return (
                <div>
                    <label className={'col-form-label'}>
                        {translateByObject(props.field.title, props.translation)}
                    </label>
                    <div>
                        {parsedValue}
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={'d-flex flex-column w-100'}>
            <label htmlFor={elemId} className={'form-label'}>
                {label}
            </label>
            <input
                className={'form-control'}
                id={elemId}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
            {
                error &&
                <div>
                    {formatError(error, props.intl)}
                </div>
            }
        </div>
    );
});

export default StringField;
