import React, { PureComponent } from 'react';

import { IProps } from './types';


class Jumbotron extends PureComponent<IProps> {
    public render() {
        return (
            <div>
                <div className='jumbotron d-flex justify-content-center'>
                    <h3 className='display-4'>
                        {this.props.message}
                    </h3>
                </div>
            </div>
        );
    }
}

export default Jumbotron;
