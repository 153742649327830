import React, {
    ElementType,
    useEffect,
    useState,
} from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {useAuth} from 'instances/auth/hooks';
import {USER_TYPE} from 'instances/users/constants';

import {ROUTE} from 'components/constants';
import {Loading} from 'components/Helpers/Other';

import {hasPageAccess} from './functions';

// TODO: Some magic with ts-ignore

function injectPageAccess<IProps>(Component: ElementType<IProps>): ElementType<IProps> {
    // @ts-ignore
    return (props) => {
        const [isPending, setIsPending] = useState(true);

        const {auth} = useAuth();

        const navigate = useNavigate();
        const location = useLocation();

        useEffect(() => {
            if (!auth) {
                return;
            }

            const isAccessed = hasPageAccess(auth, location.pathname);

            if (!isAccessed) {
                if (location.pathname === ROUTE.HOME) {
                    switch (auth.type) {
                        case USER_TYPE.SUPER_ADMIN:
                        case USER_TYPE.ORANGE_ADMIN:
                        case USER_TYPE.CLIENT_ADMIN: {
                            navigate(ROUTE.ADMIN);
                            break;
                        }
                        case USER_TYPE.SIMPLE_USER: {
                            navigate(ROUTE.SETTINGS);
                            break;
                        }
                    }
                } else {
                    navigate('/not_found');
                }
            }

            setIsPending(false);
        }, [auth]);

        if (isPending) {
            return () => <Loading isPage={true}/>;
        }

        return (
            // @ts-ignore
            <Component {...props}/>
        );
    };
}

export default injectPageAccess;
