import {
    IProps,
    ITitleInput,
} from './types';

import classNames from 'classnames';
import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    capitalize,
    translateByIntl,
} from '../../../../helpers';

import {ERROR} from './constants';
import {formatError} from './functions';
import {INTL_DATA} from './intl';

const TitleInput = forwardRef<ITitleInput, IProps>((props, ref) => {
    const [value, setValue] = useState(props.initValue);
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (value: string) => {
        setValue(capitalize(value));
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): string {
                return value;
            },
            validate(): boolean {
                if (props.title && !value) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    if (!props.title) {
        return null;
    }

    return (
        <div className={'row'}>
            <div className={'col-4'}>
                <label className={'col-form-label'}>
                    {translateByIntl(props.intl, INTL_DATA.TITLE, 'Title')}
                </label>
            </div>
            <div className={'col-4'}>
                <label className={'col-form-label'}>
                    {props.title}
                </label>
            </div>
            <div className={'col-4'}>
                <input
                    className={
                        classNames('form-control', {
                            'is-invalid': !!error,
                        })
                    }
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
                {
                    !!error &&
                    <div className={'text-danger'}>
                        {formatError(error)}
                    </div>
                }
            </div>
        </div>
    );
});

export default TitleInput;
