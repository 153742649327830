import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialRadio} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../helpers';

const RadioField: FC<IProps> = (props) => {
    return (
        <div>
            <label className={'col-form-label'}>
                {translateByObject(props.field.title, props.translation)}
            </label>
            {
                props.field.values.map(
                    (value, i) => (
                        <MaterialRadio
                            key={value}
                            label={translateByObject(value, props.translation)}
                            value={props.field.value === props.field.values[i]}
                            isDisabled={true}
                        />
                    )
                )
            }
        </div>
    );
};

export default RadioField;
