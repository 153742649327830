export enum FIELD_TYPE {
    ADDRESS = 'address',
    CHECKBOX = 'checkbox',
    CONTRACT = 'contract',
    DA_DATA = 'DaData',
    DATE = 'date',
    GEO = 'geo',
    IP = 'IP',
    NUMBER = 'number',
    PHONE = 'phone',
    RADIO = 'radio',
    RANGE = 'range',
    SELECT = 'select',
    STRING = 'string',
    TEXT = 'text',
    USER = 'user',
    US_MIGRATION = 'USMigration',
}
