import config from 'config';
import handler from './handler';

const headers = [
    ['Accept', 'application/json'],
    ['Content-Type', 'application/json'],
    ['Access-Control-Request-Headers', 'true'],
];
const credentials = 'include';

const services = {
    async get() {
        const response = await fetch(`${config.URL}/services`, {
            method: 'GET',
            headers,
            credentials,
        });
        const {body, error} = await handler(response);
        return error ? [] : body.services;
    },

    async getVersions(serviceId) {
        const response = await fetch(`${config.URL}/services_versions/service/${serviceId}`, {
            method: 'GET',
            headers,
            credentials,
        });
        const {body, error} = await handler(response);
        return error ? [] : body;
    },

    async getVersion(versionId) {
        const response = await fetch(`${config.URL}/services_versions/${versionId}`, {
            method: 'GET',
            headers,
            credentials,
        });
        const {body, error} = await handler(response);
        return error ? {} : body;
    },

    async postService(service) {
        const response = await fetch(`${config.URL}/services`, {
            method: 'POST',
            headers,
            credentials,
            body: JSON.stringify(service),
        });
        const {body, error} = await handler(response);
        return error ? {} : {...body.service, versions: []};
    },

    async patchService(service) {
        const response = await fetch(`${config.URL}/services`, {
            method: 'PATCH',
            headers,
            credentials,
            body: JSON.stringify(service),
        });
        const {body, error} = await handler(response);
        return error ? {} : body.service;

    },

    async postVersion(serviceId, version) {
        version.serviceId = serviceId;

        const response = await fetch(`${config.URL}/services_versions`, {
            method: 'POST',
            headers,
            credentials,
            body: JSON.stringify(version),
        });
        const {body, error} = await handler(response);
        return error ? {} : body;
    },

    async putVersion(serviceId, versionId, version) {
        version.id = versionId;
        version.serviceId = serviceId;

        const response = await fetch(`${config.URL}/services_versions`, {
            method: 'PATCH',
            headers,
            credentials,
            body: JSON.stringify(version),
        });
        const {body, error} = await handler(response);
        return error ? {} : body;
    },

    async setActive(serviceId, versionId) {
        const response = await fetch(`${config.URL}/services_versions/active/${versionId}`, {
            method: 'POST',
            headers,
            credentials,
        });
        const {body, error} = await handler(response);
        return error ? {} : body;
    },

    async setQueue(queue) {
        const response = await fetch(`${config.URL}/services/queue`, {
            method: 'POST',
            headers,
            credentials,
            body: JSON.stringify(queue),
        });
        const {body, error} = await handler(response);
        return error ? {} : body;
    },

    async getActiveVersions() {
        const response = await fetch(`${config.URL}/services_versions`, {
            method: 'GET',
            headers,
            credentials,
        });
        const {body, error} = await handler(response);
        return error ? [] : body;
    },
};

export default services;
