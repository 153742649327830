import {
    IProps,
    ITypeInput,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialSelect} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';
import {FIELD_TYPE} from '../../../constants';

import {INTL_DATA} from './intl';

const TypeInput = forwardRef<ITypeInput, IProps>((props, ref) => {
    const [value, setValue] = useState(props.initValue);

    const onChange = (value: FIELD_TYPE) => {
        setValue(value);
        props.onChange(value);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <MaterialSelect
            label={translateByIntl(props.intl, INTL_DATA.TYPE, 'Type')}
            value={value}
            values={props.types}
            options={props.types}
            isDisabled={props.isDisabled}
            onChange={(value) => onChange(value as FIELD_TYPE)}
        />
    );
});

export default TypeInput;
