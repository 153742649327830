import {
    IUserForAdmin,
    IUserForClient,
} from './types';
import {
    IDownloadSampleAdminBody,
    IGetByAdminQuery,
    IGetByClientQuery,
    IPostDataByAdminBody,
    IPostDataByClientBody,
    IPostExcelByAdminBody,
    IPostExcelByClientBody,
    IPostUserByAdminBody,
} from './types/requests';
import {
    IDownloadSampleByAdmin,
    IDownloadSampleByClient,
    IGetByAdmin,
    IGetByClient,
    IPostDataByAdmin,
    IPostDataByClient,
    IPostUserByAdmin,
    IRemoveByAdmin,
    IUploadExcelByAdmin,
    IUploadExcelByClient,
} from './types/responses';
import {ISuggestionsUser} from './types/suggestions';

import {useState} from 'react';

import Notifier from 'helpers/Notifier';

import * as fetches from './fetches';
import {formatFixedDate} from './functions';

export function useUsersByClient() {
    const [equippedUsers, setEquippedUsers] = useState<IUserForClient[] | null>(null);
    const [equippedUsersTotal, setEquippedUsersTotal] = useState<number | null>(null);
    const [equippedUsersSuggestions, setEquippedUsersSuggestions] = useState<ISuggestionsUser | null>(null);

    const getEquippedUsers = async (query: IGetByClientQuery): Promise<IGetByClient> => {
        const res = await fetches.getByClient(query);

        if (res.error || !res.equippedUsers || res.total === undefined || !res.suggestions) {
            console.log(res.error);
            Notifier.error('Что-то пошло не так =(');

            return res;
        }

        setEquippedUsers([...(query.skip === 0 ? [] : equippedUsers || []), ...res.equippedUsers]);
        setEquippedUsersTotal(res.total);
        setEquippedUsersSuggestions(res.suggestions);

        return res;
    };

    const postEquipmentData = async (body: IPostDataByClientBody): Promise<IPostDataByClient> => {
        const res = await fetches.postDataByClient({
            data: body.data.map((item) => {
                return {
                    ...item,
                    person: {
                        ...item.person,
                        birthday: formatFixedDate(item.person.birthday),
                    },
                    passport: {
                        ...item.passport,
                        issueDate: formatFixedDate(item.passport.issueDate),
                    },
                };
            }),
        });

        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };

    const downloadSampleByClient = async (): Promise<IDownloadSampleByClient> => {
        const res = await fetches.downloadSampleByClient();

        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };

    const uploadExelByClient = async (body: IPostExcelByClientBody): Promise<IUploadExcelByClient> => {
        const res = await fetches.uploadExelByClient(body);

        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };

    return {
        equippedUsers,
        equippedUsersTotal,
        equippedUsersSuggestions,
        getEquippedUsers,
        postEquipmentData,
        downloadSampleByClient,
        uploadExelByClient,
    };
}

export function useUsersByAdmin() {
    const [equippedUsers, setEquippedUsers] = useState<IUserForAdmin[] | null>(null);
    const [equippedUsersTotal, setEquippedUsersTotal] = useState<number | null>(null);
    const [equippedUsersSuggestions, setEquippedUsersSuggestions] = useState<ISuggestionsUser | null>(null);

    const getEquippedUsers = async (equippedClientId: string, query: IGetByAdminQuery): Promise<IGetByAdmin> => {
        const res = await fetches.getByAdmin(equippedClientId, query);

        if (res.error || !res.equippedUsers || res.total === undefined || !res.suggestions) {
            console.log(res.error);

            return res;
        }

        setEquippedUsers([...(query.skip === 0 ? [] : equippedUsers || []), ...res.equippedUsers]);
        setEquippedUsersTotal(res.total);
        setEquippedUsersSuggestions(res.suggestions);

        return res;
    };

    const postEquipmentData = async (body: IPostDataByAdminBody): Promise<IPostDataByAdmin> => {
        const res = await fetches.postDataByAdmin({
            clientId: body.clientId,
            data: body.data.map((item) => {
                return {
                    ...item,
                    person: {
                        ...item.person,
                        birthday: formatFixedDate(item.person.birthday),
                    },
                    passport: {
                        ...item.passport,
                        issueDate: formatFixedDate(item.passport.issueDate),
                    },
                };
            }),
        });

        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };

    const postUserByAdmin = async (body: IPostUserByAdminBody): Promise<IPostUserByAdmin> => {
        const res = await fetches.postUserByAdmin(body);

        if (res.error || !res.equippedUser) {
            console.log(res.error);

            return res;
        }

        setEquippedUsers(equippedUsers ? [...equippedUsers, res.equippedUser] : [res.equippedUser]);
        setEquippedUsersTotal(equippedUsers ? equippedUsers.length + 1 : 1);

        return res;
    };

    const downloadSampleByAdmin = async (body: IDownloadSampleAdminBody): Promise<IDownloadSampleByAdmin> => {
        const res = await fetches.downloadSampleByAdmin(body);

        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };

    const uploadExelByAdmin = async (body: IPostExcelByAdminBody): Promise<IUploadExcelByAdmin> => {
        const res = await fetches.uploadExelByAdmin(body);

        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };

    const removeEquippedUser = async (id: string): Promise<IRemoveByAdmin> => {
        const res = await fetches.removeByAdmin(id);

        if (res.error) {
            console.log(res.error);

            return res;
        }
        if (!equippedUsers) {
            return res;
        }

        setEquippedUsers(equippedUsers.filter((equippedUser) => equippedUser.id !== id));

        return res;
    };

    return {
        equippedUsers,
        equippedUsersTotal,
        equippedUsersSuggestions,
        getEquippedUsers,
        postEquipmentData,
        postUserByAdmin,
        downloadSampleByAdmin,
        uploadExelByAdmin,
        removeEquippedUser,
    };
}
