import {IField} from '../../types';
import {IFieldTranslation} from '../types';
import {ICommonDataForm} from './CommonDataForm/types';
import {
    IProps,
    ISpecificDataForm,
} from './types';

import React, {
    FC,
    useRef,
    useState,
} from 'react';

import CommonDataForm from './CommonDataForm';
import SpecificDataForm from './SpecificDataForm';

import {FIELD_TYPE} from '../../constants';

import {getDefaultField} from './functions';

const CreationField: FC<IProps> = (props) => {
    const [field, setField] = useState(props.field || getDefaultField(FIELD_TYPE.STRING));

    const commonDataFormRef = useRef<ICommonDataForm>(null);
    const specificDataFormRef = useRef<ISpecificDataForm<any>>(null);

    const onChangeType = (type: FIELD_TYPE) => {
        if (!commonDataFormRef.current) {
            return;
        }

        setField({
            ...getDefaultField(type),
            ...commonDataFormRef.current.getValue(),
        });
    };
    const onSave = () => {
        if (!commonDataFormRef.current?.validate()) {
            return;
        }
        if (!specificDataFormRef.current?.validate()) {
            return;
        }

        const newField: IField = {
            ...field,
            ...commonDataFormRef.current.getValue(),
            ...specificDataFormRef.current.getFieldStructure(),
        };

        const translation: IFieldTranslation = {
            name: newField.name,
            messages: {
                [newField.title]: newField.title,
                [newField.tooltip]: newField.tooltip,
                ...specificDataFormRef.current.getFieldTranslation(),
            },
        };

        props.onSave(newField, translation);
    };

    return (
        <div className={'border border-dark'}>
            <div className={'border-bottom border-dark'}>
                <div className={'d-flex flex-row justify-content-around m-2'}>
                    <button
                        className={'btn btn-sm btn-primary'}
                        onClick={onSave}
                    >
                        Сохранить
                    </button>
                    <button
                        className={'btn btn-sm btn-secondary'}
                        onClick={props.onCancel}
                    >
                        Отмена
                    </button>
                </div>
            </div>
            <CommonDataForm
                ref={commonDataFormRef}
                field={field}
                fields={props.fields}
                types={props.types}
                isDefaultField={props.isDefaultField}
                intl={props.intl || null}
                onChangeType={onChangeType}
            />
            <SpecificDataForm
                ref={specificDataFormRef}
                field={field}
                intl={props.intl || null}
            />
        </div>
    );
};

export default CreationField;
