import {IEquippedClientAdmin} from './types';
import {
    IGetByAdminQuery,
    IPatchByAdminBody,
    IPostByAdminBody,
} from './types/requests';
import {
    IGetByAdmin,
    IGetOneByAdmin,
    IPatchByAdmin,
    IPostByAdmin,
    IRemoveByAdmin,
} from './types/responses';
import {ISuggestionsAdmin} from './types/suggestions';

import {useState} from 'react';

import Notifier from 'helpers/Notifier';

import * as fetches from './fetches';

export function useClientsByAdmin() {
    const [equippedClients, setEquippedClients] = useState<IEquippedClientAdmin[] | null>(null);
    const [equippedClientsSuggestions, setEquippedClientsSuggestions] = useState<ISuggestionsAdmin | null>(null);
    const [equippedClientsTotal, setEquippedClientsTotal] = useState<number | null>(null);
    const [identifiersTotal, setIdentifiersTotal] = useState<number | null>(null);
    const [totalSentIdentifiers, setTotalSentIdentifiers] = useState<number | null>(null);

    const getClients = async (query: IGetByAdminQuery): Promise<IGetByAdmin> => {
        query.skip === 0 && setEquippedClients(null);

        const res = await fetches.getByAdmin(query);

        if (
            !res.equippedClients ||
            !res.suggestions ||
            res.total === undefined ||
            res.totalIdentifiers === undefined ||
            res.totalSentIdentifiers === undefined ||
            res.error) {
            console.log(res.error);

            return res;
        }

        setEquippedClients([...(query.skip === 0 ? [] : equippedClients || []), ...res.equippedClients]);
        setEquippedClientsSuggestions(res.suggestions);
        setEquippedClientsTotal(res.total);
        setIdentifiersTotal(res.totalIdentifiers);
        setTotalSentIdentifiers(res.totalSentIdentifiers);

        return res;
    };

    const postClient = async (body: IPostByAdminBody): Promise<IPostByAdmin> => {
        const res = await fetches.postClientByAdmin(body);

        if (!res.equippedClient || res.error) {
            console.log(res.error);

            return res;
        }
        if (!equippedClients) {
            return res;
        }

        setEquippedClients([res.equippedClient, ...equippedClients]);
        setEquippedClientsTotal((equippedClientsTotal || 0) + 1);

        return res;
    };

    const patchClient = async (body: IPatchByAdminBody): Promise<IPatchByAdmin> => {
        const res = await fetches.patchClientByAdmin(body);

        if (!res.equippedClient || res.error) {
            console.log(res.error);
            Notifier.error('Что то пошло не так. Откройте консоль (F12) для дополнительной информации');

            return res;
        }
        if (!equippedClients) {
            return res;
        }

        const index = equippedClients.findIndex((equippedClient) => equippedClient.id === body.id);

        if (index === -1) {
            return res;
        }
        if (equippedClients[index].isEnterClosed !== res.equippedClient.isEnterClosed) {
            Notifier.success('Доступ пользователя успешно изменен');
        }

        equippedClients[index] = res.equippedClient;
        setEquippedClients([...equippedClients]);

        return res;
    };

    const removeClient = async (id: string): Promise<IRemoveByAdmin> => {
        const res = await fetches.removeByAdmin(id);

        if (res.error) {
            console.log(res.error);

            return res;
        }
        if (!equippedClients) {
            return res;
        }

        setEquippedClients(equippedClients.filter((equippedClient) => equippedClient.id !== id));
        setEquippedClientsTotal((equippedClientsTotal || 1) - 1);

        return res;
    };

    return {
        equippedClients,
        equippedClientsSuggestions,
        equippedClientsTotal,
        getClients,
        patchClient,
        postClient,
        removeClient,
        identifiersTotal,
        totalSentIdentifiers,
    };
}

export function useClientByAdmin() {
    const [equippedClient, setEquippedClient] = useState<IEquippedClientAdmin | null>(null);

    const getOneEquippedClient = async (id: string): Promise<IGetOneByAdmin> => {
        const res = await fetches.getOneByAdmin(id);

        if (!res.equippedClient || res.error) {
            console.log(res.error);

            return res;
        }

        setEquippedClient(res.equippedClient);

        return res;
    };

    const postEquippedClient = async (body: IPostByAdminBody): Promise<IPostByAdmin> => {
        const res = await fetches.postClientByAdmin(body);

        if (!res.equippedClient || res.error) {
            console.log(res.error);

            return res;
        }
        if (!equippedClient) {
            return res;
        }

        setEquippedClient(res.equippedClient);

        return res;
    };

    return {
        equippedClient,
        getOneEquippedClient,
        postEquippedClient,
    };
}
