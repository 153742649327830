export enum ROUTE {
    HOME = '/',
    LOGIN = '/login',

    USER_SERVICES = '/my-services',
    SERVICES_CATALOG = '/services-catalog',
    SITES = '/sites',
    ORDERS = '/orders',

    INCIDENTS = '/incidents',
    BIDS = '/bids',

    BILLS = '/bills',

    BASKET = '/order',
    NOTIFICATIONS = '/notifications',

    SETTINGS = '/settings',

    ADMIN = '/admin',
}

export enum ADMIN_ROUTE {
    GLOBAL_DATA = '/admin/global_data',
    SERVICE_GROUPS = '/admin/service_groups',
    SERVICES = '/admin/services',
    SITES = '/admin/sites',
    CLIENTS = '/admin/clients',
    USERS = '/admin/users',
    LANGUAGES = '/admin/languages',
    LOGS = '/admin/logs',
    EQUIPPED_CLIENTS = '/admin/equipped_clients',
    EQUIPPED_CONTACTS = '/admin/equipped_contacts',
    DOCS = '/admin/docs',
}
