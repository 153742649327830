import {
    INotificationsInitialState,
    NotificationsFilters,
} from 'instances/notifications/types';

import {LS_NOTIFICATIONS_FILTER} from 'instances/notifications/constants';

export const reducers = {
    mutateFilters: (state: INotificationsInitialState, action: {payload: NotificationsFilters}) => {
        state.filters = {
            ...state.filters,
            ...action.payload,
        };
        localStorage.setItem(LS_NOTIFICATIONS_FILTER, JSON.stringify(state.filters));
    },
    toSubmitNotification: (state: INotificationsInitialState, action: {payload: string | null}) => {
        state.notificationToSubmit = action.payload;
    },
};
