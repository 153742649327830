import {IProps} from './types';

import React, {FC} from 'react';

import {
    MaterialInput,
    MaterialTooltip,
} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../helpers';
import {
    formatUserTooltip,
    formatValue,
} from '../functions';

import questionImage from '../media/question.png';

const UserField: FC<IProps> = (props) => {
    return (
        <div className={'d-flex flex-row justify-content-between align-items-center w-100'}>
            <MaterialInput
                label={translateByObject(props.field.title, props.translation)}
                value={formatValue(props.field.value)}
                isDisabled={true}
            />
            {
                props.field.value &&
                <MaterialTooltip
                    text={formatUserTooltip(props.field.value, props.intl)}
                    placement={'bottom'}
                >
                    <img
                        width={'30'}
                        height={'30'}
                        src={questionImage}
                        alt={''}
                    />
                </MaterialTooltip>
            }
        </div>
    );
};

export default UserField;
