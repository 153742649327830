import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialInput} from 'components/Helpers/MaterialUI';

import {
    formatStringWithUrl,
    translateByObject,
} from '../../../../helpers';

const StringField: FC<IProps> = (props) => {
    const title = translateByObject(props.field.title, props.translation);
    const value = translateByObject(props.field.value, props.translation);

    const parsedURLString = formatStringWithUrl(value);

    if (parsedURLString || props.field.readOnly) {
        return (
            <div>
                <label className={'col-form-label'}>
                    {title}
                </label>
                <div>
                    {parsedURLString ?? value}
                </div>
            </div>
        );
    }

    return (
        <MaterialInput
            label={title}
            value={value}
            isDisabled={true}
        />
    );
};

export default StringField;
