import {
    IGetByAdmin,
    IGetByUser,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {onLogout} from 'instances/auth/functions';

import {INSTANCE_PATH} from './constants';

export async function getByAdmin(clientId: string): Promise<IGetByAdmin> {
    return new Fetch<IGetByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin/${encodeURIComponent(clientId)}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getByUser(): Promise<IGetByUser> {
    return new Fetch<IGetByUser>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec();
}
