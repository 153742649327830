export enum INPUT {
    INN = 'INN',
    CLIENT_NAME = 'CLIENT_NAME',

    IDENTIFIER = 'IDENTIFIER',
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    MIDDLE_NAME = 'MIDDLE_NAME',
    BIRTHDAY = 'BIRTHDAY',

    PASSPORT_NAME = 'PASSPORT_NAME',
    PASSPORT_SERIES = 'PASSPORT_SERIES',
    PASSPORT_NUMBER = 'PASSPORT_NUMBER',
    PASSPORT_ISSUE_DATE = 'PASSPORT_ISSUE_DATE',
    PASSPORT_AUTHORITY = 'PASSPORT_AUTHORITY',
    PASSPORT_DEPARTMENT_CODE = 'PASSPORT_DEPARTMENT_CODE',
}

export const NAME_SYMBOL_REGEX = /[A-Za-zА-Яа-яЁё\s]/;
export const PASSPORT_AUTHORITY_REGEX = /[А-Яа-я.,--\s]/;
