import {IProps} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useId,
} from 'react';

import {translateByObject} from '../../../../helpers';

import styles from './BoostedTextField.module.scss';

const BoostedTextField: FC<IProps> = (props) => {
    const title = translateByObject(props.field.title, props.translation);
    const value = translateByObject(props.field.value, props.translation);

    const elemId = useId();

    return (
        <div className={classNames('d-flex flex-column', styles.container)}>
            <label
                htmlFor={elemId}
                className={'input-group-text p-0'}
            >
                {title}
            </label>
            <textarea
                className={'form-control w-100'}
                id={elemId}
                disabled={true}
                rows={4}
                value={value || ''}
            />
        </div>
    );
};

export default BoostedTextField;
