import CircularProgress from '@mui/material/CircularProgress';
import React, {FC} from 'react';

interface IProps {
    size?: number;
    isPage?: boolean;
    color?: string;
}

const Loading: FC<IProps> = ({
    size,
    isPage,
}) => {
    return (
        <div
            className={
                isPage ?
                    'position-fixed top-50 start-50' :
                    'd-flex flex-row justify-content-center align-items-center'
            }
            style={{height: size}}
        >
            <CircularProgress sx={{color: '#f16e00'}}/>
        </div>
    );
};

export default Loading;
