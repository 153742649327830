import {ITitleInput} from './TitleInput/types';
import {ITooltipInput} from './TooltipInput/types';
import {
    ICommonDataForm,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
} from 'react';

import TitleInput from './TitleInput';
import TooltipInput from './TooltipInput';

import {translateByObject} from '../../../helpers';

const CommonDataForm = forwardRef<ICommonDataForm, IProps>((props, ref) => {
    const titleInputRef = useRef<ITitleInput>(null);
    const tooltipInputRef = useRef<ITooltipInput>(null);

    useImperativeHandle(ref, () => {
        return {
            getValue(): Record<string, string> {
                return {
                    [props.field.title]: titleInputRef.current?.getValue() || '',
                    [props.field.tooltip]: tooltipInputRef.current?.getValue() || '',
                };
            },
            validate(): boolean {
                return ![
                    titleInputRef.current?.validate(),
                    tooltipInputRef.current?.validate(),
                ].includes(false);
            },
        };
    });

    return (
        <>
            <div className={'mb-2'}>
                <TitleInput
                    ref={titleInputRef}
                    title={props.field.title}
                    initValue={translateByObject(props.field.title, props.translation)}
                    intl={props.intl}
                />
            </div>
            <div>
                <TooltipInput
                    ref={tooltipInputRef}
                    tooltip={props.field.tooltip}
                    initValue={translateByObject(props.field.tooltip, props.translation)}
                    intl={props.intl}
                />
            </div>
        </>
    );
});

export default CommonDataForm;
