import {
    IProps,
    ITitleInput,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialInput} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';

import {ERROR} from './constants';
import {formatError} from './functions';
import {INTL_DATA} from './intl';

const TitleInput = forwardRef<ITitleInput, IProps>((props, ref) => {
    const [value, setValue] = useState(props.initValue);
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (value: string) => {
        setValue(value);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): string {
                return value;
            },
            validate(): boolean {
                if (!value) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <MaterialInput
            label={translateByIntl(props.intl, INTL_DATA.TITLE, 'Title')}
            value={value}
            onChange={(value) => onChange(value)}
            isError={!!error}
            helperText={formatError(error, props.intl)}
        />
    );
});

export default TitleInput;
