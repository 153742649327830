import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import Core from './Core';


const ItemPropType = PropTypes.shape({
    getState: PropTypes.func.isRequired,
    getAction: PropTypes.func.isRequired,
    isLoaded: PropTypes.func.isRequired,
    getActionParams: PropTypes.func,
});

class ReduxLoader extends Component {
    static propTypes = {
        WrappedComponent: PropTypes.any,
        scenario: PropTypes.oneOfType([
            PropTypes.arrayOf(ItemPropType),
            PropTypes.arrayOf(
                PropTypes.oneOfType([
                    ItemPropType,
                    PropTypes.arrayOf(ItemPropType),
                ])
            ),
        ]).isRequired,
        isPage: PropTypes.bool,
        spinnerSize: PropTypes.number,
    };

    static defaultProps = {
        scenario: [],
        isPage: false,
        spinnerSize: 50,
    };

    constructor(props) {
        super(props);
        this.scenario = this.defineIds(this.props.scenario);
    }

    setIdToItem = (item) => {
        return {
            ...item,
            stateId: v4(),
            actionId: v4(),
            loadId: v4(),
        }
    };

    defineIds = (scenario) => {
        return scenario.map(part => {
            if (Array.isArray(part)) {
                return part.map(item => this.setIdToItem(item));
            } else {
                return this.setIdToItem(part);
            }
        });
    };

    render() {
        return(
            <Core {...this.props}
                  scenario={this.scenario}
            />
        );
    }
}

export default ReduxLoader;
