import {IProps} from './types';

import React, {FC} from 'react';

import {Service} from '../Helpers';

import Place from './Place';

import {translateByObject} from '../../../../helpers';

import styles from './USMigrationField.module.scss';

const USMigrationField: FC<IProps> = (props) => {
    return (
        <div>
            <div className={'row'}>
                <div className={'col-1'}/>
                <div className={'col-4 d-flex flex-row justify-content-center'}>
                    <label className={'col-form-label mr-2'}>
                        {translateByObject(props.field.originTitle, props.translation)}
                        :
                    </label>
                    <div className={'col-form-label'}>
                        <Place
                            place={props.field.value.origin}
                            migrateTo={props.field.migrateTo}
                            newDestinationItemTitle={props.field.newDestinationItemTitle}
                            translation={props.translation}
                        />
                    </div>
                </div>
                <div className={'col-2'}/>
                <div className={'col-4 d-flex flex-row justify-content-center'}>
                    <label className={'col-form-label mr-2'}>
                        {translateByObject(props.field.destinationTitle, props.translation)}
                        :
                    </label>
                    <div className={'col-form-label'}>
                        <Place
                            place={props.field.value.destination}
                            migrateTo={props.field.migrateTo}
                            newDestinationItemTitle={props.field.newDestinationItemTitle}
                            translation={props.translation}
                        />
                    </div>
                </div>
                <div className={'col-1'}/>
            </div>
            <div className={styles.userServices}>
                {
                    props.field.value.userServices.map((userService) =>
                        <Service
                            key={userService.id}
                            migrateTo={props.field.migrateTo}
                            userService={userService}
                            isDisabled={true}
                            intl={props.intl}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default USMigrationField;
