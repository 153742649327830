import React, {FC, ReactNode, useEffect} from 'react';

import config, { CONFIG_MODE } from 'config';

import * as logsFetches from 'instances/logs/fetches';


const ErrorBoundary: FC <{children?: ReactNode}> = (props) => {
    useEffect(() => {
        window.onerror = async (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
            switch (config.MODE) {
                case CONFIG_MODE.DEV:
                case CONFIG_MODE.TEST:
                case CONFIG_MODE.PROD_BLUE:
                case CONFIG_MODE.PROD_GREEN: {
                    await logsFetches.postFrontendErrorByUser({
                        url: document.documentURI,
                        error: {
                            event,
                            source,
                            lineno,
                            colno,
                            error
                        }
                    });
                }
            }
        };
    });

    return(
        <>
            {props.children}
        </>
    );
};

export default ErrorBoundary;
