import {INormNotification} from 'instances/notifications/types';

import React, {
} from 'react';
import {
    useSelector,
} from 'react-redux';
import {
    RootState,
    useAppDispatch,
} from 'store/store';

import {useIntl} from 'helpers/hooks';

import {
    ADMIN_ROUTE,
    ROUTE,
} from 'components/constants';
import ConfirmModal from 'components/Helpers/Modals/ConfirmModal/ConfirmModal';
import {
    patchNotificationStatus,
    toSubmitNotification,
} from 'components/Notifications/slice/notificationsSlice';

import NotificationSlider from './NotificationSlider';

import {INTL_DATA} from './intl';

import style from './notificationsSlider.module.scss';

const NotificationsSliderWrapper = () => {
    const dispatch = useAppDispatch();

    const {notificationToSubmit, newNotifications} = useSelector((state: RootState) => state.notifications);
    const intl = useIntl();

    const translations = {
        onApply: intl(INTL_DATA.REMOVE_NOTIFICATION_CONFIRMATION),
        onDecline: intl(INTL_DATA.REMOVE_NOTIFICATION_DECLINE),
    };

    return (
        <>
            {location.pathname === ROUTE.USER_SERVICES || location.pathname === ADMIN_ROUTE.SERVICES ? newNotifications ?
                newNotifications.slice(0, 2).map((notification: INormNotification) =>
                    <NotificationSlider
                        key={notification.workId}
                        notification={notification}
                    />
                ) : null : null}
            {location.pathname === ROUTE.USER_SERVICES || location.pathname === ADMIN_ROUTE.SERVICES ? newNotifications && newNotifications.length > 2 ?
                <a className={style.notificationsMoreinWrapper} href={'/notifications'}>
                    {`...${intl(INTL_DATA.NOTIFICATIONS_MORE)} ${newNotifications.length - 2} ${intl(INTL_DATA.UNREAD_NOTIFICATIONS)}`}
                </a> : null : null}
            <ConfirmModal
                isOpen={notificationToSubmit ? true : false}
                onClose={() => dispatch(toSubmitNotification(null))}
                onApply={async () => {
                    notificationToSubmit !== null && dispatch(patchNotificationStatus(notificationToSubmit));
                }}
                message={intl(INTL_DATA.REMOVE_NOTIFICATION)}
                translations={{
                    onApply: translations.onApply,
                    onDecline: translations.onDecline,
                }}
            />
        </>
    );
};

export default NotificationsSliderWrapper;
