export enum INTL_DATA {
    NEED_TO_CHOOSE_SERVICE = 'forms-engine/error/need-to-choose-service',
    BILLS_MAX_NUMBER = 'forms-engine/error/bills-max-number',

    ORIGIN_TITLE = 'forms-engine/fields/field-translation/origin-title',
    DESTINATION_TITLE = 'forms-engine/fields/field-translation/destination-title',
    NEW_DESTINATION_ITEM_TITLE = 'forms-engine/fields/field-translation/new-destination-item-title',
    EXISTED_DESTINATION_ITEM_TITLE = 'forms-engine/fields/field-translation/existed-destination-item-title',
    NEW_DESTINATION_ITEM_AS_DEFAULT = 'forms-engine/fields/field-translation/new-destination-item-as-default',
    MIGRATE_TO = 'forms-engine/fields/creation-field/migrate-to',
    USER_SERVICES_NOTHING = 'forms-engine/fields/us-migration/nothing',
    USER_SERVICES_TYPE = 'forms-engine/fields/us-migration/type',
    USER_SERVICES_ADDRESS = 'forms-engine/fields/us-migration/address',
    USER_SERVICES_SITE_ADDRESS = 'forms-engine/fields/us-migration/user-service-site-address',
    USER_SERVICES_CONTRACT = 'forms-engine/fields/us-migration/contract',
    USER_SERVICES_STATUS = 'forms-engine/fields/us-migration/status',
    USER_SERVICES_ORDER_BLANK_NUMBER = 'forms-engine/fields/us-migration/order-blank-number',

    USER_SERVICES_ACTIVE = 'forms-engine/fields/us-migration/active',
}
