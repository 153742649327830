import {
    all,
    fork,
    ForkEffect,
} from 'redux-saga/effects';

import commonData from './commonData/saga';
import globalData from './globalData/saga';
import services from './services/saga';
import servicesGroups from './servicesGroups/saga';
import servicesVersions from './servicesVersions/saga';
import sites from './sites/saga';

const sagas: IterableIterator<ForkEffect>[] = [];
const instances = {
    servicesGroups,
    servicesVersions,
    globalData,
};

for (const saga of Object.values(instances)) {
    for (const method of Object.values(saga)) {
        sagas.push(
            method()
                .taker()
        );
    }
}

function* saga() {
    yield all([
        ...sagas,

        fork(commonData.get),

        fork(services.get),
        fork(services.getOne),
        fork(services.postService),
        fork(services.patchService),
        fork(services.setQueue),

        fork(sites.get),
        fork(sites.getByUser),
        fork(sites.getOne),
        fork(sites.post),
        fork(sites.setActive),

        fork(servicesVersions.getActive),
        fork(servicesVersions.setActive),
    ]);
}

export default saga;
