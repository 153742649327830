import {IProps} from './types';

import React, {
    FC,
    useId,
} from 'react';

import {formatDate} from 'tools/functions';

import {translateByObject} from '../../../../helpers';
import {formatValue} from '../functions';

const BoostedDateField: FC<IProps> = (props) => {
    const elemId = useId();

    return (
        <div>
            <label htmlFor={elemId} className={'form-label'}>
                {translateByObject(props.field.title, props.translation)}
            </label>
            <input
                className={'form-control'}
                id={elemId}
                disabled={true}
                value={formatDate(formatValue(props.field.value)) || ''}
            />
        </div>
    );
};

export default BoostedDateField;
