import { put, call, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './constants';

import fetches from './fetch';


const services = {};

function* fetchGet() {
    const services = yield call(fetches.get);
    yield put({type: ActionTypes.GetServicesSucceed, services});
}
services.get = function*() {
    yield takeLatest(ActionTypes.GetServices, fetchGet);
};

function* fetchGetVersions(action) {
    const {serviceId} = action;
    const versions = yield call(fetches.getVersions, serviceId);
    yield put({type: ActionTypes.GetServiceVersionsSucceed, versions, serviceId});
}
services.getVersions = function*() {
    yield takeLatest(ActionTypes.GetServiceVersions, fetchGetVersions);
};

function* fetchGetOne(action) {
    const {serviceId, versionId} = action;
    const version = yield call(fetches.getVersion, versionId);
    yield put({type: ActionTypes.GetServiceVersionSucceed, version, serviceId, versionId});
}
services.getOne = function*() {
    yield takeLatest(ActionTypes.GetServiceVersion, fetchGetOne);
};

function* fetchPostService(action) {
    let {service} = action;
    service = yield call(fetches.postService, service);
    yield put({type: ActionTypes.PostServiceSucceed, service});
}
services.postService = function*() {
    yield takeLatest(ActionTypes.PostService, fetchPostService);
};

function* fetchPatchService(action) {
    let {service} = action;
    service = yield call(fetches.patchService, service);
    yield put({type: ActionTypes.PatchServiceSucceed, service});
}
services.patchService = function*() {
    yield takeLatest(ActionTypes.PatchService, fetchPatchService);
};

function* fetchPostVersion(action) {
    let {serviceId, version} = action;
    version = yield call(fetches.postVersion, serviceId, version);
    yield put({type: ActionTypes.PostVersionSucceed, serviceId, version});
}
services.postVersion = function*() {
    yield takeLatest(ActionTypes.PostVersion, fetchPostVersion);
};

function* fetchPutVersion(action) {
    let {serviceId, versionId, version} = action;
    version = yield call(fetches.putVersion, serviceId, versionId, version);
    yield put({type: ActionTypes.PutVersionSucceed, serviceId, versionId, version});
}
services.putVersion = function*() {
    yield takeLatest(ActionTypes.PutVersion, fetchPutVersion);
};

function* fetchSetActive(action) {
    const {serviceId, versionId} = action;
    yield call(fetches.setActive, serviceId, versionId);
    yield put({type: ActionTypes.SetActiveServiceVersionSucceed, serviceId, versionId});
}
services.setActive = function*() {
    yield takeLatest(ActionTypes.SetActiveServiceVersion, fetchSetActive);
};

function* fetchSetQueue(action) {
    const {queue} = action;
    yield call(fetches.setQueue, queue);
    yield put({type: ActionTypes.SetServicesQueueSucceed, queue});
}
services.setQueue = function*() {
    yield takeLatest(ActionTypes.SetServicesQueue, fetchSetQueue);
};

function* fetchGetActiveVersions() {
    const services = yield call(fetches.getActiveVersions);
    yield put({type: ActionTypes.GetActiveVersionsSucceed, services});
}
services.getActiveVersions = function*() {
    yield takeLatest(ActionTypes.GetActiveVersions, fetchGetActiveVersions);
};

export default services;
