import {IIntl} from '../../../types';
import {IValue} from './types';

import {translateByIntl} from '../../../helpers';

import {INTL_DATA} from './intl';

export function formatValue(user: IValue): string {
    if (!user) {
        return '';
    }

    return `${user.firstName} ${user.lastName}`;
}

export function formatUserTooltip(user: IValue, intl: IIntl | null): string {
    if (!user) {
        return translateByIntl(intl, INTL_DATA.USER_NOT_SELECTED, 'User not selected');
    }

    return [
        formatValue(user),
        user.position,
        user.phone,
        user.email,
    ].join('\n');
}
