import {ISpecificForm} from '../../../FormField/types';
import {IProps} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialRadio} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../helpers';
import {formatFormTitle} from '../../functions';

import {ERROR} from './constants';
import {formatError} from './functions';

const RadioField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (value: string) => {
        props.onChange(props.field.name, value);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    if (!props.field.value) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <div>
            <label className={'col-form-label'}>
                {formatFormTitle(props.field, props.translation)}
            </label>
            {
                props.field.values.map(
                    (value) => (
                        <MaterialRadio
                            key={value}
                            label={translateByObject(value, props.translation)}
                            value={props.field.value === value}
                            onChange={() => onChange(value)}
                        />
                    )
                )
            }
            {
                !!error &&
                <small className={'text-danger'}>
                    {formatError(error, props.intl)}
                </small>
            }
        </div>
    );
});

export default RadioField;
