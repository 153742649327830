import {
    IGetByAdminQuery,
    IGetByUserQuery,
} from './types/requests';

import {IIntl} from 'helpers/hooks';

import {
    ORANGE_USER_DOMAIN,
    SORT_BY,
    USER_TYPE,
} from './constants';
import {INTL_USER_TYPE} from './intl';

export function getDefaultQueryAdmin(): IGetByAdminQuery {
    return {
        skip: 0,
        limit: 20,

        sortBy: SORT_BY.EMAIL,
        sortOrder: true,

        type: [],
        email: '',
        clientId: '',
        clientName: '',
        fullName: '',

        isRemoved: false,
    };
}

export function getDefaultQueryUser(): IGetByUserQuery {
    return {
        skip: 0,
        limit: 20,
        fullName: '',
    };
}

export function isOrangeEmail(email: string | null | undefined): boolean {
    if (!email) {
        return false;
    }

    return email.includes(ORANGE_USER_DOMAIN);
}

export function formatUserType(userType: USER_TYPE, intl?: IIntl): string {
    switch (userType) {
        case USER_TYPE.ORANGE_ADMIN: {
            return intl ? intl(INTL_USER_TYPE.ORANGE_ADMIN) : userType;
        }
        case USER_TYPE.SUPER_ADMIN: {
            return intl ? intl(INTL_USER_TYPE.SUPER_ADMIN) : userType;
        }
        case USER_TYPE.CLIENT_ADMIN: {
            return intl ? intl(INTL_USER_TYPE.CLIENT_ADMIN) : userType;
        }
        case USER_TYPE.SIMPLE_USER: {
            return intl ? intl(INTL_USER_TYPE.SIMPLE_USER) : userType;
        }
    }
}
