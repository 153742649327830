import React, {
    FC,
    Suspense,
} from 'react';
import {Outlet} from 'react-router-dom';

import {
    ErrorsHandler,
    LoadingFilesPanel,
    SessionKeeper,
} from './Helpers/Background';
import {Loading} from './Helpers/Other';
import ListenAuthByUser from './ListenAuthByUser';
import ListenBasketServicesByUser from './ListenBasketServicesByUser';
import ListenContractsByUser from './ListenContractsByUser';

interface IProps {
    locale: string;
}

const MainWrapper: FC<IProps> = ({
    locale,
}) => {
    return (
        <Suspense fallback={<Loading isPage={true}/>}>
            <Outlet/>
            <ErrorsHandler/>
            <SessionKeeper/>
            <LoadingFilesPanel/>
            <ListenAuthByUser/>
            <ListenContractsByUser/>
            <ListenBasketServicesByUser locale={locale}/>
        </Suspense>
    );
};

export default MainWrapper;
