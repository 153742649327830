import {IProps} from './types';

import React, {FC} from 'react';
import {
    Draggable,
    DraggingStyle,
    NotDraggingStyle,
} from 'react-beautiful-dnd';

import FieldsContainer from '../FieldsContainer';

import {
    getDraggableIdForFrame,
    getDroppableIdForFrame,
} from '../functions';

const ApplicationFrame: FC<IProps> = (props) => {
    const getStyles = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => {
        return {
            padding: 16,
            margin: '0 0 8px 0',
            background: isDragging ? 'lightgreen' : 'grey',
            ...draggableStyle,
        };
    };

    return (
        <Draggable draggableId={getDraggableIdForFrame(props.frame)} index={props.index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getStyles(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <input
                        className={'form-control form-control-sm'}
                        value={props.frame.title}
                        onChange={(e) => props.onChange({
                            ...props.frame,
                            title: e.target.value,
                        })}
                    />
                    <textarea
                        className={'form-control form-control-sm'}
                        value={props.frame.text}
                        onChange={(e) => props.onChange({
                            ...props.frame,
                            text: e.target.value,
                        })}
                    />
                    <FieldsContainer
                        fieldApplicationPositionKey={props.fieldApplicationPositionKey}
                        droppableId={getDroppableIdForFrame(props.frame)}
                        fields={props.fields}
                        intl={props.intl}
                    />
                </div>
            )}
        </Draggable>
    );
};

export default ApplicationFrame;
