import {INormNotification} from 'instances/notifications/types';

import React from 'react';
import {
    useAppDispatch,
} from 'store/store';

import {useIntl} from 'helpers/hooks';

import {normalizeNotificationDate} from 'components/Notifications/functions';
import {
    toSubmitNotification,
} from 'components/Notifications/slice/notificationsSlice';

import {notificationsSliderSVG} from './icons';
import {INTL_DATA} from './intl';

import style from './notificationsSlider.module.scss';

interface IProps {
    notification: INormNotification;
}

const NotificationSlider = (props: IProps) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    return (
        <div className={style.containerInNotificationsSlider}>
            <div className={'alert alert-info alert-sm ms-2 d-flex flex-row align-items-center'} role={'alert'}>
                <img className={'mh-100 mw-100 me-1'} src={notificationsSliderSVG}/>
                <p className={'m-0 p-0 text-truncate fs-6 fw-bold'}>
                    {intl(INTL_DATA.NOTIFICATION_START)}
                    {' '}
                    {props.notification.city}
                    {'. '}
                    {intl(INTL_DATA.WORKING_START)}
                    {' '}
                    {props.notification.dateStart ? normalizeNotificationDate(props.notification.dateStart) : '-'}
                    {' '}
                    {intl(INTL_DATA.DURATION)}
                    {' '}
                    {props.notification.servOut}
                    {' '}
                    {intl(INTL_DATA.MINUTES)}
                </p>
            </div>
            <div className={'position-absolute end-0'}>
                <button
                    type={'button'}
                    className={'btn btn-icon btn-outline-secondary btn-sm me-2 border-0 rounded-5'}
                    onClick={() => dispatch(toSubmitNotification(
                        props.notification.id
                    ))}
                >
                    <svg width={'20'} height={'20'} viewBox={'0 0 20 20'} xmlns={'http://www.w3.org/2000/svg'}>
                        <path fill={'#ffffff'} d={'M15 6.667 13.333 5 10 8.333 6.667 5 5 6.667 8.333 10 5 13.333 6.667 15 10 11.667 13.333 15 15 13.333 11.667 10z'}/>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default NotificationSlider;
