import {
    IAction,
    IState,
} from './types/redux';

import {ACTION_TYPE} from './constants';

export const initialState: IState = null;

export default function contracts(state = initialState, action: IAction): IState {
    switch (action.type) {
        case ACTION_TYPE.GET_CONTRACTS: {
            return action.contracts;
        }
        case ACTION_TYPE.ADD_CONTRACT: {
            return [...(state || []), action.contract];
        }
        case ACTION_TYPE.UPDATE_CONTRACT: {
            if (!state) {
                return state;
            }

            const index = state.findIndex((contract) => contract.id === action.contract.id);

            state[index] = action.contract;

            return state;
        }
        case ACTION_TYPE.REMOVE_CONTRACT: {
            if (!state) {
                return state;
            }

            return state.filter((contract) => contract.id !== action.id);
        }
        default: {
            return state;
        }
    }
}
