import {
    IAction,
    IState,
} from './types/redux';

import {ACTION_TYPE} from 'instances/auth/constants';

const initialState: IState = null;

export default function auth(state = initialState, action: IAction) {
    switch (action.type) {
        case ACTION_TYPE.AUTH_GET: {
            const {auth} = action;

            return auth;
        }
        case ACTION_TYPE.AUTH_POST_LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
