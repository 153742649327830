import classNames from 'classnames';
import React, {FC} from 'react';

import styles from './PageHeader.module.scss';

interface IProps {
    text: string;
}

const PageHeader: FC<IProps> = ({text}) => {
    return (
        <div
            className={classNames(
                styles.pageHeader,
                'd-flex flex-column justify-content-center p-0 w-100'
            )}
        >
            <h1 className={classNames(
                styles.title,
                'm-0 p-0 ms-3 me-3'
            )}
            >
                {text}
            </h1>
        </div>
    );
};

export default PageHeader;
