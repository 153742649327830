import {
    GET_SITES_CONSTRUCTOR_SUCCEED,
    GET_SITES_BY_USER_SUCCEED,
    GET_SITES_VERSION_SUCCEED,
    POST_SITES_CONSTRUCTOR_SUCCEED,
    SET_ACTIVE_SITES_CONSTRUCTOR_SUCCEED,
} from './actions';

import { ACTION_TYPE as ActionTypesAuth } from 'instances/auth/constants';


export default function(state=null, action) {
    switch(action.type) {
        case GET_SITES_CONSTRUCTOR_SUCCEED: {
            return action.sites;
        }
        case GET_SITES_BY_USER_SUCCEED: {
            return action.sites;
        }
        case GET_SITES_VERSION_SUCCEED: {
            const site = state.find(site => site.version === action.site.version);
            const ind = state.indexOf(site);

            state[ind] = action.site;

            return [...state];
        }
        case POST_SITES_CONSTRUCTOR_SUCCEED: {
            return [action.site, ...state];
        }
        case SET_ACTIVE_SITES_CONSTRUCTOR_SUCCEED: {
            const oldActive = state.find(site => site.isActive);
            const newActive = state.find(site => site.version === action.version);

            oldActive.isActive = false;
            newActive.isActive = true;

            return [...state];
        }
        case ActionTypesAuth.AuthLogoutSucceed:
            return null;
        default: {
            return state;
        }
    }
}
