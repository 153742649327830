import {ISpecificForm} from '../../../FormField/types';
import {IProps} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialInput} from 'components/Helpers/MaterialUI';

import {parseNumber} from '../../../../helpers';
import {formatFormTitle} from '../../functions';

import {ERROR} from './constants';
import {formatError} from './functions';

const NumberField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (value: string) => {
        const parsedValue = parseNumber(value);

        props.onChange(props.field.name, parsedValue === null ? '' : `${parsedValue}`);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    const parsedValue = parseNumber(props.field.value);

                    if (parsedValue === null) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }

                    const {min, max} = props.field.validation;

                    if (min !== undefined) {
                        if (parsedValue < min) {
                            setError(ERROR.INVALID_FORMAT);

                            return false;
                        }
                    }
                    if (max !== undefined) {
                        if (parsedValue > max) {
                            setError(ERROR.INVALID_FORMAT);

                            return false;
                        }
                    }
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <MaterialInput
            label={formatFormTitle(props.field, props.translation)}
            value={props.field.value}
            isDisabled={props.field.readOnly}
            isError={!!error}
            helperText={formatError(error, props.intl)}
            onChange={onChange}
        />
    );
});

export default NumberField;
