import {IProps} from './types';

import React, {FC} from 'react';

import AllFiltersButton from 'components/Helpers/BoostedFilters/AllFiltersButton';

import SideBar from './SideBar';
import TotalFiltersRow from './TotalFitlersRow';

const Filters: FC<IProps> = (props) => {
    return (
        <div className={'d-flex flex-column justify-content-start p-0'}>
            <div className={'d-flex flex-row'}>
                <div className={'d-flex align-items-end col-4'}>
                    <SideBar
                        filters={props.filters}
                        suggestions={props.suggestions}
                        total={props.equippedUsersTotal}
                        onChange={props.onChange}
                        translate={props.translate}
                    />
                    <AllFiltersButton
                        className={'align-items-center justify-content-center'}
                        text={'Все фильтры'}
                        isDisabled={!props.equippedUsersTotal}
                        translate={props.translate}
                    />
                </div>
            </div>
            <TotalFiltersRow
                filtersTotal={
                    props.isPending ?
                        null :
                        props.equippedUsersTotal
                }
                onClear={props.onChange}
            />
        </div>
    );
};

export default Filters;
