import React, {FC, ReactNode} from 'react';

import scrollUpArrowIcon from './media/scrollUpArrow.svg';

import styles from './ScrollUpButton.module.scss';

import classNames from 'classnames'

const ScrollUpButton: FC<{children?: ReactNode}> = (props) => {
    return (
        <div className={classNames('d-flex flex-column justify=content-center', styles.scrollUpButton)}>
            <img src={scrollUpArrowIcon} alt=''/>
            <div className={classNames('d-flex justify-content-center position-absolute font-weight-bold', styles.children)}>
                {props.children}
            </div>
        </div>
    );
};

export default ScrollUpButton;
