import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';


class OrangeCheckbox extends Component {
    id = v4();

    static propTypes = {
        title: PropTypes.string,
        value: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string,
        isDisabled: PropTypes.bool,
    };

    static defaultProps = {
        title: '',
        value: false,
    };

    render() {
        return(
            <div className="custom-control custom-checkbox mt-2">
                <input type="checkbox"
                       className="custom-control-input"
                       id={this.id}
                       name={this.props.name}
                       checked={this.props.value}
                       onChange={this.props.onChange}
                       disabled={this.props.isDisabled}
                />
                <label className="custom-control-label" htmlFor={this.id}>
                    {this.props.title}&nbsp;
                </label>
            </div>
        );
    }
}

export default OrangeCheckbox;
