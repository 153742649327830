import { put, call, takeLatest } from 'redux-saga/effects';

import * as fetches from './fetches';
import { ActionTypes } from './constants';

const commonData = {};

function* fetchCommonData() {
    const {commonData, error} = yield call(fetches.get);
    yield put({type: ActionTypes.GetCommonDataSucceed, commonData, error});
}
commonData.get = function*() {
    yield takeLatest(ActionTypes.GetCommonData, fetchCommonData);
};

export default commonData;
