import {IAuth} from './auth/types';
import {IContractUser} from './contracts/types';
import {IFormsEngineContext} from 'components/Helpers/FormsEngine/types';

import config from '../config';
import {
    getContractsByContractBill,
    getUniqueContractsWithoutBills,
} from './contracts/functions';
import * as usersFetches from './users/fetches';
import {getDefaultQueryUser as getDefaultUsersQueryByUser} from './users/functions';
import {STATUS as USER_SERVICE_STATUS} from './userServices/constants';
import * as userServicesFetches from './userServices/fetches';
import {getDefaultQueryDemoUser as getDefaultUserServicesQueryByUser} from './userServices/functions';
import * as userSitesFetches from './userSites/fetches';
import {getDefaultQueryDemoUser as getDefaultUserSitesQueryByUser} from './userSites/functions';

export function getFormsEngineContext(auth: IAuth | null, contracts: IContractUser[] | null): IFormsEngineContext {
    return {
        auth,
        contracts,
        daDataUrl: config.DA_DATA_URL,
        daDataToken: auth?.daDataToken || null,
        serviceStatuses: Object.values(USER_SERVICE_STATUS),
        getContractsByContractBill,
        getUniqueContractsWithoutBills,
        getAddresses: async ({skip, limit, ...rest}) => {
            const {userSites} = await userSitesFetches.getByUser({
                ...getDefaultUserSitesQueryByUser(),
                skip,
                limit,
                ...rest,
            });

            return {addresses: userSites};
        },
        getUsers: async (query) => {
            const res = await usersFetches.getByUser({
                ...getDefaultUsersQueryByUser(),
                ...query,
            });

            return {users: res.users};
        },
        getUserServices: async (query) => {
            const res = await userServicesFetches.getUserServicesByUser({
                ...getDefaultUserServicesQueryByUser(),
                ...query,
                status: query.status as USER_SERVICE_STATUS[],
            });

            return {userServices: res.userServices};
        },
    };
}
