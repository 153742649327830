export const INSTANCE_PATH = '/users';

export enum USER_TYPE {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ORANGE_ADMIN = 'ORANGE_ADMIN',
    CLIENT_ADMIN = 'CLIENT_ADMIN',
    SIMPLE_USER = 'SIMPLE_USER',
}

export enum ADMIN_PATH {
    GLOBAL_DATA = 'GLOBAL_DATA',
    SERVICES = 'SERVICES',
    SERVICE_GROUPS = 'SERVICE_GROUPS',
    CLIENTS = 'CLIENTS',
    USERS = 'USERS',
    SITES = 'SITES',
    LOGS = 'LOGS',
    LANGUAGES = 'LANGUAGES',
    EQUIPPED_CLIENTS = 'EQUIPPED_CLIENTS',
    EQUIPPED_CONTACTS = 'EQUIPPED_CONTACTS',
    DOCS = 'DOCS',
}

export enum USER_PATH {
    HOME = 'HOME',
    USER_SERVICES = 'USER_SERVICES',
    SERVICES_CATALOG = 'SERVICES_CATALOG',
    USER_SITES = 'USER_SITES',
    INCIDENTS = 'INCIDENTS',
    BIDS = 'BIDS',
    BILLS = 'BILLS',
    BASKET_SERVICES = 'BASKET_SERVICES',
    ORDERS = 'ORDERS',
    NOTIFICATIONS = 'NOTIFICATIONS',
}

export enum USER_ACTION {
    CHANGE_SERVICES = 'CHANGE_SERVICES',
    DISABLE_SERVICES = 'DISABLE_SERVICES',
    CHECKOUT_BASKET = 'CHECKOUT_BASKET',
    REMOVE_USER_SITES = 'REMOVE_USER_SITES',
}

export enum SORT_BY {
    TYPE = 'TYPE',
    EMAIL = 'EMAIL',
    FULL_NAME = 'FULL_NAME',
    CLIENT_NAME = 'CLIENT_NAME',
    CLIENT_ID = 'CLIENT_ID',
}

export enum RESPONSE_ERROR {
    PARSER_ERROR = 'PARSER_ERROR',
    NOTHING_TO_NORMALIZE = 'NOTHING_TO_NORMALIZE',
    AUTH_NOT_FOUND = 'AUTH_NOT_FOUND',
    STREAM_NOT_FOUND = 'STREAM_NOT_FOUND',
    PERMISSION_DENIED = 'PERMISSION_DENIED',
    WRONG_NOTIFICATIONS_SETTINGS = 'WRONG_NOTIFICATIONS_SETTINGS',
    NOT_FOUND = 'NOT_FOUND',
    ORANGE_CLIENT_NOT_FOUND = 'ORANGE_CLIENT_NOT_FOUND',
    INVALID_EMAIL = 'INVALID_EMAIL',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    PASSWORD_IS_REQUIRED = 'PASSWORD_IS_REQUIRED',
    INVALID_PERMISSIONS_DATA_CONTRACTS = 'INVALID_PERMISSIONS_DATA_CONTRACTS',
    INVALID_PERMISSIONS_DATA_STRUCTURE = 'INVALID_PERMISSIONS_DATA_STRUCTURE',
    KEY_CLOAK_ID_NOT_FOUND = 'KEY_CLOAK_ID_NOT_FOUND',

    NOTIFY_ERROR_CREATED_USER_NOT_FOUND = 'NOTIFY_ERROR_CREATED_USER_NOT_FOUND',
    NOTIFY_ERROR_CONTRACTS_NOT_FOUND = 'NOTIFY_ERROR_CONTRACTS_NOT_FOUND',
    INVALID_EMAIL_MX = 'INVALID_EMAIL_MX',
}

export const ORANGE_ADMIN_CLIENT_ID = 'none';
export const ORANGE_USER_DOMAIN = '@orange.com';
