import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, {
    FC,
} from 'react';

interface IProps {
    id?: string;
    value: string;
    label?: string;
    options?: string[];
    onChange?(e, value): void;
}

const AutocompleteInput: FC<IProps> = ({
    id,
    value,
    label,
    options,
    onChange,
}) => {
    return (
        <Autocomplete
            disablePortal={true}
            id={id}
            options={options || []}
            onChange={onChange}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={label}
                    value={value}
                    sx={{
                        minWidth: 250,
                        maxWidth: '100%',
                    }}
                />}
        />
    );
};

export default AutocompleteInput;
