import {IProps} from './types';

import classNames from 'classnames';
import React, {FC} from 'react';


const ListItem: FC<IProps> = (props) => {
    return (
        <div className={classNames('', props.className)}>
            {props.children}
        </div>
    );
};

export default ListItem;
