import {IProps} from './types';

import React, {
    FC,
    useId,
} from 'react';

import {
    formatStringWithUrl,
    translateByObject,
} from '../../../../helpers';

const BoostedStringField: FC<IProps> = (props) => {
    const label = translateByObject(props.field.title, props.translation);
    const value = translateByObject(props.field.value, props.translation);

    const elemId = useId();

    const parsedURLString = formatStringWithUrl(value);

    if (parsedURLString || props.field.readOnly) {
        return (
            <div>
                <label className={'col-form-label'}>
                    {label}
                </label>
                <div>
                    {parsedURLString ?? value}
                </div>
            </div>
        );
    }

    return (
        <div className={'d-flex flex-column w-100'}>
            <label htmlFor={elemId} className={'form-label'}>
                {label}
            </label>
            <input
                className={'form-control'}
                id={elemId}
                disabled={true}
                value={value}
            />
        </div>
    );
};

export default BoostedStringField;
