import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionTypes } from './constants';
import * as fetches from './fetches';

import * as NSFetchResult from './types/fetchResult';
import * as NSRedux from './types/redux';
import * as NSSaga from './types/saga';


export default {
    get(apiCaller?: typeof fetches.get): NSSaga.IGet {
        function* caller() {
            const {data, error}: NSFetchResult.IGet = yield call(apiCaller || fetches.get);
            yield put<NSRedux.IGetSucceed>({
                type: ActionTypes.GetGlobalDataSucceed,
                data,
                error,
            });
        }

        function* taker() {
            yield takeLatest(ActionTypes.GetGlobalData, caller);
        }

        return {caller, taker};
    },

    patch(apiCaller?: typeof fetches.patch): NSSaga.IPatch {
        function* caller(action: NSRedux.IPatch) {
            const {data, error}: NSFetchResult.IPatch = yield call(apiCaller || fetches.patch, action.data);

            !error && action.onSucceed();

            yield put<NSRedux.IPatchSucceed>({
                type: ActionTypes.PatchGlobalDataSucceed,
                data,
                error,
            });
        }

        function* taker() {
            yield takeLatest(ActionTypes.PatchGlobalData, caller);
        }

        return {caller, taker};
    },
};
