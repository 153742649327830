import classNames from 'classnames';
import React, {FC} from 'react';

interface IProps {
    file: File;
    className?: string;
    isError?: boolean;

    onRemove(): void;
}

const FileView: FC<IProps> = (props) => {
    return (
        <div className={classNames('d-flex flex-row align-items-center justify-content-between', props.className, {
            'text-primary': props.isError,
            minHeight: '50px',
        })}
        >
            <h4>
                {props.file.name}
            </h4>
            <button
                className={'btn btn-secondary'}
                onClick={props.onRemove}
            >
                Удалить
            </button>
        </div>
    );
};

export default FileView;
