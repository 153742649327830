export enum INTL_DATA {
    BIDS = 'requests/bids',
    NOTHING_TO_SHOW = 'requests/bids/nothing-to-show',

    STATUS_NEW = 'requests/bids/status/new',
    STATUS_IN_PROGRESS = 'requests/bids/status/in-proc',
    STATUS_PROCESSED = 'requests/bids/status/processed',
    STATUS_REJECTED = 'requests/bids/status/rejected',
    STATUS_CANCELED = 'requests/bids/status/canceled',
    STATUS_CANCEL_REQUESTED = 'requests/bids/status/cancel-requested',
    STATUS_CLOSED = 'requests/bids/status/closed',
    STATUS_ARCHIVE = 'requests/bids/status/archive',
    STATUS_HOLD = 'requests/bids/status/hold',
    STATUS_MANUAL_PROC = 'requests/bids/status/manual-proc',

    TYPE_INSTALL = 'bids/filters/install',
    TYPE_UPGRADE = 'bids/filters/upgrade',
    TYPE_CHANGE = 'bids/filters/change',
    TYPE_CLOSE = 'bids/filters/close',
    TYPE_SUPPL = 'bids/filters/suppl',
    TYPE_CLAIM = 'bids/filters/claim',
    TYPE_SUSPEND = 'bids/filters/suspend',
    TYPE_RESTORE = 'bids/filters/restore',

    PERMISSION_ERROR = 'requests/bids/errors/no-permission',
    ERROR = 'requests/bids/errors/error',
}
