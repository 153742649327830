import {
    IFieldPosition,
    IFormWrapper,
} from '../../../types';
import {
    IItem,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useMemo,
    useRef,
} from 'react';

import {isUnableField} from '../../../../helpers';

const Item = forwardRef<IItem, IProps>((props, ref) => {
    const formWrapperRef = useRef<IFormWrapper>(null);

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (!formWrapperRef.current) {
                    return true;
                }

                return formWrapperRef.current.validate();
            },
        };
    });

    const isUnable = useMemo(() => {
        return isUnableField(props.field.name, props.fields);
    }, [props.field, props.fields]);

    const position: IFieldPosition = props.field[props.positionKey];
    const {formWrapper: FormWrapper, displayWrapper: DisplayWrapper} = props;

    return (
        <div className={`col-${position.width} pl-2 pr-2`}>
            {
                props.isForm ?
                    <FormWrapper
                        ref={formWrapperRef}
                        field={props.field}
                        isUnable={isUnable}
                        intl={props.intl}
                        translation={props.translation}
                        onChange={props.onChange}
                    /> :
                    <DisplayWrapper
                        field={props.field}
                        isUnable={isUnable}
                        intl={props.intl}
                        translation={props.translation}
                    />
            }
        </div>
    );
});

export default Item;
