import {IIntl} from '../../../../types';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA} from '../../intl';

import {ERROR} from './constants';

export function formatError(error: ERROR | null, intl: IIntl | null): string {
    switch (error) {
        case ERROR.IS_REQUIRED: {
            return translateByIntl(intl, INTL_DATA.NO_EMPTY, 'Required');
        }
        case null: {
            return '';
        }
    }
}

export function getMin(pastIsDisabled: boolean | undefined): Date | undefined {
    return pastIsDisabled ? new Date() : undefined;
}

export function getMax(max: number | undefined): Date | undefined {
    if (max === undefined) {
        return undefined;
    }

    const now = new Date();

    now.setDate(now.getDate() + max);

    return now;
}
