import {
    IGet,
    IGetDocs,
    IPatch,
} from './types/fetchResult';
import {IToPatch} from './types/instance';

import config from 'config';

import Fetch from 'tools/Fetch';

import {onLogout} from 'instances/auth/functions';

import {INSTANCE_PATH} from './constants';

export async function get(): Promise<IGet> {
    return new Fetch<IGet>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return {
                data: body,
                error: null,
            };
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function patch(body: IToPatch): Promise<IPatch> {
    return new Fetch<IPatch>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'PATCH',
        body,
    })
        .on([200], (body) => {
            return {
                data: body,
                error: null,
            };
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getDocs(): Promise<IGetDocs> {
    return new Fetch<IGetDocs>({
        url: `${config.URL}${INSTANCE_PATH}/docs`,
        method: 'GET',
    })
        .on([200], (body) => {
            return {
                data: body,
                error: null,
            };
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec();
}
