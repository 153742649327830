import {IDENTIFIER_STATUS} from 'instances/equippedUsers/constants';

export function formatStatusOptions(option: IDENTIFIER_STATUS): string {
    switch (option) {
        case IDENTIFIER_STATUS.ALL: {
            return 'Все';
        }
        case IDENTIFIER_STATUS.PROVIDED: {
            return 'Предоставленные';
        }
        case IDENTIFIER_STATUS.UNPROVIDED: {
            return 'Непредоставленные';
        }
    }
}
