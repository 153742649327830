import {ISpecificDataForm} from './SpecificDataForm/types';
import {IProps} from './types';

import React, {
    FC,
    useEffect,
    useRef,
    useState,
} from 'react';

import CommonDataForm from './CommonDataForm';
import SpecificDataForm from './SpecificDataForm';

import {TRIGGER_TYPE} from '../constants';

import {getDefaultTrigger} from './functions';

const Trigger: FC<IProps> = (props) => {
    const [trigger, setTrigger] = useState(props.trigger || getDefaultTrigger(TRIGGER_TYPE.LOCK));

    const specificDataFormRef = useRef<ISpecificDataForm>(null);

    const onChangeTriggerType = (type: TRIGGER_TYPE) => {
        setTrigger({
            ...trigger,
            type,
        });
    };
    const onSave = () => {
        if (!specificDataFormRef.current?.validate()) {
            props.onSave(props.targetFieldName, null);

            return;
        }

        props.onSave(props.targetFieldName, {
            ...trigger,
            ...specificDataFormRef.current.getTriggerStructure(),
        });
    };

    useEffect(() => {
        setTrigger(props.trigger || getDefaultTrigger(TRIGGER_TYPE.LOCK));
    }, [props.trigger]);

    return (
        <div className={'border border-dark'}>
            <div className={'m-2'}>
                <div className={'d-flex flex-row justify-content-between'}>
                    <CommonDataForm
                        trigger={trigger}
                        types={props.types}
                        intl={props.intl || null}
                        onChangeType={onChangeTriggerType}
                    />
                    <button
                        className={'btn btn-sm btn-primary'}
                        onClick={onSave}
                    >
                        Сохранить
                    </button>
                    <button
                        className={'btn btn-sm btn-secondary'}
                        onClick={props.onCancel}
                    >
                        Отмена
                    </button>
                </div>
                <div className={'mt-3'}>
                    <SpecificDataForm
                        ref={specificDataFormRef}
                        targetFieldName={props.targetFieldName}
                        trigger={trigger}
                        fields={props.fields}
                        intl={props.intl || null}
                        translation={props.translation || null}
                    />
                </div>
            </div>
        </div>
    );
};

export default Trigger;
