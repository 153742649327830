import {IProps} from './types';

import React, {PureComponent} from 'react';

class PageTitle extends PureComponent<IProps> {
    public render() {
        return (
            <div className={'d-flex flex-row justify-content-center align-items-center mt-4'}>
                <h3 className={'mb-2'}>
                    {this.props.text}
                </h3>
            </div>
        );
    }
}

export default PageTitle;
