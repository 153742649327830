import {ISpecificForm} from '../FormField/types';
import {IProps} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
} from 'react';

import {MaterialInput} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../helpers';

const UnableField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <MaterialInput
            type={'text'}
            label={translateByObject(props.title, props.translation)}
            value={'—'}
            isDisabled={true}
        />
    );
});

export default UnableField;
