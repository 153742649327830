import {IReType} from './types';

import {INTL_DATA} from '../intl';

export const RE_TYPES: IReType[] = [
    {
        name: 'Email',
        value: '^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$',
        translation: INTL_DATA.EMAIL,
    },
];

export const IF_NO_REGEX_TYPES_FOUND_IN_THE_RE_TYPES_CONSTANT_NONE = 'None';
