import {IProps} from './types';

import React, {
    FC,
    useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';

import MaterialInput from '../MaterialInput';

import './MaterialDatePicker.scss';

const MaterialDatePicker: FC<IProps> = (props) => {
    const [isOpen, setOpen] = useState(false);

    const onChange = (date: Date) => {
        setOpen(false);
        props.onChange?.(date);
    };
    const onFocus = () => {
        setOpen(true);
        props.onFocus?.();
    };

    return (
        <ReactDatePicker
            wrapperClassName={'w-100'}
            customInput={
                <MaterialInput
                    value={props.value ? `${props.value}` : ''}
                    label={props.label}
                    isDisabled={props.isDisabled}
                    isError={props.isError}
                    helperText={props.helperText}
                    isInputAvailable={false}
                />
            }
            dateFormat={'dd.MM.yyyy'}
            selected={props.value}
            onChange={onChange}
            disabled={props.isDisabled}
            minDate={props.min}
            maxDate={props.max}
            open={isOpen}
            onFocus={onFocus}
            onClickOutside={() => setOpen(false)}
        />
    );
};

export default MaterialDatePicker;
