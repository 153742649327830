import {
    IGet,
    IPost,
    IPatch,
    IRemove
} from './types/fetchResult';
import {
    IToPost,
    IToPatch
} from './types/instance';

import config from 'config';

import Fetch from 'tools/Fetch';

import {onLogout} from 'instances/auth/functions';

import {INSTANCE_PATH} from './constants';

export async function get(): Promise<IGet> {
    return new Fetch<IGet>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return {servicesGroups: body};
        })
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec()
}

export async function post(servicesGroup: IToPost): Promise<IPost> {
    return new Fetch<IPost>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'POST',
        body: servicesGroup,
    })
        .on([200], (body) => {
            return {servicesGroup: body};
        })
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec()
}

export async function patch(servicesGroup: IToPatch): Promise<IPatch> {
    return new Fetch<IPatch>({
        url: `${config.URL}${INSTANCE_PATH}/${servicesGroup.id}`,
        method: 'PATCH',
        body: servicesGroup,
    })
        .on([200], (body) => {
            return {servicesGroup: body};
        })
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec()
}

export async function remove(id: string): Promise<IRemove> {
    return new Fetch<IRemove>({
        url: `${config.URL}${INSTANCE_PATH}/${id}`,
        method: 'DELETE',
    })
        .onEmptyBody([204], () => {
            return {id};
        })
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([403, 404, 500], (body) => {
            return body;
        })
        .exec()
}
