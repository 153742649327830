export enum INTL_DATA {
    VALIDATION = 'forms-engine/fields/creation-field/validation',
    REQUIRED = 'forms-engine/fields/creation-field/required',
    DEFAULT_VALUE = 'forms-engine/fields/creation-field/default-value',
    NO_EMPTY = 'forms-engine/error/no-empty',
    MAX = 'forms-engine/fields/creation-field/max',
    MIN = 'forms-engine/fields/creation-field/min',
    STEP = 'forms-engine/fields/creation-field/step',
    VALUES = 'forms-engine/fields/creation-field/values',
    MUST_BE_UNIQUE = 'forms-engine/fields/creation-field/must-be-unique',
    VALUE = 'forms-engine/fields/field-translation/value',
    INVALID_FORMAT = 'forms-engine/error/invalid-format',
}
