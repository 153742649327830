export const INSTANCE_PATH = '/global_data';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ActionTypes {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GetGlobalData = 'GET_GLOBAL_DATA',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GetGlobalDataSucceed = 'GET_GLOBAL_DATA_SUCCEED',

    // eslint-disable-next-line @typescript-eslint/naming-convention
    PatchGlobalData = 'PATCH_GLOBAL_DATA',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    PatchGlobalDataSucceed = 'PATCH_GLOBAL_DATA_SUCCEED',
}
