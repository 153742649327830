import {
    IProps,
    IReadOnlyInput,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';

import {INTL_DATA} from './intl';

const ReadOnlyInput = forwardRef<IReadOnlyInput, IProps>((props, ref) => {
    const [value, setValue] = useState(props.initValue);

    const onChange = (value: boolean) => {
        setValue(value);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): boolean {
                return value;
            },
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <MaterialCheckbox
            label={translateByIntl(props.intl, INTL_DATA.READ_ONLY, 'Read only')}
            value={value}
            onChange={(value) => onChange(value)}
        />
    );
});

export default ReadOnlyInput;
