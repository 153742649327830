import {
    IDataItem,
    IUserForAdmin,
} from 'instances/equippedUsers/types';
import {
    IGetByAdminQuery,
    IPostUserByAdminBody,
} from 'instances/equippedUsers/types/requests';

import React, {
    FC,
    useEffect,
    useState,
} from 'react';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';

import {
    useDelay,
    useQuery,
} from 'tools/hooks';

import {useIntl} from 'helpers/hooks';
import Notifier from 'helpers/Notifier';

import {useClientByAdmin} from 'instances/equippedClients/hooks';
import {getDefaultQueryAdmin} from 'instances/equippedUsers/functions';
import {useUsersByAdmin} from 'instances/equippedUsers/hooks';

import {Loading} from 'components/Helpers/Other';

import Filters from './Filters';
import PdIdentifiersList from './PdIdentifiersList';

import PageHeading from '../../PageHeading';

import {FETCH_BY_FILTERS_DELAY} from './constants';
import {parseAdminQuery} from './functions';

const PdClientIdentifiers: FC = () => {
    const [filters, setFilters] = useQuery<IGetByAdminQuery>(getDefaultQueryAdmin, parseAdminQuery);
    const [isPending, setIsPending] = useState(false);

    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();
    const intl = useIntl();

    const {
        equippedClient,
        getOneEquippedClient,
    } = useClientByAdmin();

    const {
        equippedUsers,
        equippedUsersSuggestions,
        equippedUsersTotal,
        getEquippedUsers,
        postUserByAdmin,
        postEquipmentData,
        downloadSampleByAdmin,
        uploadExelByAdmin,
        removeEquippedUser,
    } = useUsersByAdmin();

    const loadMoreIdentifiers = async (skip: number) => {
        if (!id) {
            return;
        }
        if (isPending) {
            return;
        }
        if (!filters) {
            return;
        }

        setIsPending(true);
        await getEquippedUsers(id, {
            ...filters,
            skip,
        });
        setIsPending(false);
    };

    const onAddUser = async (body: IPostUserByAdminBody): Promise<IUserForAdmin | null> => {
        const res = await postUserByAdmin(body);

        if (!res.equippedUser || res.error) {
            Notifier.ooops(intl);

            return null;
        }

        Notifier.success('Идентификатор успешно добавлен в конец списка!');

        return res.equippedUser;
    };

    const onRemoveUser = async (id: string): Promise<void> => {
        const res = await removeEquippedUser(id);

        if (res.error) {
            Notifier.ooops(intl);

            return;
        }

        Notifier.success('Идентификатор успешно удалён!');
    };

    const onSubmitDataItems = async (equipmentDataItems: IDataItem[]): Promise<void> => {
        if (!equippedClient) {
            return;
        }

        const res = await postEquipmentData({
            clientId: equippedClient.clientId,
            data: equipmentDataItems,
        });

        if (res.error) {
            Notifier.ooops(intl);

            return;
        }

        Notifier.success('Успешно!');
        window.location.reload();
    };

    const onDownloadSample = async () => {
        if (isPending) return;
        if (!equippedClient || !equippedClient.id) return;

        const res = await downloadSampleByAdmin(
            {equippedClientId: equippedClient.id}
        );

        if (res.error) {
            console.log(res.error);
        }
    };

    const onUploadFile = async (file: File) => {
        if (isPending) return;
        if (!equippedClient) return;

        const res = await uploadExelByAdmin({
            file,
            clientId: equippedClient.clientId,
            inn: equippedClient.inn,
            clientName: equippedClient.clientName,

        });

        if (res.error) {
            return res.error;
        }
    };

    useEffect(() => {
        (async () => {
            if (!id) {
                return;
            }

            const res = await getOneEquippedClient(id);

            if (res.error) {
                navigate('/not_found');
            }
        })();
    }, []);

    useDelay(async () => {
        await loadMoreIdentifiers(0);
    }, FETCH_BY_FILTERS_DELAY, filters);

    if (
        !equippedClient ||
        !equippedUsers ||
        equippedUsersTotal === undefined ||
        equippedUsersTotal === null ||
        !filters
    ) {
        return (
            <Loading isPage={true}/>
        );
    }

    return (
        <div className={'container'}>
            <PageHeading
                title={`Идентификаторы пользователя ${equippedClient.clientId}, ИНН ${equippedClient.inn}`}
            />
            <Filters
                filters={filters}
                suggestions={equippedUsersSuggestions}
                equippedUsersTotal={equippedUsersTotal}
                onChange={setFilters}
                isPending={isPending}
                translate={intl}
            />
            <PdIdentifiersList
                equippedClient={equippedClient}
                equippedUsers={equippedUsers}
                equippedUsersTotal={equippedUsersTotal}
                loadMoreIdentifiers={loadMoreIdentifiers}
                onAddUser={onAddUser}
                onRemoveUser={onRemoveUser}
                onSubmitDataItems={onSubmitDataItems}
                onDownloadSample={onDownloadSample}
                onUploadFile={onUploadFile}
                isAdmin={true}
                intl={intl}
            />
        </div>
    );
};

export default PdClientIdentifiers;
