import {IProps} from './types';

import React, {FC} from 'react';

import {IDENTIFIER_STATUS} from 'instances/equippedUsers/constants';
import {getDefaultQueryClient} from 'instances/equippedUsers/functions';

import {OrangeSelect} from 'components/Helpers/Inputs';

import {InputItem} from './InputItem';
import {Top} from './Top';

import {formatStatusOptions} from './functions';

const SideBar: FC<IProps> = (props) => {
    const onClearFilters = () => {
        props.onChange(getDefaultQueryClient());
    };
    const onStatusChange = (identifierStatus: IDENTIFIER_STATUS) => {
        props.onChange({
            ...props.filters,
            identifierStatus,
        });
    };

    return (
        <Top
            onClearFilters={onClearFilters}
            total={props.total}
            translate={props.translate}
        >
            <OrangeSelect
                title={'Статус предоставления'}
                value={props.filters.identifierStatus}
                values={Object.values(IDENTIFIER_STATUS)}
                formatOption={formatStatusOptions}
                onChange={onStatusChange}
            />
            <InputItem
                filterType={'contractNumber'}
                title={'Номер контракта'}
                value={props.filters.contractNumber}
                filters={props.filters}
                itemSuggestions={props.suggestions?.contractNumber || []}
                onChange={props.onChange}
                placeholder={'Начните ввод'}
            />
            <InputItem
                filterType={'identifier'}
                title={'Идентификатор'}
                value={props.filters.identifier}
                filters={props.filters}
                itemSuggestions={props.suggestions?.identifier.map((city) => city) || []}
                onChange={props.onChange}
                placeholder={'Начните ввод'}
            />
            <InputItem
                filterType={'orderBlankNumber'}
                title={'Номер бланка заказа'}
                value={props.filters.orderBlankNumber}
                filters={props.filters}
                itemSuggestions={props.suggestions?.orderBlankNumber || []}
                onChange={props.onChange}
                placeholder={'Начните ввод'}
            />
            <InputItem
                filterType={'city'}
                title={'Город'}
                value={props.filters.city}
                filters={props.filters}
                itemSuggestions={props.suggestions?.city.map((city) => city) || []}
                onChange={props.onChange}
                placeholder={'Начните ввод'}
            />
            <InputItem
                filterType={'address'}
                title={'Адрес'}
                value={props.filters.address}
                filters={props.filters}
                itemSuggestions={props.suggestions?.address || []}
                onChange={props.onChange}
                placeholder={'Начните ввод'}
            />
        </Top>
    );
};

export default SideBar;
