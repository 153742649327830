import {IProps} from './types';

import classNames from 'classnames';
import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import {getBaseRoute} from 'instances/auth/functions';

import logoIcon from './media/logo.png';

import styles from './LogoAndToggle.module.scss';

const LogoAndToggle: FC<IProps> = (props) => {
    const navigate = useNavigate();

    const goHome = () => {
        if (!props.auth) {
            return;
        }

        const path = getBaseRoute(props.auth);

        navigate(path);
    };

    return (
        <div
            onClick={goHome}
            className={classNames('cursor-pointer', styles.logoContainer)}
        >
            <img
                className={classNames({
                    [styles.iconSticky]: props.isSticky,
                    [styles.icon]: !props.isSticky,
                })}
                src={logoIcon}
                alt={'Orange logo'}
            />
        </div>
    );
};

export default LogoAndToggle;
