import {IValue as IAddressFieldValue} from '../../../../AddressField/types';
import {
    IContract,
    IValue as IContractFieldValue,
} from '../../../../ContractField/types';
import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialSelect} from '../../../../../../../MaterialUI';
import {translateByObject} from '../../../../../../helpers';
import {FIELD_TYPE} from '../../../../../constants';
import AddressField from '../../../../AddressField/FormField';
import {formatValue as formatContractValue} from '../../../../ContractField/functions';
import {MIGRATE_TO} from '../../../constants';

const SpecificInput: FC<IProps> = (props) => {
    const onChangeUniqueContract = (id: string, uniqueContracts: IContract[]) => {
        if (props.migrateTo !== MIGRATE_TO.CONTRACT) {
            return;
        }

        const uniqueContract = uniqueContracts.find((uniqueContract) => uniqueContract.id === id);

        if (!uniqueContract) {
            return;
        }

        props.onChange(uniqueContract);
    };
    const onChangeBillContract = (id: string, billContracts: IContract[]) => {
        if (props.migrateTo !== MIGRATE_TO.BILL) {
            return;
        }

        const billContract = billContracts.find((billContract) => billContract.id === id);

        if (!billContract) {
            return;
        }

        props.onChange(billContract);
    };
    const onChangeAddress = (address: IAddressFieldValue) => {
        if (props.migrateTo !== MIGRATE_TO.SITE) {
            return;
        }

        props.onChange(address);
    };

    switch (props.migrateTo) {
        case MIGRATE_TO.CONTRACT: {
            const {origin} = props;

            if (!origin) {
                return null;
            }

            const billContracts = props.contracts.filter((contract) => contract.id !== props.origin?.id);

            return (
                <MaterialSelect
                    label={translateByObject(props.destinationTitle, props.translation)}
                    value={(props.destination as IContractFieldValue)?.id || ''}
                    values={billContracts.map((uniqueContract) => uniqueContract.id)}
                    options={billContracts.map((uniqueContract) => formatContractValue(uniqueContract))}
                    onChange={(id) => onChangeUniqueContract(id, billContracts)}
                />
            );
        }
        case MIGRATE_TO.BILL: {
            const {origin} = props;

            if (!origin) {
                return null;
            }

            const billContracts = props.contracts.filter((contract) => contract.id !== props.origin?.id);

            return (
                <MaterialSelect
                    label={translateByObject(props.destinationTitle, props.translation)}
                    value={(props.destination as IContractFieldValue)?.id || ''}
                    values={billContracts.map((billContract) => billContract.id)}
                    options={billContracts.map((billContract) => formatContractValue(billContract))}
                    onChange={(id) => onChangeBillContract(id, billContracts)}
                />
            );
        }
        case MIGRATE_TO.SITE: {
            return (
                <AddressField
                    field={{
                        name: '',
                        title: props.destinationTitle,
                        tooltip: '',
                        readOnly: false,
                        value: props.destination as IAddressFieldValue,
                        type: FIELD_TYPE.ADDRESS,
                        validation: {required: false},
                    }}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={(_, value) => onChangeAddress(value as IAddressFieldValue)}
                />
            );
        }
    }
};

export default SpecificInput;
