import {ILink} from './types';

import {ROUTE} from 'components/constants';

import {INTL_DATA} from './intl';

export const LINKS: ILink[] = [
    {
        intlKey: INTL_DATA.USER_SERVICES,
        route: ROUTE.USER_SERVICES,
        routes: null,
    },
    {
        intlKey: INTL_DATA.USER_SITES,
        route: ROUTE.SITES,
        routes: null,
    },
    {
        intlKey: INTL_DATA.SERVICES_CATALOG,
        route: ROUTE.SERVICES_CATALOG,
        routes: null,
    },
    {
        intlKey: INTL_DATA.ANALYTICS,
        route: null,
        routes: [
        ],
    },
    {
        intlKey: INTL_DATA.REQUESTS,
        route: null,
        routes: [
            ROUTE.INCIDENTS,
            ROUTE.BIDS,
            ROUTE.ORDERS,
        ],
    },
    {
        intlKey: INTL_DATA.BILLS,
        route: ROUTE.BILLS,
        routes: null,
    },
    {
        intlKey: INTL_DATA.BASKET,
        route: ROUTE.BASKET,
        routes: null,
    },
    {
        intlKey: INTL_DATA.ADMIN,
        route: ROUTE.ADMIN,
        routes: null,
    },
];
