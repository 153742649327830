export function myThrottle(func: (...args: any) => void, delay: number) {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    return (...args: any) => {
        if (!timeoutId) {
            func(...args);
        }
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            timeoutId = null;
        }, delay);
    };
}
