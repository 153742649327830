import {IProps} from './types';

import React, {
    FC,
    useEffect,
    useState,
} from 'react';
import {
    Link,
    useLocation,
} from 'react-router-dom';

import {hasRoutePermission} from 'instances/auth/functions';
import {useBasketServicesByUser} from 'instances/basketServices/hooks';

import {ROUTE} from 'components/constants';
import Loading from 'components/Helpers/Other/Loading';

import {
    basketIconOrange,
    basketIconWhite,
} from './media';

const Basket: FC<IProps> = (props) => {
    const [isActive, setIsActive] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const location = useLocation();
    const {basketServices} = useBasketServicesByUser();
    const {auth} = props;

    useEffect(() => {
        setIsActive(location.pathname === ROUTE.BASKET);
    }, [location]);

    if (!auth || !hasRoutePermission(auth, ROUTE.BASKET)) {
        return null;
    }

    return (
        <div className={'d-flex flex-row align-items-center justify-content-center'}>
            <Link to={'/order'} className={'d-flex flex-row'}>
                {
                    basketServices ?
                        <>
                            <img
                                src={isActive || isHover ? basketIconOrange : basketIconWhite}
                                onMouseEnter={() => setIsHover(true)}
                                onMouseLeave={() => setIsHover(false)}
                            />
                            {basketServices.length > 0 &&
                            <div className={`d-flex align-self-center badge bg-info rounded-pill fs-7 translate-middle ${isHover ? 'text-primary' : 'text-white'}`}>
                                {basketServices.length}
                            </div>}
                        </> :
                        <Loading size={50}/>
                }
            </Link>
        </div>
    );
};

export default Basket;
