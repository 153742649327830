import {IBidUser} from './types';
import {
    IGetDemoByUserQuery,
    IPatchCancelByUserBody,
    IPostClaimByUserBody,
    ISuggestionsQuery,
} from './types/requests';
import {
    IGetDemoByUser,
    IGetOneFullByUser,
    IPatchCancelByUser,
    IPostClaimByUser,
} from './types/responses';
import {ISuggestionsUser} from './types/suggestions';

import {useState} from 'react';

import {useIntl} from 'helpers/hooks';
import Notifier from 'helpers/Notifier';

import {
    LS_BIDS_FILTER,
    NOTIFICATION_ERROR,
} from 'instances/bids/constants';
import {getLSCurrentLocale} from 'instances/languages/functions';

import {INTL_DATA} from 'components/Requests/Bids/intl';

import {NORM_MODE} from './constants';
import * as fetches from './fetches';
import {getSuggestionsByUser} from './fetches';

export function useBidsByUser() {
    const [bids, setBids] = useState<IBidUser[] | null>(null);
    const [bidsSuggestions, setBidsSuggestions] = useState<ISuggestionsUser | null>(null);
    const [bidsTotal, setBidsTotal] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    const intl = useIntl();

    const getSuggestions = async (query: ISuggestionsQuery) => {
        const res = await getSuggestionsByUser({
            ...query,
        });

        if (res.error || !res.suggestions) {
            if (res.error === NOTIFICATION_ERROR.PERMISSION_DENIED) {
                console.log(res.error);
                Notifier.ooops(intl, intl(INTL_DATA.PERMISSION_ERROR));

                return res;
            }

            Notifier.ooops(intl);

            return res;
        }

        setBidsSuggestions({
            ...bidsSuggestions,
            ...res.suggestions,
        });

        return res;
    };
    const getDemoBids = async (query: IGetDemoByUserQuery): Promise<IGetDemoByUser> => {
        localStorage.setItem(LS_BIDS_FILTER, JSON.stringify(query));
        query.skip === 0 && setBids(null);

        const res = await fetches.getDemoByUser(query);

        if (!res.bids || res.total === undefined || res.error) {
            if (res.error === NOTIFICATION_ERROR.PERMISSION_DENIED) {
                console.log(res.error);
                Notifier.ooops(intl, intl(INTL_DATA.PERMISSION_ERROR));

                return res;
            }

            setError(res.error);
            Notifier.ooops(intl);

            return res;
        }

        setBids([...(query.skip === 0 ? [] : bids || []), ...res.bids]);
        setBidsTotal(res.total);

        return res;
    };
    const getOneFullBid = async (id: string): Promise<IGetOneFullByUser | null> => {
        if (bids?.find((bid) => bid.id === id)?._mode === NORM_MODE.USER_FULL) {
            return null;
        }

        const locale = getLSCurrentLocale();
        const res = await fetches.getOneFullByUser(id, {locale});

        if (!res.bid || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }
        if (!bids) {
            return res;
        }

        const index = bids.findIndex((bid) => bid.id === id);

        if (index === -1) {
            return res;
        }

        bids[index] = res.bid;
        setBids([...bids]);

        return res;
    };
    const postClaim = async (body: IPostClaimByUserBody): Promise<IPostClaimByUser> => {
        const res = await fetches.postClaimByUser(body);

        if (!res.requestId || res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };
    const patchCancelBid = async (body: IPatchCancelByUserBody): Promise<IPatchCancelByUser> => {
        const locale = getLSCurrentLocale();

        const res = await fetches.patchCancelByUser({locale}, body);

        if (!res.bid || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }
        if (!bids) {
            return res;
        }

        const index = bids.findIndex((bid) => bid.id === body.id);

        if (index === -1) {
            return res;
        }

        bids[index] = res.bid;
        setBids([...bids]);

        return res;
    };

    return {
        bids,
        bidsSuggestions,
        bidsTotal,
        getDemoBids,
        getOneFullBid,
        postClaim,
        patchCancelBid,
        error,
        getSuggestions,
    };
}
