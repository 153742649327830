import {IApplicationFrameTranslation} from '../../../types';
import {
    IFrameForm,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    capitalize,
    translateByIntl,
} from '../../../../helpers';

import {ERROR} from './constants';
import {
    formatError,
    getDefaultFrameTranslation,
} from './functions';
import {INTL_DATA} from './intl';

const FrameForm = forwardRef<IFrameForm, IProps>((props, ref) => {
    const [frameTranslation, setFrameTranslation] = useState(props.frameTranslation || getDefaultFrameTranslation(props.frame));
    const [error, setError] = useState<ERROR | null>(null);

    const onChangeTitle = (title: string) => {
        setFrameTranslation({
            ...frameTranslation,
            title: capitalize(title),
        });
        setError(null);
    };
    const onChangeText = (text: string) => {
        setFrameTranslation({
            ...frameTranslation,
            text: capitalize(text),
        });
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): IApplicationFrameTranslation {
                return frameTranslation;
            },
            validate(): boolean {
                if (props.frame.title && !frameTranslation.title) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }
                if (props.frame.text && !frameTranslation.text) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <div>
            <div className={'row'}>
                <div className={'col-4'}>
                    <label className={'col-form-label'}>
                        {translateByIntl(props.intl, INTL_DATA.TITLE, 'Title')}
                    </label>
                </div>
                <div className={'col-4'}>
                    <input
                        className={'form-control'}
                        value={props.frame.title}
                        disabled={true}
                    />
                </div>
                <div className={'col-4'}>
                    <input
                        className={'form-control'}
                        value={frameTranslation.title}
                        onChange={(e) => onChangeTitle(e.target.value)}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-4'}>
                    <label className={'col-form-label'}>
                        {translateByIntl(props.intl, INTL_DATA.TEXT, 'Text')}
                    </label>
                </div>
                <div className={'col-4'}>
                    <input
                        className={'form-control'}
                        value={props.frame.text}
                        disabled={true}
                    />
                </div>
                <div className={'col-4'}>
                    <input
                        className={'form-control'}
                        value={frameTranslation.text}
                        onChange={(e) => onChangeText(e.target.value)}
                    />
                </div>
            </div>
            {
                !!error &&
                <div className={'text-danger'}>
                    {formatError(error, props.intl)}
                </div>
            }
        </div>
    );
});

export default FrameForm;
