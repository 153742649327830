import {
    IDownloadSampleAdminBody,
    IGetByAdminQuery,
    IGetByClientQuery,
    IPostDataByAdminBody,
    IPostDataByClientBody,
    IPostExcelByAdminBody,
    IPostExcelByClientBody,
    IPostUserByAdminBody,
} from './types/requests';
import {
    IDownloadSampleByAdmin,
    IDownloadSampleByClient,
    IGetByAdmin,
    IGetByClient,
    IPostDataByAdmin,
    IPostDataByClient,
    IPostUserByAdmin,
    IRemoveByAdmin,
    IUploadExcelByAdmin,
    IUploadExcelByClient,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {logoutEquippedClient} from 'instances/equippedClients/functions';

import {
    DOWNLOADED_REPORT_FILENAME,
    DOWNLOADED_SAMPLE_FILENAME,
    INSTANCE_PATH,
} from './constants';

export async function getByAdmin(equippedClientId: string, query: IGetByAdminQuery): Promise<IGetByAdmin> {
    return new Fetch<IGetByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin/${equippedClientId}`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getByClient(query: IGetByClientQuery): Promise<IGetByClient> {
    return new Fetch({
        url: `${config.PD_URL}${INSTANCE_PATH}`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            logoutEquippedClient();

            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec();
}

export async function postUserByAdmin(body: IPostUserByAdminBody): Promise<IPostUserByAdmin> {
    return new Fetch<IPostUserByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            return body;
        })
        .on([400], (body) => {
            return body;
        })
        .on([404], (body) => {
            return body;
        })
        .exec();
}

export async function postDataByAdmin(body: IPostDataByAdminBody): Promise<IPostDataByAdmin> {
    return new Fetch<IPostDataByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin/equipment_data`,
        method: 'POST',
        body,
    })
        .onDownload([200], DOWNLOADED_REPORT_FILENAME)
        .on([401], (body) => {
            return body;
        })
        .on([400], (body) => {
            return body;
        })
        .on([404], (body) => {
            return body;
        })
        .exec();
}

export async function postDataByClient(body: IPostDataByClientBody): Promise<IPostDataByClient> {
    return new Fetch<IPostDataByClient>({
        url: `${config.PD_URL}${INSTANCE_PATH}/equipment_data`,
        method: 'POST',
        body,
    })
        .onDownload([200], DOWNLOADED_REPORT_FILENAME)
        .on([401], (body) => {
            logoutEquippedClient();

            return {notAuthError: body.error};
        })
        .on([400], (body) => {
            return body;
        })
        .on([404], (body) => {
            return body;
        })
        .exec();
}

export async function removeByAdmin(id: string): Promise<IRemoveByAdmin> {
    return new Fetch<IRemoveByAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/admin/${id}`,
        method: 'DELETE',
    })
        .onEmptyBody([204])
        .on([401], (body) => {
            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function downloadSampleByAdmin(body: IDownloadSampleAdminBody): Promise<IDownloadSampleByAdmin> {
    return new Fetch({
        url: `${config.URL}${INSTANCE_PATH}/admin/equipment_data/download_sample`,
        method: 'POST',
        body,
    })
        .onDownload([200], DOWNLOADED_SAMPLE_FILENAME)
        .on([401], (body) => {
            return body;
        })
        .on([400, 500], (body) => {
            return body;
        })
        .exec();
}

export async function downloadSampleByClient(): Promise<IDownloadSampleByClient> {
    return new Fetch({
        url: `${config.PD_URL}${INSTANCE_PATH}/equipment_data/download_sample`,
        method: 'POST',
    })
        .onDownload([200], DOWNLOADED_SAMPLE_FILENAME)
        .on([401], (body) => {
            logoutEquippedClient();

            return body;
        })
        .on([400, 500], (body) => {
            return body;
        })
        .exec();
}

export async function uploadExelByClient(body: IPostExcelByClientBody): Promise<IUploadExcelByClient> {
    const formData = new FormData();

    formData.set('file', body.file);
    formData.append('clientId', body.clientId);
    formData.append('inn', body.inn);
    formData.append('clientName', body.clientName);

    return new Fetch({
        url: `${config.PD_URL}${INSTANCE_PATH}/equipment_data/upload`,
        method: 'POST',
        formData,
    })
        .onDownload([200], DOWNLOADED_REPORT_FILENAME)
        .on([401], (body) => {
            logoutEquippedClient();

            return body;
        })
        .on([404], (body) => {
            return body;
        })
        .on([400, 500], (body) => {
            return body;
        })
        .exec();
}

export async function uploadExelByAdmin(body: IPostExcelByAdminBody): Promise<IUploadExcelByAdmin> {
    const formData = new FormData();

    formData.set('file', body.file);
    formData.append('clientId', body.clientId);
    formData.append('inn', body.inn);
    formData.append('clientName', body.clientName);

    return new Fetch({
        url: `${config.URL}${INSTANCE_PATH}/admin/equipment_data/upload`,
        method: 'POST',
        formData,
    })
        .onDownload([200], DOWNLOADED_REPORT_FILENAME)
        .on([401], (body) => {
            return body;
        })
        .on([404], (body) => {
            return body;
        })
        .on([400, 500], (body) => {
            return body;
        })
        .exec();
}
