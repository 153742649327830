import { IState } from './types/redux';
import { IReduxAction } from 'instances/types';

import { ActionTypes as ActionTypesCommonData } from './constants';


const initState: IState = {
    data: null,
    isLoaded: false,
    error: null,
};

export default function commonData(state=initState, action: IReduxAction) {
    switch(action.type) {
        case ActionTypesCommonData.GetCommonDataSucceed:
            const {commonData, error} = action;

            return error ?
                {
                    ...state,
                    isLoaded: false,
                    error,
                }:
                {
                    ...state,
                    data: {
                        checkoutMessage: state.data?.checkoutMessage || commonData.checkoutMessage,
                        ...commonData,
                    },
                    isLoaded: true,
                    error: null,
                };
        case ActionTypesCommonData.UpdateMyServiceActualTime:
            if (state) {
                return {
                    ...state,
                    data: {
                        ...(state.data || {}),
                        myServicesActualTime: action.time,
                    },
                };
            } else {
                return state;
            }
        case ActionTypesCommonData.SetCheckoutMessage: {
            return {
                ...state,
                data: {
                    ...(state.data || {}),
                    checkoutMessage: action.message,
                }
            };
        }
        default:
            return state;
    }
}
