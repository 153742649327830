import {IProps} from './types';

import classNames from 'classnames';
import React, {FC} from 'react';

import AllFiltersButton from 'components/Helpers/BoostedFilters/AllFiltersButton';

import {
    filter,
    leftArrow,
} from './media';

import styles from './Top.module.scss';

const Top: FC<IProps> = (props) => {
    return (
        <div
            id={'offcanvasScrolling'}
            className={classNames('offcanvas offcanvas-start border border-3 border-light', styles.sidebar)}
            data-bs-scroll={true}
            data-bs-backdrop={false}
            tabIndex={-1}
            aria-labelledby={'offcanvasScrollingLabel'}
        >
            <div className={'offcanvas-header d-flex flex-row justify-content-start p-0 pt-4 pe-3 ps-3'}>
                <img src={filter} alt={''}/>
                <h5
                    id={'offcanvasScrollingLabel'}
                    className={classNames('offcanvas-title ms-2', styles.sidebarLabel)}
                >
                    {props.label || 'Фильтры'}
                </h5>
                <button
                    className={'ms-auto'}
                    data-bs-dismiss={'offcanvas'}
                >
                    <img src={leftArrow} alt={''}/>
                </button>
            </div>
            <div className={classNames('border-bottom border-light border-2 ms-3 me-3', styles.total)}>
                {`Найдено идентификаторов ${props.total}`}
            </div>
            <div className={classNames('offcanvas-body d-flex flex-column', styles.body)}>
                {props.children}
                <AllFiltersButton
                    className={'mt-4'}
                    text={'Сбросить фильтры'}
                    onClick={props.onClearFilters}
                    translate={props.translate}
                />
            </div>
        </div>
    );
};

export default Top;
