import {configureStore} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';

import orderSlice from 'instances/orders/orderSlice';

import notificationsSlice from 'components/Notifications/slice/notificationsSlice';

const store = configureStore({
    reducer: {
        orders: orderSlice.reducer,
        notifications: notificationsSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
