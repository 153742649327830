import {IProps} from './types';

import React, {
    FC,
    useState,
} from 'react';
import {Droppable} from 'react-beautiful-dnd';

import Element from './Element';

import {translateByIntl} from '../../../helpers';
import {UNSET} from '../constants';

import {INTL_DATA} from './intl';

import styles from './UnsetItems.module.scss';

const UnsetItems: FC<IProps> = (props) => {
    const [show, setShow] = useState(false);

    return (
        <div className={`${styles.tableEditorUnset} d-flex flex-row`}>
            <div className={styles.tableEditorUnsetButton}>
                <input
                    type={'button'}
                    className={'btn btn-sm btn-primary'}
                    value={
                        show ?
                            translateByIntl(props.intl, INTL_DATA.HIDE, 'Hide') :
                            translateByIntl(props.intl, INTL_DATA.SHOW, 'Show')
                    }
                    onClick={() => setShow((show) => !show)}
                />
            </div>
            {
                show &&
                <div className={`${styles.tableEditorUnsetContent} bg-white border border-dark`}>
                    <div className={'m-2'}>
                        <div className={'d-flex justify-content-center m-2'}>
                            <input
                                type={'button'}
                                className={'btn btn-danger'}
                                value={translateByIntl(props.intl, INTL_DATA.RESET, 'Reset')}
                                onClick={props.onReset}
                            />
                        </div>
                        <Droppable droppableId={UNSET} direction={'vertical'}>
                            {(provided, snapshot) => {
                                const styleForDroppableField = snapshot.isDraggingOver ? styles.droppableContainerBlue : styles.droppableContainerWhite;

                                return (
                                    <div
                                        ref={provided.innerRef}
                                        className={styleForDroppableField}
                                        {...provided.droppableProps}
                                    >
                                        {
                                            props.fields.map((field, i) =>
                                                <Element
                                                    key={field.name}
                                                    index={i}
                                                    isForm={props.isForm}
                                                    field={field}
                                                    displayWrapper={props.displayWrapper}
                                                    formWrapper={props.formWrapper}
                                                    intl={props.intl}
                                                    translation={props.translation}
                                                />
                                            )
                                        }
                                        {
                                            !props.fields.length &&
                                            <div
                                                className={`${styles.tableEditorEmptyUnset} d-flex flex-row justify-content-center`}
                                            >
                                                <label>
                                                    {translateByIntl(props.intl, INTL_DATA.EMPTY, 'Empty')}
                                                </label>
                                            </div>
                                        }
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </div>
                </div>
            }
        </div>
    );
};

export default UnsetItems;
