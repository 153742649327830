import {Tooltip} from '@mui/material';
import {TooltipProps} from '@mui/material/Tooltip/Tooltip';
import classNames from 'classnames';
import React, {
    FC,
    ReactNode,
    useState,
} from 'react';

import {formatText} from './functions';

interface IProps {
    className?: string;
    placement?: TooltipProps['placement'];
    text?: string | null;
    followCursor?: boolean;
    children?: ReactNode;
}

const MaterialTooltip: FC<IProps> = ({
    className,
    placement = 'top',
    text,
    followCursor = false,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Tooltip
            followCursor={followCursor}
            title={formatText(text || '')}
            open={isOpen}
            placement={placement}
            arrow={true}
            onOpen={() => setIsOpen(!!text)}
            onClose={() => setIsOpen(false)}
        >
            <div className={classNames(className)}>
                {children}
            </div>
        </Tooltip>
    );
};

export default MaterialTooltip;
