import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialInput} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../helpers';
import {splitStringToWords} from '../functions';

const TextField: FC<IProps> = (props) => {
    const title = translateByObject(props.field.title, props.translation);
    const value = translateByObject(props.field.value, props.translation);

    return (
        <div>
            {
                props.field.readOnly ?
                    <div>
                        {
                            !props.field.hideTitle &&
                                <label>
                                    {title}
                                    :
                                </label>
                        }
                        {splitStringToWords(value)}
                    </div> :
                    <MaterialInput
                        label={props.field.hideTitle ? '' : title}
                        value={value}
                        isMultiline={true}
                        isDisabled={true}
                    />
            }
        </div>
    );
};

export default TextField;
