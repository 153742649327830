import {ISpecificDataForm} from '../../../TranslationField/types';
import {IProps} from './types';

import classNames from 'classnames';
import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    capitalize,
    translateByIntl,
} from '../../../../helpers';
import {INTL_DATA} from '../../intl';

import {ERROR} from './constants';
import {getDefaultTranslation} from './functions';

const StringField = forwardRef<ISpecificDataForm, IProps>((props, ref) => {
    const [translation, setTranslation] = useState(getDefaultTranslation(props.field, props.translation));
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (key: string, value: string) => {
        setError(null);
        setTranslation({
            ...translation,
            [key]: capitalize(value),
        });
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): Record<string, string> {
                return translation;
            },
            validate(): boolean {
                if (props.field.value && Object.values(translation).includes('')) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    if (!props.field.value) {
        return null;
    }

    return (
        <div className={'row mt-2'}>
            <div className={'col col-4'}>
                <label>
                    {translateByIntl(props.intl, INTL_DATA.VALUE, 'Value')}
                </label>
            </div>
            <div className={'col col-4'}>
                <div className={'mt-2'}>
                    {props.field.value}
                </div>
            </div>
            <div className={'col col-4'}>
                <input
                    className={classNames('form-control', {'is-invalid': !!error})}
                    value={translation[props.field.value]}
                    onChange={(e) => onChange(props.field.value, e.target.value)}
                />
                {
                    !!error &&
                    <div className={'text-danger'}>
                        {
                            error === ERROR.IS_REQUIRED &&
                            translateByIntl(props.intl, INTL_DATA.REQUIRED, 'Required')
                        }
                    </div>
                }
            </div>
        </div>
    );
});

export default StringField;
