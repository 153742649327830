import {IProps} from './types';

import classNames from 'classnames';
import React, {FC} from 'react';

import {capitalize} from 'tools/functions';

const Locale: FC<IProps> = (props) => {
    return (
        <ul
            className={'navbar-nav'}
            aria-labelledby={'language-buttons'}
        >
            {
                props.availableLocales.map((locale) =>
                    <li
                        key={locale}
                        role={'button'}
                        className={'nav-item'}
                        onClick={() => props.changeLocale(locale)}
                    >
                        <a
                            className={classNames('nav-link fs-4 lh-18 text-capitalize', {'active': locale === props.locale})}
                            aria-label={`${locale} version`}
                            aria-current={locale === props.locale}
                        >
                            {capitalize(locale)}
                        </a>
                    </li>
                )
            }
        </ul>
    );
};

export default Locale;
