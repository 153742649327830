import {IGetUserServicesQuery} from '../../../../../Provider/types';
import {IUserService} from '../../types';
import {IProps} from './types';

import React, {
    FC,
    useEffect,
    useState,
} from 'react';

import Filters from './Filters';

import {translateByIntl} from '../../../../../helpers';
import {SERVICE_SELECTION_TYPE} from '../../constants';
import {Service} from '../../Helpers';
import {INTL_DATA} from '../../intl';

import {FETCH_SERVICES_DELAY} from './constants';
import {
    getDefaultQuery,
    getIsChosenAll,
} from './functions';

import styles from './Services.module.scss';

const Services: FC<IProps> = (props) => {
    const [filters, setFilters] = useState<IGetUserServicesQuery>(getDefaultQuery(props.origin, props.migrateTo));
    const [userServices, setUserUserServices] = useState<IUserService[] | null>(null);
    const [delayTimeoutId, setDelayTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const onChooseAll = () => {
        if (!userServices) {
            return;
        }
        if (isChosenAll) {
            const selectedServices = props.selectedServices.filter(
                (selectedService) =>
                    !userServices.find((userService) => userService.id === selectedService.id)
            );

            props.onChooseServices(selectedServices);
        } else {
            const selectedServices = [
                ...props.selectedServices,
                ...userServices.filter(
                    (userService) =>
                        !props.selectedServices.find((selectedService) => selectedService.id === userService.id)
                ),
            ];

            props.onChooseServices(selectedServices);
        }
    };

    useEffect(() => {
        if (delayTimeoutId !== null) {
            clearTimeout(delayTimeoutId);
        }

        const id = setTimeout(async () => {
            const {userServices} = await props.getUserServices(filters);

            setUserUserServices(userServices || []);

            switch (props.serviceSelectionType) {
                case SERVICE_SELECTION_TYPE.FREE_EMPTY: {
                    break;
                }
                case SERVICE_SELECTION_TYPE.FREE_ALL:
                case SERVICE_SELECTION_TYPE.FROZEN_ALL: {
                    props.onChooseServices(userServices || []);

                    break;
                }
            }
        }, FETCH_SERVICES_DELAY);

        setDelayTimeoutId(id);

        return () => {
            if (delayTimeoutId === null) {
                return;
            }

            clearTimeout(delayTimeoutId);
        };
    }, [filters]);

    const isChosenAll = getIsChosenAll(props.selectedServices, userServices);

    return (
        <div className={styles.userServices}>
            <Filters
                migrateTo={props.migrateTo}
                isChosenAll={isChosenAll}
                serviceSelectionType={props.serviceSelectionType}
                statuses={props.serviceStatuses}
                filters={filters}
                intl={props.intl}
                onChooseAll={onChooseAll}
                onChange={setFilters}
            />
            {
                userServices ?
                    userServices.length ?
                        userServices.map((userService) => (
                            <Service
                                key={userService.id}
                                userService={userService}
                                migrateTo={props.migrateTo}
                                isDisabled={props.serviceSelectionType === SERVICE_SELECTION_TYPE.FROZEN_ALL}
                                isSelected={!!props.selectedServices.find((selectedService) => selectedService.id === userService.id)}
                                intl={props.intl}
                                onChoose={props.onChooseService}
                            />
                        )) :
                        <h5 className={'mt-3'}>
                            {translateByIntl(props.intl, INTL_DATA.USER_SERVICES_NOTHING, 'Nothing to show')}
                        </h5> :
                    <div className={'d-flex flex-row justify-content-center align-items-center'}>
                        <label className={'col-form-label'}>
                            Loading...
                        </label>
                    </div>
            }
        </div>
    );
};

export default Services;
