import {IProps} from './types';

import classNames from 'classnames';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import {useIntl} from 'helpers/hooks';

import {getCompanyName} from './functions';
import {INTL_DATA} from './intl';

const NameAndUserDropdown: FC<IProps> = (props) => {
    const intl = useIntl();

    if (!props.auth) {
        return null;
    }

    return (
        <div className={classNames('d-flex flex-row justify-content-between nav-item')}>
            <button
                className={'navbar-toggler'}
                type={'button'}
                data-bs-toggle={'collapse'}
                data-bs-target={'#navbarNavDarkDropdown'}
                aria-controls={'navbarNavDarkDropdown'}
                aria-expanded={'true'}
                aria-label={'Toggle navigation'}
            />
            <div className={'collapse navbar-collapse'} id={'navbarNavDarkDropdown'}>
                <ul className={'navbar-nav'}>
                    <li className={'nav-item dropdown'}>
                        <button className={'nav-link dropdown-toggle'} data-bs-toggle={'dropdown'} aria-expanded={'false'}>
                            <div className={'d-flex flex-column justify-content-end align-items-end text-start'}>
                                {
                                    props.auth.checkedInn.inn ?
                                        <>
                                            {getCompanyName(props.auth.checkedInn.companyName)}
                                            <br/>
                                            {intl(INTL_DATA.INN)}
                                                    : &nbsp;
                                            {props.auth.checkedInn.inn}
                                            <br/>
                                        </> :
                                        <>
                                            {props?.clientCompanyName}
                                            <br/>
                                        </>
                                }
                                {`${props.auth.firstName} ${props.auth.lastName}`}
                            </div>
                        </button>
                        <ul className={'dropdown-menu dropdown-menu-dark'}>
                            <li>
                                <Link to={'/settings'} className={'dropdown-item text-decoration-none'}>
                                    {intl(INTL_DATA.SETTINGS)}
                                </Link>
                                {' '}

                            </li>
                            <li>
                                <div
                                    className={'dropdown-item cursor-pointer'}
                                    onClick={props.postLogoutAuth}
                                >
                                    {intl(INTL_DATA.LOGOUT)}
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default NameAndUserDropdown;
