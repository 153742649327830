import {
    IProps,
    IRef,
} from './types';

import classNames from 'classnames';
import React, {
    ChangeEvent,
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import InputMask from 'react-input-mask';

import {useClickOutside} from 'tools/hooks';

import {ERROR} from './constants';
import {
    getDefaultTranslation,
    symbolRegexpToFullRegexp,
} from './functions';

import styles from './OrangeInput.module.scss';

const OrangeInput = forwardRef<IRef, IProps>((props, ref) => {
    const [value, setValue] = useState(props.initValue || '');
    const [error, setError] = useState<ERROR | null>(null);
    const [validatorError, setValidatorError] = useState<string | null>(null);
    const [isFocus, setIsFocus] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    const validate = async (defaultValueToCheck?: string) => {
        const valueToCheck = defaultValueToCheck ?? value;

        if (props.isRequired && !valueToCheck) {
            setError(ERROR.ERROR_REQUIRED);

            return false;
        }
        if (props.mask && props.isRequired && props.mask.length !== valueToCheck.length) {
            setError(ERROR.ERROR_FORMAT);

            return false;
        }
        if (!defaultValueToCheck && !!valueToCheck && !!props.regexp && !props.regexp.test(valueToCheck)) {
            setError(ERROR.ERROR_FORMAT);

            return false;
        }

        for (const validator of props.validators || []) {
            const validatorResult = await validator(valueToCheck);

            if (validatorResult) {
                setValidatorError(validatorResult);

                return false;
            }
        }

        setError(null);
        setValidatorError(null);

        return true;
    };

    const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value;

        if (props.capitalize && newValue) {
            newValue = newValue[0].toUpperCase() + newValue.slice(1);
        }
        if (props.maxLength && newValue.length > props.maxLength) {
            newValue = newValue.slice(0, props.maxLength);
        }
        if (props.symbolRegexp && newValue.length > value.length && !symbolRegexpToFullRegexp(props.symbolRegexp).test(newValue)) {
            return;
        }
        if (newValue.length < value.length) {
            await validate(newValue);
        }
        if (props.mask && newValue.length === props.mask.length) {
            await validate(newValue);
        }

        setValue(newValue);

        setError(null);
        props.onChange?.(e);
        props.onChangeValue?.(newValue);
    };

    const onSelectSuggestion = (suggestion: string) => {
        if (!isFocus) {
            return;
        }

        props.onChangeValue?.(suggestion);
        setIsFocus(false);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            setValue(value) {
                setValue(value);
                setError(null);
                setValidatorError(null);
            },
            clearValue() {
                setError(null);
                setValue('');
                setValidatorError(null);
            },
            async validate() {
                return validate();
            },
            setError(error: string | null) {
                setError(null);
                setValidatorError(error);
            },
        };
    });

    useClickOutside(containerRef, () => {
        setIsFocus(false);
    });

    return (
        <div
            ref={containerRef}
            className={classNames(styles.orangeAutoSuggest, props.className)}
        >
            {' '}
            {
                props.title &&
                <label className={'col-form-label p-0 ms-1'}>
                    {props.isRequired ? `${props.title} *` : props.title}
                </label>
            }
            {
                props.mask ?
                    <InputMask
                        className={classNames('form-control', props.className, styles.inputMaskContainer, {
                            'is-invalid': !!error || !!validatorError,
                        })}
                        placeholder={props.placeholder}
                        value={props.value || value}
                        mask={props.mask}
                        disabled={props.isDisabled}
                        onChange={(e) => onChange(e)}
                        // @ts-ignore
                        maskChar={props.showMask ? '_' : ''}
                        // formatChars={props.formatMaskChars}
                        autoComplete={props.autoComplete}
                        onFocus={props.onFocus ? props.onFocus : () => setIsFocus(true)}
                        onBlur={props.onBlur}
                    /> :
                    <div className={classNames(
                        'form-group has-feedback has-feedback-left',
                        props.containerClassName,
                        styles.inputContainer,
                    )}>
                        <input
                            className={classNames(
                                'form-control',
                                props.className, {
                                'is-invalid': !!error || !!validatorError,
                            })}
                            placeholder={props.placeholder}
                            value={props.value || value}
                            disabled={props.isDisabled}
                            type={props.isPassword ? 'password' : 'text'}
                            autoComplete={props.autoComplete}
                            onChange={(e) => onChange(e)}
                            onFocus={props.onFocus ? props.onFocus : () => setIsFocus(true)}
                            onBlur={props.onBlur}
                        />
                    </div>
            }
            {
                isFocus &&
                props.suggestions &&
                props.suggestions.length > 0 &&
                props.value !== undefined &&
                !props.suggestions.includes(props.value) &&
                <div className={classNames(styles.suggestions)}>
                    {
                        props.suggestions.map(
                            (suggestion, i) =>
                                <div
                                    key={i}
                                    className={styles.suggestion}
                                    onClick={() => onSelectSuggestion(suggestion)}
                                >
                                    {suggestion}
                                </div>
                        )
                    }
                </div>
            }
            <span className={classNames('text-primary', styles.inputError)}>
                {
                    error ?
                        getDefaultTranslation(error) :
                        validatorError ?? ''
                }
            </span>
            <span className={classNames('text-primary ms-1 ', styles.inputError)}>
                {props.isError ? props.errorMessage : ''}
            </span>
        </div>
    );
});

export default OrangeInput;
