import {IAuth} from './types';

import {LS_BIDS_FILTER} from 'instances/bids/constants';
import {LS_BILLS_FILTER} from 'instances/bills/constants';
import {LS_INCIDENTS_FILTER} from 'instances/incidents/constants';
import {LS_CURRENT_LOCALE} from 'instances/languages/constants';
import {LS_NOTIFICATIONS_FILTER} from 'instances/notifications/constants';
import {LS_ORDERS_FILTER} from 'instances/orders/constants';
import {
    ADMIN_PATH,
    USER_ACTION,
    USER_PATH,
    USER_TYPE,
} from 'instances/users/constants';
import {LS_USER_SERVICES_FILTER} from 'instances/userServices/constants';
import {LS_USER_SITES_FILTER} from 'instances/userSites/constants';

import {
    ADMIN_ROUTE,
    ROUTE,
} from 'components/constants';

function clearLsFilters() {
    localStorage.removeItem(LS_ORDERS_FILTER);
    localStorage.removeItem(LS_USER_SERVICES_FILTER);
    localStorage.removeItem(LS_USER_SITES_FILTER);
    localStorage.removeItem(LS_NOTIFICATIONS_FILTER);
    localStorage.removeItem(LS_CURRENT_LOCALE);
    localStorage.removeItem(LS_INCIDENTS_FILTER);
    localStorage.removeItem(LS_BIDS_FILTER);
    localStorage.removeItem(LS_BILLS_FILTER);
}

export function onLogout() {
    clearLsFilters();
    document.location.replace('/login');
}

export function getBaseRoute(auth: IAuth): ROUTE {
    switch (auth.type) {
        case USER_TYPE.SUPER_ADMIN:
        case USER_TYPE.ORANGE_ADMIN: {
            return ROUTE.ADMIN;
        }
        case USER_TYPE.CLIENT_ADMIN: {
            return ROUTE.HOME;
        }
        case USER_TYPE.SIMPLE_USER: {
            if (auth.permissionsData.isAllUserPaths) {
                return ROUTE.HOME;
            }
            if (!auth.permissionsData.userPaths.includes(USER_PATH.HOME)) {
                return ROUTE.SETTINGS;
            }

            return ROUTE.HOME;
        }
    }
}

export function hasAdminRoutePermission(auth: IAuth, adminRoute: ADMIN_ROUTE): boolean {
    switch (auth.type) {
        case USER_TYPE.SUPER_ADMIN: {
            return true;
        }
        case USER_TYPE.ORANGE_ADMIN: {
            switch (adminRoute) {
                case ADMIN_ROUTE.GLOBAL_DATA: {
                    return auth.permissionsData.adminPaths.includes(ADMIN_PATH.GLOBAL_DATA);
                }
                case ADMIN_ROUTE.SERVICE_GROUPS: {
                    return auth.permissionsData.adminPaths.includes(ADMIN_PATH.SERVICE_GROUPS);
                }
                case ADMIN_ROUTE.SERVICES: {
                    return auth.permissionsData.adminPaths.includes(ADMIN_PATH.SERVICES);
                }
                case ADMIN_ROUTE.SITES: {
                    return auth.permissionsData.adminPaths.includes(ADMIN_PATH.SITES);
                }
                case ADMIN_ROUTE.CLIENTS: {
                    return auth.permissionsData.adminPaths.includes(ADMIN_PATH.CLIENTS);
                }
                case ADMIN_ROUTE.USERS: {
                    return auth.permissionsData.adminPaths.includes(ADMIN_PATH.USERS);
                }
                case ADMIN_ROUTE.LANGUAGES: {
                    return auth.permissionsData.adminPaths.includes(ADMIN_PATH.LANGUAGES);
                }
                case ADMIN_ROUTE.LOGS: {
                    return auth.permissionsData.adminPaths.includes(ADMIN_PATH.LOGS);
                }
                case ADMIN_ROUTE.EQUIPPED_CLIENTS: {
                    return auth.permissionsData.adminPaths.includes(ADMIN_PATH.EQUIPPED_CLIENTS);
                }
                case ADMIN_ROUTE.EQUIPPED_CONTACTS: {
                    return auth.permissionsData.adminPaths.includes(ADMIN_PATH.EQUIPPED_CONTACTS);
                }
                case ADMIN_ROUTE.DOCS: {
                    return auth.permissionsData.adminPaths.includes(ADMIN_PATH.DOCS);
                }
            }

            break;
        }
        case USER_TYPE.CLIENT_ADMIN: {
            return [ADMIN_ROUTE.USERS].includes(adminRoute);
        }
        case USER_TYPE.SIMPLE_USER: {
            return false;
        }
    }
}

export function hasRoutePermission(auth: IAuth, route: ROUTE): boolean {
    switch (auth.type) {
        case USER_TYPE.SUPER_ADMIN:
        case USER_TYPE.ORANGE_ADMIN: {
            switch (route) {
                case ROUTE.LOGIN:
                case ROUTE.SETTINGS:
                case ROUTE.ADMIN: {
                    return true;
                }
                default: {
                    return false;
                }
            }
        }
        case USER_TYPE.CLIENT_ADMIN: {
            switch (route) {
                case ROUTE.HOME: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.HOME);
                }
                case ROUTE.LOGIN: {
                    return true;
                }
                case ROUTE.USER_SERVICES: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.USER_SERVICES);
                }
                case ROUTE.SERVICES_CATALOG: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.SERVICES_CATALOG);
                }
                case ROUTE.SITES: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.USER_SITES);
                }
                case ROUTE.INCIDENTS: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.INCIDENTS);
                }
                case ROUTE.BIDS: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.BIDS);
                }
                case ROUTE.BILLS: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.BILLS);
                }
                case ROUTE.BASKET: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.BASKET_SERVICES);
                }
                case ROUTE.NOTIFICATIONS: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.NOTIFICATIONS);
                }
                case ROUTE.ORDERS: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.ORDERS);
                }
                case ROUTE.SETTINGS: {
                    return true;
                }
                case ROUTE.ADMIN: {
                    return true;
                }
            }

            break;
        }
        case USER_TYPE.SIMPLE_USER: {
            switch (route) {
                case ROUTE.HOME: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.HOME);
                }
                case ROUTE.LOGIN: {
                    return true;
                }
                case ROUTE.USER_SERVICES: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.USER_SERVICES);
                }
                case ROUTE.SERVICES_CATALOG: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.SERVICES_CATALOG);
                }
                case ROUTE.SITES: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.USER_SITES);
                }
                case ROUTE.INCIDENTS: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.INCIDENTS);
                }
                case ROUTE.BIDS: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.BIDS);
                }
                case ROUTE.BILLS: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.BILLS);
                }
                case ROUTE.BASKET: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.BASKET_SERVICES);
                }
                case ROUTE.NOTIFICATIONS: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.NOTIFICATIONS);
                }
                case ROUTE.ORDERS: {
                    return auth.permissionsData.isAllUserPaths ||
                        auth.permissionsData.userPaths.includes(USER_PATH.ORDERS);
                }
                case ROUTE.SETTINGS: {
                    return true;
                }
                case ROUTE.ADMIN: {
                    return false;
                }
            }
        }
    }
}

export function hasActionPermission(auth: IAuth, userAction: USER_ACTION): boolean {
    switch (auth.type) {
        case USER_TYPE.SUPER_ADMIN:
        case USER_TYPE.ORANGE_ADMIN:
        case USER_TYPE.CLIENT_ADMIN: {
            return true;
        }
        case USER_TYPE.SIMPLE_USER: {
            if (auth.permissionsData.isAllUserActions) {
                return true;
            }

            return auth.permissionsData.userActions.includes(userAction);
        }
    }
}

export function isNeedToWatchContracts(auth: IAuth): boolean {
    switch (auth.type) {
        case USER_TYPE.SUPER_ADMIN:
        case USER_TYPE.ORANGE_ADMIN:
        case USER_TYPE.CLIENT_ADMIN:
        case USER_TYPE.SIMPLE_USER: {
            return true;
        }
    }
}

export function isNeedToWatchClient(auth: IAuth): boolean {
    switch (auth.type) {
        case USER_TYPE.SUPER_ADMIN:
        case USER_TYPE.ORANGE_ADMIN: {
            return false;
        }
        case USER_TYPE.CLIENT_ADMIN:
        case USER_TYPE.SIMPLE_USER: {
            return true;
        }
    }
}

export function isNeedToWatchBasketServices(auth: IAuth): boolean {
    switch (auth.type) {
        case USER_TYPE.SUPER_ADMIN:
        case USER_TYPE.ORANGE_ADMIN: {
            return false;
        }
        case USER_TYPE.CLIENT_ADMIN: {
            return hasRoutePermission(auth, ROUTE.BASKET);
        }
        case USER_TYPE.SIMPLE_USER: {
            return hasRoutePermission(auth, ROUTE.BASKET);
        }
    }
}
