import {IFieldValue} from '../Fields/FieldsTypes/types';
import {IUSMigrationField} from '../Fields/FieldsTypes/USMigrationField/types';
import {IField} from '../types';

import {usMigrationFieldsAreEqual} from '../Fields/FieldsTypes/USMigrationField/functions';

import {FIELD_TYPE} from '../constants';

export default function(field: IField, value: IFieldValue) {
    switch (field.type) {
        case FIELD_TYPE.US_MIGRATION: {
            return usMigrationFieldsAreEqual(field.value, value as IUSMigrationField['value']);
        }
        default: {
            return JSON.stringify(field.value) === JSON.stringify(value);
        }
    }
}
