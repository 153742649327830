export enum DROPPABLE_TYPE {
    FIELDS_CONTAINER = 'FIELDS_CONTAINER',
    FRAMES_CONTAINER = 'FRAMES_CONTAINER',
}

export enum MAIN_DROPPABLE {
    STEP = 'STEP',
    FRAMES = 'FRAMES',
    FIELDS = 'FIELDS',
}
