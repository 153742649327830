import classNames from 'classnames';
import React, {FC} from 'react';

type IButtonType = 'primary' | 'secondary';

interface IProps {
    className?: string;
    isDisabled?: boolean;
    text: string;
    style?: IButtonType;
    autoFocus?: boolean;

    onClick(): void;
}

const Button: FC<IProps> = ({
    className,
    text,
    style = 'secondary',
    onClick,
    isDisabled = false,
    autoFocus = false,
}) => {
    return (
        <button
            className={classNames('btn', {
                'btn-secondary': style === 'secondary',
                'btn-primary': style === 'primary',
            }, className)}
            type={'button'}
            onClick={onClick}
            disabled={isDisabled}
            style={{minWidth: '120px'}}
            autoFocus={autoFocus}
        >
            {text}
        </button>
    );
};

export default Button;
