import { IProps, IOwnProps, IReduxInjectedState } from './types';
import { IRootReduxState } from 'instances/types';

import { PureComponent } from 'react';

import Notifier, { INTL_DATA } from 'helpers/Notifier';

import { injectReduxConnect, injectIntl } from 'components/Helpers/HOCs';

import { PERIOD_OF_NOTIFICATIONS } from './constants';
import { mapStateToProps, errorIsHere, cleanUpGarbage } from './functions';


class ErrorsHandler extends PureComponent<IProps> {
    private lastNotification: Date = new Date(0);

    public componentDidUpdate(): void {
        const isErrorHere = errorIsHere(this.props);
        const now = new Date();

        if (isErrorHere) {
            cleanUpGarbage(this.props)
                .forEach(([_, error]) => {
                        if (!error) {
                            return;
                        }
                        if (now.getTime() - this.lastNotification.getTime() > PERIOD_OF_NOTIFICATIONS) {
                            Notifier.warning(INTL_DATA.SOMETHING_WENT_WRONG);
                            this.lastNotification = now;
                        }
                        console.log(error);
                    }
                );
        }
    }

    public render() {
        return null;
    }
}

export default injectIntl()(
    injectReduxConnect<IReduxInjectedState, null, IOwnProps, IRootReduxState>(
        mapStateToProps,
    )(ErrorsHandler)
);
