import {IUSMigrationField} from '../types';

import {translateByObject} from '../../../../helpers';

export function getDefaultTranslation(field: IUSMigrationField, translation: Record<string, string>) {
    return {
        [field.originTitle]: translateByObject(field.originTitle, translation),
        [field.destinationTitle]: translateByObject(field.destinationTitle, translation),
        [field.newDestinationItemTitle]: translateByObject(field.newDestinationItemTitle, translation),
        [field.existedDestinationItemTitle]: translateByObject(field.existedDestinationItemTitle, translation),
    };
}
