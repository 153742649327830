import React, {
    FC,
    lazy,
} from 'react';
import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';

import {useAuth} from 'instances/auth/hooks';
import {useContractsByUser} from 'instances/contracts/hooks';
import {getFormsEngineContext} from 'instances/functions';

import PdClientIdentifiers from 'components/Admin/PD/PdIdentifiers';
import Footer from 'components/Footer';
import Header from 'components/Header';
import {FormsEngineProvider} from 'components/Helpers/FormsEngine';
import {ErrorBoundary} from 'components/Helpers/Other';
import Home from 'components/Home';

const Login = lazy(() => import('components/Login'));
const UserServices = lazy(() => import('components/UserServices'));
const ServicesCatalog = lazy(() => import('components/ServicesCatalog'));
const UserSites = lazy(() => import('components/UserSites'));
const Incidents = lazy(() => import('components/Requests/Incidents'));
const Bids = lazy(() => import('components/Requests/Bids'));
const Bills = lazy(() => import('components/Bills'));
const Basket = lazy(() => import('components/Basket'));
const UserSettings = lazy(() => import('components/UserSettings'));
const Orders = lazy(() => import('components/Orders/StoreWrapper'));
const Notifications = lazy(() => import('components/Notifications/NotificationsHOC'));

const Admin = lazy(() => import('components/Admin'));
const AdminGlobalData = lazy(() => import('components/Admin/AdminGlobalData'));
const AdminServicesGroups = lazy(() => import('components/Admin/AdminServicesGroups'));
const AdminServices = lazy(() => import('components/Admin/AdminServices'));
const AdminServiceVersions = lazy(() => import('components/Admin/AdminServices/AdminServiceVersions'));
const AdminServiceVersionPage = lazy(() => import('components/Admin/AdminServices/AdminServiceVersions/AdminServiceVersionPage'));
const AdminSites = lazy(() => import('components/Admin/AdminSites'));
const AdminSiteEditor = lazy(() => import('components/Admin/AdminSites/SiteEditor'));
const AdminClients = lazy(() => import('components/Admin/AdminClients'));
const ClientEditor = lazy(() => import('components/Admin/AdminClients/ClientEditor'));
const AdminClientsInstructions = lazy(() => import('components/Admin/AdminClients/InstructionsPage'));
const AdminUsers = lazy(() => import('components/Admin/AdminUsers'));
const AdminUser = lazy(() => import('components/Admin/AdminUsers/UserPage'));
const AdminUsersInstructions = lazy(() => import('components/Admin/AdminUsers/InstructionsPage'));
const AdminLanguages = lazy(() => import('components/Admin/AdminLanguages'));
const AdminEquippedClients = lazy(() => import('components/Admin/PD/PdClients/PdClients'));
const AdminEquippedContacts = lazy(() => import('components/Admin/PD/PdContacts'));
const AdminDocs = lazy(() => import('components/Admin/AdminDocs'));
const Page404 = lazy(() => import('components/Page404'));

import {ToastContainer} from 'react-toastify';

import MainWrapper from 'components/MainWrapper';

import {
    ADMIN_ROUTE,
    ROUTE,
} from './constants';

interface IProps {
    locale: string;
    availableLocales: Array<string>;
    setLocale(locale: string): void;
}

const Main: FC<IProps> = ({
    locale,
    availableLocales,
    setLocale,
}) => {
    const {auth} = useAuth();
    const {contracts} = useContractsByUser();

    return (
        <ErrorBoundary>
            <BrowserRouter>
                <FormsEngineProvider value={getFormsEngineContext(auth, contracts)}>
                    <Header
                        changeLocale={setLocale}
                        availableLocales={availableLocales}
                        locale={locale}
                    />
                    <Routes>
                        <Route
                            path={ROUTE.HOME}
                            element={<MainWrapper locale={locale}/>}
                        >
                            <Route
                                path={ROUTE.HOME}
                                element={<Home/>}
                            />
                            <Route
                                path={`${ROUTE.USER_SERVICES}`}
                                element={<UserServices/>}
                            />
                            <Route
                                path={ROUTE.SERVICES_CATALOG}
                                element={<ServicesCatalog/>}
                            />
                            <Route
                                path={ROUTE.SITES}
                                element={<UserSites/>}
                            />
                            <Route
                                path={ROUTE.INCIDENTS}
                                element={<Incidents/>}
                            />
                            <Route
                                path={ROUTE.BIDS}
                                element={<Bids/>}
                            />
                            <Route
                                path={ROUTE.BILLS}
                                element={<Bills/>}
                            />
                            <Route
                                path={ROUTE.BASKET}
                                element={<Basket/>}
                            />
                            <Route
                                path={ROUTE.ORDERS}
                                element={<Orders/>}
                            />
                            <Route
                                path={ROUTE.NOTIFICATIONS}
                                element={<Notifications/>}
                            />
                            <Route
                                path={ROUTE.SETTINGS}
                                element={<UserSettings/>}
                            />
                            <Route
                                path={ROUTE.LOGIN}
                                element={<Login/>}
                            />

                            <Route
                                path={ROUTE.ADMIN}
                                element={<Admin/>}
                            />
                            <Route
                                path={ADMIN_ROUTE.GLOBAL_DATA}
                                element={<AdminGlobalData/>}
                            />
                            <Route
                                path={ADMIN_ROUTE.SERVICE_GROUPS}
                                element={<AdminServicesGroups/>}
                            />
                            <Route
                                path={ADMIN_ROUTE.SERVICES}
                                element={<AdminServices/>}
                            />
                            <Route
                                path={`${ADMIN_ROUTE.SERVICES}/:code`}
                                element={<AdminServiceVersions/>}
                            />
                            <Route
                                path={`${ADMIN_ROUTE.SERVICES}/:code/:version`}
                                element={<AdminServiceVersionPage/>}
                            />
                            <Route
                                path={`${ADMIN_ROUTE.SERVICES}/:code/new`}
                                element={<AdminServiceVersionPage/>}
                            />
                            <Route
                                path={ADMIN_ROUTE.SITES}
                                element={<AdminSites/>}
                            />
                            <Route
                                path={`${ADMIN_ROUTE.SITES}/:version`}
                                element={<AdminSiteEditor/>}
                            />
                            <Route
                                path={ADMIN_ROUTE.CLIENTS}
                                element={<AdminClients/>}
                            />
                            <Route
                                path={`${ADMIN_ROUTE.CLIENTS}/:clientId`}
                                element={<ClientEditor/>}
                            />
                            <Route
                                path={`${ADMIN_ROUTE.CLIENTS}/instructions`}
                                element={<AdminClientsInstructions/>}
                            />
                            <Route
                                path={ADMIN_ROUTE.USERS}
                                element={<AdminUsers/>}
                            />
                            <Route
                                path={`${ADMIN_ROUTE.USERS}/instructions`}
                                element={<AdminUsersInstructions/>}
                            />
                            <Route
                                path={`${ADMIN_ROUTE.USERS}/:id`}
                                element={<AdminUser/>}
                            />
                            <Route
                                path={ADMIN_ROUTE.LANGUAGES}
                                element={<AdminLanguages/>}
                            />
                            <Route
                                path={ADMIN_ROUTE.EQUIPPED_CLIENTS}
                                element={<AdminEquippedClients/>}
                            />
                            <Route
                                path={ADMIN_ROUTE.EQUIPPED_CONTACTS}
                                element={<AdminEquippedContacts/>}
                            />
                            <Route
                                path={`${ADMIN_ROUTE.EQUIPPED_CLIENTS}/:id`}
                                element={<PdClientIdentifiers/>}
                            />
                            <Route
                                path={ADMIN_ROUTE.DOCS}
                                element={<AdminDocs/>}
                            />
                            <Route
                                path={'/*'}
                                element={<Page404/>}
                            />
                        </Route>
                    </Routes>
                    <Footer locale={locale}/>
                    <ToastContainer
                        autoClose={5000}
                        limit={3}
                    />
                </FormsEngineProvider>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default Main;
