import {IApplicationFrameTranslation} from '../../types';
import {IFrameForm} from './FrameForm/types';
import {
    IFramesForm,
    IProps,
} from './types';

import React, {
    createRef,
    forwardRef,
    RefObject,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import FrameForm from './FrameForm';

import {translateByIntl} from '../../../helpers';

import {findFrameTranslationById} from './functions';
import {INTL_DATA} from './intl';

const FramesForm = forwardRef<IFramesForm, IProps>((props, ref) => {
    const [frameFormRefs, setFrameFormRefs] = useState<RefObject<IFrameForm>[]>([]);

    useImperativeHandle(ref, () => {
        return {
            getValue(): IApplicationFrameTranslation[] {
                return frameFormRefs
                    .map((ref) => ref.current?.getValue()) as IApplicationFrameTranslation[];
            },
            validate(): boolean {
                return !frameFormRefs
                    .map((ref) => ref.current?.validate())
                    .includes(false);
            },
        };
    });
    useEffect(() => {
        const frameFormRefs = props.applicationFrames.map(() => createRef<IFrameForm>());

        setFrameFormRefs(frameFormRefs);
    }, [props.applicationFrames]);

    return (
        <div>
            <h5>
                {translateByIntl(props.intl, INTL_DATA.APPLICATION_FRAMES, 'Application frames')}
            </h5>
            {
                props.applicationFrames.map(
                    (frame, i) =>
                        <FrameForm
                            key={frame.id}
                            ref={frameFormRefs[i]}
                            frame={frame}
                            frameTranslation={findFrameTranslationById(frame.id, props.applicationFrameTranslations)}
                            intl={props.intl}
                        />
                )
            }
        </div>
    );
});

export default FramesForm;
