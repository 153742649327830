import {ILanguage} from 'instances/languages/types/instance';

import {StyledEngineProvider} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import React, {
    FC,
    useLayoutEffect,
    useState,
} from 'react';
import {IntlProvider} from 'react-intl';
import {Provider} from 'react-redux';
import theme from 'theme';

import {createReduxStore} from 'tools/redux';

import * as languagesFetches from 'instances/languages/fetches';
import {
    getDefaultLocale,
    getLSCurrentLocale,
    setLSCurrentLocale,
} from 'instances/languages/functions';
import reducer from 'instances/reducer';
import saga from 'instances/saga';

import {Loading} from 'components/Helpers/Other';
import Main from 'components/Main';

import 'boosted/dist/css/boosted.min.css';
import 'boosted/dist/css/orange-helvetica.min.css';
import 'boosted/dist/js/boosted.min';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';

const store = createReduxStore(reducer, saga);

const App: FC = () => {
    const [locale, setLocale] = useState<string>(getLSCurrentLocale());
    const [availableLocales, setAvailableLocales] = useState<string[] | null>(null);
    const [language, setLanguage] = useState<ILanguage | null>(null);

    const getLanguage = async (locale: string) => {
        const {language, error} = window.location.pathname === '/login' ?
            await languagesFetches.getLogin(locale) :
            await languagesFetches.getOne(locale);

        if (!language || error) {
            console.log(error);

            return null;
        }

        return language;
    };

    const changeLocale = (locale: string) => {
        setLocale(locale);
        setLSCurrentLocale(locale);
    };

    useLayoutEffect(() => {
        (async () => {
            if (!locale || !availableLocales) {
                const {locales, error} = await languagesFetches.getLocales();

                if (!locales || error) {
                    console.log(error);

                    return;
                }

                const locale = getDefaultLocale(locales);

                setLocale(locale);
                setLSCurrentLocale(locale);
                setAvailableLocales(locales);
            }

            const language = await getLanguage(locale);

            setLanguage(language);
        })();
    }, [locale]);

    return (
        <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst={true}>
                <Provider store={store}>
                    {
                        !locale ||
                        !availableLocales ||
                        !language ?
                            <Loading isPage={true}/> :
                            <IntlProvider
                                key={locale}
                                locale={locale}
                                messages={language.messages}
                            >
                                <Main
                                    locale={locale}
                                    setLocale={changeLocale}
                                    availableLocales={availableLocales}
                                />
                            </IntlProvider>
                    }
                </Provider>
            </StyledEngineProvider>
        </ThemeProvider>
    );
};

export default App;
