import {IListenByAuth} from 'instances/users/types/responses';

import React, {
    FC,
    useEffect,
} from 'react';

import {WebSocket} from 'tools/components';

import {myThrottle} from 'helpers/functions/throttle';

import {useAuth} from 'instances/auth/hooks';
import {INSTANCE_PATH} from 'instances/users/constants';

const ListenAuthByUser: FC = () => {
    const {
        auth,
        getAuth,
        updateAuthByListening,
    } = useAuth();

    const onMessage = (res: IListenByAuth) => {
        updateAuthByListening(res);
    };

    useEffect(() => {
        myThrottle(getAuth, 1000)();
    }, []);

    if (!auth) {
        return null;
    }

    return (
        <WebSocket
            path={`${INSTANCE_PATH}/listen/auth`}
            onMessage={onMessage}
        />
    );
};

export default ListenAuthByUser;
