import Notifier from 'helpers/Notifier';

export async function parseBody(response: Response) {
    let body: any = null;
    let error: any = null;

    try {
        body = await response.json();
    } catch (err) {
        error = err;
    }

    return {
        body,
        error,
    };
}

export const notify = function(): void {
    Notifier.warning('Ooops!\nSomething went wrong.\nPlease, open console for more information.');
};

export default {
    notify,
    parseBody,
};
