import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialRangeSlider} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../helpers';

const RangeField: FC<IProps> = (props) => {
    return (
        <MaterialRangeSlider
            min={props.field.min}
            max={props.field.max}
            title={translateByObject(props.field.title, props.translation)}
            value={props.field.value}
            isDisabled={true}
        />
    );
};

export default RangeField;
