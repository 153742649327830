import {
    IGetDemoByUserQuery,
    ISuggestionsQuery,
} from './types/requests';

import {getLSCurrentLocale} from 'instances/languages/functions';

import {
    SORT_BY,
    STATUS,
} from './constants';

export function getDefaultQueryDemoUser(): IGetDemoByUserQuery {
    return {
        skip: 0,
        limit: 20,

        sortBy: SORT_BY.SVID,
        sortOrder: true,

        type: [],
        SVID: [],
        CID: [],
        orderAddendumNumber: [],

        siteAddress: [],
        city: [],
        clientKERef: [],
        clientServId: [],
        siteId: [],
        customerUserSiteRef: [],

        contractNumber: [],
        status: [STATUS.ACTIVE],
        isChangePlanned: false,
        isAccessLinesShows: false,

        locale: getLSCurrentLocale(),
    };
}
export function getDefaultSuggestionsQueryDemoUser(): ISuggestionsQuery {
    return {
        type: '',
        SVID: '',
        CID: '',
        orderAddendumNumber: '',

        siteAddress: '',
        city: '',
        clientKERef: '',
        clientServId: '',
        siteId: '',
        customerUserSiteRef: '',

        contractNumber: '',

        isAccessLinesShows: false,
    };
}

export function getDefaultOneQueryFullUser() {
    return {
        locale: getLSCurrentLocale(),
    };
}
