import {IProps} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useState,
} from 'react';
import {Link} from 'react-router-dom';

import {useIntl} from 'helpers/hooks';

import {hasRoutePermission} from 'instances/auth/functions';

import {formatRoute} from '../../functions';

const LinkGroupView: FC<IProps> = (props) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const intl = useIntl();

    return (
        <div className={classNames('d-flex flex-row justify-content-between nav-item')}>
            <button
                className={'navbar-toggler'}
                type={'button'}
                data-bs-toggle={'collapse'}
                data-bs-target={'#navbarNavDarkDropdown'}
                aria-controls={'navbarNavDarkDropdown'}
                aria-expanded={'true'}
            />
            <div className={'collapse navbar-collapse'} id={'navbarNavDarkDropdown'}>
                <ul className={'navbar-nav'}>
                    <li className={'nav-item dropdown'}>
                        <button className={'nav-link dropdown-toggle'} data-bs-toggle={'dropdown'} aria-expanded={'false'}>
                            <div className={'d-flex flex-column justify-content-end align-items-end'}>
                                <div className={'fs-5 lh-lg'}>
                                    {intl(props.intlKey)}
                                </div>
                            </div>
                        </button>
                        <ul className={'dropdown-menu dropdown-menu-dark'}>
                            {
                                props.routes.map((route) => (
                                    hasRoutePermission(props.auth, route) &&
                                <li key={route} onClick={() => setShowDropdown(!showDropdown)}>
                                    <Link to={route} className={'dropdown-item text-decoration-none'}>
                                        {formatRoute(route, intl)}
                                    </Link>
                                </li>
                                ))
                            }
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default LinkGroupView;
