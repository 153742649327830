import {
    IGet,
    IGetAllLocales,
    IGetLocales,
    IGetLogin,
    IGetOne,
    IPatch,
    IPost,
    IRemove,
} from './types/fetchResult';
import {ILanguage} from './types/instance';

import config from 'config';

import Fetch from 'tools/Fetch';

import {onLogout} from 'instances/auth/functions';

import {INSTANCE_PATH} from './constants';

export async function getLocales(): Promise<IGetLocales> {
    return new Fetch<IGetLocales>({
        url: `${config.URL}${INSTANCE_PATH}/locales`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec();
}

export async function getAllLocales(): Promise<IGetAllLocales> {
    return new Fetch<IGetAllLocales>({
        url: `${config.URL}${INSTANCE_PATH}/all_locales`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function get(): Promise<IGet> {
    return new Fetch<IGet>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getLogin(locale: string): Promise<IGetLogin> {
    return new Fetch<IGetLogin>({
        url: `${config.URL}${INSTANCE_PATH}/login/${locale}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec();
}

export async function getOne(locale: string): Promise<IGetOne> {
    return new Fetch<IGetOne>({
        url: `${config.URL}${INSTANCE_PATH}/${locale}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec();
}

export async function post(language: ILanguage): Promise<IPost> {
    return new Fetch<IPost>({
        url: `${config.URL}${INSTANCE_PATH}/${language.locale}`,
        method: 'POST',
        body: language,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 409, 500], (body) => {
            return body;
        })
        .exec();
}

export async function patch(language: ILanguage): Promise<IPatch> {
    return new Fetch<IPatch>({
        url: `${config.URL}${INSTANCE_PATH}/${language.locale}`,
        method: 'PATCH',
        body: language,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function remove(locale: string): Promise<IRemove> {
    return new Fetch<IRemove>({
        url: `${config.URL}${INSTANCE_PATH}/${locale}`,
        method: 'DELETE',
    })
        .onEmptyBody([204])
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([403, 404, 500], (body) => {
            return body;
        })
        .exec();
}
