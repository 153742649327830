import classNames from 'classnames';
import React, {
    FC,
    useState,
} from 'react';

interface IProps {
    isChecked: boolean;
    isActive: boolean;
    text: string;

    onClick(value: string): void;
}

const Suggestion: FC<IProps> = ({
    text,
    onClick,

}) => {
    const [hovered, setHovered] = useState(false)

    return (
        <div
            className={classNames('text-truncate cursor-pointer mt-2 mb-2', {
                'text-primary': hovered
            })}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => onClick(text)}

        >
            <label className={'form-check-label ms-2 cursor-pointer'}>
                {text}
            </label>
        </div>
    );
};

export default Suggestion;
