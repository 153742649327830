import {IField} from '../../FieldsTypes/types';
import {
    IProps,
    ISpecificDataForm,
} from './types';

import React, {forwardRef} from 'react';

import {FIELD_TYPE} from '../../constants';
import AddressField from '../../FieldsTypes/AddressField/CreationField';
import CheckboxField from '../../FieldsTypes/CheckboxField/CreationField';
import ContractField from '../../FieldsTypes/ContractField/CreationField';
import DaDataField from '../../FieldsTypes/DaDataField/CreationField';
import DateField from '../../FieldsTypes/DateField/CreationField';
import GeoField from '../../FieldsTypes/GeoField/CreationField';
import IPField from '../../FieldsTypes/IPField/CreationField';
import NumberField from '../../FieldsTypes/NumberField/CreationField';
import PhoneField from '../../FieldsTypes/PhoneField/CreationField';
import RadioField from '../../FieldsTypes/RadioField/CreationField';
import RangeField from '../../FieldsTypes/RangeField/CreationField';
import SelectField from '../../FieldsTypes/SelectField/CreationField';
import StringField from '../../FieldsTypes/StringField/CreationField';
import TextField from '../../FieldsTypes/TextField/CreationField';
import UserField from '../../FieldsTypes/UserField/CreationField';
import USMigrationField from '../../FieldsTypes/USMigrationField/CreationField';

const SpecificDataForm = forwardRef<ISpecificDataForm<any>, IProps<IField>>((props, ref) => {
    switch (props.field.type) {
        case FIELD_TYPE.ADDRESS: {
            return (
                <AddressField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.CHECKBOX: {
            return (
                <CheckboxField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.RADIO: {
            return (
                <RadioField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.CONTRACT: {
            return (
                <ContractField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.DA_DATA: {
            return (
                <DaDataField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.DATE: {
            return (
                <DateField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.GEO: {
            return (
                <GeoField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.IP: {
            return (
                <IPField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.NUMBER: {
            return (
                <NumberField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.PHONE: {
            return (
                <PhoneField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.RANGE: {
            return (
                <RangeField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.SELECT: {
            return (
                <SelectField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.STRING: {
            return (
                <StringField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.TEXT: {
            return (
                <TextField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.USER: {
            return (
                <UserField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.US_MIGRATION: {
            return (
                <USMigrationField
                    ref={ref}
                    field={props.field}
                    intl={props.intl}
                />
            );
        }
    }
});

export default SpecificDataForm;
