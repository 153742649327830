import {IContractAdmin} from './types';
import * as NSRedux from './types/redux';
import {
    IGetByAdmin,
    IGetByUser,
    IListenContractsByUser,
} from './types/responses';
import {IRootReduxState} from 'instances/types';

import {useState} from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';

import {WS_EVENT_TYPE} from 'tools/constants';

import {useIntl} from 'helpers/hooks';
import Notifier from 'helpers/Notifier';

import {ACTION_TYPE} from './constants';
import * as fetches from './fetches';

export function useContractsByAdmin() {
    const [contracts, setContracts] = useState<IContractAdmin[] | null>(null);

    const intl = useIntl();

    const getContracts = async (clientId: string): Promise<IGetByAdmin> => {
        setContracts(null);

        const res = await fetches.getByAdmin(clientId);

        if (!res.contracts || res.error) {
            console.log(res.error);
            Notifier.ooops(intl);

            return res;
        }

        setContracts(res.contracts);

        return res;
    };

    return {
        contracts,
        getContracts,
    };
}

export function useContractsByUser() {
    const contracts = useSelector((state: IRootReduxState) => state.contracts);

    const dispatch = useDispatch();

    const intl = useIntl();

    const getContracts = async (): Promise<IGetByUser> => {
        const res = await fetches.getByUser();

        if (!res.contracts || res.error) {
            Notifier.ooops(intl);

            return res;
        }

        dispatch<NSRedux.IGet>({
            type: ACTION_TYPE.GET_CONTRACTS,
            contracts: res.contracts,
        });

        return res;
    };
    const updateContractsByListening = (res: IListenContractsByUser): void => {
        switch (res.type) {
            case WS_EVENT_TYPE.INSERT: {
                if (!res.contract || res.error) {
                    console.log(res.error);

                    return;
                }

                dispatch({
                    type: ACTION_TYPE.ADD_CONTRACT,
                    contract: res.contract,
                });

                return;
            }
            case WS_EVENT_TYPE.UPDATE: {
                if (!res.contract || res.error) {
                    console.log(res.error);

                    return;
                }

                dispatch({
                    type: ACTION_TYPE.UPDATE_CONTRACT,
                    contract: res.contract,
                });

                return;
            }
            case WS_EVENT_TYPE.DELETE: {
                if (!res.id || res.error) {
                    console.log(res.error);

                    return;
                }

                dispatch({
                    type: ACTION_TYPE.REMOVE_CONTRACT,
                    id: res.id,
                });

                return;
            }
        }
    };

    return {
        contracts,
        getContracts,
        updateContractsByListening,
    };
}
