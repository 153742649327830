import {IPostUserByAdminBody} from 'instances/equippedUsers/types/requests';

import React, {
    FC,
    useState,
} from 'react';

import {IDENTIFIER_TYPE} from 'instances/equippedUsers/constants';
import {getDefaultBodyPostByAdmin} from 'instances/equippedUsers/functions';

import {
    OrangeInput,
    useOrangeInputRef,
} from 'components/Helpers/Inputs';
import {Modal} from 'components/Helpers/Other';

interface IProps {
    isOpen: boolean;
    clientId: string;

    onAdd(body: IPostUserByAdminBody): void;
    onClose(): void;
}

const NewEquippedUserModal: FC<IProps> = (props) => {
    const [type, setType] = useState(IDENTIFIER_TYPE.PHONE);

    const inputRef = useOrangeInputRef();

    const onAdd = () => {
        const body = getDefaultBodyPostByAdmin(props.clientId);
        const identifier = inputRef.current?.getValue();

        if (!identifier) {
            return;
        }

        body.identifier = identifier;

        props.onAdd(body);
    };

    return (
        <Modal
            isOpen={props.isOpen}
            title={'Добавить'}
            onClose={props.onClose}
        >
            <div className={'row'}>
                <div className={'col-6'}>
                    <label className={'col-form-label'}>
                        Идентификатор
                    </label>
                </div>
                <div className={'col-6'}>
                    <select
                        className={'form-control'}
                        value={type}
                        onChange={(e) => setType(e.target.value as IDENTIFIER_TYPE)}
                    >
                        <option value={IDENTIFIER_TYPE.PHONE}>Телефон</option>
                        <option value={IDENTIFIER_TYPE.IP}>IP адрес</option>
                    </select>
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={'col-6'}>
                    <label className={'col-form-label'}>
                        Значение
                    </label>
                </div>
                <div className={'col-6'}>
                    <OrangeInput
                        ref={inputRef}
                        initValue={''}
                        isRequired={true}
                        symbolRegexp={type === IDENTIFIER_TYPE.PHONE ? /\d/ : undefined}
                    />
                </div>
            </div>
            <div className={'d-flex flex-row justify-content-center mt-2'}>
                <button className={'btn btn-secondary'} onClick={onAdd}>
                    Добавить конечного пользователя
                </button>
            </div>
        </Modal>
    );
};

export default NewEquippedUserModal;
