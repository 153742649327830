import {IProps} from './types';

import classNames from 'classnames';
import React, {FC} from 'react';

import {MaterialTooltip} from 'components/Helpers/MaterialUI';
import {Loading} from 'components/Helpers/Other';

import {getIcon} from './functions';

import styles from './ActionButton.module.scss';

const ActionButton: FC<IProps> = (props) => {
    const className = classNames(props.className, styles.actionButton, {
        'btn-primary': !props.isActive,
        'bg-primary': !!props.isActive && !props.isDisabled,
        'bg-light': !props.isActive,
        [styles.small]: props.size === 'small',
    });

    return (
        <>
            {
                props.loading ?
                    <div className={props.className}>
                        <div
                            className={classNames('btn-secondary bg-light border border-dark d-flex justify-content-center align-items-center', styles.actionButton)}
                        >
                            <Loading size={25}/>
                        </div>
                    </div> :
                    <button
                        className={className}
                        onClick={props.onClick}
                        disabled={props.isDisabled}
                        data-toggle={'tooltip'}
                        data-placement={'top'}
                        title={props.tooltip}
                    >
                        <MaterialTooltip
                            text={props.tooltip}
                            placement={'top'}
                            className={styles.tooltip}
                        >
                            <img
                                className={props.isDisabled ? styles.disabledImg : ''}
                                src={getIcon(props.mode)}
                                alt={''}
                            />
                        </MaterialTooltip>
                    </button>
            }
        </>
    );
};

export default ActionButton;
