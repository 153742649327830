import {ISpecificForm} from '../../../FormField/types';
import {IProps} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
} from 'react';

import {MaterialRangeSlider} from 'components/Helpers/MaterialUI';

import {formatFormTitle} from '../../functions';

const RangeField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            validate() {
                return true;
            },
        };
    });

    return (
        <MaterialRangeSlider
            title={formatFormTitle(props.field, props.translation)}
            min={props.field.min}
            max={props.field.max}
            step={props.field.step}
            value={props.field.value}
            onChange={(value) => props.onChange(props.field.name, value)}
        />
    );
});

export default RangeField;
