export const GET_SITES_CONSTRUCTOR = 'GET_SITES_CONSTRUCTOR';
export const GET_SITES_CONSTRUCTOR_SUCCEED = 'GET_SITES_CONSTRUCTOR_SUCCEED';

export const GET_SITES_BY_USER = 'GET_SITES_BY_USER';
export const GET_SITES_BY_USER_SUCCEED = 'GET_SITES_BY_USER_SUCCEED';

export const GET_SITES_VERSION = 'GET_SITES_VERSION';
export const GET_SITES_VERSION_SUCCEED = 'GET_SITES_VERSION_SUCCEED';

export const POST_SITES_CONSTRUCTOR = 'POST_SITES_CONSTRUCTOR';
export const POST_SITES_CONSTRUCTOR_SUCCEED = 'POST_SITES_CONSTRUCTOR_SUCCEED';

export const SET_ACTIVE_SITES_CONSTRUCTOR = 'SET_ACTIVE_SITES_CONSTRUCTOR';
export const SET_ACTIVE_SITES_CONSTRUCTOR_SUCCEED = 'SET_ACTIVE_SITES_CONSTRUCTOR_SUCCEED';

export function getState({sites}) {
    return sites;
}

export function isLoaded({sites}) {
    return !!sites;
}

export function get(dispatch) {
    return function() {
        dispatch({type: GET_SITES_CONSTRUCTOR});
    }
}
