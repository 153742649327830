import { ACTION_TYPE as ActionTypesAuth } from '../auth/constants';
import { ActionTypes as ActionTypesServices } from './constants';


export default function allServices(state=null, action) {
    switch(action.type) {
        case ActionTypesServices.GetServicesSucceed: {
            return action.services;
        }
        case ActionTypesServices.GetServiceVersionsSucceed: {
            const {serviceId, versions} = action;
            const service = state.find(service => service.id === serviceId);

            service.versions = versions;
            return [...state];
        }
        case ActionTypesServices.GetServiceVersionSucceed: {
            const {version, serviceId, versionId} = action;
            const service = state.find(service => service.id === serviceId);
            const serviceVersion = service.versions.find(element => element.id === versionId);
            const ind = service.versions.indexOf(serviceVersion);

            service.versions[ind] = {...serviceVersion, ...version};
            return [...state];
        }
        case ActionTypesServices.PostServiceSucceed: {
            return [...state, action.service];
        }
        case ActionTypesServices.PatchServiceSucceed: {
            const {service} = action;
            const defaultService = state.find(defaultService => defaultService.id === service.id);
            const ind = state.indexOf(defaultService);

            state[ind] = {...state[ind], ...service};
            return [...state];
        }
        case ActionTypesServices.PostVersionSucceed: {
            const {serviceId, version} = action;
            const service = state.find(service => service.id === serviceId);

            if (service.versions) {
                service.versions.push(version);
            } else {
                service.versions = [version];
            }
            return [...state];
        }
        case ActionTypesServices.PutVersionSucceed: {
            const {serviceId, versionId, version} = action;
            const service = state.find(service => service.id === serviceId);
            const variant = service.versions.find(variant => variant.id === versionId);
            const ind = service.versions.indexOf(variant);
            service.versions[ind] = version;
            return [...state];
        }
        case ActionTypesServices.SetActiveServiceVersionSucceed: {
            const {serviceId, versionId} = action;
            const service = state.find(service => service.id === serviceId);
            const activeVersion = service.versions.find(version => version.isActive);
            const newActiveVersion = service.versions.find(version => version.id === versionId);

            activeVersion.isActive = false;
            newActiveVersion.isActive = true;
            return [...state];
        }
        case ActionTypesServices.SetServicesQueueSucceed: {
            const {queue} = action;

            state.forEach(family => {
                const familyQueue = queue.find(element => element.id === family.id);

                family.queue = familyQueue.queue;
            });
            return [...state];
        }
        case ActionTypesServices.GetActiveVersionsSucceed: {
            if (state === null) {
                return action.services;
            }
            action.services.forEach(service => {
                const stateService = state.find(stateService => stateService.code === service.code);

                if (!stateService) {
                    state.push(service);
                } else if (!stateService.versions) {
                    stateService.versions = service.versions;
                } else if (!stateService.versions.find(variant => variant.version === service.versions[0].version)) {
                    stateService.versions = [...stateService.versions, service.versions];
                }
            });
            return [...state];
        }
        case ActionTypesAuth.AuthLogoutSucceed:
            return null;
        default:
            return state;
    }
}
