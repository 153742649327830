import {ISelectField} from '../types';

import {translateByObject} from '../../../../helpers';

export function getDefaultTranslation(field: ISelectField, translation: Record<string, string>) {
    return field.values.reduce(
        (result, value) => ({
            ...result,
            [value]: translateByObject(value, translation),
        }),
        {}
    );
}
