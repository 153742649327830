import {INTL_DATA} from './intl';
import {InputType} from './types';

export function getLabelByType(inputType: InputType, intl) {
    switch (inputType) {
        case 'SVID': {
            return intl(INTL_DATA.SVID);
        }
        case 'CID': {
            return intl(INTL_DATA.CID);
        }
        case 'orderAddendumNumber': {
            return intl(INTL_DATA.ORDER_ADDENDUM_NUMBER);
        }
        case 'contractNumber': {
            return intl(INTL_DATA.CONTRACT_NUMBER);
        }
        default: {
            return  ''
        }
    }
}