import React, {FC, ReactNode} from 'react';
import {Collapse as MaterialCollapse} from '@mui/material';

interface IProps {
    isOpened: boolean;
    className?: string;
    children?: ReactNode;
}

const Collapse: FC<IProps> = (props) => {
    return (
        <MaterialCollapse
            in={props.isOpened}
            timeout={500}
            unmountOnExit={true}
            className={props.className}
        >
            {props.children}
        </MaterialCollapse>
    );
};

export default Collapse;
