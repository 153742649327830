import {IField} from '../../FieldsTypes/types';
import {IProps} from './types';

import React, {FC} from 'react';

import {FIELD_TYPE} from '../../constants';
import BoostedAddressField from '../../FieldsTypes/AddressField/BoostedDisplayField';
import BoostedCheckboxField from '../../FieldsTypes/CheckboxField/BoostedCheckboxField';
import BoostedContractField from '../../FieldsTypes/ContractField/BoostedDisplayField';
import DaDataField from '../../FieldsTypes/DaDataField/DisplayField/DaDataField';
import BoostedDateField from '../../FieldsTypes/DateField/BoostedDateField';
import GeoField from '../../FieldsTypes/GeoField/DisplayField/GeoField';
import IPField from '../../FieldsTypes/IPField/DisplayField/IPField';
import BoostedNumberField from '../../FieldsTypes/NumberField/BoostedDisplayField';
import PhoneField from '../../FieldsTypes/PhoneField/DisplayField/PhoneField';
import RadioField from '../../FieldsTypes/RadioField/DisplayField/RadioField';
import RangeField from '../../FieldsTypes/RangeField/DisplayField/RangeField';
import SelectField from '../../FieldsTypes/SelectField/BoostedDisplayField';
import BoostedStringField from '../../FieldsTypes/StringField/BoostedDisplayField';
import BoostedTextField from '../../FieldsTypes/TextField/BoostedDisplayField';
import BoostedUserField from '../../FieldsTypes/UserField/BoostedUserField';
import USMigrationField from '../../FieldsTypes/USMigrationField/DisplayField/USMigrationField';

const BoostedSpecificDisplay: FC<IProps<IField>> = (props) => {
    switch (props.field.type) {
        case FIELD_TYPE.ADDRESS: {
            return (
                <BoostedAddressField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.CHECKBOX: {
            return (
                <BoostedCheckboxField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.CONTRACT: {
            return (
                <BoostedContractField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.DA_DATA: {
            return (
                <DaDataField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.DATE: {
            return (
                <BoostedDateField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.GEO: {
            return (
                <GeoField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.IP: {
            return (
                <IPField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.NUMBER: {
            return (
                <BoostedNumberField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.PHONE: {
            return (
                <PhoneField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.RADIO: {
            return (
                <RadioField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.RANGE: {
            return (
                <RangeField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.SELECT: {
            return (
                <SelectField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.STRING: {
            return (
                <BoostedStringField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.TEXT: {
            return (
                <BoostedTextField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.USER: {
            return (
                <BoostedUserField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.US_MIGRATION: {
            return (
                <USMigrationField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
    }
};

export default BoostedSpecificDisplay;
