import {
    IDataItem,
    IEquippedUser,
} from './types';
import {
    IGetByAdminQuery,
    IGetByClientQuery,
    IPostUserByAdminBody,
} from './types/requests';
import {IEquippedClient} from 'instances/equippedClients/types';

import {
    IDENTIFIER_STATUS,
    IDENTIFIER_TYPE,
    PASSPORT_TYPE,
} from './constants';

export function getDefaultQueryClient(): IGetByClientQuery {
    return {
        skip: 0,
        limit: 10,

        identifier: '',
        identifierType: [IDENTIFIER_TYPE.IP, IDENTIFIER_TYPE.PHONE],
        identifierStatus: IDENTIFIER_STATUS.ALL,
        serviceType: '',
        orderBlankNumber: '',
        address: '',
        city: '',
        contractNumber: '',
        pdSentAt: null,
    };
}

export function getDefaultQueryAdmin(): IGetByAdminQuery {
    return {
        skip: 0,
        limit: 10,

        identifier: '',
        identifierType: [IDENTIFIER_TYPE.IP, IDENTIFIER_TYPE.PHONE],
        identifierStatus: IDENTIFIER_STATUS.ALL,
        serviceType: '',
        orderBlankNumber: '',
        address: '',
        city: '',
        contractNumber: '',
        pdSentAt: null,
    };
}

export function getDefaultBodyPostByAdmin(clientId: string): IPostUserByAdminBody {
    return {
        clientId,
        identifier: '',
        identifierType: IDENTIFIER_TYPE.PHONE,
        servId: null,
        SVID: null,
        sormContractNumber: null,
        showContractNumber: null,

        serviceName: '',
        orderNumber: null,
        orderBlankNumber: '',
        addressId: '',
        address: '',
        city: null,
        serviceStartedAt: null,
        pdSentAt: null,
        isRemoved: false,
    };
}

export function getDefaultDataItem(equippedClient: IEquippedClient, equippedUser: IEquippedUser): IDataItem {
    return {
        id: equippedUser.id,

        identifier: equippedUser.identifier,
        person: {
            firstName: '',
            lastName: '',
            middleName: '',
            birthday: null,
        },
        passport: {
            name: 'Паспорт',
            type: PASSPORT_TYPE.REGULAR_PASSPORT,
            series: '',
            number: '',
            issueDate: null,
            authority: '',
            departmentCode: '',
        },
        inn: equippedClient.inn,
        clientName: equippedClient.clientName,
    };
}

export function formatFixedDate(value: Date | string | null): string | null {
    if (!value) {
        return null;
    }

    const date = new Date(value);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day < 10 ? 0 : ''}${day}.${month < 10 ? 0 : ''}${month}.${year}`;
}
