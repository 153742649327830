export const INSTANCE_PATH = '/equipped_users';
export const DOWNLOADED_REPORT_FILENAME = 'report.pdf';
export const DOWNLOADED_SAMPLE_FILENAME = 'Шаблон - пользователи оконечного оборудования.xlsx';

export enum IDENTIFIER_TYPE {
    PHONE = 'PHONE',
    IP = 'IP',
}

export enum IDENTIFIER_STATUS {
    PROVIDED = 'PROVIDED',
    UNPROVIDED = 'UNPROVIDED',
    ALL = 'ALL',
}

export enum PASSPORT_TYPE {
    REGULAR_PASSPORT = 'REGULAR_PASSPORT',
    INTERNATIONAL_PASSPORT = 'INTERNATIONAL_PASSPORT',
}

export enum RESPONSE_ERROR {
    NOT_FOUND = 'NOT_FOUND',
}
