import {IPostFrontendErrorByUserBody} from './types/requests';
import {IPostFrontendErrorByUser} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {onLogout} from 'instances/auth/functions';

import {INSTANCE_PATH} from './constants';

export async function postFrontendErrorByUser(body: IPostFrontendErrorByUserBody): Promise<IPostFrontendErrorByUser> {
    return new Fetch<IPostFrontendErrorByUser>({
        url: `${config.URL}${INSTANCE_PATH}/frontend_error`,
        method: 'POST',
        body,
    })
        .onEmptyBody([204])
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 500], (body) => {
            return body;
        })
        .exec();
}
