import {IProps} from './types';

import classNames from 'classnames';
import React, {PureComponent} from 'react';

import {OverflowHiddenText} from 'components/Helpers/Other';

class StringRowItem extends PureComponent<IProps> {
    public render() {
        const className = classNames(
            'd-flex flex-row justify-content-center justify-content-md-start align-items-center h-100',
            {'opacity-25': this.props.isFrozen}
        );

        return (
            <div className={className}>
                <OverflowHiddenText
                    className={'col-form-label font-weight-bold'}
                    text={this.props.text}
                    withCopying={this.props.withCopying}
                />
            </div>
        );
    }
}

export default StringRowItem;
