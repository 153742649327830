import {IField} from '../../../Fields/types';
import {IFieldPosition} from '../../types';

import {isUnableField} from '../../../helpers';

export function getRowFields(fields: IField[], row: number, positionKey: string): IField[] {
    return fields
        .filter((field) => {
            const position: IFieldPosition | undefined = field[positionKey];

            if (!position) {
                return false;
            }

            return position.row === row;
        })
        .sort((a, b) => {
            const aPosition: IFieldPosition | undefined = a[positionKey];
            const bPosition: IFieldPosition | undefined = b[positionKey];

            if (!aPosition || !bPosition) {
                return -1;
            }

            return aPosition.column - bPosition.column;
        });
}

export function isDisabledRow(rowFields: IField[], fields: IField[]): boolean {
    return !rowFields
        .map((field) => isUnableField(field.name, fields))
        .includes(false);
}
