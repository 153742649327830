import {IDaDataFieldSpecificProps} from '../types';
import {
    ICreationField,
    IProps,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA as FIELDS_INTL_DATA} from '../../intl';
import {DA_DATA_TYPE} from '../constants';
import {INTL_DATA} from '../intl';

import {formatType} from './functions';

const DaDataField = forwardRef<ICreationField, IProps>((props, ref) => {
    const [dataType, setDataType] = useState<DA_DATA_TYPE>(props.field.dataType);
    const [required, setRequired] = useState(props.field.validation.required);

    useImperativeHandle(ref, () => {
        return {
            getFieldStructure(): IDaDataFieldSpecificProps {
                return {
                    value: null,
                    dataType,
                    validation: {
                        required,
                    },
                };
            },
            getFieldTranslation(): Record<string, string> {
                return {};
            },
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <>
            <div className={'border-bottom border-dark p-2'}>
                <div className={'row m-2'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, INTL_DATA.TYPE, 'Type')}
                            :
                        </label>
                    </div>
                    <div className={'col-8'}>
                        <select
                            className={'form-control'}
                            value={dataType}
                            onChange={(e) => setDataType(e.target.value as DA_DATA_TYPE)}
                        >
                            {
                                Object
                                    .values(DA_DATA_TYPE)
                                    .map(
                                        (type) =>
                                            <option key={type} value={type}>
                                                {formatType(type, props.intl)}
                                            </option>
                                    )
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className={'m-2'}>
                <h5>
                    {translateByIntl(props.intl, FIELDS_INTL_DATA.VALIDATION, 'Validation')}
                </h5>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <label className={'col-form-label'}>
                            {translateByIntl(props.intl, FIELDS_INTL_DATA.REQUIRED, 'Required')}
                            :
                        </label>
                    </div>
                    <div className={'col-2'}>
                        <MaterialCheckbox
                            value={required}
                            onChange={setRequired}
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

export default DaDataField;
