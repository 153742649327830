import {IApplicationFrame} from '../types';
import {IProps} from './types';

import React, {
    FC,
    useState,
} from 'react';
import {
    DragDropContext,
    DragStart,
    DropResult,
} from 'react-beautiful-dnd';

import FieldsContainer from './FieldsContainer';
import FramesContainer from './FramesContainer';
import OptionalPlaces from './OptionalPlaces';
import {
    CREATE_FRAME_DROPPABLE_ID,
    REMOVE_FRAME_DROPPABLE_ID,
} from './OptionalPlaces/constants';
import StepBar from './StepBar';

import {translateByIntl} from '../../helpers';

import {
    DROPPABLE_TYPE,
    MAIN_DROPPABLE,
} from './constants';
import * as f from './functions';
import {INTL_DATA} from './intl';

const CreationApplicationForm: FC<IProps> = (props) => {
    const [fieldIsDragging, setFieldIsDragging] = useState(false);
    const [frameIsDragging, setFrameIsDragging] = useState(false);

    const lastStep = props.stepTitles.length;
    const isAbleToCreateStep = !!props.frames.find((frame) => frame.step === lastStep);

    const onCreateStep = (stepTitle: string) => {
        props.onChangeStepTitles([...props.stepTitles, stepTitle]);
    };
    const onChangeStep = (stepNumber: number, title: string) => {
        const stepTitles = [...props.stepTitles];

        stepTitles[stepNumber - 1] = title;
        props.onChangeStepTitles(stepTitles);
    };
    const onRemoveStep = (stepNumber: number) => {
        const stepTitles = [...props.stepTitles];

        stepTitles.splice(stepNumber - 1, 1);
        props.onChangeStepTitles(stepTitles);

        const frames = props.frames.map((frame) => ({...frame}));

        frames.forEach((frame) => {
            if (!frame.step) {
                return;
            }
            if (frame.step === stepNumber) {
                frame.step = undefined;

                return;
            }
            if (frame.step > stepNumber) {
                frame.step -= 1;

                return;
            }
        });
        props.onChangeFrames(frames);
        props.onSetCurrentStep(1);
    };
    const onChangeFrame = (frame: IApplicationFrame) => {
        const frames = props.frames.filter((oldFrame) => oldFrame.id !== frame.id);

        props.onChangeFrames([...frames, frame]);
    };
    const onDragStart = (data: DragStart) => {
        if (data.type === DROPPABLE_TYPE.FIELDS_CONTAINER) {
            setFieldIsDragging(true);
        }
        if (data.type === DROPPABLE_TYPE.FRAMES_CONTAINER) {
            setFrameIsDragging(true);
        }
    };
    const onDragEnd = (data: DropResult) => {
        const {type, draggableId, source, destination} = data;

        if (type === DROPPABLE_TYPE.FIELDS_CONTAINER) {
            setFieldIsDragging(false);
        }
        if (type === DROPPABLE_TYPE.FRAMES_CONTAINER) {
            setFrameIsDragging(false);
        }
        if (!destination) {
            return;
        }
        if (type === DROPPABLE_TYPE.FIELDS_CONTAINER) {
            const movedField = props.fields.find((field) => f.getDraggableIdForField(field) === draggableId);

            if (!movedField) {
                return;
            }
            if (destination.droppableId === CREATE_FRAME_DROPPABLE_ID) {
                const sourceFrame = props.frames.find((frame) => f.getDroppableIdForFrame(frame) === source.droppableId);

                if (sourceFrame) {
                    const {fields, frames} = f.createFrameWithFieldFromOtherFrame(props.fieldApplicationPositionKey, movedField, sourceFrame, props.fields, props.frames);

                    props.onChangeFields(fields);
                    props.onChangeFrames(frames);

                    return;
                }
                if (source.droppableId === MAIN_DROPPABLE.FIELDS) {
                    const {fields, frames} = f.createFrameWithFieldFromUnsetFields(props.fieldApplicationPositionKey, movedField, props.fields, props.frames);

                    props.onChangeFields(fields);
                    props.onChangeFrames(frames);

                    return;
                }
            }
            if (destination.droppableId === MAIN_DROPPABLE.FIELDS) {
                const sourceFrame = props.frames.find((frame) => f.getDroppableIdForFrame(frame) === source.droppableId);

                if (sourceFrame) {
                    const {fields, frames} = f.moveFieldFromFrameToUnsetFields(props.fieldApplicationPositionKey, movedField, sourceFrame, props.fields, props.frames);

                    props.onChangeFields(fields);
                    props.onChangeFrames(frames);

                    return;
                }
            }

            const destinationFrame = props.frames.find((frame) => f.getDroppableIdForFrame(frame) === destination.droppableId);

            if (destinationFrame) {
                const sourceFrame = props.frames.find((frame) => f.getDroppableIdForFrame(frame) === source.droppableId);

                if (sourceFrame) {
                    const {fields, frames} = f.moveFieldFromFrameToOtherFrame(props.fieldApplicationPositionKey, movedField, destination.index, sourceFrame, destinationFrame, props.fields, props.frames);

                    props.onChangeFields(fields);
                    props.onChangeFrames(frames);

                    return;
                }
                if (source.droppableId === MAIN_DROPPABLE.FIELDS) {
                    const {fields, frames} = f.moveFieldFromUnsetFieldsToFrame(props.fieldApplicationPositionKey, movedField, destination.index, destinationFrame, props.fields, props.frames);

                    props.onChangeFields(fields);
                    props.onChangeFrames(frames);

                    return;
                }
            }
        }
        if (type === DROPPABLE_TYPE.FRAMES_CONTAINER) {
            const movedFrame = props.frames.find((frame) => f.getDraggableIdForFrame(frame) === draggableId);

            if (!movedFrame) {
                return;
            }
            if (destination.droppableId === REMOVE_FRAME_DROPPABLE_ID) {
                const {fields, frames} = f.removeFrame(props.fieldApplicationPositionKey, movedFrame, props.fields, props.frames);

                props.onChangeFields(fields);
                props.onChangeFrames(frames);

                return;
            }
            if (destination.droppableId === MAIN_DROPPABLE.FRAMES) {
                if (source.droppableId === MAIN_DROPPABLE.STEP) {
                    const {fields, frames} = f.moveFrameFromStepToUnset(movedFrame, destination.index, props.fields, props.frames);

                    props.onChangeFields(fields);
                    props.onChangeFrames(frames);

                    return;
                }
                if (source.droppableId === MAIN_DROPPABLE.FRAMES) {
                    const {fields, frames} = f.moveFrameFromUnsetToUnset(movedFrame, destination.index, props.fields, props.frames);

                    props.onChangeFields(fields);
                    props.onChangeFrames(frames);

                    return;
                }
            }
            if (destination.droppableId === MAIN_DROPPABLE.STEP) {
                if (source.droppableId === MAIN_DROPPABLE.FRAMES) {
                    const {fields, frames} = f.moveFrameFromUnsetToStep(props.currentStepNumber, movedFrame, destination.index, props.fields, props.frames);

                    props.onChangeFields(fields);
                    props.onChangeFrames(frames);

                    return;
                }
                if (source.droppableId === MAIN_DROPPABLE.STEP) {
                    const {fields, frames} = f.moveFrameFromStepToStep(props.currentStepNumber, movedFrame, destination.index, props.fields, props.frames);

                    props.onChangeFields(fields);
                    props.onChangeFrames(frames);

                    return;
                }
            }
        }
    };

    return (
        <div className={'row'}>
            <div className={'w-100 mb-4'}>
                <StepBar
                    stepTitles={props.stepTitles}
                    currentStepNumber={props.currentStepNumber}
                    isAbleToCreateStep={isAbleToCreateStep}
                    intl={props.intl || null}
                    onCreate={onCreateStep}
                    onChange={onChangeStep}
                    onRemove={onRemoveStep}
                    onSetCurrent={props.onSetCurrentStep}
                />

            </div>
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <div className={'d-flex flex-row justify-content-center w-100'}>
                    <OptionalPlaces
                        fieldIsDragging={fieldIsDragging}
                        frameIsDragging={frameIsDragging}
                        intl={props.intl || null}
                    />
                </div>
                <div className={'row w-100'}>
                    <div className={'col-4'}>
                        <div className={'d-flex flex-row justify-content-center mt-4'}>
                            <h4>
                                {translateByIntl(props.intl, INTL_DATA.STEP_STRUCTURE, 'Step structure')}
                            </h4>
                        </div>
                        <FramesContainer
                            fieldApplicationPositionKey={props.fieldApplicationPositionKey}
                            droppableId={MAIN_DROPPABLE.STEP}
                            frames={props.frames.filter((frame) => frame.step === props.currentStepNumber)}
                            fields={props.fields}
                            intl={props.intl || null}
                            onChangeFrame={onChangeFrame}
                        />
                    </div>
                    <div className={'col-4'}>
                        <div className={'d-flex flex-row justify-content-center mt-4'}>
                            <h4>
                                {translateByIntl(props.intl, INTL_DATA.UNSET_FRAMES, 'Unset frames')}
                            </h4>
                        </div>
                        <FramesContainer
                            fieldApplicationPositionKey={props.fieldApplicationPositionKey}
                            droppableId={MAIN_DROPPABLE.FRAMES}
                            frames={props.frames.filter((frame) => !frame.step)}
                            fields={props.fields}
                            intl={props.intl || null}
                            onChangeFrame={onChangeFrame}
                        />
                    </div>
                    <div className={'col-4'}>
                        <div className={'d-flex flex-row justify-content-center mt-4'}>
                            <h4>
                                {translateByIntl(props.intl, INTL_DATA.UNSET_FIELDS, 'Unset fields')}
                            </h4>
                        </div>
                        <FieldsContainer
                            fieldApplicationPositionKey={props.fieldApplicationPositionKey}
                            droppableId={MAIN_DROPPABLE.FIELDS}
                            fields={props.fields.filter((field) => !field[props.fieldApplicationPositionKey])}
                            intl={props.intl || null}
                        />
                    </div>
                </div>
            </DragDropContext>
        </div>
    );
};

export default CreationApplicationForm;
