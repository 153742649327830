import {IValue as IAddressFieldValue} from '../../../AddressField/types';
import {
    IContract,
    IValue as IContractFieldValue,
} from '../../../ContractField/types';
import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialSelect} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../../helpers';
import {FIELD_TYPE} from '../../../../constants';
import AddressField from '../../../AddressField/FormField';
import ContractField from '../../../ContractField/FormField';
import {formatValue as formatContractValue} from '../../../ContractField/functions';
import {MIGRATE_TO} from '../../constants';

const Origin: FC<IProps> = (props) => {
    const onChangeContract = (contract: IContractFieldValue) => {
        if (props.migrateTo !== MIGRATE_TO.BILL) {
            return;
        }

        props.onChange(contract);
    };
    const onChangeUniqueContract = (id: string, uniqueContracts: IContract[]) => {
        if (props.migrateTo !== MIGRATE_TO.CONTRACT) {
            return;
        }

        const uniqueContract = uniqueContracts.find((uniqueContract) => uniqueContract.id === id);

        if (!uniqueContract) {
            return;
        }

        props.onChange(uniqueContract);
    };
    const onChangeAddress = (address: IAddressFieldValue) => {
        if (props.migrateTo !== MIGRATE_TO.SITE) {
            return;
        }

        props.onChange(address);
    };

    switch (props.migrateTo) {
        case MIGRATE_TO.BILL: {
            return (
                <ContractField
                    field={{
                        name: '',
                        title: props.originTitle,
                        tooltip: '',
                        readOnly: false,
                        value: props.origin as IContractFieldValue,
                        type: FIELD_TYPE.CONTRACT,
                        validation: {required: false},
                    }}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={(_, value) => onChangeContract(value as IContractFieldValue)}
                />
            );
        }
        case MIGRATE_TO.CONTRACT: {
            const uniqueContracts = props.getUniqueContractsWithoutBills(props.contracts);

            return (
                <MaterialSelect
                    label={translateByObject(props.originTitle, props.translation)}
                    value={(props.origin as IContractFieldValue)?.id || ''}
                    values={uniqueContracts.map((uniqueContract) => uniqueContract.id)}
                    options={uniqueContracts.map((uniqueContract) => formatContractValue(uniqueContract))}
                    onChange={(id) => onChangeUniqueContract(id, uniqueContracts)}
                />
            );
        }
        case MIGRATE_TO.SITE: {
            return (
                <AddressField
                    field={{
                        name: '',
                        title: props.originTitle,
                        tooltip: '',
                        readOnly: false,
                        value: props.origin as IAddressFieldValue,
                        type: FIELD_TYPE.ADDRESS,
                        validation: {required: false},
                    }}
                    translation={props.translation}
                    intl={props.intl}
                    onChange={(_, value) => onChangeAddress(value as IAddressFieldValue)}
                />
            );
        }
    }
};

export default Origin;
