import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Loading } from 'components/Helpers/Other';


const ItemPropType = PropTypes.shape({
    stateId: PropTypes.string.isRequired,
    getState: PropTypes.func.isRequired,
    actionId: PropTypes.string.isRequired,
    getAction: PropTypes.func.isRequired,
    loadId: PropTypes.string.isRequired,
    isLoaded: PropTypes.func.isRequired,
    getActionParams: PropTypes.func,
});

class Core extends Component {
    static propTypes = {
        WrappedComponent: PropTypes.any,
        scenario: PropTypes.oneOfType([
            PropTypes.arrayOf(ItemPropType),
            PropTypes.arrayOf(
                PropTypes.oneOfType([
                    ItemPropType,
                    PropTypes.arrayOf(ItemPropType),
                ])
            ),
        ]),
        isPage: PropTypes.bool,
        spinnerSize: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.fetchedActions = this.props.scenario
            .flat()
            .reduce(
                (res, item) =>
                    ({...res, [item.actionId]: this.props[item.loadId]}),
                {}
            );
    }

    componentDidMount() {
        this.runRequests();
    }

    componentDidUpdate() {
        this.runRequests();
    }

    makeRequest = (item) => {
        const action = this.props[item.actionId];
        const params = item.getActionParams && item.getActionParams(this.props.storageData);

        action(...(params || []));
        this.fetchedActions[item.actionId] = true;
    };

    runRequests = () => {
        const {scenario} = this.props;

        for(const part of scenario) {
            if (!Array.isArray(part)) {
                if (!this.fetchedActions[part.actionId]) {
                    this.makeRequest(part);
                }
                continue;
            }

            for (const item of part) {
                if (!this.fetchedActions[item.actionId]) {
                    this.makeRequest(item);
                    break;
                }
            }
        }
    };

    isLoaded = () => {
        return this.props.scenario
            .flat()
            .reduce(
                (res, item) =>
                    res && this.props[item.loadId],
                true
            )
    };

    filterPropsFromLocalProps = () => {
        const {scenario} = this.props;
        const keys = [
            'WrappedComponent',
            'scenario',
            'isPage',
            'spinnerSize',
            ...scenario
                .flat()
                .map(item => [item.stateId, item.actionId])
                .flat()
        ];

        return Object.keys(this.props)
            .filter(key => keys.indexOf(key) === -1)
            .reduce(
                (res, key) => ({...res, [key]: this.props[key]}),
                {}
            );
    };

    render() {
        const {WrappedComponent} = this.props;

        return(
            this.isLoaded() ?
                WrappedComponent ?
                    <WrappedComponent {...this.filterPropsFromLocalProps()}/>
                :
                    <>
                        {this.props.children}
                    </>
            :
                <Loading isPage={this.props.isPage}
                         size={this.props.isPage ? undefined : this.props.spinnerSize}
                />
        );
    }
}

export default connect(
    (state, props) => {
        return props.scenario
            .flat()
            .reduce(
                (res, item) =>
                    ({
                        ...res,
                        [item.stateId]: item.getState(state),
                        [item.loadId]: item.isLoaded(state),
                    }),
                {storageData: state}
            )
    },
    (dispatch, props) => {
        return props.scenario
            .flat()
            .reduce(
                (res, item) =>
                    ({...res, [item.actionId]: item.getAction(dispatch)}),
                {}
            )
    },
)(Core);
