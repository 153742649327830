import {ISpecificForm} from '../../../FormField/types';
import {IProps} from './types';

import React, {
    forwardRef,
    useContext,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialSelect} from 'components/Helpers/MaterialUI';

import {context} from '../../../../Provider';
import {formatFormTitle} from '../../functions';
import {formatValue} from '../functions';

import {ERROR} from './constants';
import {formatError} from './functions';

const ContractField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [error, setError] = useState<ERROR | null>(null);

    const {contracts} = useContext(context);

    const onChange = (id: string) => {
        const contract = contracts?.find((contract) => contract.id === id) || null;

        props.onChange(props.field.name, contract);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    if (!props.field.value) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <MaterialSelect
            label={formatFormTitle(props.field, props.translation)}
            value={props.field.value?.id || ''}
            values={contracts?.map((contract) => contract.id)}
            options={contracts?.map((contract) => formatValue(contract)) || []}
            isDisabled={props.field.readOnly}
            isError={!!error}
            helperText={formatError(error, props.intl)}
            onChange={onChange}
        />
    );
});

export default ContractField;
