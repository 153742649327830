import { IReduxAction } from '../types';
import * as NSRedux from './types/redux';

import { ActionTypes } from './constants';
import { setNewActiveVersion } from './functions';


export const initialState: NSRedux.IState = {
    data: [],
    error: null,
    activeAreLoaded: false,
    loadedServicesIds: [],
};

export default function(state: NSRedux.IState = initialState, action: IReduxAction): NSRedux.IState {
    switch (action.type) {
        case ActionTypes.GetActiveServicesVersionsSucceed: {
            const {versions, error} = <NSRedux.IGetActiveSucceed>action;

            return error ?
                {
                    ...state,
                    error,
                    activeAreLoaded: true,
                } :
                {
                    ...state,
                    error: null,
                    activeAreLoaded: true,
                    data: versions!,
                };
        }
        case ActionTypes.PostActiveServiceVersionSucceed: {
            const {version, error} = <NSRedux.IPostSucceed>action;

            return error ?
                {
                    ...state,
                    error,
                } :
                {
                    ...state,
                    data: [
                        ...state.data,
                        version!,
                    ],
                };
        }
        case ActionTypes.GetServiceVersionsByServiceSucceed: {
            const {id, versions, error} = <NSRedux.IGetByServiceSucceed>action;

            return error ?
                {
                    ...state,
                    error,
                } :
                {
                    ...state,
                    error: null,
                    data: [
                        ...state.data,
                        ...versions!,
                    ],
                    loadedServicesIds: [
                        ...state.loadedServicesIds,
                        id,
                    ],
                };
        }
        case ActionTypes.GetServiceVersionSucceed: {
            const {version, error} = <NSRedux.IGetOneSucceed>action;
            const versionToUpdate = state.data.find((dataVersion) => dataVersion.id === version?.id);

            return error ?
                {
                    ...state,
                    error,
                } :
                {
                    ...state,
                    data: [
                        ...state.data.filter((dataVersion) => dataVersion.id !== version!.id),
                        {
                            ...versionToUpdate,
                            ...version!,
                        },
                    ],
                };
        }
        case ActionTypes.PatchServiceVersionSucceed: {
            const {version, error} = <NSRedux.IPatchSucceed>action;

            return error ?
                {
                    ...state,
                    error,
                } :
                {
                    ...state,
                    data: [
                        ...state.data.filter((dataVersion) => dataVersion.id !== version!.id),
                        version!,
                    ],
                };
        }
        case ActionTypes.SetActiveServiceVersionSucceed: {
            const {id, error} = <NSRedux.ISetActiveSucceed>action;

            return error ?
                {
                    ...state,
                    error,
                } :
                {
                    ...state,
                    data: setNewActiveVersion(id, state.data),
                };
        }
        default:
            return state;
    }
}
