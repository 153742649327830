import React, {ReactElement} from 'react';

export default function(value: string): ReactElement[] | null {
    const URLRe = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;
    const URLMatches = [...value.matchAll(URLRe)];
    const tryDate = new Date(value.replace(/\./g, '/'));

    if (!URLMatches.length || !isNaN(tryDate.getTime())) {
        return null;
    }

    const res: JSX.Element[] = [];
    let position = 0;
    let key = 0;

    URLMatches.forEach((match) => {
        let url = match[0];
        const index = match.index;

        if (index === undefined) {
            return;
        }

        const newPosition = index + url.length;

        if (url.slice(0, 8) !== 'https://' && url.slice(0, 7) !== 'http://') {
            url = 'https://' + url;
        }

        res.push(
            <span key={key}>
                {value.slice(position, index)}
            </span>
        );
        key += 1;
        res.push(
            <a href={url} target={'_blank'} key={key} rel={'noreferrer'}>
                {url}
            </a>
        );
        key += 1;

        position = newPosition;
    });

    res.push(
        <span key={key}>
            {value.slice(position)}
        </span>
    );

    return res;
}
