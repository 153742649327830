import {IValue} from './types';

import {DA_DATA_TYPE} from './constants';

export function formatValue(value: IValue, type: DA_DATA_TYPE): string {
    if (!value) {
        return '';
    }

    switch (type) {
        case DA_DATA_TYPE.ADDRESS: {
            return value.input;
        }
        case DA_DATA_TYPE.REGION: {
            return value.data?.region_with_type;
        }
        case DA_DATA_TYPE.COMPANY: {
            return `${value.input} ${value.data?.inn || ''}`;
        }
    }

    return value.input;
}
