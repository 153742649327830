import {
    IDownloadExcelByUserQuery,
    IGetDemoByUserQuery,
    IGetOneFullByUserQuery,
    IGetSuggestionsQuery,
} from './types/requests';
import {
    IDownloadExcelByUser,
    IGetDemoByUser,
    IGetOneFullByUser,
    IGetSuggestionsByUser,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {onLogout} from 'instances/auth/functions';

import {
    DOWNLOADED_USER_SERVICES_EXCEL_FILE,
    INSTANCE_PATH,
} from './constants';

export async function getUserServicesByUser(query: IGetDemoByUserQuery): Promise<IGetDemoByUser> {
    return new Fetch<IGetDemoByUser>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .exec();
}

export async function getOneFullUserServiceByUser(id: string, query: IGetOneFullByUserQuery): Promise<IGetOneFullByUser> {
    return new Fetch<IGetOneFullByUser>({
        url: `${config.URL}${INSTANCE_PATH}/${id}`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .exec();
}

export async function getDownloadExcelByUser(query: IDownloadExcelByUserQuery): Promise<IDownloadExcelByUser> {
    return new Fetch<IDownloadExcelByUser>({
        url: `${config.URL}${INSTANCE_PATH}/download`,
        method: 'GET',
        query,
    })
        .onDownload([200], DOWNLOADED_USER_SERVICES_EXCEL_FILE)
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getSuggestionsByUser(query: IGetSuggestionsQuery): Promise<IGetSuggestionsByUser> {
    return new Fetch<IGetSuggestionsByUser>({
        url: `${config.URL}${INSTANCE_PATH}/suggestions`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}
