import {IProps} from './types';

import React, {
    FC,
    useState,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../../helpers';
import {INTL_DATA} from '../../../intl';

import styles from './Values.module.scss';

const Values: FC<IProps> = (props) => {
    const [newValue, setNewValue] = useState('');

    const addValue = () => {
        props.onAdd(newValue);
        setNewValue('');
    };

    const isUniqueNewValue = !props.values.includes(newValue);

    return (
        <>
            <div className={`overflow-y-scroll ${styles.values}`}>
                {
                    props.values.map((value, i) =>
                        <div key={value} className={'list-group-item'}>
                            <div className={'d-flex flex-row justify-content-between'}>
                                <label className={'col-form-label'}>
                                    {value}
                                </label>
                                <div className={'d-flex flex-row align-items-center'}>
                                    <MaterialCheckbox
                                        value={props.value === value}
                                        onChange={() => props.onSetDefault(value)}
                                    />
                                    <button
                                        className={'btn btn-sm btn-danger'}
                                        onClick={() => props.onRemove(i)}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className={'d-flex flex-row justify-content-center mt-2'}>
                <div className={'m-1'}>
                    <input
                        className={'form-control'}
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                    />
                </div>
                <div className={'d-flex flex-row align-items-center m-1'}>
                    <button
                        className={'btn btn-sm btn-secondary'}
                        disabled={!isUniqueNewValue}
                        onClick={addValue}
                    >
                        &#10010;
                    </button>
                </div>
            </div>
            <div className={'d-flex flex-row justify-content-center'}>
                {
                    !isUniqueNewValue &&
                    <span className={'text-danger'}>
                        {translateByIntl(props.intl, INTL_DATA.MUST_BE_UNIQUE, 'Must be unique')}
                    </span>
                }
            </div>
        </>
    );
};

export default Values;
