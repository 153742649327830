export const INSTANCE_PATH = '/basket_services';

export enum ACTION_TYPES {
    GET_BASKET_SERVICES = 'GET_BASKET_SERVICES',

    GET_BASKET_SERVICE = 'GET_BASKET_SERVICE',

    POST_BASKET_SERVICE = 'POST_BASKET_SERVICE',
    PATCH_BASKET_SERVICE = 'PATCH_BASKET_SERVICE',
    PATCH_BY_USER = 'PATCH_BY_USER',
    REMOVE_BASKET_SERVICE = 'REMOVE_BASKET_SERVICE',

    LISTEN_BASKET_SERVICE_INSERT = 'LISTEN_BASKET_SERVICE_INSERT',
    LISTEN_BASKET_SERVICE_UPDATE = 'LISTEN_BASKET_SERVICE_UPDATE',
}

export enum NORM_MODE {
    USER_DEMO = 'USER_DEMO',
    USER_FULL = 'USER_FULL',
}

export enum BID_TYPE {
    INSTALL = 'INSTALL',
    UPGRADE = 'UPGRADE',
    CHANGE = 'CHANGE',
    CLOSE = 'CLOSE',
    SUPPL = 'SUPPL',
    CLAIM = 'CLAIM',
    SUSPEND = 'SUSPEND',
    RESTORE = 'RESTORE',
}

export enum STATUS {
    READY_TO_SEND = 'READY_TO_SEND',
    IS_BEING_EDITED = 'IS_BEING_EDITED',
    FROZEN = 'FROZEN',
}

export enum NOTIFICATION_ERROR {
    PERMISSION_DENIED = 'PERMISSION_DENIED',
}
