import {useIntl} from 'helpers/hooks';
import {getLabelByType} from './functions';
import {IProps} from './types';
import React, {FC, useId} from 'react';

const BoostedInput: FC<IProps> = (props) => {
    const id = useId();
    const intl = useIntl();

    return (
        <div>
            <label htmlFor={id} className="form-label">
                {getLabelByType(props.type, intl)}
            </label>
            <input
                className={'form-control'}
                id={id}
                placeholder={props.placeHolder ?? ''}
                value={props.value}
                disabled={true}
            />
        </div>
    )
}

export default BoostedInput