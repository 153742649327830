import {IProps} from './types';

import React, {FC} from 'react';
import {Droppable} from 'react-beautiful-dnd';

import Field from '../ApplicationField';

import {DROPPABLE_TYPE} from '../constants';

const FieldsContainer: FC<IProps> = (props) => {
    const getStyles = (isDraggingOver: boolean) => {
        return {
            background: isDraggingOver ? 'lightblue' : 'lightgrey',
            padding: 8,
            width: '100%',
            zIndex: 10,
            minHeight: 100,
        };
    };

    return (
        <Droppable type={DROPPABLE_TYPE.FIELDS_CONTAINER} droppableId={props.droppableId}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getStyles(snapshot.isDraggingOver)}>
                    {
                        props.fields
                            .sort((a, b) => (a[props.fieldApplicationPositionKey]?.row || 0) - (b[props.fieldApplicationPositionKey]?.row || 0))
                            .map(
                                (field, i) =>
                                    <Field
                                        key={field.name}
                                        index={i}
                                        field={field}
                                        intl={props.intl}
                                    />
                            )
                    }
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default FieldsContainer;
