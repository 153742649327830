import {
    CrumbsForNotifications,
    NotificationsFilters,
} from 'instances/notifications/types';

import {IIntl} from 'helpers/hooks';

import {
    SORT_BY,
    STATUS,
} from 'instances/notifications/constants';

import {INTL_STATUS} from './intl';

export const translateNotificationStatus = (status: string | null, translate: IIntl) => {
    switch (status) {
        case STATUS.NEW: {
            return translate(INTL_STATUS.UNREADED);
        }
        case STATUS.SAW: {
            return translate(INTL_STATUS.READED);
        }
        case STATUS.OVERDUE: {
            return translate(INTL_STATUS.EXPIRED);
        }
        case STATUS.IGNORE: {
            return translate(INTL_STATUS.IGNORE);
        }
        default: {
            return '';
        }
    }
};

export const normalizeNotificationDate = (date: string) => {
    const dataIzDatenew = new Date(date);

    const timeString = dataIzDatenew.toLocaleTimeString('ru-RU', {
        hour: '2-digit',
        minute: '2-digit',
    });

    const dateString = dataIzDatenew.toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    return `${dateString} ${timeString}`;
};

export const defaultFilters: NotificationsFilters = {
    skip: 0,
    limit: 100,
    sortBy: SORT_BY.DATE_SENT,
    sortOrder: false,
    locale: 'ru',
    notificationId: [],
    city: [],
    dateSentFrom: [],
    dateSentTo: [],
    dateStartFrom: [],
    dateStartTo: [],
    dateEndFrom: [],
    dateEndTo: [],
    status: [],
    workId: [],
    affectedContracts: [],
    SVID: [],
    CID: [],
    serviceName: [],
    serviceAddress: [],
    contractNumber: [],
};

export function getCrumbsByFilters(filters: NotificationsFilters | null): [string, string[]][] {
    const result: CrumbsForNotifications = {
        notificationId: [],
        city: [],
        dateSentFrom: [],
        dateSentTo: [],
        dateStartFrom: [],
        dateStartTo: [],
        dateEndFrom: [],
        dateEndTo: [],
        status: [],
        workId: [],
        affectedContracts: [],
        SVID: [],
        CID: [],
        serviceName: [],
        serviceAddress: [],
        contractNumber: [],
    };

    if (!filters) return [];

    for (const key in filters) {
        switch (key) {
            case 'city': {
                filters['city'].length &&
                typeof filters['city'] === 'string' ?
                    result['city'] = [filters['city']] :
                    filters['city']
                        .forEach((elem) => result['city'].push(elem));
                break;
            }
            case 'clientId': {
                filters['clientId'].length &&
                typeof filters['clientId'] === 'string' ?
                    result['clientId'] = [filters['clientId']] :
                    filters['clientId']
                        .forEach((elem) => result['clientId'].push(elem));
                break;
            }
            case 'contractNumber': {
                filters['contractNumber'].length &&
                typeof filters['contractNumber'] === 'string' ?
                    result['contractNumber'] = [filters['contractNumber']] :
                    filters['contractNumber']
                        .forEach((elem) => result['contractNumber'].push(elem));
                break;
            }
            case 'dateSentFrom': {
                filters['dateSentFrom'].length &&
                typeof filters['dateSentFrom'] === 'string' ?
                    result['dateSentFrom'] = [filters['dateSentFrom']] :
                    filters['dateSentFrom']
                        .forEach((elem) => result['dateSentFrom'].push(elem));
                break;
            }
            case 'dateSentTo': {
                filters['dateSentTo'].length &&
                typeof filters['dateSentTo'] === 'string' ?
                    result['dateSentTo'] = [filters['dateSentTo']] :
                    filters['dateSentTo']
                        .forEach((elem) => result['dateSentTo'].push(elem));
                break;
            }
            case 'dateStartFrom': {
                filters['dateStartFrom'].length &&
                typeof filters['dateStartFrom'] === 'string' ?
                    result['dateStartFrom'] = [filters['dateStartFrom']] :
                    filters['dateStartFrom']
                        .forEach((elem) => result['dateStartFrom'].push(elem));
                break;
            }
            case 'dateStartTo': {
                filters['dateStartTo'].length &&
                typeof filters['dateStartTo'] === 'string' ?
                    result['dateStartTo'] = [filters['dateStartTo']] :
                    filters['dateStartTo']
                        .forEach((elem) => result['dateStartTo'].push(elem));
                break;
            }
            case 'dateEndFrom': {
                filters['dateEndFrom'].length &&
                typeof filters['dateEndFrom'] === 'string' ?
                    result['dateEndFrom'] = [filters['dateEndFrom']] :
                    filters['dateEndFrom']
                        .forEach((elem) => result['dateEndFrom'].push(elem));
                break;
            }
            case 'dateEndTo': {
                filters['dateEndTo'].length &&
                typeof filters['dateEndTo'] === 'string' ?
                    result['dateEndTo'] = [filters['dateEndTo']] :
                    filters['dateEndTo']
                        .forEach((elem) => result['dateEndTo'].push(elem));
                break;
            }
            case 'notificationBody': {
                filters['notificationBody'].length &&
                typeof filters['notificationBody'] === 'string' ?
                    result['notificationBody'] = [filters['notificationBody']] :
                    filters['notificationBody']
                        .forEach((elem) => result['notificationBody'].push(elem));
                break;
            }
            case 'notificationId': {
                filters['notificationId'].length &&
                typeof filters['notificationId'] === 'string' ?
                    result['notificationId'] = [filters['notificationId']] :
                    filters['notificationId']
                        .forEach((elem) => result['notificationId'].push(elem));
                break;
            }
            case 'servOut': {
                filters['servOut'].length &&
                typeof filters['servOut'] === 'string' ?
                    result['servOut'] = [filters['servOut']] :
                    filters['servOut']
                        .forEach((elem) => result['servOut'].push(elem));
                break;
            }
            case 'type': {
                filters['type'].length &&
                typeof filters['type'] === 'string' ?
                    result['type'] = [filters['type']] :
                    filters['type']
                        .forEach((elem) => result['type'].push(elem));
                break;
            }
            case 'workId': {
                filters['workId'].length &&
                typeof filters['workId'] === 'string' ?
                    result['workId'] = [filters['workId']] :
                    filters['workId']
                        .forEach((elem) => result['workId'].push(elem));
                break;
            }
            case 'status': {
                filters['status'].length &&
                typeof filters['status'] === 'string' ?
                    result['status'] = [filters['status']] :
                    filters['status']
                        .forEach((elem) => result['status'].push(elem));
                break;
            }
            case 'duration': {
                filters['duration'].length &&
                typeof filters['duration'] === 'string' ?
                    result['duration'] = [filters['duration']] :
                    filters['duration']
                        .forEach((elem) => result['duration'].push(elem));
                break;
            }
            case 'affectedContracts': {
                filters['affectedContracts'].length &&
                typeof filters['affectedContracts'] === 'string' ?
                    result['affectedContracts'] = [filters['affectedContracts']] :
                    filters['affectedContracts']
                        .forEach((elem) => result['affectedContracts'].push(elem));
                break;
            }
            case 'SVID': {
                filters['SVID'].length &&
                typeof filters['SVID'] === 'string' ?
                    result['SVID'] = [filters['SVID']] :
                    filters['SVID']
                        .forEach((elem) => result['SVID'].push(elem));
                break;
            }
            case 'CID': {
                filters['CID'].length &&
                typeof filters['CID'] === 'string' ?
                    result['CID'] = [filters['CID']] :
                    filters['CID']
                        .forEach((elem) => result['CID'].push(elem));
                break;
            }
            case 'serviceName': {
                filters['serviceName'].length &&
                typeof filters['serviceName'] === 'string' ?
                    result['serviceName'] = [filters['serviceName']] :
                    filters['serviceName']
                        .forEach((elem) => result['serviceName'].push(elem));
                break;
            }
            case 'serviceAddress': {
                filters['serviceAddress'].length &&
                typeof filters['serviceAddress'] === 'string' ?
                    result['serviceAddress'] = [filters['serviceAddress']] :
                    filters['serviceAddress']
                        .forEach((elem) => result['serviceAddress'].push(elem));
                break;
            }
            default: {
                break;
            }
        }
    }

    return Object.entries(result).filter(([_, item]) => item.length);
}
