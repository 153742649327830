import {
    IGetByAdminQuery,
    IGetByClientQuery,
} from 'instances/equippedUsers/types/requests';

import Query from 'tools/Query';

import {IDENTIFIER_TYPE} from 'instances/equippedUsers/constants';
import {
    getDefaultQueryAdmin,
    getDefaultQueryClient,
} from 'instances/equippedUsers/functions';

export function parseClientQuery(query: string): IGetByClientQuery {
    return Query.parse(getDefaultQueryClient, query, {
        skip: {
            type: 'number',
        },
        limit: {
            type: 'number',
        },
        identifier: {
            type: 'string',
        },
        identifierType: {
            type: 'string[]',
            enum: Object.values(IDENTIFIER_TYPE),
        },
        serviceType: {
            type: 'string',
        },
        orderBlankNumber: {
            type: 'string',
        },
        address: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        contractNumber: {
            type: 'string',
        },
        pdSentAt: {
            type: 'date',
        },
    });
}

export function parseAdminQuery(query: string): IGetByAdminQuery {
    return Query.parse(getDefaultQueryAdmin, query, {
        skip: {
            type: 'number',
        },
        limit: {
            type: 'number',
        },
        identifier: {
            type: 'string',
        },
        identifierType: {
            type: 'string[]',
            enum: Object.values(IDENTIFIER_TYPE),
        },
        serviceType: {
            type: 'string',
        },
        orderBlankNumber: {
            type: 'string',
        },
        address: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        contractNumber: {
            type: 'string',
        },
        pdSentAt: {
            type: 'date',
        },
    });
}
