export const INSTANCE_PATH = '/users_sites';

export const LS_USER_SITES_FILTER = 'LS_USER_SITES_FILTER';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ActionTypes {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GetUserSites = 'GET_USER_SITES',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GetUserSitesSucceed = 'GET_USER_SITES_SUCCEED',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GetUserSitesOnFilter = 'GET_USER_SITES_ON_FILTER',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GetUserSitesOnFilterSucceed = 'GET_USER_SITES_ON_FILTER_SUCCEED',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    PatchUserSite = 'PATCH_USER_SITE',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    PatchUserSiteSucceed = 'PATCH_USER_SITE_SUCCEED',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ResetUserSiteConflictError = 'RESET_USER_SITE_CONFLICT_ERROR',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GetUserSiteOne = 'GET_USER_SITE_ONE',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GetUserSiteOneSucceed = 'GET_USER_SITE_ONE_SUCCEED',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ChangeSitesFilters = 'CHANGE_SITES_FILTERS',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ChangeSiteFields = 'CHANGE_SITE_FIELDS',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GetMapData = 'GET_MAP_DATA',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GetMapDataSucceed = 'GET_MAP_DATA_SUCCEED',
}

export enum DEFAULT_FIELDS_NAMES {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Address = 'address',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    CustomerRef = 'customer_ref',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    City = 'city',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Status = 'status',
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum Statuses {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Active = 'Active',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Inactive = 'Inactive',
}

export enum SORT_BY {
    ADDRESS = 'address',
    CUSTOMER_SITE_REF = 'customerSiteRef',
    SITE_ID = 'siteId',
    CITY = 'city',
}

export enum NORM_MODE {
    USER_DEMO = 'DEMO',
    USER_FULL = 'FULL',
}

export enum STATUS {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
}

export enum RESPONSE_ERROR {
    NOT_FOUND = 'NOT_FOUND',
    NOTHING_TO_NORMALIZE = 'NOTHING_TO_NORMALIZE',
    CUSTOMER_SITE_REF_NOT_FOUND = 'CUSTOMER_SITE_REF_NOT_FOUND',
    DUPLICATE = 'DUPLICATE',
    AUTH_NOT_FOUND = 'AUTH_NOT_FOUND',
    PERMISSION_DENIED = 'PERMISSION_DENIED',
}

export enum RESPONSE_ERROR_TEXT {
    DUPLICATE = 'Невозможно сохранить введенный идентификатор сайта, потому что он дублирует уже существующий. Клиентский идентификатор сайта должен быть уникален в масштабе всей организациии.',

}

export const DOWNLOADED_USER_SITES_EXCEL_FILE = 'Мои сайты.xlsx';
