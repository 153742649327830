import {
    useEffect,
    useState,
} from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import Query from 'tools/Query';

type IDataGenerator<IQueryData> = () => IQueryData;
type IQueryParser<IQueryData> = (queryString: string) => IQueryData;
type IResult<IQueryData> = [IQueryData | null, (data: IQueryData) => void];

export default function <IQueryData>(dataGenerator: IDataGenerator<IQueryData>, queryParser: IQueryParser<IQueryData>): IResult<IQueryData> {
    const [filters, setFilters] = useState<IQueryData | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const filters = queryParser(location.search);

        setFilters(filters);
    }, []);
    useEffect(() => {
        if (!filters) {
            return;
        }

        navigate({search: Query.stringify(filters)});
    }, [filters]);

    return [filters, setFilters];
}
