import {ISpecificForm} from '../../../FormField/types';
import {IProps} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {MaterialSelect} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../../helpers';
import {formatFormTitle} from '../../functions';

import {ERROR} from './constants';
import {formatError} from './functions';

const SelectField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (value: string) => {
        props.onChange(props.field.name, value);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    if (!props.field.value) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <MaterialSelect
            label={formatFormTitle(props.field, props.translation)}
            value={props.field.value}
            options={props.field.values.map((value) => translateByObject(value, props.translation))}
            values={props.field.values}
            isDisabled={props.field.readOnly}
            isError={!!error}
            helperText={formatError(error, props.intl)}
            onChange={onChange}
        />
    );
});

export default SelectField;
