import React, { FC, useEffect, useState } from 'react';

import { useLoadingFiles } from 'instances/loadingFiles/hooks';

import { Loading } from 'components/Helpers/Other';

import classNames from 'classnames';
import styles from './LoadingFilesPanel.module.scss';


const LoadingFilesPanel: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHide, setIsHide] = useState(false);

    const {items} = useLoadingFiles();

    useEffect(() => {
        if (items.length) {
            setIsOpen(true);
            setIsHide(true);
        } else {
            setIsOpen(false);
            setIsHide(false);
        }
    }, [items]);

    if (!isOpen) {
        return null;
    }

    return(
        <div className={classNames(
            'border border-dark border-bottom-0',
            styles.loadingPanel,
            )}>
            <div className={classNames(
                'd-flex flex-row justify-content-between p-1',
                styles.top,
                )}>
                <div>
                    Очередь на скачивание
                </div>
                <div>
                    <div className='d-flex flex-row justify-content-end'>
                        <div className={styles.topButton} onClick={() => setIsHide(!isHide)}>
                            {
                                isHide ?
                                    <span>&#65343;</span>
                                    :
                                    <span>&#x26F6;</span>
                            }
                        </div>
                        <div className={styles.topButton} onClick={() => setIsOpen(false)}>
                            &#x2715;
                        </div>
                    </div>
                </div>
            </div>
            {
                isHide &&
                <div className={classNames('p-2', styles.items)}>
                    {
                        items.map(
                            (item) =>
                                <div key={item.id} className={styles.item}>
                                    <div className='row pt-1 pb-1'>
                                        <div
                                            className={classNames('col-9', styles.itemText)}
                                            title={`${item.text}`}
                                        >
                                            {item.text}
                                        </div>
                                        <div className='col-3'>
                                            <div className='d-flex flex-row justify-content-end align-items-center'>
                                                <Loading size={30}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        )
                    }
                </div>
            }
        </div>
    );
};

export default LoadingFilesPanel;
