import {IProps} from './types';

import React, {FC} from 'react';

import {
    MaterialCheckbox,
    MaterialInput,
    MaterialMultipleSelect,
} from 'components/Helpers/MaterialUI';

import {translateByIntl} from '../../../../../../helpers';
import {
    MIGRATE_TO,
    SERVICE_SELECTION_TYPE,
} from '../../../constants';
import {formatServiceStatus} from '../../../functions';
import {INTL_DATA} from '../../../intl';

const Filters: FC<IProps> = (props) => {
    return (
        <div className={'bg-light mt-1 mb-1 pl-2 pr-2'}>
            <div className={'row'}>
                <div className={'d-flex col-3 flex-row align-items-center'}>
                    <div className={'mr-2'}>
                        <MaterialCheckbox
                            value={props.isChosenAll}
                            isDisabled={props.serviceSelectionType === SERVICE_SELECTION_TYPE.FROZEN_ALL}
                            onChange={props.onChooseAll}
                        />
                    </div>
                    <MaterialInput
                        label={translateByIntl(props.intl, INTL_DATA.USER_SERVICES_TYPE, 'Type')}
                        value={props.filters.type[0]}
                        onChange={(type) => props.onChange({
                            ...props.filters,
                            type: [type],
                        })}
                    />
                </div>
                <div className={'col-1'}>
                    <MaterialInput
                        label={'SVID'}
                        value={props.filters.SVID[0]}
                        onChange={(SVID) => props.onChange({
                            ...props.filters,
                            SVID: [SVID],
                        })}
                    />
                </div>
                <div className={'col-2'}>
                    <MaterialInput
                        label={translateByIntl(props.intl, INTL_DATA.USER_SERVICES_ORDER_BLANK_NUMBER, 'Addendum number')}
                        value={props.filters.orderAddendumNumber[0]}
                        onChange={(orderAddendumNumber) => props.onChange({
                            ...props.filters,
                            orderAddendumNumber: [orderAddendumNumber],
                        })}
                    />
                </div>
                {
                    (
                        props.migrateTo === MIGRATE_TO.BILL ||
                        props.migrateTo === MIGRATE_TO.CONTRACT
                    ) &&
                    <div className={'col-sm-6 col-md-3'}>
                        <MaterialInput
                            label={translateByIntl(props.intl, INTL_DATA.USER_SERVICES_ADDRESS, 'Address')}
                            value={props.filters.siteAddress[0]}
                            onChange={(siteAddress) => props.onChange({
                                ...props.filters,
                                siteAddress: [siteAddress],
                            })}
                        />
                    </div>
                }
                {
                    props.migrateTo === MIGRATE_TO.SITE &&
                    <>
                        <div className={'col-2'}>
                            <MaterialInput
                                label={translateByIntl(props.intl, INTL_DATA.USER_SERVICES_CONTRACT, 'Contract')}
                                value={props.filters.contractNumber[0]}
                                onChange={(contractNumber) => props.onChange({
                                    ...props.filters,
                                    contractNumber: [contractNumber],
                                })}
                            />
                        </div>
                        <div className={'col-2'}>
                            <MaterialInput
                                label={translateByIntl(props.intl, INTL_DATA.USER_SERVICES_SITE_ADDRESS, 'Clients\'s Site')}
                                value={props.filters.customerUserSiteRef[0]}
                                onChange={(customerUserSiteRef) => props.onChange({
                                    ...props.filters,
                                    customerUserSiteRef: [customerUserSiteRef],
                                })}
                            />
                        </div>
                    </>
                }
                <div className={'col-2'}>
                    <MaterialMultipleSelect
                        label={translateByIntl(props.intl, INTL_DATA.USER_SERVICES_STATUS, 'Status')}
                        values={props.statuses}
                        options={props.statuses.map((status) => formatServiceStatus(status, props.intl))}
                        value={props.filters.status}
                        onChange={(status) => props.onChange({
                            ...props.filters,
                            status,
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

export default Filters;
