import {ISpecificForm} from '../../../FormField/types';
import {IValue} from '../types';
import {IProps} from './types';

import {OpenStreetMapProvider} from 'leaflet-geosearch';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {InputAutoSuggest} from 'components/Helpers/Inputs';

import {formatFormTitle} from '../../functions';
import {formatValue} from '../functions';

import {
    ERROR,
    FETCH_SUGGESTIONS_DELAY,
} from './constants';
import {
    formatError,
    getSuggestions,
} from './functions';

const GeoField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [value, setValue] = useState(formatValue(props.field.value));
    const [provider] = useState(new OpenStreetMapProvider());
    const [suggestions, setSuggestions] = useState<IValue[]>([]);
    const [error, setError] = useState<ERROR | null>(null);
    const [delayTimeoutId, setDelayTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const onChange = (value: string) => {
        setValue(value);

        const suggestion = suggestions.find((suggestion) => suggestion?.name === value);

        props.onChange(props.field.name, suggestion || null);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    if (!props.field.value) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                }

                setError(null);

                return true;
            },
        };
    });
    useEffect(() => {
        if (delayTimeoutId !== null) {
            clearTimeout(delayTimeoutId);
        }
        if (!value) {
            return;
        }

        const id = setTimeout(async () => {
            const suggestions = await getSuggestions(provider, value);
            const suggestion = suggestions.find((suggestion) => suggestion?.name === value);

            if (suggestion) {
                if (suggestion.name !== props.field.value?.name) {
                    props.onChange(props.field.name, suggestion);
                }
            }

            setSuggestions(suggestions);
            setDelayTimeoutId(null);
        }, FETCH_SUGGESTIONS_DELAY);

        setDelayTimeoutId(id);

        return () => {
            if (delayTimeoutId === null) {
                return;
            }

            clearTimeout(delayTimeoutId);
        };
    }, [value]);

    return (
        <InputAutoSuggest
            label={formatFormTitle(props.field, props.translation)}
            value={value}
            suggestions={suggestions.map((suggestion) => formatValue(suggestion))}
            onChange={onChange}
            error={formatError(error, props.intl)}
        />
    );
});

export default GeoField;
