import {IContractUser} from './types';

import {IIntl} from 'helpers/hooks';

import {INTL_DATA} from 'instances/contracts/intl';

import {STATUS} from './constants';

export function getOriginalContractNumber(contract: IContractUser): string {
    function lastSymbolIsCapitalizedAlpha(str: string): boolean {
        return !!str.length && /[A-Z]/.test(str.slice(-1));
    }

    return lastSymbolIsCapitalizedAlpha(contract.number) ?
        contract.number.slice(0, -1) :
        contract.number;
}

export function getContractsByContractBill(contract: IContractUser, contracts: IContractUser[]): IContractUser[] {
    return contracts.filter((bill) => getOriginalContractNumber(bill) === getOriginalContractNumber(contract));
}

export function getUniqueContractsWithoutBills(contracts: IContractUser[]): IContractUser[] {
    const contractsNumbers = contracts
        .map((contract) => getOriginalContractNumber(contract));
    const existedContractsNumbers = contractsNumbers
        .filter((contractNumber) => contracts.find((contract) => contract.number === contractNumber));
    const uniqueExistedContractsNumbers = [...new Set(existedContractsNumbers)];
    const returnArrayOfContracts: IContractUser[] = [];

    uniqueExistedContractsNumbers
        .forEach((contractNumber) => {
            const contract = contracts.find((contract) => contract.number === contractNumber);

            if (contract) {
                returnArrayOfContracts.push(contract);
            }
        });

    return returnArrayOfContracts;
}

export function translateStatus(status: STATUS, translate: IIntl): string {
    switch (status) {
        case STATUS.NEW: {
            return translate(INTL_DATA.STATUS_NEW);
        }
        case STATUS.ACTIVE: {
            return translate(INTL_DATA.STATUS_ACTIVE);
        }
    }
}
