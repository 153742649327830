import {IRef} from './types';

import {useRef} from 'react';

import {ERROR} from './constants';

export function useOrangeInputRef() {
    return useRef<IRef>({
        getValue(): string {
            return '';
        },
        setValue(): void {
            return;
        },
        clearValue(): void {
            return;
        },
        setError(): void {
            return;
        },
        async validate(): Promise<boolean> {
            return false;
        },
    });
}

export function getDefaultTranslation(message: ERROR) {
    switch (message) {
        case ERROR.ERROR_REQUIRED: {
            return ERROR.ERROR_REQUIRED;
        }
        case ERROR.ERROR_FORMAT: {
            return ERROR.ERROR_FORMAT;
        }
    }
}

export function symbolRegexpToFullRegexp(regexp: RegExp) {
    return new RegExp(`^${regexp.source}*$`);
}
