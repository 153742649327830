import {IAuth} from 'instances/auth/types';

import {
    hasAdminRoutePermission,
    hasRoutePermission,
} from 'instances/auth/functions';

import {
    ADMIN_ROUTE,
    ROUTE,
} from 'components/constants';

export function hasPageAccess(auth: IAuth, pathname: string): boolean {
    function parsePathName(pathname: string): {route: ROUTE | null; adminRoute: ADMIN_ROUTE | null} {
        const adminRoute = Object
            .values(ADMIN_ROUTE)
            .find((adminRoute) => {
                return pathname.startsWith(adminRoute);
            });

        if (adminRoute) {
            return {
                adminRoute,
                route: null,
            };
        }
        if (pathname === ROUTE.HOME) {
            return {
                route: ROUTE.HOME,
                adminRoute: null,
            };
        }

        const route = Object
            .values(ROUTE)
            .filter((route) => route !== ROUTE.HOME)
            .find((route) => {
                return pathname.startsWith(route);
            });

        if (route) {
            return {
                route,
                adminRoute: null,
            };
        }

        return {
            route: null,
            adminRoute: null,
        };
    }

    const {route, adminRoute} = parsePathName(pathname);

    if (route) {
        return hasRoutePermission(auth, route);
    } else if (adminRoute) {
        return hasAdminRoutePermission(auth, adminRoute);
    } else {
        return false;
    }
}
