import {IRootReduxState} from './types';

import {routerReducer} from 'react-router-redux';
import {combineReducers} from 'redux';

import auth from './auth/reducer';
import basketServices from './basketServices/reducer';
import commonData from './commonData/reducer';
import contracts from './contracts/reducer';
import globalData from './globalData/reducer';
import loadingFiles from './loadingFiles/reducer';
import services from './services/reducer';
import servicesGroups from './servicesGroups/reducer';
import servicesVersions from './servicesVersions/reducer';
import sites from './sites/reducer';

// @ts-ignore
export default combineReducers<IRootReduxState>({
    routing: routerReducer,
    loadingFiles,

    auth,
    basketServices,
    commonData,
    contracts,
    globalData,
    services,
    servicesGroups,
    sites,
    servicesVersions,
});
