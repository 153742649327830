import {IIntl} from '../../../../types';

import {translateByIntl} from '../../../../helpers';

import {ERROR} from './constants';
import {INTL_DATA} from './intl';

export function formatError(error: ERROR, intl: IIntl | null): string {
    switch (error) {
        case ERROR.IS_REQUIRED: {
            return translateByIntl(intl, INTL_DATA.IS_REQUIRED, 'Is required');
        }
    }
}
