import {
    IGetDemoByUserQuery,
    IGetOneFullByUserQuery,
    IPatchCancelByUserBody,
    IPatchCancelByUserQuery,
    IPostClaimByUserBody,
    ISuggestionsQuery,
} from './types/requests';
import {
    IGetDemoByUser,
    IGetOneFullByUser,
    IGetSuggestionsByUser,
    IPatchCancelByUser,
    IPostClaimByUser,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {onLogout} from 'instances/auth/functions';

import {INSTANCE_PATH} from './constants';

export async function getSuggestionsByUser(query: ISuggestionsQuery): Promise<IGetSuggestionsByUser> {
    return new Fetch<IGetSuggestionsByUser>({
        url: `${config.URL}${INSTANCE_PATH}/suggestions`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getDemoByUser(query: IGetDemoByUserQuery): Promise<IGetDemoByUser> {
    return new Fetch<IGetDemoByUser>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([500, 403], (body) => {
            return body;
        })
        .exec();
}

export async function getOneFullByUser(id: string, query: IGetOneFullByUserQuery): Promise<IGetOneFullByUser> {
    return new Fetch<IGetOneFullByUser>({
        url: `${config.URL}${INSTANCE_PATH}/${id}`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postClaimByUser(body: IPostClaimByUserBody): Promise<IPostClaimByUser> {
    return new Fetch<IPostClaimByUser>({
        url: `${config.URL}${INSTANCE_PATH}/claim`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function patchCancelByUser(query: IPatchCancelByUserQuery, body: IPatchCancelByUserBody): Promise<IPatchCancelByUser> {
    return new Fetch<IPatchCancelByUser>({
        url: `${config.URL}${INSTANCE_PATH}/cancel`,
        method: 'PATCH',
        query,
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onLogout();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}
