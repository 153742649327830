import {IProps} from './types';

import ClassNames from 'classnames';
import React, {FC} from 'react';

import {translateByIntl} from '../../../helpers';

import {INTL_DATA} from './intl';

const Footer: FC<IProps> = (props) => {
    const isLastStep = props.currentStep === props.maxStep;

    return (
        <div
            className={
                ClassNames('d-flex', 'flex-row', 'mt-1', {
                    'justify-content-between': props.currentStep !== 1,
                    'justify-content-end': props.currentStep === 1,
                })
            }
        >
            {
                props.currentStep !== 1 &&
                <input
                    type={'button'}
                    className={'btn btn-info'}
                    value={translateByIntl(props.intl, INTL_DATA.BACK, 'Back')}
                    onClick={props.onDecrease}
                />
            }
            {
                props.currentStep !== props.maxStep && !isLastStep &&
                <input
                    type={'button'}
                    className={'btn btn-primary'}
                    value={translateByIntl(props.intl, INTL_DATA.NEXT, 'Next')}
                    onClick={props.onIncrease}
                />
            }
            {
                isLastStep &&
                <input
                    type={'button'}
                    className={'btn btn-primary'}
                    value={translateByIntl(props.intl, INTL_DATA.CONFIRM, 'Confirm')}
                    disabled={props.isPending}
                    onClick={props.onConfirm}
                />
            }
        </div>
    );
};

export default Footer;
