export const INSTANCE_PATH = '/contracts';

export enum ACTION_TYPE {
    GET_CONTRACTS = 'GET_CONTRACTS',
    ADD_CONTRACT = 'ADD_CONTRACT',
    UPDATE_CONTRACT = 'UPDATE_CONTRACT',
    REMOVE_CONTRACT = 'REMOVE_CONTRACT',
}

export enum STATUS {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE'
}
