import {ROUTE} from 'components/constants';
import {hasRoutePermission} from 'instances/auth/functions';
import {IAuth} from 'instances/auth/types';
import {USER_TYPE} from 'instances/users/constants';
import {IServiceVersion, ITranslation} from './types/instance';
import {IField} from 'components/Helpers/FormsEngine/types';

import {DEFAULT_FIELDS_NAMES, NORM_MODES, RESPONSE_ERROR} from './constants';


export function setNewActiveVersion(id: string, versions: IServiceVersion[]): IServiceVersion[] {
    const newActiveIndex = versions.findIndex((version) => version.id === id);
    const oldActiveIndex = versions.findIndex((version) =>
        version.isActive && version.serviceId === versions[newActiveIndex].serviceId
    );

    if (oldActiveIndex === -1) {
        return versions;
    }
    if (newActiveIndex === -1) {
        return versions;
    }

    versions = [...versions];
    versions[oldActiveIndex].isActive = false;
    versions[newActiveIndex].isActive = true;

    return versions;
}

export function findByServiceId(id: string, versions: IServiceVersion[]): IServiceVersion[] {
    return versions.filter((version) => version.serviceId === id);
}

export function findByServiceCode(code: string, versions: IServiceVersion[]): IServiceVersion[] {
    return versions.filter((version) => version.code === code);
}

export function getByServiceAndVersion(serviceId: string, version: string, versions: IServiceVersion[]) {
    return findByServiceId(serviceId, versions).find((item) => item.version === version);
}

export function getSiteField(fields: IField[]): IField | null {
    return fields.find((field) => field.name === DEFAULT_FIELDS_NAMES.SITE) || null;
}

export function hasApplicationMode(version?: IServiceVersion) {
    return version?._mode === NORM_MODES.APPLICATION;
}

export function isDefaultField(field: IField): boolean {
    return Object.values<string>(DEFAULT_FIELDS_NAMES).includes(field.name);
}

export function fieldsToTranslationObject(fields: IField[], translations: ITranslation[], locale: string): Record<string, string> | null {
    const translation = translations.find((translation) => translation.locale === locale);

    if (!translation) {
        return null;
    }

    return fields.reduce((res, field) => {
        const fieldTranslation = translation.fields
            .find((fieldTranslation) => fieldTranslation.name === field.name);

        return {...res, ...(fieldTranslation?.messages || {})}
    }, {});
}

export function isNeedToWatchServiceVersion(auth: IAuth): boolean {
    switch (auth.type) {
        case USER_TYPE.SUPER_ADMIN:
        case USER_TYPE.ORANGE_ADMIN: {
            return false;
        }
        case USER_TYPE.CLIENT_ADMIN: {
            return true;
        }
        case USER_TYPE.SIMPLE_USER: {
            return hasRoutePermission(auth, ROUTE.SERVICES_CATALOG);
        }
    }
}

export function formatServiceVersionsError(error: RESPONSE_ERROR | string): string {
    switch (error) {
        case RESPONSE_ERROR.NOT_FOUND: {
            return 'Service version not found';
        }
        case RESPONSE_ERROR.INVALID_SERVICE_VERSION: {
            return 'Invalid service version';
        }
        case RESPONSE_ERROR.NOTHING_TO_NORMALIZE:
        case RESPONSE_ERROR.CLIENT_NOT_FOUND:
        case RESPONSE_ERROR.ACTIVE_VERSION_DOES_NOT_EXIST:
        case RESPONSE_ERROR.AUTH_NOT_FOUND:
        case RESPONSE_ERROR.INVALID_CODE:
        default:
            return 'Something went wrong';
    }
}