import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    components: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        MuiButton: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    color: '#000000',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    fontSize: '14px',
                },
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    background: '#f16e00',
                    fontSize: '12px',
                    fontWeight: 'bold',
                },
                arrow: {
                    color: '#f16e00',
                },
            },
        },
    },
});

export default theme;
