import {IField} from '../../../../../Fields/types';
import {IProps} from './types';

import React, {FC} from 'react';

import {FormField} from '../../../../../Fields';
import {FIELD_TYPE} from '../../../../../Fields/constants';
import {translateByIntl} from '../../../../../helpers';

import {INTL_DATA} from './intl';

const Condition: FC<IProps> = (props) => {
    const {field} = props;

    if (!field) {
        return null;
    }

    return (
        <div className={'row'}>
            <div className={'col col-10'}>
                <label className={'col-form-label'}>
                    {field.name}
                </label>
                {
                    field.type === FIELD_TYPE.US_MIGRATION ?
                        <div>
                            {translateByIntl(props.intl, INTL_DATA.NEW_DESTINATION_ITEM_TITLE, 'New')}
                        </div> :
                        <FormField
                            field={{
                                ...field,
                                value: props.condition.value,
                            } as IField}
                            intl={props.intl}
                            translation={props.translation}
                            onChange={props.onChange}
                        />
                }
            </div>
            <div className={'col col-2 d-flex flex-row align-items-center'}>
                <button
                    className={'btn btn-sm btn-danger'}
                    onClick={() => props.onRemove(field.name)}
                >
                    x
                </button>
            </div>
        </div>
    );
};

export default Condition;
