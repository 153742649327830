import React, {
    FC,
    ReactElement,
} from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {ActionButton} from 'components/Helpers/Buttons';
import {PageTitle} from 'components/Helpers/Other';

interface IProps {
    title: string;
    controlPanel?: ReactElement;
    customBackBtnPath?: string;

    onBack?(): void;
    onAddNew?(): void;
}

const PageHeading: FC<IProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onBack = () => {
        if (props.onBack) {
            props.onBack();

            return;
        }
        if (location.search) {
            navigate(-2);

            return;
        }

        navigate(-1);
    };

    return (
        <div className={'row d-flex flex-column flex-md-row justify-content-between align-items-center mb-2'}>
            <div className={'col d-flex justify-content-center justify-content-md-start'}>
                <ActionButton
                    mode={'back'}
                    onClick={onBack}
                />
            </div>
            <div className={'col-12 col-md-8'}>
                <PageTitle text={props.title}/>
            </div>
            <div className={'col d-flex justify-content-center justify-content-md-end mb-md-0 mb-4'}>
                {props.controlPanel}
            </div>
            {
                props.onAddNew &&
                <div className={'col d-flex justify-content-end justify-content-md-end'}>
                    <ActionButton
                        mode={'add'}
                        onClick={props.onAddNew}
                    />
                </div>
            }
        </div>
    );
};

export default PageHeading;
