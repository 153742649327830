import {
    IApplicationFrame,
    IBasketServiceFullUser,
    IBasketServiceUser,
} from './types';
import {
    ICheckoutByUserBody,
    IGetDemoByUserQuery,
    IGetOneFullByUserQuery,
    IListenFullByUserQuery,
} from 'instances/basketServices/types/requests';

import {getLSCurrentLocale} from 'instances/languages/functions';
import {
    FIELD_APPLICATION_KEY,
    FIELD_POSITION_KEY,
} from 'instances/servicesVersions/constants';

import {
    BID_TYPE,
    STATUS,
} from './constants';

export function getDefaultQueryDemoUser(): IGetDemoByUserQuery {
    return {
        locale: getLSCurrentLocale(),
    };
}

export function getDefaultOneQueryFullUser(): IGetOneFullByUserQuery {
    return {
        locale: getLSCurrentLocale(),
    };
}

export function getDefaultListenFullQueryUser(): IListenFullByUserQuery {
    return {
        locale: getLSCurrentLocale(),
    };
}

export function getDefaultCheckoutByUserBody(): ICheckoutByUserBody {
    return {
        isTest: false,
        isSingleOffer: true,
        locale: getLSCurrentLocale(),
    };
}

export function sortBasketServicesByDate(basketServices: IBasketServiceUser[]) {
    return basketServices.sort((first, second) => {
        return new Date(first.createdAt).getTime() - new Date(second.createdAt).getTime();
    });
}

export function basketServiceCanBeCheckout(basketService: IBasketServiceUser): boolean {
    return basketService.status === STATUS.READY_TO_SEND;
}

export function isAbleToCheckout(basketServices: IBasketServiceUser[]): boolean {
    return !!basketServices.find(basketServiceCanBeCheckout);
}

export function getApplicationIsEnabled(basketService: IBasketServiceUser): boolean {
    switch (basketService.bidType) {
        case BID_TYPE.INSTALL:
        case BID_TYPE.SUPPL:
        {
            return basketService.orderApplicationIsEnabled;
        }
        case BID_TYPE.UPGRADE:
        case BID_TYPE.CHANGE:
        {
            return basketService.applicationIsEnabled;
        }
        case BID_TYPE.CLAIM:
        case BID_TYPE.CLOSE:
        case BID_TYPE.RESTORE:
        case BID_TYPE.SUSPEND: {
            return false;
        }
    }
}

export function getFieldPositionKey(basketService: IBasketServiceFullUser): FIELD_POSITION_KEY {
    switch (basketService.bidType) {
        case BID_TYPE.INSTALL:
        case BID_TYPE.SUPPL:
        {
            return FIELD_POSITION_KEY.ORDER_POSITION;
        }
        case BID_TYPE.UPGRADE:
        case BID_TYPE.CHANGE:
        {
            return FIELD_POSITION_KEY.POSITION;
        }
        case BID_TYPE.CLOSE:
        case BID_TYPE.SUSPEND:
        case BID_TYPE.RESTORE:
        case BID_TYPE.CLAIM:
        {
            return FIELD_POSITION_KEY.POSITION;
        }
    }
}

export function getApplicationStepTitles(basketService: IBasketServiceFullUser): string[] {
    switch (basketService.bidType) {
        case BID_TYPE.INSTALL:
        case BID_TYPE.SUPPL:
        {
            return basketService.orderApplicationStepTitles || [];
        }
        case BID_TYPE.UPGRADE:
        case BID_TYPE.CHANGE:
        {
            return basketService.applicationStepTitles || [];
        }
        case BID_TYPE.CLOSE:
        case BID_TYPE.SUSPEND:
        case BID_TYPE.RESTORE:
        case BID_TYPE.CLAIM: {
            return [];
        }
    }
}

export function getApplicationFrames(basketService: IBasketServiceFullUser): IApplicationFrame[] {
    switch (basketService.bidType) {
        case BID_TYPE.INSTALL:
        case BID_TYPE.SUPPL:
        {
            return basketService.orderApplicationFrames || [];
        }
        case BID_TYPE.CHANGE:
        case BID_TYPE.UPGRADE:
        {
            return basketService.applicationFrames || [];
        }
        case BID_TYPE.CLOSE:
        case BID_TYPE.SUSPEND:
        case BID_TYPE.RESTORE:
        case BID_TYPE.CLAIM:
        {
            return [];
        }
    }
}

export function getFieldApplicationPositionKey(basketService: IBasketServiceFullUser): FIELD_APPLICATION_KEY {
    switch (basketService.bidType) {
        case BID_TYPE.INSTALL:
        case BID_TYPE.SUPPL: {
            return FIELD_APPLICATION_KEY.ORDER_APPLICATION;
        }
        case BID_TYPE.UPGRADE:
        case BID_TYPE.CHANGE:
        {
            return FIELD_APPLICATION_KEY.APPLICATION;
        }
        case BID_TYPE.CLOSE:
        case BID_TYPE.SUSPEND:
        case BID_TYPE.RESTORE:
        case BID_TYPE.CLAIM:
        {
            return FIELD_APPLICATION_KEY.APPLICATION;
        }
    }
}
