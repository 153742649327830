import {
    IProps,
    IRowsColumns,
} from './types';

import React, {
    FC,
    useState,
} from 'react';
import {
    DragDropContext,
    DropResult,
} from 'react-beautiful-dnd';

import Line from './Line';
import UnsetItems from './UnsetItems';

import {
    DisplayField,
    FormField,
} from '../../Fields';
import {rangeArray} from '../../helpers';

import {
    COLUMNS_COUNT,
    UNSET,
} from './constants';
import {
    calculateRowsCount,
    getDraggableId,
    moveFieldFromUnset,
    moveFieldInRow,
    moveFieldToAnotherRow,
    moveFieldToUnset,
    setNewWidth,
} from './functions';

const CreationTable: FC<IProps> = (props) => {
    const [rowsCount, setRowsCount] = useState(calculateRowsCount(props.fields, props.positionKey));

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index >= COLUMNS_COUNT) {
            return;
        }

        const startRow = parseInt(result.source.droppableId);
        const startColumn = result.source.index;
        const endRow = parseInt(result.destination.droppableId);
        const endColumn = result.destination.index;
        const movedField = props.fields.find((field) => getDraggableId(field) === result.draggableId);

        const rowsAndColumns: IRowsColumns = {
            startRow,
            startColumn,
            endRow,
            endColumn,
        };

        if (!movedField) {
            return;
        }
        if (result.source.droppableId === UNSET) {
            if (isNaN(endRow)) {
                return;
            }

            const fields = moveFieldFromUnset(movedField, props.fields, rowsAndColumns, props.positionKey);

            setRowsCount(calculateRowsCount(fields, props.positionKey));
            props.onChange(fields);

            return;
        }
        if (result.destination.droppableId === UNSET) {
            const fields = moveFieldToUnset(movedField, props.fields, props.positionKey);

            setRowsCount(calculateRowsCount(fields, props.positionKey));
            props.onChange(fields);

            return;
        }
        if (startRow === endRow) {
            const fields = moveFieldInRow(movedField, props.fields, rowsAndColumns, props.positionKey);

            props.onChange(fields);

            return;
        } else {
            const fields = moveFieldToAnotherRow(movedField, props.fields, rowsAndColumns, props.positionKey);

            setRowsCount(calculateRowsCount(fields, props.positionKey));
            props.onChange(fields);
        }
    };

    const onChangeWidth = (name, width) => {
        const fields = setNewWidth(props.fields, name, width, props.positionKey);

        if (fields) {
            props.onChange(fields);
        }
    };

    const onReset = () => {
        const fields = props.fields.map((field) => ({...field}));

        fields.forEach((field) => field[props.positionKey] = undefined);

        setRowsCount(1);
        props.onChange(fields);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <UnsetItems
                isForm={props.isForm}
                fields={props.fields.filter((field) => !field[props.positionKey])}
                formWrapper={props.formWrapper || FormField}
                displayWrapper={props.displayWrapper || DisplayField}
                intl={props.intl || null}
                translation={props.translation || null}
                onReset={onReset}
            />
            {
                rangeArray(rowsCount)
                    .map((row) =>
                        <Line
                            key={row}
                            positionKey={props.positionKey}
                            isForm={props.isForm}
                            row={row}
                            fields={props.fields.filter((field) => field[props.positionKey] && field[props.positionKey].row === row)}
                            formWrapper={props.formWrapper || FormField}
                            displayWrapper={props.displayWrapper || DisplayField}
                            intl={props.intl || null}
                            translation={props.translation || null}
                            onChangeWidth={onChangeWidth}
                        />
                    )
            }
        </DragDropContext>
    );
};

export default CreationTable;
