import { IRootReduxState } from '../types';
import { IToPatch } from './types/instance';

import { Dispatch } from 'redux';
import * as NSRedux from './types/redux';

import { ActionTypes } from './constants';


export function getState({globalData}: IRootReduxState): typeof globalData.data {
    return globalData.data;
}

export function isLoaded({globalData}: IRootReduxState): typeof globalData.isLoaded {
    return globalData.isLoaded;
}

export function getError({globalData}: IRootReduxState): typeof globalData.error {
    return globalData.error;
}

export function get(dispatch: Dispatch<NSRedux.IGet>) {
    return () => {
        dispatch({
            type: ActionTypes.GetGlobalData,
        });
    };
}

export function patch(dispatch: Dispatch<NSRedux.IPatch>) {
    return (data: IToPatch, onSucceed: () => void) => {
        dispatch({
            type: ActionTypes.PatchGlobalData,
            data,
            onSucceed,
        });
    };
}
