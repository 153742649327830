import {IProps} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useEffect,
    useState,
} from 'react';
import ReactModal from 'react-modal';

import {Loading} from 'components/Helpers/Other';

import styles from './Modal.module.css';

import {
    activeCloseIcon,
    closeIcon,
} from './media';

const Modal: FC<IProps> = (props) => {
    const [isHover, setIsHover] = useState(false);

    useEffect(() => {
        if (props.isOpen) {
            document.body.style.overflowY = 'hidden';
        }

        return () => {
            document.body.style.overflowY = 'initial';
        };
    }, [props.isOpen]);

    return (
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={() => props.onClose()}
            className={styles.modal}
            overlayClassName={classNames('d-flex flex-row justify-content-center', styles.overlay, {
                'mt-5': !!props.isNested,
            })}
            ariaHideApp={false}
        >
            {
                !props.isPure &&
                <div className={'row mt-2'}>
                    <div className={'col-1'}/>
                    <div className={'col-10'}>
                        <div className={'d-flex flex-row justify-content-start'}>
                            <h3>
                                {props.title}
                            </h3>
                        </div>
                    </div>
                    <div className={'col-1 cursor-pointer'} onClick={() => props.onClose()}>
                        <div
                            className={'d-flex justify-content-center align-items-center'}
                            onMouseEnter={() => setIsHover(true)}
                            onMouseLeave={() => setIsHover(false)}
                        >
                            <img src={isHover ? activeCloseIcon : closeIcon} alt={''}/>
                        </div>
                    </div>
                </div>
            }
            {
                props.isLoading ?
                    <div className={'mt-4'}>
                        <Loading size={70}/>
                    </div> :
                    props.children
            }
        </ReactModal>
    );
};

export default Modal;
