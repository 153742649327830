import {IProps} from './types';

import {Switch} from '@mui/material';
import classNames from 'classnames';
import React, {
    FC,
    useId,
} from 'react';

const MaterialCheckbox: FC<IProps> = (props) => {
    const inputId = useId();

    const onChange = () => {
        props.onChange?.(!props.value);
    };

    return (
        <div className={classNames('d-flex flex-row align-items-end')}>
            <label className={'col-form-label'}>
                {props.label}
            </label>
            <Switch
                id={inputId}
                checked={props.value}
                onChange={onChange}
                disabled={props.isDisabled}
            />
        </div>
    );
};

export default MaterialCheckbox;
