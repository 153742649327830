import {IProps} from './types';

import {
    FormControl,
    InputLabel,
    Slider,
} from '@mui/material';
import React, {
    FC,
    useId,
    useState,
} from 'react';

const MaterialRangeSlider: FC<IProps> = (props) => {
    const inputId = useId();

    const [isFocused, setIsFocused] = useState(false);

    const onChange = (event, newValue: number | number[]) => {
        props.onChange?.(newValue as [number, number]);
    };

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = () => {
        setIsFocused(false);
    };

    //todo mui fix
    return (
        <FormControl fullWidth={true}>
            <InputLabel
                htmlFor={inputId}
                shrink={true}
                focused={isFocused}
            >
                {props.title}
            </InputLabel>
            <Slider
                id={inputId}
                disabled={props.isDisabled}
                min={props.min}
                max={props.max}
                value={props.value}
                onChange={onChange}
                valueLabelDisplay={props.isDisabled ? 'on' : 'auto'}
                aria-labelledby={'range-slider'}
                step={props.step}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </FormControl>
    );
};

export default MaterialRangeSlider;
