import classNames from 'classnames';
import {GLOBAL_INTL} from 'constants/globalIntl';
import React, {
    FC,
    useState,
} from 'react';

import {IIntl} from 'helpers/hooks';

import {ReactComponent as FilterLogo} from './media/filterIcon.svg';
import {ReactComponent as FilterLogoHovered} from './media/filterIconHover.svg';

interface IProps {
    className?: string;
    isDisabled?: boolean;
    text?: string;

    onClick?(): void;

    translate: IIntl;
}

const AllFiltersButton: FC<IProps> = ({
    className,
    isDisabled,
    text,
    onClick,
    translate,
}) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <button
            data-bs-toggle={'offcanvas'}
            data-bs-target={'#offcanvasScrolling'}
            className={classNames('btn border border-3 btn-secondary', className)}
            type={'button'}
            disabled={isDisabled}
            onClick={onClick}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {isHover ? <FilterLogoHovered/> : <FilterLogo/>}
            {text ? text : translate(GLOBAL_INTL.ALL_FILTERS_BUTTON)}
        </button>
    );
};

export default AllFiltersButton;
