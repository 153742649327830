import {IField} from '../../FieldsTypes/types';
import {IProps} from './types';

import React, {FC} from 'react';

import {FIELD_TYPE} from '../../constants';
import AddressField from '../../FieldsTypes/AddressField/DisplayField/AddressField';
import CheckboxField from '../../FieldsTypes/CheckboxField/DisplayField/CheckboxField';
import ContractField from '../../FieldsTypes/ContractField/DisplayField/ContractField';
import DaDataField from '../../FieldsTypes/DaDataField/DisplayField/DaDataField';
import DateField from '../../FieldsTypes/DateField/DisplayField/DateField';
import GeoField from '../../FieldsTypes/GeoField/DisplayField/GeoField';
import IPField from '../../FieldsTypes/IPField/DisplayField/IPField';
import NumberField from '../../FieldsTypes/NumberField/DisplayField/NumberField';
import PhoneField from '../../FieldsTypes/PhoneField/DisplayField/PhoneField';
import RadioField from '../../FieldsTypes/RadioField/DisplayField/RadioField';
import RangeField from '../../FieldsTypes/RangeField/DisplayField/RangeField';
import SelectField from '../../FieldsTypes/SelectField/DisplayField/SelectField';
import StringField from '../../FieldsTypes/StringField/DisplayField/StringField';
import TextField from '../../FieldsTypes/TextField/DisplayField/TextField';
import UserField from '../../FieldsTypes/UserField/DisplayField/UserField';
import USMigrationField from '../../FieldsTypes/USMigrationField/DisplayField/USMigrationField';

const SpecificDisplay: FC<IProps<IField>> = (props) => {
    switch (props.field.type) {
        case FIELD_TYPE.ADDRESS: {
            return (
                <AddressField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.CHECKBOX: {
            return (
                <CheckboxField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.CONTRACT: {
            return (
                <ContractField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.DA_DATA: {
            return (
                <DaDataField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.DATE: {
            return (
                <DateField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.GEO: {
            return (
                <GeoField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.IP: {
            return (
                <IPField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.NUMBER: {
            return (
                <NumberField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.PHONE: {
            return (
                <PhoneField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.RADIO: {
            return (
                <RadioField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.RANGE: {
            return (
                <RangeField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.SELECT: {
            return (
                <SelectField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.STRING: {
            return (
                <StringField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.TEXT: {
            return (
                <TextField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.USER: {
            return (
                <UserField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
        case FIELD_TYPE.US_MIGRATION: {
            return (
                <USMigrationField
                    field={props.field}
                    translation={props.translation}
                    intl={props.intl}
                />
            );
        }
    }
};

export default SpecificDisplay;
