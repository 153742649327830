import {IProps} from './types';

import ClassNames from 'classnames';
import React, {FC} from 'react';
import {Droppable} from 'react-beautiful-dnd';

import styles from './OptionalPlaces.module.css';

import {translateByIntl} from '../../../helpers';
import {DROPPABLE_TYPE} from '../constants';

import {
    CREATE_FRAME_DROPPABLE_ID,
    REMOVE_FRAME_DROPPABLE_ID,
} from './constants';
import {INTL_DATA} from './intl';

const OptionalPlaces: FC<IProps> = (props) => {
    const createFrameClassNames = ClassNames(`${styles.optionalPlace} d-flex flex-row justify-content-center align-items-center w-100`, {
        'border': props.fieldIsDragging,
        'border-dark': props.fieldIsDragging,
        'bg-primary': props.fieldIsDragging,
        'bg-light': !props.fieldIsDragging,
    });
    const removeFrameClassNames = ClassNames(`${styles.optionalPlace} d-flex flex-row justify-content-center align-items-center w-100`, {
        'border': props.frameIsDragging,
        'border-dark': props.frameIsDragging,
        'bg-danger': props.frameIsDragging,
        'bg-light': !props.frameIsDragging,
    });

    return (
        <div className={'row w-100'}>
            <div className={'col-6'}>
                <Droppable type={DROPPABLE_TYPE.FIELDS_CONTAINER} droppableId={CREATE_FRAME_DROPPABLE_ID}>
                    {(provided) => (
                        <div ref={provided.innerRef}>
                            <div className={createFrameClassNames}>
                                <h3>
                                    {translateByIntl(props.intl, INTL_DATA.CREATE_FRAME, 'Create frame')}
                                </h3>
                            </div>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
            <div className={'col-6'}>
                <Droppable type={DROPPABLE_TYPE.FRAMES_CONTAINER} droppableId={REMOVE_FRAME_DROPPABLE_ID}>
                    {(provided) => (
                        <div ref={provided.innerRef}>
                            <div className={removeFrameClassNames}>
                                <h3>
                                    {translateByIntl(props.intl, INTL_DATA.REMOVE_FRAME, 'Remove frame')}
                                </h3>
                            </div>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );
};

export default OptionalPlaces;
