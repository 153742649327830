import {IField} from '../../types';
import {ICommonData} from './CommonDataForm/types';

import {DA_DATA_TYPE} from '../FieldsTypes/DaDataField/constants';
import {
    MIGRATE_TO,
    SERVICE_SELECTION_TYPE,
} from '../FieldsTypes/USMigrationField/constants';

import {FIELD_TYPE} from '../constants';

export function getDefaultField(type: FIELD_TYPE): IField {
    const commonData: ICommonData = {
        name: '',
        title: '',
        tooltip: '',
        readOnly: false,
    };

    switch (type) {
        case FIELD_TYPE.ADDRESS: {
            return {
                ...commonData,
                type,
                value: null,
                validation: {
                    required: true,
                },
            };
        }
        case FIELD_TYPE.CHECKBOX: {
            return {
                ...commonData,
                type,
                value: false,
                validation: {},
            };
        }
        case FIELD_TYPE.CONTRACT: {
            return {
                ...commonData,
                type,
                value: null,
                validation: {
                    required: true,
                },
            };
        }
        case FIELD_TYPE.DA_DATA: {
            return {
                ...commonData,
                type,
                value: null,
                dataType: DA_DATA_TYPE.ADDRESS,
                validation: {
                    required: false,
                },
            };
        }
        case FIELD_TYPE.DATE: {
            return {
                ...commonData,
                type,
                value: '',
                validation: {
                    required: false,
                    pastIsDisabled: false,
                },
            };
        }
        case FIELD_TYPE.GEO: {
            return {
                ...commonData,
                type,
                value: null,
                validation: {
                    required: false,
                },
            };
        }
        case FIELD_TYPE.IP: {
            return {
                ...commonData,
                type,
                value: '',
                validation: {
                    required: false,
                },
            };
        }
        case FIELD_TYPE.NUMBER: {
            return {
                ...commonData,
                type,
                value: '',
                validation: {
                    required: false,
                },
            };
        }
        case FIELD_TYPE.PHONE: {
            return {
                ...commonData,
                type,
                value: '',
                validation: {
                    required: false,
                },
            };
        }
        case FIELD_TYPE.RADIO: {
            return {
                ...commonData,
                type,
                value: '',
                values: [],
                validation: {
                    required: false,
                },
            };
        }
        case FIELD_TYPE.RANGE: {
            return {
                ...commonData,
                type,
                value: 0,
                min: 0,
                max: 100,
                step: 1,
                isDoubleRange: false,
                validation: {
                    required: true,
                },
            };
        }
        case FIELD_TYPE.SELECT: {
            return {
                ...commonData,
                type,
                withDefaultValue: false,
                value: '',
                values: [],
                validation: {
                    required: true,
                },
            };
        }
        case FIELD_TYPE.STRING: {
            return {
                ...commonData,
                type,
                value: '',
                validation: {
                    required: false,
                },
            };
        }
        case FIELD_TYPE.TEXT: {
            return {
                ...commonData,
                type,
                value: '',
                hideTitle: false,
                validation: {
                    required: false,
                },
            };
        }
        case FIELD_TYPE.USER: {
            return {
                ...commonData,
                type,
                value: null,
                withCurrentUserAsDefaultValue: false,
                validation: {
                    required: false,
                },
            };
        }
        case FIELD_TYPE.US_MIGRATION: {
            return {
                ...commonData,
                type,
                value: {
                    origin: null,
                    userServices: [],
                    destination: null,
                },
                migrateTo: MIGRATE_TO.BILL,
                originTitle: 'Origin',
                destinationTitle: 'Destination',
                newDestinationItemTitle: 'New',
                existedDestinationItemTitle: 'Existing',
                newDestinationValueAsDefault: false,
                serviceSelectionType: SERVICE_SELECTION_TYPE.FREE_EMPTY,
                validation: {
                    required: false,
                },
            };
        }
    }
}
