import {IIntl} from '../../../../types';

import {translateByIntl} from '../../../../helpers';
import {INTL_DATA} from '../../intl';

import {ERROR} from './constants';

export function formatError(error: ERROR | null, intl: IIntl | null): string {
    switch (error) {
        case ERROR.IS_REQUIRED: {
            return translateByIntl(intl, INTL_DATA.NO_EMPTY, 'Required');
        }
        case ERROR.INVALID_FORMAT: {
            return translateByIntl(intl, INTL_DATA.INVALID_FORMAT, 'Invalid value');
        }
        case null: {
            return '';
        }
    }
}

export function confirmValueBeforeDisplay(value: string): string | false {
    if (value === '.' || value.endsWith('..')) {
        return false;
    }

    const parts = value.split('.');
    const zeroAndDigitPattern = /(0[1-9]\d*)/;

    if (parts.length > 4) {
        return false;
    }
    if (parts.some((part: string) => zeroAndDigitPattern.test(part) || +part < 0 || +part > 255)) {
        return false;
    }

    return value;
}
