import AcUnitIcon from '@mui/icons-material/AcUnit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import RefreshIcon from '@mui/icons-material/Refresh';
import RepartitionIcon from '@mui/icons-material/Repartition';
import RestoreIcon from '@mui/icons-material/Restore';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import React, {FC} from 'react';

import {MaterialTooltip} from 'components/Helpers/MaterialUI';

export type IIcon = 'delete' | 'newTab' | 'edit' |
'goBack' | 'addNew' | 'save' | 'restore' | 'freeze' |
'refresh' | 'remove' | 'clean';

interface IProps {
    className?: string;
    tooltip: any;
    icon: IIcon | null;
    isDisabled?: boolean;

    onClick(): void;
}

const TooltipIconButton: FC<IProps> = ({
    className,
    tooltip,
    icon = null,
    onClick,
    isDisabled = false,
}) => {
    const image = icon === 'delete' ? <DeleteIcon/> :
        icon === 'newTab' ? <RepartitionIcon/> :
            icon === 'edit' ? <EditIcon/> :
                icon === 'goBack' ? <ArrowBackIcon/> :
                    icon === 'addNew' ? <AddCircleOutlineIcon/> :
                        icon === 'save' ? <SaveIcon/> :
                            icon === 'restore' ? <RestoreIcon/> :
                                icon === 'freeze' ? <AcUnitIcon/> :
                                    icon === 'refresh' ? <RefreshIcon/> :
                                        icon === 'remove' ? <PersonRemoveIcon/> :
                                            icon === 'clean' ? <HighlightOffIcon/> :
                                                null;

    return (
        <MaterialTooltip
            className={classNames(
                'd-flex align-items-center justify-content-center',
                className
            )}
            text={tooltip.text || ''}
            placement={tooltip.placement || 'top'}
        >
            <IconButton
                onClick={onClick}
                disabled={isDisabled}
                sx={{
                    width: '32px',
                    height: '32px',
                    ':hover': {
                        color: '#f16e00',
                    },
                }}
            >
                {image}
            </IconButton>
        </MaterialTooltip>
    );
};

export default TooltipIconButton;
