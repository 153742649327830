import { IItem } from './types';
import { IState, IPush, IRemove } from './types/redux';
import { IRootReduxState } from '../types';

import { useDispatch, useSelector } from 'react-redux';

import { ACTION_TYPE } from './constants';


export function useLoadingFiles() {
    const {items} = useSelector<IRootReduxState, IState>((state) => state.loadingFiles);

    return {items};
}

export function useLoadingFilesActions() {
    const dispatch = useDispatch();

    const push = (item: IItem) => {
        dispatch<IPush>({
            type: ACTION_TYPE.PUSH,
            item,
        });
    };
    const remove = (id: string) => {
        dispatch<IRemove>({
            type: ACTION_TYPE.REMOVE,
            id,
        });
    };

    return {push, remove};
}
