import * as NSFetchResult from './types/fetchResult';
import * as NSRedux from './types/redux';
import { IGetActive, IGetByService, IGetOne, IPatch, IPost, ISetActive } from './types/saga';

import { call, put, takeLatest } from 'redux-saga/effects';

import * as fetches from './fetches';
import { ActionTypes } from './constants';


export default {
    getActive(apiCaller?: typeof fetches.getActive): IGetActive {
        function* caller(action: NSRedux.IGetActive) {
            const {versions, error}: NSFetchResult.IGetActive = yield call(
                apiCaller ? apiCaller : fetches.getActive
            );
            const success: NSRedux.IGetActiveSucceed = {
                type: ActionTypes.GetActiveServicesVersionsSucceed,
                versions,
                error,
            };

            yield put(success);
        }

        function* taker() {
            yield takeLatest(ActionTypes.GetActiveServicesVersions, caller);
        }

        return {taker, caller};
    },

    post(apiCaller?: typeof fetches.post): IPost {
        function* caller(action: NSRedux.IPost) {
            const {version, error}: NSFetchResult.IPost = yield call(
                apiCaller ? apiCaller : fetches.post,
                action.body,
            );
            const success: NSRedux.IPostSucceed = {
                type: ActionTypes.PostActiveServiceVersionSucceed,
                version,
                error,
            };

            version && action.onSucceed();
            error && action.onFailed();

            yield put(success);
        }

        function* taker() {
            yield takeLatest(ActionTypes.PostActiveServiceVersion, caller);
        }

        return {taker, caller};
    },

    getByService(apiCaller?: typeof fetches.getByService): IGetByService {
        function* caller(action: NSRedux.IGetByService) {
            const {versions, error}: NSFetchResult.IGetByService = yield call(
                apiCaller ? apiCaller : fetches.getByService,
                action.id,
            );
            const success: NSRedux.IGetByServiceSucceed = {
                type: ActionTypes.GetServiceVersionsByServiceSucceed,
                error,
                id: action.id,
                versions,
            };

            yield put(success);
        }

        function* taker() {
            yield takeLatest(ActionTypes.GetServiceVersionsByService, caller);
        }

        return {taker, caller};
    },

    getOne(apiCaller?: typeof fetches.getOne): IGetOne {
        function* caller(action: NSRedux.IGetOne) {
            const {version, error}: NSFetchResult.IGetOne = yield call(
                apiCaller ? apiCaller : fetches.getOne,
                action.id,
            );

            if (version) {
                action.onSucceed?.(version);
            }

            const success: NSRedux.IGetOneSucceed = {
                type: ActionTypes.GetServiceVersionSucceed,
                version,
                error,
            };

            yield put(success);
        }

        function* taker() {
            yield takeLatest(ActionTypes.GetServiceVersion, caller);
        }

        return {taker, caller};
    },

    patch(apiCaller?: typeof fetches.patch): IPatch {
        function* caller(action: NSRedux.IPatch) {
            const {version, error}: NSFetchResult.IPatch = yield call(
                apiCaller ? apiCaller : fetches.patch,
                action.id,
                action.body,
            );
            const success: NSRedux.IPatchSucceed = {
                type: ActionTypes.PatchServiceVersionSucceed,
                version,
                error,
            };

            version && action.onSucceed();
            error && action.onFailed();

            yield put(success);
        }

        function* taker() {
            yield takeLatest(ActionTypes.PatchServiceVersion, caller);
        }

        return {taker, caller};
    },

    setActive(apiCaller?: typeof fetches.setActive): ISetActive {
        function* caller(action: NSRedux.ISetActive) {
            const {error}: NSFetchResult.ISetActive = yield call(
                apiCaller ? apiCaller : fetches.setActive,
                action.id,
            );
            const success: NSRedux.ISetActiveSucceed = {
                type: ActionTypes.SetActiveServiceVersionSucceed,
                id: action.id,
                error,
            };

            yield put(success);
        }

        function* taker() {
            yield takeLatest(ActionTypes.SetActiveServiceVersion, caller);
        }

        return {taker, caller};
    },
};
