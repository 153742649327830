export enum INTL_DATA {
    TITLE = 'header/notifications',
    NO_NOTIFICATIONS = 'notifications/no-notifications',
    NOTIFICATIONS_IS_DISABLED = 'notifications/notifications-is-disabled',

    READ_NOTIFICATION = 'notifications/userNotification/read-notification',
    READ_NOTIFICATION_STATUS = 'notifications/userNotification/read-notification-status',
    NOTIFICATION_READED = 'notifications/userNotification/notification-readed',
    REMOVE_NOTIFICATION = 'header/notificationsSlider/remove-notification',
    REMOVE_NOTIFICATION_CONFIRMATION = 'header/notificationsSlider/remove-notification-confirmation',
    REMOVE_NOTIFICATION_DECLINE = 'header/notificationsSlider/remove-notification-decline',
}

export enum INTL_STATUS {
    EXPIRED = 'notification/statuses/expired',
    UNREADED = 'notification/statuses/unreaded',
    READED = 'notification/statuses/readed',
    IGNORE = 'notification/statuses/ignore',
}
