export const INSTANCE_PATH = '/equipped_clients';

export const LS_EQUIPMENT_DATA_FORM_PAGE = 'LS_EQUIPMENT_DATA_FORM_PAGE';
export const LS_EQUIPMENT_DATA_VALID_PAGES = 'LS_EQUIPMENT_DATA_VALID_PAGES';
export const LS_EQUIPMENT_DATA_ITEMS = 'LS_EQUIPMENT_DATA_ITEMS';
export const LS_EQUIPMENT_LAST_LOGIN = 'LS_EQUIPMENT_LAST_LOGIN';

export const SESSION_DURATION = 1000 * 60 * 60 * 24;

export const FETCH_BY_FILTERS_DELAY = 1000;

export const DOWNLOADED_REPORT_FILENAME = 'report.pdf';

export const DOWNLOADED_SAMPLE_FILENAME = 'Шаблон - пользователи оконечного оборудования.xlsx';

export enum LOGIN_METHOD {
    INN = 'INN',
    CLIENT_ID = 'CLIENT_ID'
}

export enum ACCESS_STATUS_FILTER {
    ALL = '1',
    ACTIVE = '2',
    BLOCKED = '3',
}

export enum RESPONSE_ERROR {
    DUPLICATED_CLIENT_ID = 'DUPLICATED_CLIENT_ID',
    DUPLICATED_CLIENT_NAME = 'DUPLICATED_CLIENT_NAME',
    DUPLICATED_INN = 'DUPLICATED_INN',
    NOT_FOUND = 'NOT_FOUND',
}
