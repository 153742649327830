import {IProps} from './types';

import {Collapse} from '@mui/material';
import classNames from 'classnames';
import React, {
    FC,
    useState,
} from 'react';

import {FullModeToggler} from 'components/Helpers/MaterialUI';

import styles from './InputItem.module.scss';

const InputItem: FC<IProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [suggestions, setSuggestions] = useState(props.itemSuggestions);
    const [inputValue, setInputValue] = useState('');
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        if (!value) {
            setSuggestions(props.itemSuggestions);
            setInputValue('');

            return;
        }

        setInputValue(value);

        const searchValue = value.toLowerCase();

        setSuggestions(suggestions.filter((suggestion) => suggestion ? suggestion.toLowerCase().includes(searchValue) : false));
    };

    const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = encodeURIComponent(e.target.value);
        const currentFilters = props.filters[props.filterType];

        if (currentFilters.includes(value)) {
            props.onChange({
                ...props.filters,
                [props.filterType]: currentFilters.filter((item: string) => item !== value),
            });
        } else {
            props.onChange({
                ...props.filters,
                [props.filterType]: [value],
            });
        }
    };

    return (
        <div className={'border-bottom border-light border-1'}>
            <div className={'d-flex flex-row justify-content-between mw-100 mt-2 pb-2 fs-5 fw-bold'}>
                {props.title}
                <FullModeToggler
                    isFull={isOpen}
                    isPending={false}
                    onClick={() => setIsOpen(!isOpen)}
                />
            </div>
            <Collapse in={isOpen}>
                <input
                    type={'text'}
                    className={'form-control'}
                    value={inputValue}
                    onChange={onChange}
                    placeholder={props.placeholder || 'Начните ввод'}
                />
                <div className={styles.suggestions}>
                    <div className={'list-group list-group-flush'}>
                        {
                            suggestions.map(
                                (suggestion, i) =>
                                    suggestion ?
                                        <div
                                            key={i}
                                            className={classNames(
                                                'list-group-item border-0 d-flex flex-row align-items-center justify-content-start ps-1 pb-1',
                                                styles.suggestion,
                                                {
                                                    'active': suggestion === props.value,
                                                })}
                                        >
                                            <input
                                                className={'form-check-input m-0 p-0 border border-3 border-light'}
                                                type={'checkbox'}
                                                value={suggestion}
                                                id={suggestion}
                                                checked={props.filters[props.filterType]?.includes(encodeURIComponent(suggestion))}
                                                onChange={onChangeCheckbox}
                                            />
                                            <label
                                                className={'form-check-label ms-2'}
                                                htmlFor={suggestion}
                                            >
                                                {suggestion}
                                            </label>
                                        </div> : null
                            )
                        }
                    </div>
                </div>
            </Collapse>
        </div>);
};

export default InputItem;
