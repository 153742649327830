export const INSTANCE_PATH = '/languages';
export const DEFAULT_LANGUAGE = 'ru';
export const LS_CURRENT_LOCALE = 'locale';
export const LS_LANGUAGES_ADMIN = 'LS_LANGUAGES_ADMIN';

export enum ACTION_TYPES {
    GET_LANGUAGES = 'LOAD_ALL_LANGUAGES',
    GET_LANGUAGES_SUCCEED = 'LOAD_ALL_LANGUAGES_SUCCEED',
}

export const SAVE_LS_ADMIN_LANGUAGE_DELAY = 5000;
