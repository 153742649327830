import {IOrdersFilters} from './types';

import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    getAllOrders,
    getSuggestionsForOrders,
} from './fetches';
import {
    getCrumbsByFilters,
    getInitState,
} from './functions';
import {reducers} from './reducers';

export const getOrders = createAsyncThunk(
    'orders/getOrders',
    (query: IOrdersFilters) => getAllOrders(query)
);
export const getOrdersSuggestions = createAsyncThunk(
    'orders/getOrdersSuggestions',
    () => getSuggestionsForOrders()
);

const orderSlice = createSlice({
    name: 'orders',
    initialState: getInitState(),
    reducers: {
        mutateFilters: reducers.mutateFilters,
    },
    extraReducers: (builder) => {
        builder.addCase(getOrders.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOrders.fulfilled, (state, action) => {
            const filters = action.meta.arg;

            state.isLoading = true;
            state.orders = action.payload.orders;
            state.total = action.payload.orders.length;
            state.isLoading = false;
            state.crumbs = getCrumbsByFilters(filters);
        });
        builder.addCase(getOrders.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(getOrdersSuggestions.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOrdersSuggestions.fulfilled, (state, action) => {
            state.isLoading = true;
            state.suggestions = action.payload.suggestions;
            state.isLoading = false;
        });
        builder.addCase(getOrdersSuggestions.rejected, (state, action) => {
            state.error = action.error.message;
        });
    },
});

export default orderSlice;
export const {
    mutateFilters,
} =
    orderSlice.actions;
