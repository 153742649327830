import {PlayArrow} from '@mui/icons-material';
import {Tooltip} from '@mui/material';
import React, {FC} from 'react';

import {Loading} from 'components/Helpers/Other';

interface IProps {
    isFull: boolean;
    isPending?: boolean;
    isActive?: boolean;
    tooltipText?: string;

    onClick?(): void;
}

const FullModeToggler: FC<IProps> = ({
    isFull,
    isPending = false,
    isActive = false,
    tooltipText,
    onClick,
}) => {
    return (
        <Tooltip
            title={tooltipText}
            sx={{
                borderRadius: '50%',
                height: '25px',
                width: '25px',
                color: isActive || isFull ? '#f16e00' : '#242729',
                ':hover': {
                    color: '#f16e00',
                },
                transform: isFull ? 'rotate(270deg)' : 'rotate(90deg)',
                cursor: 'pointer',
            }}
            data-testid={'full-mode-toggler'}
        >
            {
                isPending ?
                    <Loading/> :
                    <PlayArrow
                        sx={{
                            borderRadius: '50%',
                            height: '25px',
                            width: '25px',
                            color: isActive || isFull ? '#f16e00' : '#242729',
                            ':hover': {
                                color: '#f16e00',
                            },
                            transform: isFull ? 'rotate(270deg)' : 'rotate(90deg)',
                            cursor: 'pointer',
                        }}
                        onClick={onClick}
                    />
            }
        </Tooltip>
    );
};

export default FullModeToggler;
