import {IState} from './types/redux';
import {IReduxAction} from 'instances/types';

import {ACTION_TYPES} from './constants';
import {sortBasketServicesByDate} from './functions';

export const initialState: IState = {
    data: null,
};

export default function(state: IState = initialState, action: IReduxAction) {
    switch (action.type) {
        case ACTION_TYPES.GET_BASKET_SERVICES: {
            return {
                ...state,
                data: sortBasketServicesByDate(action.basketServices),
            };
        }
        case ACTION_TYPES.GET_BASKET_SERVICE: {
            return {
                ...state,
                data: sortBasketServicesByDate([
                    action.basketService,
                    ...(state.data?.filter((service) => service.id !== action.basketService.id) || []),
                ]),
            };
        }
        case ACTION_TYPES.LISTEN_BASKET_SERVICE_INSERT: {
            return {
                ...state,
                data: sortBasketServicesByDate([
                    action.basketService,
                    ...(state.data || []),
                ]),
            };
        }
        case ACTION_TYPES.LISTEN_BASKET_SERVICE_UPDATE: {
            if (action.basketService.isRemoved) {
                return {
                    ...state,
                    data: state.data?.filter((basketService) => basketService.id !== action.basketService.id),
                };
            }

            return {
                ...state,
                data: sortBasketServicesByDate([
                    ...(state.data?.filter((basketService) => basketService.id !== action.basketService.id) || []),
                    action.basketService,
                ]),
            };
        }
        case ACTION_TYPES.PATCH_BY_USER: {
            return {
                ...state,
                data: state.data?.map((item) => {
                    if (item.id === action.basketService.id) {
                        return {
                            ...item,
                            ...action.basketService,
                        };
                    }

                    return item;
                }),

            };
        }
        default: {
            return state;
        }
    }
}
