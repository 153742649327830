import { Dispatch } from 'redux';

import { IRootReduxState } from '../types';
import * as NSRedux from './types/redux';

import { ActionTypes } from './constants';


export function getState({commonData}: IRootReduxState) {
    return commonData.data;
}

export function isLoaded({commonData}: IRootReduxState) {
    return commonData.isLoaded;
}

export function getError({commonData}: IRootReduxState) {
    return commonData.error;
}

export function get(dispatch: Dispatch<NSRedux.IGet>) {
    return () => {
        dispatch({type: ActionTypes.GetCommonData});
    };
}

export function commonDataIsLoaded({commonData}: IRootReduxState) {
    return !!commonData;
}

export function setCheckoutMessage(dispatch: Dispatch<NSRedux.ISetCheckoutMessage>) {
    return (message) => {
        dispatch({type: ActionTypes.SetCheckoutMessage, message});
    };
}

export function updateMyServicesActualTime(dispatch: Dispatch<NSRedux.IUpdateMyServicesActualTime>) {
    return (time) => {
        dispatch({type: ActionTypes.UpdateMyServiceActualTime, time});
    }
}
