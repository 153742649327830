import injectReduxLoader from './injectReduxLoader';


export function createLoaderPart(getState, getAction, isLoaded, getActionParams) {
    return { getState, getAction, isLoaded, getActionParams };
}

export {injectReduxLoader};

export {default} from './ReduxLoader';
