import {IProps} from './types';

import classNames from 'classnames';
import React, {FC} from 'react';

import {getDefaultQueryClient} from 'instances/equippedUsers/functions';

import {Loading} from 'components/Helpers/Other';

import styles from './TotalFiltersRow.module.scss';

const TotalFiltersRow: FC<IProps> = (props) => {
    const onClearFilters = () => {
        props.onClear(getDefaultQueryClient());
    };

    return (
        <div className={classNames(
            'd-flex flex-row mt-4 mb-2 ',
            styles.totalFiltersRow,
            props.className
        )}
        >
            <div className={classNames(
                'col-6 d-flex justify-content-start align-items-center ps-4 mt-2 mb-2',
                styles.foundField
            )}
            >
                {
                    props.filtersTotal !== null ?
                        `Найдено идентификаторов: ${props.filtersTotal}` :
                        <Loading size={20}/>
                }
            </div>
            <div className={classNames(
                'col-6 d-flex pe-4',
                styles.clearButton
            )}
            >
                <div
                    className={'ms-auto d-flex align-items-center cursor-pointer'}
                    onClick={onClearFilters}
                >
                    <div className={'me-2'}>
                        Сбросить фильтры
                    </div>
                    <div className={'d-flex align-items-center cursor-pointer'}>
                        <img
                            className={'d-flex'}
                            alt={''}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TotalFiltersRow;
