export enum INTL_DATA {
    BASKET = 'basket-services/title',
    NOTHING = 'basket-services/nothing',
    SEND_TO_ORANGE = 'basket-services/send-to-orange',
    SEND_ALL_TO_ORANGE = 'basket-services/send-all-to-orange',

    PERMISSION_ERROR = 'basket-services/errors/no-permission',

    BID_TYPE_INSTALL = 'basket-services-bid-type/install',
    BID_TYPE_UPGRADE = 'basket-services-bid-type/upgrade',
    BID_TYPE_CHANGE = 'basket-services-bid-type/change',
    BID_TYPE_CLOSE = 'basket-services-bid-type/close',
    BID_TYPE_SUPPL = 'basket-services-bid-type/suppl',
    BID_TYPE_CLAIM = 'basket-services-bid-type/claim',
    BID_TYPE_SUSPEND = 'basket-services-bid-type/suspend',
    BID_TYPE_RESTORE = 'basket-services-bid-type/restore',

    FILTER_INITIATOR = 'basket/basket-service/filters/horizontal-filters/initiator',
    FILTER_SERVICE = 'basket/basket-service/filters/horizontal-filters/service',
    FILTER_SERVICE_TYPE = 'basket/basket-service/filters/horizontal-filters/type',
    FILTER_ADDRESS = 'basket/basket-service/filters/horizontal-filters/address',
    FILTER_DATE = 'basket/basket-service/filters/horizontal-filters/date',
    FILTER_ACTIONS = 'basket/basket-service/filters/horizontal-filters/action',

    REMOVE_MODAL_HEADER = 'basket/confirm-remove-modal/accept-remove-message',
    REMOVE_MODAL_MESSAGE = 'basket/confirm-remove-modal/message',
}
