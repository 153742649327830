import { IProps } from './types';

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import copy from 'copy-text-to-clipboard';

import Notifier from 'helpers/Notifier';

import { injectIntl } from 'components/Helpers/HOCs';

import { INTL_DATA } from './intl';

import './styles.css';


class OverflowHiddenText extends PureComponent<IProps> {
    public onClick = () => {
        if (!this.props.withCopying) {
            return;
        }

        copy(this.props.text || '');
        Notifier.info(
            this.props.intl.formatMessage({id: INTL_DATA.HAS_BEEN_COPIED})
        );
    };

    public render() {
        const classes = classNames(`${this.props.className}`, {
            'cursor-pointer': this.props.withCopying,
        });

        return (
                <span
                    className={classNames('overflow-hidden-text', classes) }
                    title={this.props.text || undefined}
                    onClick={this.onClick}
                >
                    {this.props.text}
                </span>
        );
    }
}

export default injectIntl()(OverflowHiddenText);
