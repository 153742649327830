import config from 'config';

const headers = [
    ['Accept', 'application/json'],
    ['Content-Type', 'application/json'],
    ['Access-Control-Request-Headers', 'true'],
];
const credentials = 'include';

const sitesConstructor = {
    async get() {
        const response = await fetch(`${config.URL}/sites`, {
            method: 'GET',
            headers,
            credentials,
        });
        return await response.json();
    },

    async getByUser() {
        const response = await fetch(`${config.URL}/sites/user`, {
            method: 'GET',
            headers,
            credentials,
        });
        return await response.json();
    },

    async getOne(version) {
        const response = await fetch(`${config.URL}/sites/${version}`, {
            method: 'GET',
            headers,
            credentials,
        });
        return await response.json();
    },

    async post(site) {
        const response = await fetch(`${config.URL}/sites`, {
            method: 'POST',
            headers,
            credentials,
            body: JSON.stringify(site)
        });

        if (response.status === 400) {
            console.log(await response.json());
            return null;
        }

        return await response.json();
    },

    async setActive(version) {
        const response = await fetch(`${config.URL}/sites/active/${version}`, {
            method: 'POST',
            headers,
            credentials,
            body: JSON.stringify({version})
        });
        const body = await response.json();
        return body.version;
    },
};

export default sitesConstructor;
