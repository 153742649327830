export const INSTANCE_PATH = '/notifications';

export const LS_NOTIFICATIONS_FILTER = 'LS_NOTIFICATIONS_FILTER';

export enum SORT_BY {
    NOTIFICATION_ID = 'NOTIFICATION_ID',
    CITY = 'CITY',
    DATE_SENT = 'DATE_SENT',
    DATE_START = 'DATE_START',
    DATE_END = 'DATE_END',
    STATUS = 'STATUS',

    SVID = 'SVID',
    CID = 'CID',
    SERVICE_NAME = 'SERVICE_NAME',
    SERVICE_ADDRESS = 'SERVICE_ADDRESS',
    WORK_ID = 'WORK_ID',
    AFFECTED_CONTRACTS = 'AFFECTED_CONTRACTS',
}

export enum ACTION {
    ACTIVE = 'ACTIVE',
    IN_PROGRESS_OR_READY = 'IN_PROGRESS_OR_READY',
    SUSPENDED = 'SUSPENDED',
    CHANGE_PLANNED = 'CHANGE_PLANNED',
}

export enum STATUS {
    NEW = 'NEW',
    SAW = 'SAW',
    IGNORE = 'IGNORE',
    OVERDUE = 'OVERDUE'
}
