import {IGetUserServicesQuery} from '../../../../../Provider/types';
import {IAddress} from '../../../AddressField/types';
import {IContract} from '../../../ContractField/types';
import {
    IOrigin,
    IUserService,
} from '../../types';

import {MIGRATE_TO} from '../../constants';

export function getDefaultQuery(origin: IOrigin, migrateTo: MIGRATE_TO): IGetUserServicesQuery {
    let contractNumber = '';
    let customerUserSiteRef = '';

    switch (migrateTo) {
        case MIGRATE_TO.BILL: {
            if (origin) {
                contractNumber = (origin as IContract).number;
            }
            break;
        }
        case MIGRATE_TO.CONTRACT: {
            if (origin) {
                contractNumber = (origin as IContract).number;
            }
            break;
        }
        case MIGRATE_TO.SITE: {
            if (origin) {
                customerUserSiteRef = (origin as IAddress).customerSiteRef;
            }
            break;
        }
    }

    return {
        skip: 0,
        limit: 1_000_000,

        type: [],
        SVID: [],
        orderAddendumNumber: [],
        clientKERef: [],

        siteAddress: [],
        customerUserSiteRef: [customerUserSiteRef],
        contractNumber: [contractNumber],

        status: [],
    };
}

export function getIsChosenAll(selectedServices: IUserService[], userServices: IUserService[] | null): boolean {
    if (!userServices) {
        return false;
    }

    const selectedServicesCount = selectedServices.length;
    const servicesCount = userServices.length;

    for (let i = 0; i < servicesCount; ++i) {
        let isFoundService = false;

        for (let j = 0; j < selectedServicesCount; ++j) {
            if (selectedServices[j].id === userServices[i].id) {
                isFoundService = true;
                break;
            }
        }

        if (!isFoundService) {
            return false;
        }
    }

    return true;
}
