import {IProps} from './types';

import ClassNames from 'classnames';
import React from 'react';

import {MaterialTooltip} from 'components/Helpers/MaterialUI';

import {translateByObject} from '../../../helpers';

const StepBar = (props: IProps) => {
    return (
        <nav
            className={'stepped-process d-flex flex-row'}
            aria-label={'Checkout process'}
        >
            {
                props.titles.map((title, i) =>
                    !props.hiddenSteps.find((step) => step === i + 1) && (
                        <div
                            key={i}
                            className={ClassNames('stepped-process-item', {
                                active: props.currentStep === i + 1,
                            })}
                        >
                            <MaterialTooltip
                                placement={'top'}
                                text={translateByObject(title, props.translation)}
                            >
                                <div
                                    className={'ms-3 stepped-process-link hidden-overflow-text d-flex'}
                                    title={title}
                                >
                                    <div
                                        className={'step-title'}
                                        aria-current={'step'}
                                    >

                                        {translateByObject(title, props.translation)}
                                    </div>
                                </div>
                            </MaterialTooltip>
                        </div>
                    )
                )
            }
        </nav>
    );
};

export default StepBar;
