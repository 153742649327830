import {INotificationsInitialState} from 'instances/notifications/types';

import {defaultFilters} from '../functions';

export const initialState: INotificationsInitialState = {
    notifications: null,
    error: undefined,
    isLoading: true,
    crumbs: [],
    notificationToSubmit: null,
    notificationsCounter: null,
    newNotifications: null,
    total: null,
    currentTotal: null,
    filters: defaultFilters,
    suggestions: {
        notificationId: [],
        city: [],
        status: [],
        workId: [],
        affectedContracts: [],
        SVID: [],
        CID: [],
        serviceName: [],
        serviceAddress: [],
        contractNumber: [],
    },
};
