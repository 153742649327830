import {NotificationsFilters} from 'instances/notifications/types';

import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    STATUS,
} from 'instances/notifications/constants';
import {
    getAllNotifications,
    getSuggestionsForNotifications,
    patchStatus,
} from 'instances/notifications/fetches';

import {
    defaultFilters,
    getCrumbsByFilters,
} from '../functions';

import {
    initialState,
} from './constants';
import {reducers} from './reducers';

export const getNotifications = createAsyncThunk('notifications/getAllNotifications', (query: NotificationsFilters) => getAllNotifications(query));
export const patchNotificationStatus = createAsyncThunk('notifications/patchNotificationStatus', (id: string) => patchStatus(id));
export const getNotificationsSuggestions = createAsyncThunk('notifications/getNotificationsSuggestions', () => getSuggestionsForNotifications());
export const getNewNotifications = createAsyncThunk('notifications/getNewNotifications', () => getAllNotifications(defaultFilters));

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        mutateFilters: reducers.mutateFilters,
        toSubmitNotification: reducers.toSubmitNotification,
    },
    extraReducers: (builder) => {
        builder.addCase(getNotifications.pending, (state) => {
            state.isLoading = true;
        });
        builder
            .addCase(getNotifications.fulfilled, (state, action) => {
                const filters = action.meta.arg;

                state.isLoading = true;

                if (action.payload.error) {
                    const error = action.payload.error;

                    state.error = error;
                    state.isLoading = false;

                    return;
                } else {
                    state.notifications = action.payload.notifications;
                    state.total = action.payload.notifications.length;
                    state.crumbs = getCrumbsByFilters(filters);
                    state.isLoading = false;
                }
            });

        builder.addCase(getNotifications.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });

        builder.addCase(getNotificationsSuggestions.fulfilled, (state, action) => {
            state.suggestions = action.payload.suggestions;
        });
        builder.addCase(getNotificationsSuggestions.rejected, (state, action) => {
            state.error = action.error.message;
        });

        builder.addCase(patchNotificationStatus.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(patchNotificationStatus.fulfilled, (state, action) => {
            state.isLoading = true;
            if (!state.notificationToSubmit || !state.newNotifications) {
                state.isLoading = false;

                return;
            }
            if (state.newNotifications) {
                state.newNotifications = state.newNotifications.map((notification) => {
                    if (notification.id === action.payload.notification.id) {
                        notification.status = action.payload.notification.status;
                    }

                    return notification;
                });
                if (state.notifications) {
                    state.notifications = state.notifications.map((notification) => {
                        if (notification.id === action.payload.notification.id) {
                            notification.status = action.payload.notification.status;
                        }

                        return notification;
                    });
                }

                state.notificationsCounter = state.newNotifications.length;
            }

            state.notificationToSubmit = null;
            state.isLoading = false;
        });
        builder.addCase(patchNotificationStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });

        builder.addCase(getNewNotifications.fulfilled, (state, action) => {
            if (action.payload.error) {
                const error = action.payload.error;

                state.error = error;
            } else {
                state.newNotifications = action.payload.notifications.filter((notification) => notification.status === STATUS.NEW);
                state.notificationsCounter = state.newNotifications.length;
            }
        });
    },
});

export default notificationsSlice;

export const {
    mutateFilters,
    toSubmitNotification,
} = notificationsSlice.actions;
