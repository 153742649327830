import {ISpecificForm} from '../../../FormField/types';
import {IAddress} from '../types';
import {IProps} from './types';

import React, {
    ChangeEvent,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {OrangeInput} from 'components/Helpers/Inputs';

import {context} from '../../../../Provider';
import {formatFormTitle} from '../../functions';
import {formatValue} from '../functions';

import {
    ERROR,
    FETCH_SUGGESTIONS_DELAY,
    SUGGESTIONS_COUNT,
} from './constants';
import {formatError} from './functions';
import {INTL_DATA} from './intl';

const AddressField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    const [value, setValue] = useState(formatValue(props.field.value));
    const [addresses, setAddresses] = useState<IAddress[]>([]);
    const [error, setError] = useState<ERROR | null>(null);
    const [delayTimeoutId, setDelayTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [suggestions, setSuggestions] = useState<string[] >([]);

    const {getAddresses} = useContext(context);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setValue(value);

        const address = addresses.find((address) => formatValue(address) === value);

        props.onChange(props.field.name, address || null);
        setError(null);
    };
    const onSelect = (value: string) => {
        setValue(value);

        const address = addresses.find((address) => formatValue(address) === value);

        if (address) {
            props.onChange(props.field.name, address);
        } else {
            props.onChange(props.field.name, null);
        }

        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                if (props.field.validation.required) {
                    if (!props.field.value) {
                        setError(ERROR.IS_REQUIRED);

                        return false;
                    }
                }

                setError(null);

                return true;
            },
        };
    });
    useEffect(() => {
        if (delayTimeoutId !== null) {
            clearTimeout(delayTimeoutId);
        }

        const id = setTimeout(async () => {
            const {addresses} = await getAddresses({
                skip: 0,
                limit: SUGGESTIONS_COUNT,
                customerSiteRef: [value],
            });

            const customerSiteRef = addresses?.find((address) => formatValue(address) === value);

            if (customerSiteRef) {
                if (customerSiteRef.id !== props.field.value?.id) {
                    props.onChange(props.field.name, customerSiteRef);
                }
            }

            const suggestions = addresses?.slice(0, SUGGESTIONS_COUNT)
                .map((suggestion) => suggestion.customerSiteRef);

            setSuggestions(suggestions || []);
            setAddresses(addresses || []);
        }, FETCH_SUGGESTIONS_DELAY);

        setDelayTimeoutId(id);

        return () => {
            if (delayTimeoutId === null) {
                return;
            }

            clearTimeout(delayTimeoutId);
        };
    }, [value]);

    return (
        <OrangeInput
            title={formatFormTitle(props.field, props.translation)}
            value={value}
            suggestions={suggestions}
            isError={!!error}
            errorMessage={formatError(error, props.intl)}
            onChange={onChange}
            onChangeValue={onSelect}
            placeholder={props.intl ? props.intl(INTL_DATA.PLACEHOLDER) : ''}
        />
    );
});

export default AddressField;
