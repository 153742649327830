import {IField} from '../../Fields/types';
import {
    ICondition,
    ITrigger,
} from '../types';

import {updater as lockUpdater} from './SpecificDataForm/Lock/functions';
import {updater as unlockUpdater} from './SpecificDataForm/Unlock/functions';

import {FIELD_TYPE} from '../../Fields/constants';
import {DESTINATION_NEW_VALUE} from '../../Fields/FieldsTypes/USMigrationField/constants';
import {TRIGGER_TYPE} from '../constants';

export function updater(trigger: ITrigger, fields: IField[]) {
    switch (trigger.type) {
        case TRIGGER_TYPE.LOCK: {
            return lockUpdater(trigger, fields);
        }
        case TRIGGER_TYPE.UNLOCK: {
            return unlockUpdater(trigger, fields);
        }
    }
}

export function getDefaultCondition(field: IField): ICondition {
    switch (field.type) {
        case FIELD_TYPE.US_MIGRATION: {
            return {
                name: field.name,
                value: {
                    ...field.value,
                    destination: DESTINATION_NEW_VALUE,
                },
            };
        }
        default: {
            return {
                name: field.name,
                value: field.value,
            };
        }
    }
}

export function getDefaultTrigger(type: TRIGGER_TYPE): ITrigger {
    return {
        type,
        conditions: [],
    };
}
