import axios, {AxiosResponse} from 'axios';

import config from 'config';

import {onLogout} from 'instances/auth/functions';
import {getLSCurrentLocale} from 'instances/languages/functions';

import Fetch from 'tools/Fetch';

import {INSTANCE_PATH} from './constants';
import * as NS from './types/fetchResult';
import {IGetByCodeAndVersion, IGetByCodeAndVersionRes, IGetNew} from './types/fetchResult';
import {IToPatch, IToPost,} from './types/instance';

export async function getActive(): Promise<NS.IGetActive> {
    const locale = getLSCurrentLocale();

    return new Fetch<NS.IGetActive>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
        query: {
            locale,
        },
    })
        .on([200], (body) => {
            return {versions: body}
        })
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec();
}

export async function post(body: IToPost): Promise<NS.IPost> {
    return new Fetch<NS.IPost>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return {version: body}
        })
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([400, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getByService(id: string): Promise<NS.IGetByService> {
    return new Fetch<NS.IGetByService>({
        url: `${config.URL}${INSTANCE_PATH}/service/${id}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return {versions: body}
        })
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([400, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getOne(id: string): Promise<NS.IGetOne> {
    const locale = getLSCurrentLocale();

    return new Fetch<NS.IGetOne>({
        url: `${config.URL}${INSTANCE_PATH}/${id}`,
        method: 'GET',
        query: {
            locale,
        },
    })
        .on([200], (body) => {
            return {version: body}
        })
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([400, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getOneByCode(code: string): Promise<NS.IGetByCode> {
    const locale = getLSCurrentLocale();

    return new Fetch<NS.IGetByCode>({
        url: `${config.URL}${INSTANCE_PATH}/code/${code}`,
        method: 'GET',
        query: {
            locale,
        },
    })
        .on([200], (body) => {
            return {serviceVersions: body}
        })
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([400, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function patch(id: string, body: IToPatch): Promise<NS.IPatch> {
    return new Fetch<NS.IPatch>({
        url: `${config.URL}${INSTANCE_PATH}/${id}`,
        method: 'PATCH',
        body,
    })
        .on([200], (body) => {
            return {version: body}
        })
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function setActive(id: string): Promise<NS.ISetActive> {
    return new Fetch<NS.ISetActive>({
        url: `${config.URL}${INSTANCE_PATH}/active/${id}`,
        method: 'POST',
    })
        .onEmptyBody([204])
        .on([401], (body) => {
            onLogout();
            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

const serviceVersionApi = axios.create({
    baseURL: `${config.URL}${INSTANCE_PATH}`,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    }
})

export async function getByCodeAndVersion(code: string, version: string): Promise<IGetByCodeAndVersion> {
    const res: AxiosResponse<IGetByCodeAndVersionRes & { error?: any }> = await serviceVersionApi.get(`/code/${code}/version/${version}`);

    if (res.status !== 200) {
        return {error: res.data.error};
    }
    return {serviceVersion: res.data};
}

export async function getNew(code: string): Promise<IGetNew> {
    const res: AxiosResponse<IGetNew> = await serviceVersionApi.get(`/code/${encodeURIComponent(code)}/new`);

    if (res.status !== 200) {
        return res.data;
    }

    return res.data
}
