import { IState } from './types/redux';

import { IReduxAction } from 'instances/types';

import { ACTION_TYPE } from './constants';


const initState: IState = {
    items: [],
};

export default function(state: IState = initState, action: IReduxAction) {
    switch (action.type) {
        case ACTION_TYPE.PUSH: {
            const {item} = action;
            const {items} = state;

            return {
                ...state,
                items: [...items, item],
            };
        }
        case ACTION_TYPE.REMOVE: {
            const {id} = action;
            const {items} = state;

            return {
                ...state,
                items: items.filter((item) => item.id !== id),
            };
        }
        default: {
            return state;
        }
    }
}
