import {IProps} from './types';

import React, {FC} from 'react';

import {MaterialRadio} from 'components/Helpers/MaterialUI';

import SpecificInput from './SpecificInput';

import {translateByObject} from '../../../../../helpers';
import {DESTINATION_NEW_VALUE} from '../../constants';

const Destination: FC<IProps> = (props) => {
    const isNewValue = props.destination === DESTINATION_NEW_VALUE;

    return (
        <div className={'mt-4'}>
            <div>
                <MaterialRadio
                    label={translateByObject(props.newDestinationItemTitle, props.translation)}
                    value={isNewValue}
                    onChange={() => props.onChange(DESTINATION_NEW_VALUE)}
                />
                <MaterialRadio
                    label={translateByObject(props.existedDestinationItemTitle, props.translation)}
                    value={!isNewValue}
                    onChange={() => props.onChange(null)}
                />
            </div>
            {
                !isNewValue &&
                    <SpecificInput
                        migrateTo={props.migrateTo}
                        destinationTitle={props.destinationTitle}
                        origin={props.origin}
                        destination={props.destination}
                        contracts={props.contracts}
                        translation={props.translation}
                        intl={props.intl}
                        getContractsByContractBill={props.getContractsByContractBill}
                        getUniqueContractsWithoutBills={props.getUniqueContractsWithoutBills}
                        onChange={props.onChange}
                    />
            }
        </div>
    );
};

export default Destination;
