import {
    IField,
    ITrigger,
} from '../types';

import {TRIGGER_TYPE} from '../constants';

import {isMatchedFieldValue} from './index';

export default function(name: string, fields: IField[]): boolean {
    function unlockHandler(trigger: ITrigger) {
        const {conditions} = trigger;

        for (const {name, value} of conditions) {
            const conditionalField = fields.find((field) => field.name === name);

            if (!conditionalField) {
                continue;
            }
            if (!isMatchedFieldValue(conditionalField, value)) {
                return false;
            }
            if (conditionalField.trigger) {
                needToCheck.push(conditionalField);
            }
        }

        return true;
    }

    function lockHandler(trigger: ITrigger) {
        const {conditions} = trigger;

        for (const {name, value} of conditions) {
            const conditionalField = fields.find((field) => field.name === name);

            if (!conditionalField) {
                continue;
            }
            if (!isMatchedFieldValue(conditionalField, value)) {
                return true;
            }
            if (conditionalField.trigger) {
                needToCheck.push(conditionalField);
            }
        }

        return false;
    }

    const field = fields.find((field) => field.name === name);

    if (!field) {
        return true;
    }

    const needToCheck = [field];

    while (needToCheck.length) {
        const field = needToCheck.pop();
        const trigger = field?.trigger;

        if (!trigger) {
            continue;
        }

        switch (trigger.type) {
            case TRIGGER_TYPE.UNLOCK: {
                if (!unlockHandler(trigger)) {
                    return true;
                }
                break;
            }
            case TRIGGER_TYPE.LOCK: {
                if (!lockHandler(trigger)) {
                    return true;
                }
                break;
            }
        }
    }

    return false;
}
