import React from 'react';

export const splitStringToWords = (value: string) => {
    return value.split('\n').map((p, i) =>
        <div key={i}>
            &emsp;
            {p}
        </div>
    );
};
