import classNames from 'classnames';
import React, {
    FC,
    useId,
} from 'react';

import {MaterialTooltip} from 'components/Helpers/MaterialUI';

interface IProps {
    containerClassName?: string;
    className?: string;
    label?: string;
    value: boolean;
    isDisabled?: boolean;
    tooltip?: string;
    iconPosition?: 'start' | 'end';

    onChange?(checked: boolean): void;
}

const BoostedCheckbox: FC<IProps> = ({
    containerClassName,
    className,
    label,
    value,
    isDisabled,
    tooltip,
    iconPosition = 'start',
    onChange,
}) => {
    const id = useId();

    const onCheckboxChange = () => {
        onChange?.(!value);
    };

    return (
        <MaterialTooltip
            text={tooltip}
            placement={'top'}
        >
            <div className={classNames('d-flex justify-content-start align-items-center form-check form-switch', {
                'form-check-reverse': iconPosition === 'end',
                containerClassName,
            })}
            >
                <input
                    className={classNames(
                        'form-check-input cursor-pointer me-1',
                        className
                    )}
                    checked={value}
                    disabled={isDisabled}
                    onChange={onCheckboxChange}
                    type={'checkbox'}
                    role={'switch'}
                    id={id}
                />
                {
                    label &&
                    <label
                        htmlFor={id}
                        className={'form-check-label ms-2 p-0 fs-4'}
                    >
                        {label}
                    </label>
                }
            </div>
        </MaterialTooltip>
    );
};

export default BoostedCheckbox;
