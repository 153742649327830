import {ISpecificForm} from '../../../FormField/types';
import {IProps} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
} from 'react';

import {MaterialCheckbox} from 'components/Helpers/MaterialUI';

import {formatFormTitle} from '../../functions';

const CheckboxField = forwardRef<ISpecificForm, IProps>((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <MaterialCheckbox
            label={formatFormTitle(props.field, props.translation)}
            value={props.field.value}
            isDisabled={props.field.readOnly}
            onChange={(checked) => props.onChange(props.field.name, checked)}
        />
    );
});

export default CheckboxField;
