import {ILink} from '../types';
import {IAuth} from 'instances/auth/types';

import {hasRoutePermission} from 'instances/auth/functions';

import {ROUTE} from 'components/constants';

export function getPermissibleLinks(auth: IAuth, headerLinks: ILink[]): ILink[] {
    return headerLinks
        .filter((link) => {
            if (link.route === ROUTE.BASKET) return false;
            if (link.routes) {
                return link.routes.some((route) => hasRoutePermission(auth, route));
            }
            if (link.route) {
                return hasRoutePermission(auth, link.route);
            }

            return true;
        });
}
