import {ISpecificDataForm} from '../../../TranslationField/types';
import {IProps} from './types';

import classNames from 'classnames';
import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    capitalize,
    translateByIntl,
} from '../../../../helpers';
import {INTL_DATA as GLOBAL_INTL_DATA} from '../../intl';
import {INTL_DATA} from '../intl';

import {ERROR} from './constants';
import {getDefaultTranslation} from './functions';

const USMigrationField = forwardRef<ISpecificDataForm, IProps>((props, ref) => {
    const [translation, setTranslation] = useState(getDefaultTranslation(props.field, props.translation));
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (key: string, value: string) => {
        setError(null);
        setTranslation({
            ...translation,
            [key]: capitalize(value),
        });
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): Record<string, string> {
                return translation;
            },
            validate(): boolean {
                if (Object.values(translation).includes('')) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <>
            <div className={'row mt-2'}>
                <div className={'col col-4'}>
                    <label className={'col-form-label'}>
                        {translateByIntl(props.intl, INTL_DATA.ORIGIN_TITLE, 'Origin title')}
                    </label>
                </div>
                <div className={'col col-4'}>
                    <label className={'col-form-label'}>
                        {props.field.originTitle}
                    </label>
                </div>
                <div className={'col col-4'}>
                    <input
                        className={classNames('form-control', {'is-invalid': !!error})}
                        value={translation[props.field.originTitle]}
                        onChange={(e) => onChange(props.field.originTitle, e.target.value)}
                    />
                    {
                        !!error &&
                        <div className={'text-danger'}>
                            {
                                error === ERROR.IS_REQUIRED &&
                                translateByIntl(props.intl, GLOBAL_INTL_DATA.REQUIRED, 'Required')
                            }
                        </div>
                    }
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={'col col-4'}>
                    <label className={'col-form-label'}>
                        {translateByIntl(props.intl, INTL_DATA.DESTINATION_TITLE, 'Destination title')}
                    </label>
                </div>
                <div className={'col col-4'}>
                    <label className={'col-form-label'}>
                        {props.field.destinationTitle}
                    </label>
                </div>
                <div className={'col col-4'}>
                    <input
                        className={classNames('form-control', {'is-invalid': !!error})}
                        value={translation[props.field.destinationTitle]}
                        onChange={(e) => onChange(props.field.destinationTitle, e.target.value)}
                    />
                    {
                        !!error &&
                        <div className={'text-danger'}>
                            {
                                error === ERROR.IS_REQUIRED &&
                                translateByIntl(props.intl, GLOBAL_INTL_DATA.REQUIRED, 'Required')
                            }
                        </div>
                    }
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={'col col-4'}>
                    <label className={'col-form-label'}>
                        {translateByIntl(props.intl, INTL_DATA.NEW_DESTINATION_ITEM_TITLE, 'New destination item title')}
                    </label>
                </div>
                <div className={'col col-4'}>
                    <label className={'col-form-label'}>
                        {props.field.newDestinationItemTitle}
                    </label>
                </div>
                <div className={'col col-4'}>
                    <input
                        className={classNames('form-control', {'is-invalid': !!error})}
                        value={translation[props.field.newDestinationItemTitle]}
                        onChange={(e) => onChange(props.field.newDestinationItemTitle, e.target.value)}
                    />
                    {
                        !!error &&
                        <div className={'text-danger'}>
                            {
                                error === ERROR.IS_REQUIRED &&
                                translateByIntl(props.intl, GLOBAL_INTL_DATA.REQUIRED, 'Required')
                            }
                        </div>
                    }
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={'col col-4'}>
                    <label className={'col-form-label'}>
                        {translateByIntl(props.intl, INTL_DATA.EXISTED_DESTINATION_ITEM_TITLE, 'Existed destination item title')}
                    </label>
                </div>
                <div className={'col col-4'}>
                    <label className={'col-form-label'}>
                        {props.field.existedDestinationItemTitle}
                    </label>
                </div>
                <div className={'col col-4'}>
                    <input
                        className={classNames('form-control', {'is-invalid': !!error})}
                        value={translation[props.field.existedDestinationItemTitle]}
                        onChange={(e) => onChange(props.field.existedDestinationItemTitle, e.target.value)}
                    />
                    {
                        !!error &&
                        <div className={'text-danger'}>
                            {
                                error === ERROR.IS_REQUIRED &&
                                translateByIntl(props.intl, GLOBAL_INTL_DATA.REQUIRED, 'Required')
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    );
});

export default USMigrationField;
