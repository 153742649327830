import React, {ReactNode} from 'react';

export function formatText(text: string): ReactNode[] {
    return text
        .split('\n')
        .map(
            (text, i) =>
                <div key={i}>
                    {text}
                </div>
        );
}
